import React from "react";
import styles from "./TermsAndConditions.module.css";

const TermsAndConditions = () => {
  return (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.wrapper}>
          <h1 className={styles.title}>TERMS AND CONDITIONS FOR USERS</h1>
          <section className={styles.content}>
            <p>
              Welcome to YOGSEWA's website! We are pleased to provide you with a
              platform that facilitates the booking process for yoga centers,
              hotels and resorts, and trekking agencies offering yoga retreats
              and spiritual journeys within Nepal. Before you start using our
              services, please take the time to read and understand the
              following terms and conditions("Terms"). YOGSEWA offers this
              website, including all information, tools and services available
              from this site to you, the user, conditioned upon your acceptance
              of all terms, conditions, policies and notices stated here.
            </p>
            <p>
              By visiting our site and/ or booking something from us, you engage
              in our “Service” and agree to be bound by the following terms and
              conditions (“Terms of Service”, “Terms”), including those
              additional terms and conditions and policies referenced herein
              and/or available by hyperlink. These Terms of Service apply to all
              users of the site, including without limitation users who are
              browsers, vendors, customers, merchants, and/ or contributors of
              content.
            </p>
            <p>
              Please read these Terms of Service carefully before accessing or
              using our website. By accessing or using any part of the site, you
              agree to be bound by these Terms of Service. If you do not agree
              to all the terms and conditions of this agreement, then you may
              not access the website or use any services. If these Terms of
              Service are considered an offer, acceptance is expressly limited
              to these Terms of Service.
            </p>
            <p>
              Any new features or tools which are added to the website of
              YOGSEWA shall also be subject to the Terms of Service. You can
              review the most current version of the Terms of Service at any
              time on this page. We reserve the right to update, change or
              replace any part of these Terms of Service by posting updates
              and/or changes to our website. It is your responsibility to check
              this page periodically for changes. Your continued use of or
              access to the website following the posting of any changes
              constitutes acceptance of those changes.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>SECTION 1- DEFINITIONS </h1>
            <ul>
              <li>"We," "Us," or "Our" refers to YOGSEWA.</li>
              <li>
                "USER", "YOU" refers to the any individual/entity who place a
                booking through YOGSEWA's website for services mentioned in
                section 1 of subsection 3 below or who goes through YOGSEWA's
                website.{" "}
              </li>
              <li>
                <p>
                  <span>"Services"</span> shall mean the various services
                  offered by the Vendor, which may include, but are not limited
                  to, the following:
                </p>
                <ul>
                  <li>
                    Yoga Classes and Retreats/ Drop-in Yoga sessions: The Vendor
                    may provide yoga classes and retreats for individuals or
                    groups interested in practicing yoga, meditation, and
                    relaxation techniques in serene and conducive environments
                    including accommodation services.
                  </li>
                  <li>
                    Yoga Trek and Tours: The Vendor may organize and conduct
                    trekking tours for Spiritual adventurous individuals and
                    groups, traditional healing offering guided hikes through
                    scenic landscapes, mountains, and trails.
                  </li>
                  <li>
                    Wellness and Spa Services: The Vendor may offer wellness and
                    spa services, providing customers with rejuvenating
                    experiences, such as massages, facials, and holistic
                    treatments.
                  </li>
                  <li>
                    Yoga Equipment and Merchandise: The Vendor may offer a
                    selection of yoga-related equipment, apparel, and
                    merchandise for sale, catering to customers' needs for
                    high-quality yoga mats, clothing, accessories, and more.
                  </li>
                  <li>
                    Adventure and Outdoor Activities: The Vendor may provide
                    various adventure and outdoor yoga activities, such as
                    meditation and scenic outdoor yoga along with rock climbing,
                    kayaking, nature walks, and other thrilling experiences for
                    adventure enthusiasts.
                  </li>
                  <li>
                    Yoga Teacher Training: The Vendor may conduct yoga teacher
                    training programs, equipping aspiring yoga instructors with
                    the knowledge and skills to teach yoga professionally.
                  </li>
                  <li>
                    Wellness Workshops and Events: The Vendor may organize and
                    host wellness workshops, seminars, and events, focusing on
                    various aspects of health, mindfulness, and personal
                    development.{" "}
                  </li>
                </ul>
              </li>
              <li>
                <span>“Vendor”</span> shall mean the Hotels, Resorts, Yoga
                Centers, Trekking agencies listed in the website which provide
                its services through the Online Platform of YOGSEWA.
              </li>
              <li>
                <span>“Bookings”</span> shall mean a book of hotels, resorts,
                centers, travel and trekking agencies listed in the website of
                YOGSEWA who have the yoga related services wherein customer has
                agreed to book upon the terms and conditions and at the Price
                indicated on the online platform of the YOGSEWA.
              </li>
            </ul>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 2- ONLINE MARKETPLACE TERMS{" "}
            </h1>
            <p>
              By agreeing to these Terms of Service, you represent that you are
              at least the age of majority in your state or province of
              residence, or that you are the age of majority in your state or
              province of residence and you have given us your consent to allow
              any of your minor dependents to use this site.
            </p>
            <p>
              You may not use our website for any illegal or unauthorized
              purpose nor may you, in the use of the Service, violate any laws
              in your jurisdiction (including but not limited to copyright
              laws).
            </p>
            <p>
              You must not transmit any worms or viruses or any code of a
              destructive nature.
            </p>
            <p>
              A breach or violation of any of the Terms will result in an
              immediate termination of your Services.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 3- GENERAL CONDITIONS
            </h1>
            <p>
              We reserve the right to refuse service to anyone for any reason at
              any time.
            </p>
            <p>
              You understand that your content (not including credit card
              information), may be transferred unencrypted and involve (a)
              transmissions over various networks; and (b) changes to conform
              and adapt to technical requirements of connecting networks or
              devices. Credit card information is always encrypted during
              transfer over networks.
            </p>
            <p>
              You agree not to reproduce, duplicate, copy, sell, resell or
              exploit any portion of the Service, use of the Service, or access
              to the Service or any contact on the website through which the
              service is provided, without express written permission by us.
            </p>
            <p>
              The headings used in this agreement are included for convenience
              only and will not limit or otherwise affect these Terms.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 4- SERVICES (if applicable)
            </h1>
            <p>
              Services may be available exclusively online through the website.
              These services may have limited quantities and are subject to
              cancellation only according to Vendor's Cancellation Policy.
            </p>
            <p>
              We have made every effort to display as accurately as possible the
              colors and images of Vendor's services that appear on the site. We
              cannot guarantee that your computer monitor's display of any color
              will be accurate.
            </p>
            <p>
              We reserve the right, but are not obligated, to limit bookings of
              listed Services in our site to any person, geographic region or
              jurisdiction. We may exercise this right on a case-by-case basis.
              We reserve the right to limit the quantities of services that we
              offer. All descriptions of Services pricing are subject to change
              at any time without notice, at the sole discretion of us. We
              reserve the right to reject/ decline the bookings at any time. Any
              offer for any Service made on this site is void where prohibited.
            </p>
            <p>
              We do not warrant that the quality of any services or information
              obtained by you will meet your expectations, or that any errors in
              the Service. We are liable only for the communication made during
              the bookings if any until the acceptance of the bookings. After
              bookings has been accepted, we shall not the liable for any
              information made between the Vendors or Customers.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 6- THIRD-PARTY LINKS
            </h1>
            <p>
              The content, services available via our Service include materials
              from third-parties. Third-party links on this site may direct you
              to third-party websites that are not affiliated with us. We are
              not responsible for examining or evaluating the content or
              accuracy and we do not warrant and will not have any liability or
              responsibility for any third-party materials or websites, or for
              any other materials or services of third-parties.
            </p>
            <p>
              We are not liable for any harm or damages related to the purchase
              of services, resources, content, or any other transactions made in
              connection with any third-party websites. Please review carefully
              the third-party's policies and practices and make sure you
              understand them before you engage in any transaction. Complaints,
              claims, concerns, or questions regarding third-party products
              should be directed to the third-party.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 7- PRIVACY AND COOKIES
            </h1>
            <p>
              If you book any services, read our Privacy and Cookie Notice for
              more info on privacy, cookies, and how we might contact you and
              process personal data.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 8- ERRORS, INACCURACIES AND OMISSIONS
            </h1>
            <p>
              Occasionally there may be information on our site or in the
              Service that contains typographical errors, inaccuracies or
              omissions that may relate to Service descriptions, pricing,
              promotions, contents, offers. We reserve the right to correct any
              errors, inaccuracies or omissions, and to change or update
              information or cancel bookings if any information in the Services
              or on any related website is inaccurate at any time without prior
              notice (including after you have submitted your order).
            </p>
            <p>
              We undertake no obligation to update, amend or clarify information
              in the Service or on any related website, including without
              limitation, pricing information, except as required by law. No
              specified update or refresh date applied in the Service or on any
              related website, should be taken to indicate that all information
              in the Service or on any related website has been modified or
              updated.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 9- PAYMENTS ANE PAYOUTS
            </h1>
            <p className={styles.heading}>
              Booking Process throughout our site
            </p>
            <p>
              YOGSEWA acts as a platform to facilitate the booking process
              between Customers and Vendors offering Services listed in the
              site. Customers can browse and select service packages provided by
              Vendors through the YOGSEWA platform. To make a booking, the
              Customer must provide accurate and truthful information during the
              booking process, including personal details and payment
              information. Once the booking is confirmed, the Customer will
              receive a confirmation email with the booking details and payment
              receipt.
            </p>
            <p className={styles.heading}>Transferrable Booking</p>
            <p>
              A Transferable Booking allows the Customer to change their arrival
              date to a future date within 1 year of the original arrival date,
              dependent on the Vendor’s future availability. This request can
              only be made once per booking no later than 7 days before the
              original arrival date.
            </p>
            <p className={styles.heading}>Commissions</p>
            <p>
              When a Customer makes a Booking, a certain percentage of the total
              Booking amount, as agreed upon with the Vendor, will be held as a
              commission by YOGSEWA. The remaining amount after deducting the
              commission will be transferred to the Vendor after the completion
              of the booked services. The agreed commission amount will be
              automatically deducted from the total/partial booking amount paid
              by the Customer at the time of payment processing.
            </p>
            <p>
              The remaining amount after deducting the commission will be
              released to the Vendor after the successful completion of the
              booked services. There may be situations in which we pay out USD
              and it can be converted to a local currency, but this is at our
              discretion. We are not responsible in any way for fluctuations in
              currency exchanges, nor are they set by us.The payment processing
              fee of 3% on the deposit will be deducted when we pay out to the
              Vendors after the arrival of the customers. YOGSEWA has the
              discretion to change the methods of money transfer at any time. We
              will do our best in finding the most efficient way of sending
              money.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 10- REFUNDS AND CANCELLATIONS
            </h1>
            <p className={styles.heading}>Refunds</p>
            <p>
              Customers who wish to cancel their booking are entitled to a
              refund subject to the terms and conditions set forth by the
              respective Vendor.The refund amount will be calculated based on
              the Vendor's cancellation policy and the total booking amount paid
              by the Customer, minus the applicable 10% commission charged by
              Yogsewa. Yogsewa will process the refund on behalf of the Vendor
              and will not be held liable for any delays in the refund process
              due to circumstances beyond our control, such as banking
              procedures or technical issues.
            </p>
            <p className={styles.heading}>Cancellations</p>
            <p>
              Customers must adhere to the cancellation policy specified by the
              Vendor at the time of booking. The cancellation policy will
              dictate the time frame and conditions under which cancellations
              can be made.If a customer wishes to cancel a booking, they must
              contact Yogsewa through the designated channels to initiate the
              cancellation process. Yogsewa will communicate the cancellation
              request to the Vendor and facilitate the refund process as per the
              Vendor's cancellation policy.Refunds for cancellations will be
              subject to the policies set forth by the respective Vendor and
              will be processed by Yogsewa on behalf of the Vendor. Yogsewa will
              not be held liable for any refunds or disputes arising between the
              Customer and the Vendor
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 11- RESPONSIBILITIES OF YOGSEWA, VENDORS AND CUSTOMERS
            </h1>
            <p className={styles.heading}>YOGSEWA</p>
            <p>
              YOGSEWA will make all reasonable efforts to provide accurate and
              up-to-date information about the services offered by the Vendors.
              However, we do not guarantee the accuracy or completeness of such
              information. YOGSEWA will handle the payment processing securely
              and ensure that the commission is appropriately deducted from the
              Booking amount.
            </p>
            <p className={styles.heading}>Customers</p>
            <p>
              Customers must provide accurate and truthful information during
              the Booking process.Customers must adhere to the cancellation and
              refund policies of the respective Vendors as communicated during
              the Booking process.
            </p>
            <p className={styles.heading}>Vendors</p>
            <p>
              Vendors must provide accurate and up-to-date information about the
              services they offer through our platform. Vendors must honor the
              Booking made by Customers and deliver the services as described
              during the Booking process. Vendors must adhere to the
              cancellation and refund policies communicated during the Booking
              process.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 12- Intellectual Property
            </h1>
            <p>
              The Company name and logo, and other Company trademarks, service
              marks, graphics and logos used in connection with the Website are
              trademarks of Company (collectively{" "}
              <span>“Company Trademarks”</span>).Other trademarks, service
              marks, graphics and logos used in connection with the App are the
              trademarks of their respective owners (collectively{" "}
              <span>“Third Party Trademarks”</span>). Company Trademarks and
              Third party Trademarks may not be copied, imitated or used, in
              whole or in part, without the prior written permission of Company
              or the applicable trademark holder. The website and the content
              featured in the website are protected by copyright, trademark,
              patent and other intellectual property and proprietary laws which
              are reserved to Company and its licensors. Customers and Vendors
              are prohibited from using, reproducing, or distributing any
              content from the YOGSEWA platform without prior written consent.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 13- DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
            </h1>
            <p>
              We do not guarantee, represent or warrant that your use of
              Services booked from our site will be uninterrupted, timely,
              secure or error-free.
            </p>
            <p>
              We do not warrant that the results that may be obtained from the
              use of the Services will be accurate or reliable.
            </p>
            <p>
              You agree that from time to time we may remove the Services for
              indefinite periods of time or cancel the Bookings at any time,
              without notice to you.
            </p>
            <p>
              You expressly agree that your use of, or inability to use, the
              service is at your sole risk. The services delivered to you
              through the YOGSEWA are (except as expressly stated by us)
              provided 'as is' and 'as available' for your use, without any
              representation, warranties or conditions of any kind, either
              express or implied, including all implied warranties or conditions
              of merchantability, merchantable quality, fitness for a particular
              purpose, durability, title, and non-infringement.
            </p>
            <p>
              In no case shall YOGSEWA, our directors, officers, employees,
              affiliates, agents, contractors, interns, suppliers, Vendors or
              licensors be liable for any injury, loss, claim, or any direct,
              indirect, incidental, punitive, special, or consequential damages
              of any kind, including, without limitation lost profits, lost
              revenue, lost savings, loss of data, replacement costs, or any
              similar damages, whether based in contract, tort (including
              negligence), strict liability or otherwise, arising from your use
              of any of the Services using this site, or for any other claim
              related in any way to your use of the Services,including, but not
              limited to, any errors or omissions in any content, or any loss or
              damage of any kind incurred as a result of the use of the service
              or any content (or product) posted, transmitted, or otherwise made
              available via the site, even if advised of their possibility.
              Because some states or jurisdictions do not allow the exclusion or
              the limitation of liability for consequential or incidental
              damages, in such states or jurisdictions, our liability shall be
              limited to the maximum extent permitted by law.
            </p>
            <p>
              To the extent permitted by mandatory consumer law, we’ll only be
              liable for costs you incur as a direct result of a failure on our
              behalf. This means, to the extent permitted by law, we won’t be
              liable for any:
            </p>
            <ul>
              <li>indirect loss or indirect damage</li>
              <li>inaccurate information about a Vendor</li>
              <li>
                product, service, or action of a Vendor or other business
                partner
              </li>
              <li>
                mistake in an email address, phone number, or credit card number
                (unless it’s our fault)
              </li>
              <li>force majeure or event beyond our control.</li>
            </ul>
            <p>
              any costs incurred due to cancellation of events from either party
            </p>
            <ul>
              <li>
                If you breach these Terms and/or the Vendor’s terms, to the
                extent permitted by law:
              </li>
              <ul>
                <li>
                  we won’t be liable for any costs you incur as a result, and
                </li>
                <li>you won’t be entitled to any refund.</li>
              </ul>
              <li>
                To the extent permitted by law, the most that we or any Vendor
                will be liable for (whether for one or a series of connected
                events) is the cost of your Booking, as set out in your
                confirmation email.
              </li>
              <li>
                Nothing in these terms will limit our (or the Vendor’s)
                liability in respect of our (or their) own (i) negligence that
                leads to death or personal injury or (ii) fraud or fraudulent
                misrepresentation.
              </li>
              <li>
                We don’t make any promises about Vendors’ services apart from
                what we expressly state in these Terms. Making the right
                choice(s) is entirely your responsibility.
              </li>
              <li>
                Just to be clear, nothing in these Terms will entitle any third
                party other than the Vendor to anything.
              </li>
              <li>
                You may be protected by mandatory consumer protection laws and
                regulations, which guarantee you rights that no company’s terms
                can overrule. In that case, our liability is determined not just
                by these Terms, but also by any applicable consumer protection
                laws and regulations.
              </li>
            </ul>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>SECTION 14- WARRANTY</h1>
            <p>
              Except as expressly provided in these terms and cinditions ,
              YOGSEWA makes no warranties of any kind, whether express, implied,
              statutory or otherwise, including but not limited to any
              warranties of merchantability or fitness for a particular purpose,
              quality, safety, integrity or accuracy of the Platform.
              Furthermore, YOGSEWA does not guarantee that the Platform shall
              lead to a successful yoga experience or to the achievement of any
              skills, levels and/or experiences related to a particular travel
              experience.
            </p>
            <p>
              The conclusion and performance of bookings, including any payment
              obligation resulting therefrom, as well as the cancellation of
              bookings is the sole responsibility of you and a vendor. You
              indemnify YOGSEWA from all claims submitted by a vendor or a third
              party that relates to bookings made by you with that specific
              vendor, as well as to the payment of such bookings
            </p>
            <p>
              {" "}
              However, YOGSEWA will provide assistance to you to the best of its
              abilities in case any issues arise between you and an Organizer in
              order to resolve those issues.
            </p>
            <p>
              Notwithstanding the abovementioned, YOGSEWA guarantees that you
              get a refund of what you have paid
            </p>
            <ul>
              <li>
                when you cancel your bookings in conformity with the vednor’s
                terms and conditions regarding cancellation, and{" "}
              </li>
              <li>
                when your bookings doesn’t comply with the services that was
                offered by Vendor
              </li>
            </ul>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 15- Changes to This Terms and Conditions
            </h1>
            <p>
              We may update our Terms and Conditions from time to time. Thus,
              you are advised to review this page periodically for any changes.
              We will notify you of any changes by posting new terms and
              conditions on this page.
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>
              SECTION 16- GOVERNING LAW AND DISPUTE SETTLEMENT
            </h1>
            <p>
              These App Terms shall be governed by the laws of Nepal and the
              parties submit to the exclusive jurisdiction of the courts of
              Nepal to resolve any dispute between them arising under or in
              connection with these website Terms.
            </p>
            <p>
              If any provision (or part of a provision) of these website Terms
              is found by any court or administrative body of competent
              jurisdiction to be invalid, unenforceable or illegal, such term,
              condition or provision will to that extent be severed from the
              remaining terms, conditions and provisions which will continue to
              be valid to the fullest extent permitted by law.
            </p>
            <p>
              By using the YOGSEWA platform, you acknowledge that you have read
              and understood these terms and agree to be bound by them. If you
              do not agree with any part of these terms, you may not use our
              platform for Booking services
            </p>
          </section>
          <section className={styles.content}>
            <h1 className={styles.sectionTitle}>SECTION 17- Contact Us</h1>
            <p>
              If you have any questions regarding our Webiste, you can email us
              at <a href="mailto:support@yogsewa.com">support@yogsewa.com</a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
