import { FileImageOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";

// Converting bytes into base64
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function FileUpload({
  fileList,
  setFileList,
  title = "Choose your file",
  maxUpload,
  setError,
}) {
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (setError) {
      setError("");
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.type === "application/pdf") {
      // Download the PDF file
      const link = document.createElement("a");
      link.href = file.url || file.preview;
      link.download = file.name;
      link.click();
    } else {
      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    }
  };
  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const beforeUpload = (file) => {
    return false;
  };

  return (
    <div>
      <Upload.Dragger
        action="http://localhost:3000/"
        fileList={fileList}
        onChange={onChange}
        onPreview={handlePreview}
        beforeUpload={beforeUpload}
        multiple={false}
        maxCount={maxUpload}
      >
        <div className={styles.imageContainer}>
          <FileImageOutlined style={{ fontSize: "2rem" }} />
          <div className={styles.titleContainer}>
            <h1 className={styles.imageHeading}>{title}</h1>
            <p className={styles.subHeading}>Drag and Drop or Choose File</p>
          </div>
        </div>
      </Upload.Dragger>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {previewImage && (
          <img
            alt="example"
            style={{ width: "100%" }}
            src={previewImage}
            loading="lazy"
          />
        )}
        {!previewImage && <p>This file type is not supported for preview.</p>}
      </Modal>
    </div>
  );
}

export default FileUpload;
