import React, { useState } from "react";
import SearchCategory from "../../sections/SearchListing/SearchCategory/SearchCategory";
import { SearchFilter } from "../../sections/SearchListing/SearchFilter";
import styles from "./SearchListing.module.css";

function SearchListing() {
  const [categoryDetails, setCategoryDetails] = useState();
  const [faq, setFaq] = useState();
  return (
    <div className={styles.container}>
      <SearchFilter setCategoryDetails={setCategoryDetails} setFaq={setFaq} />
      <SearchCategory categoryDetails={categoryDetails} faq={faq} />
    </div>
  );
}

export default SearchListing;
