import { baseRequest } from "./base";

const getUserReviews = async (page, signal) => {
  var response = await baseRequest({
    url: `/api/user-reviews?page=${page}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getUserToReview = async (signal) => {
  var response = await baseRequest({
    url: `/api/user-to-review`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const rateBooking = async (id, data) => {
  var response = await baseRequest({
    url: `/api/bookings/${id}/rate`,
    method: "POST",
    body: data,
  });

  return response;
};

export { getUserReviews, getUserToReview, rateBooking };
