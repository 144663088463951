import { baseRequest } from "./base";

const updateProviderProfile = async (body) => {
  var data = new FormData();
  Object.keys(body).forEach((key) => {
    if (body[key] !== null && body[key] !== undefined) {
      data.append(key, body[key]);
    }
  });
  data.append("photo", data.photo);

  var response = await baseRequest({
    url: "/api/update-provider-profile",
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });

  return response;
};

const updateProvider = async (body) => {
  var data = new FormData();
  Object.keys(body).forEach((key) => {
    if (body[key] !== null && body[key] !== undefined) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: "/api/update-provider",
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });

  return response;
};

const updateProviderBusiness = async (body) => {
  var data = new FormData();
  Object.keys(body).forEach((key) => {
    if (body[key] !== null && body[key] !== undefined) {
      data.append(key, body[key]);
    }
  });
  var response = await baseRequest({
    url: `/api/update-provider-business`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });

  return response;
};

const getProvider = async (signal) => {
  var response = await baseRequest({
    url: `/api/get-provider`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const updateProviderPayment = async (body) => {
  var data = new FormData();
  Object.keys(body).forEach((key) => {
    if (body[key] !== null && body[key] !== undefined) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: "/api/provider-profile/payment",
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });

  return response;
};

export {
  updateProvider,
  getProvider,
  updateProviderProfile,
  updateProviderBusiness,
  updateProviderPayment,
};
