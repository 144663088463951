import { Button, DatePicker, Form, Input, Select } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../../App";
import {
  getProvider,
  updateProviderBusiness,
} from "../../../services/api/admin";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import styles from "./AdminBusiness.module.css";
import countries from "./country.json";

const AdminInformation = ({ refresh, setRefresh }) => {
  const [form] = Form.useForm();

  const [date, setDate] = useState(moment.now());
  const [sameAsAbove, setSameAsAbove] = useState(false);

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const [data, setData] = useState({
    business_name: "",
    website: "",
    business_registration_number: "",
    country_id: "",
    street_number: "",
    zip_code: "",
    city: "",
    province: "",
    contact_f_name: "",
    contact_l_name: "",
    contact_country_code: "",
    contact_phone: "",
    contact_email: "",
    owner_f_name: "",
    owner_l_name: "",
    owner_dob: "",
    owner_country_code: "",
    owner_phone: "",
    owner_email: "",
  });
  const [loading, setLoading] = useState();

  const handleCancel = () => {
    setRefresh((p) => !p);
  };

  const handleSubmit = () => {
    setLoading(true);
    let formData = { ...data };
    if (sameAsAbove) {
      formData.owner_f_name = data.contact_f_name;
      formData.owner_l_name = data.contact_l_name;
      formData.owner_email = data.contact_email;
      formData.owner_country_code = data.contact_country_code;
      formData.owner_phone = data.contact_phone;
    }
    updateProviderBusiness(formData)
      .then((res) => {
        if (res.status === "Success") {
          notifySuccess(res.message);
          setRefresh((p) => !p);
          setLoading(false);
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  useEffect(() => {
    getProvider()
      .then((res) => {
        if (res.status === "Success") {
          form.setFieldsValue({
            Business: res.data.user.service_provider.business_name,
            Website: res.data.user.service_provider.website,
            Registration:
              res.data.user.service_provider.business_registration_number,
            CountryId: res.data.user.service_provider.country_id,
            Street: res.data.user.service_provider.street_number,
            ZipCode: res.data.user.service_provider.zip_code,
            City: res.data.user.service_provider.city,
            Province: res.data.user.service_provider.province,
            ContactFirst: res.data.user.service_provider.contact_f_name,
            ContactLast: res.data.user.service_provider.contact_l_name,
            CountryCode: res.data.user.service_provider.contact_country_code,
            ContactPhone: res.data.user.service_provider.contact_phone,
            ContactEmail: res.data.user.service_provider.contact_email,
            OwnerFname: res.data.user.service_provider.owner_f_name,
            OwnerLname: res.data.user.service_provider.owner_l_name,
            OwnerDob:
              res.data.user.service_provider.owner_dob &&
              moment(res.data.user.service_provider.owner_dob),
            OwnerCountryCode: res.data.user.service_provider.owner_country_code,
            OwnerPhone: res.data.user.service_provider.owner_phone,
            OwnerEmail: res.data.user.service_provider.owner_email,
          });

          setData({
            business_name: res.data.user.service_provider.business_name,
            website: res.data.user.service_provider.website,
            business_registration_number:
              res.data.user.service_provider.business_registration_number,
            country_id: res.data.user.service_provider.country_id,
            street_number: res.data.user.service_provider.street_number,
            zip_code: res.data.user.service_provider.zip_code,
            city: res.data.user.service_provider.city,
            province: res.data.user.service_provider.province,
            contact_f_name: res.data.user.service_provider.contact_f_name,
            contact_l_name: res.data.user.service_provider.contact_l_name,
            contact_name: res.data.user.service_provider.contact_name,
            contact_country_code:
              res.data.user.service_provider.contact_country_code,
            contact_phone: res.data.user.service_provider.contact_phone,
            contact_email: res.data.user.service_provider.contact_email,
            owner_f_name: res.data.user.service_provider.owner_f_name,
            owner_l_name: res.data.user.service_provider.owner_l_name,
            owner_dob: res.data.user.service_provider.owner_dob,
            owner_country_code:
              res.data.user.service_provider.owner_country_code,
            owner_phone: res.data.user.service_provider.owner_phone,
            owner_email: res.data.user.service_provider.owner_email,
          });
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
  }, [refresh]);
  return (
    <Form
      className={styles.formContainer}
      name="basic"
      autoComplete="off"
      layout="vertical"
      onSubmit={(e) => e.preventDefault()}
      onFinish={handleSubmit}
      form={form}
    >
      <div className={styles.section}>
        <div className={styles.sectionTitle}>Business information</div>
        <div className={styles.sectionContent}>
          <div className={styles.content}>
            <div className={styles.label}>Business Name</div>
          </div>
          <Form.Item
            name="Business"
            rules={[{ required: true, message: "Please Enter Business Name" }]}
          >
            <Input
              className={styles.input}
              value={data.business_name}
              onChange={(e) => {
                setData({ ...data, business_name: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>Website</div>
          </div>
          <Form.Item
            name="Website"
            rules={[{ required: true, message: "Please Website URL" }]}
          >
            <Input
              className={styles.input}
              value={data.website}
              onChange={(e) => {
                setData({ ...data, website: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>Business registration number</div>
          </div>
          <Form.Item
            name="Registration"
            rules={[
              {
                required: true,
                message: "Please Enter Business Registration Number",
              },
            ]}
          >
            <Input
              className={styles.input}
              value={data.business_registration_number}
              onChange={(e) => {
                setData({
                  ...data,
                  business_registration_number: e.target.value,
                });
              }}
            />
          </Form.Item>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Business registration address</div>
        <div className={styles.sectionContent}>
          <div className={styles.content}>
            <div className={styles.label}>Street and house number</div>
          </div>
          <Form.Item
            name="Street"
            rules={[
              {
                required: true,
                message: "Please Enter Street or House Number",
              },
            ]}
          >
            <Input
              className={styles.input}
              value={data.street_number}
              onChange={(e) => {
                setData({ ...data, street_number: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>City</div>
          </div>
          <Form.Item
            name="City"
            rules={[{ required: true, message: "Please Enter City" }]}
          >
            <Input
              className={styles.input}
              value={data.city}
              onChange={(e) => {
                setData({ ...data, city: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>Zip/Postal code</div>
          </div>
          <Form.Item
            name="ZipCode"
            rules={[{ required: true, message: "Please Enter The Zip Code" }]}
          >
            <Input
              className={styles.input}
              value={data.zip_code}
              onChange={(e) => {
                setData({ ...data, zip_code: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>State/Province</div>
          </div>
          <Form.Item
            name="Province"
            rules={[
              { required: true, message: "Please Enter The State/Province" },
            ]}
          >
            <Input
              className={styles.input}
              value={data.province}
              onChange={(e) => {
                setData({ ...data, province: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>Country</div>
          </div>
          <Form.Item
            name="CountryId"
            rules={[{ required: true, message: "Please Enter The Country" }]}
          >
            <Select
              placeholder="Select country"
              onChange={(val) => {
                setData({ ...data, country_id: val });
              }}
              filterOption={(input, option) => {
                const optionValue = String(option.props.value);
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  optionValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              showSearch
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {countries?.countries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Contact person</div>
        <div className={styles.sectionContent}>
          <div className={styles.content}>
            <div className={styles.label}>First Name</div>
          </div>
          <Form.Item
            name="ContactFirst"
            rules={[
              { required: true, message: "Please Enter Your First Name" },
            ]}
          >
            <Input
              className={styles.input}
              value={data.contact_f_name}
              onChange={(e) => {
                setData({ ...data, contact_f_name: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>Last Name</div>
          </div>
          <Form.Item
            name="ContactLast"
            rules={[{ required: true, message: "Please Enter Your Last Name" }]}
          >
            <Input
              className={styles.input}
              value={data.contact_l_name}
              onChange={(e) => {
                setData({ ...data, contact_l_name: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>
              Email{" "}
              {/* <Tag icon={<CheckCircleOutlined />} color="success">
                verified
              </Tag>
              <Tag icon={<CloseCircleOutlined />} color="error">
                not verified
              </Tag> */}
            </div>
          </div>
          <Form.Item
            name="ContactEmail"
            rules={[{ required: true, message: "Please Enter The Email" }]}
          >
            <Input
              type="email"
              className={styles.input}
              value={data.contact_email}
              onChange={(e) => {
                setData({ ...data, contact_email: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>
              Mobile Number{" "}
              {/* <Tag icon={<CheckCircleOutlined />} color="success">
                verified
              </Tag>
              <Tag icon={<CloseCircleOutlined />} color="error">
                not verified
              </Tag> */}
            </div>
          </div>
          <div className={styles.formGroupItem}>
            {" "}
            <Form.Item
              name="CountryCode"
              className={styles.formItem}
              rules={[
                {
                  required: data?.contact_phone,
                  message: `Please enter your country code`,
                },
              ]}
            >
              <Select
                placeholder="Select country code"
                onChange={(val) => {
                  setData({ ...data, contact_country_code: val });
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                showSearch
              >
                {countries?.countries.map((country, index) => (
                  <Option key={index} value={country.code}>
                    {country.name + " " + country.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="ContactPhone"
              rules={[
                {
                  required: true,
                  message: `Please enter your phone number`,
                },
                {
                  validator: (_, value) => {
                    if (!value || /^\d+$/.test(value)) {
                      // The input is either empty or contains only numeric characters
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Please enter only numeric characters"
                      );
                    }
                  },
                },
              ]}
            >
              <Input
                value={data.contact_phone}
                onChange={(e) => {
                  setData({ ...data, contact_phone: e.target.value });
                }}
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Business Owner</div>
        <div className={styles.sectionContent}>
          {/* <Form.Item>
            <Checkbox onChange={(e) => setSameAsAbove(e.target.checked)}>
              Same as contact person
            </Checkbox>
          </Form.Item> */}
          <div className={styles.content}>
            <div className={styles.label}>First Name</div>
          </div>
          <Form.Item
            name="OwnerFname"
            rules={[
              { required: true, message: "Please Enter The Owner First Name" },
            ]}
          >
            <Input
              disabled={sameAsAbove}
              className={styles.input}
              value={data.owner_f_name}
              onChange={(e) => {
                setData({ ...data, owner_f_name: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>Last Name</div>
          </div>
          <Form.Item
            name="OwnerLname"
            rules={[
              { required: true, message: "Please Enter The Owner Last Name" },
            ]}
          >
            <Input
              disabled={sameAsAbove}
              className={styles.input}
              value={data.owner_l_name}
              onChange={(e) => {
                setData({ ...data, owner_l_name: e.target.value });
              }}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>Date of Birth</div>
          </div>
          <Form.Item
            name="OwnerDob"
            rules={[
              { required: true, message: "Please Select Your Date of Birth" },
            ]}
          >
            <DatePicker
              valuePropName="OwnerDob"
              value={date}
              onChange={(date, dateString) => {
                setData({ ...data, owner_dob: dateString });
                setDate(date);
              }}
              disabledDate={disabledDate}
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>
              Email{" "}
              {/* <Tag icon={<CheckCircleOutlined />} color="success">
                verified
              </Tag>
              <Tag icon={<CloseCircleOutlined />} color="error">
                not verified
              </Tag> */}
            </div>
          </div>
          <Form.Item
            name="OwnerEmail"
            rules={[
              { required: true, message: "Please Enter The Owner Email" },
            ]}
          >
            <Input
              disabled={sameAsAbove}
              className={styles.input}
              value={data.owner_email}
              onChange={(e) => {
                setData({ ...data, owner_email: e.target.value });
              }}
              type="email"
            />
          </Form.Item>
          <div className={styles.content}>
            <div className={styles.label}>
              Mobile Number{" "}
              {/* <Tag icon={<CheckCircleOutlined />} color="success">
                verified
              </Tag>
              <Tag icon={<CloseCircleOutlined />} color="error">
                not verified
              </Tag> */}
            </div>
          </div>
          <div className={styles.formGroupItem}>
            {" "}
            <Form.Item
              name="OwnerCountryCode"
              className={styles.formItem}
              rules={[
                {
                  required: data?.owner_phone,
                  message: `Please enter your country code`,
                },
              ]}
            >
              <Select
                placeholder="Select country code"
                disabled={sameAsAbove}
                onChange={(val) => {
                  setData({ ...data, owner_country_code: val });
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {countries?.countries.map((country, index) => (
                  <Option key={index} value={country.code}>
                    {country.name + " " + country.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="OwnerPhone"
              rules={[
                {
                  required: true,
                  message: `Please enter your phone number`,
                },
                {
                  validator: (_, value) => {
                    if (!value || /^\d+$/.test(value)) {
                      // The input is either empty or contains only numeric characters
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Please enter only numeric characters"
                      );
                    }
                  },
                },
              ]}
            >
              <Input
                value={data.owner_phone}
                disabled={sameAsAbove}
                onChange={(e) => {
                  setData({ ...data, owner_phone: e.target.value });
                }}
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          type="primary"
          className={styles.saveButton}
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>
        <Button
          className={styles.cancelButton}
          type="danger"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default AdminInformation;
