import cx from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Landing.module.css";
import ReactTyped from "react-typed";

function Landing() {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/search");
  };
  return (
    <section className={styles.hero}>
      {/* <img src="/images/hero.png" className={styles.image} /> */}
      <div className="container">
        <div className={styles.gridContainer}>
          <div className={styles.content}>
            <img
              src="/images/yogaa1.webp"
              alt="yoga"
              className={cx(styles.contentImage, styles.contentFirst)}
              loading="lazy"
            />
            <img
              src="/images/yogaa2.webp"
              alt="yoga"
              className={cx(styles.contentImage, styles.contentSecond)}
              loading="lazy"
            />
            <img
              src="/images/yogaa3.webp"
              alt="yoga"
              className={cx(styles.contentImage, styles.contentThird)}
              loading="lazy"
            />
            <img
              src="/images/yogaa4.webp"
              alt="yoga"
              className={cx(styles.contentImage, styles.contentFourth)}
              loading="lazy"
            />
            <img
              src="/images/yogaa5.webp"
              alt="yoga"
              className={cx(styles.contentImage, styles.contentFifth)}
              loading="lazy"
            />
            <h4 className={styles.title}>
              YogSewa Connects You To... <br />
              <ReactTyped
                strings={[
                  "...Transformative Spiritual Journeys.",
                  "...Enlightening Yoga Experiences.",
                  "...Nepal's Mystical Landscape.",
                ]}
                typeSpeed={50}
                backSpeed={50}
                loop
              />
              <span className={styles.body}></span>
            </h4>
            <h5 className={styles.subtitle}>
              Discover the soulful fusion of yoga and nature in Nepal's
              enchanting landscapes. YogSewa bridges the gap, providing access
              to transformative retreats, enlightening yoga practices, and
              Nepal's mystical heritage.
            </h5>
            <button
              className={cx("light-btn", styles.btn)}
              onClick={handleSubmit}
            >
              Discover More
              <div className={styles.icon}>
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div>
            </button>
          </div>
          {/* <div className={styles.imageContainer}>
            <img
              src="/images/yog1.png"
              alt="yoga"
              className={cx(styles.image, styles.first)}
              loading="lazy"
            />
            <img
              src="/images/yog2.png"
              alt="yoga"
              className={cx(styles.image, styles.second)}
              loading="lazy"
            />
            <img
              src="/images/yog3.png"
              alt="yoga"
              className={cx(styles.image, styles.third)}
              loading="lazy"
            />
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Landing;
