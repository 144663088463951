import { Button, Result } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError } from "../../App";
import { getBookingById } from "../../services/api/bookings";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";

function BookingResult() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchBookings = (id, signal) => {
    setLoading(true);
    getBookingById(id, signal)
      .then((res) => {
        setLoading(false);
        setData(res.data.booking);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchBookings(id, abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, [id]);

  return loading ? (
    <div style={{ minHeight: "100vh" }}>Loading...</div>
  ) : (
    <div
      style={{
        marginBottom: "10px",
        minHeight: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="success"
        title={`${data?.program_title} has been booked sucessfully!`}
        subTitle={`Unique ID: ${data?.unique_id}`}
        extra={[
          <Button type="primary" onClick={() => navigate("/")}>
            Home
          </Button>,
          <Button onClick={() => navigate("/search/")}>Explore Again</Button>,
        ]}
      />
    </div>
  );
}

export default BookingResult;
