import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import React from "react";
import Slider from "react-slick";
import styles from "./ReviewSlider.module.css";
import cx from "classnames";

function ReviewSlider({ cardData }) {
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: cardData.length < 2 ? 1 : cardData.length < 3 ? 2 : 3,
    speed: 500,
    rows: 1,
    arrows: false,
    dots: true,
    dotsClass: "button__bar",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          className: "center",
          infinite: true,
          centerPadding: "60px",
          slidesToShow: cardData.length < 2 ? 1 : 2,
          speed: 500,
          rows: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          className: "center",
          infinite: true,
          centerPadding: "60px",
          slidesToShow: 1,
          speed: 500,
          rows: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings} className={styles.cardContainer}>
        {cardData.map((item, index) => (
          <div key={index}>
            <div
              className={cx(
                styles.card,
                cardData.length === 1 ? styles.singleCard : null
              )}
              key={index}
            >
              <div className={styles.content} title={item.description}>
                {item.description}
              </div>
              <div className={styles.profile}>
                <Avatar size={48} icon={<UserOutlined />} />
                <p className={styles.name}>
                  {item.user?.f_name + " " + item.user?.l_name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ReviewSlider;
