import React, { useEffect, useState } from "react";
import { PackageCard } from "../../../components/PackageCard";
import styles from "./ProgramPackages.module.css";
import { getPublicPrograms } from "../../../services/api/programs";
import PackageSkeleton from "../../../components/PackageCard/PackageSkeleton";

function ProgramPackages() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortCont = new AbortController();
    setLoading(true);
    getPublicPrograms(1, abortCont.signal)
      .then((res) => {
        setData(res.data.yoga_programs.slice(0, 3));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    return () => {
      abortCont.abort();
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className="container">
        <h3 className={styles.title}>Similar Packages</h3>
        <div className={styles.cardContainer}>
          {loading ? (
            <>
              <PackageSkeleton /> <PackageSkeleton /> <PackageSkeleton />
              <PackageSkeleton />
            </>
          ) : (
            data.map((item, index) => <PackageCard item={item} key={index} programDetails={true} />)
          )}
        </div>
      </div>
    </div>
  );
}

export default ProgramPackages;
