import React from "react";
import styles from "../Program.module.css";
import ImageUpload from "../../../components/ImageUpload";
import { Button, Col, Form, Input, Row } from "antd";
import TextEditor from "../../../components/TextEditor";
import { CloseOutlined } from "@ant-design/icons";

function InstructorAdd({
  addInstructor,
  data,
  removeInstructor,
  handleInstructorNameChange,
  handleInstructorDescriptionChange,
  handleInstructorDesignationChange,
  handleInstructorPhotoChange,
  errors,
  setImageSizeError,
}) {
  const [form] = Form.useForm();

  return (
    <div className={styles.container}>
      <Form
        form={form}
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onSubmit={(e) => e.preventDefault()}
        id="program-form"
      >
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item wrapperCol={{ span: 16 }} label="Instructors">
              <Button size="medium" onClick={addInstructor}>
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.accomodationGrid}>
          {data.instructor.map((item, index) => {
            return (
              <div className={styles.accomodationContainer} key={index}>
                <div className={styles.removeBtnContainer}>
                  <Button
                    size="small"
                    icon={<CloseOutlined />}
                    shape="circle"
                    onClick={() => {
                      removeInstructor(index);
                    }}
                    className={styles.removeBtn}
                  />
                </div>
                <div className={styles.accGrid}>
                  <div className={styles.accomodationLabel}>Name</div>
                  <Col
                    key={index}
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                  >
                    <Input
                      placeholder="Name"
                      value={item.name}
                      onChange={(e) => {
                        handleInstructorNameChange(e, index);
                      }}
                    />
                    {errors[`instructor.${index}.name`] && (
                      <div className={styles.fieldError}>
                        {errors[`instructor.${index}.name`][0]}
                      </div>
                    )}
                  </Col>
                </div>

                <Col
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                >
                  <ImageUpload
                    fileList={item.photo}
                    photoWidth="280"
                    photoHeight="300"
                    setFileList={(list) => {
                      handleInstructorPhotoChange(list, index);
                    }}
                    multiple={false}
                    maxUpload={1}
                    setError={(msg) => {
                      setImageSizeError(msg);
                    }}
                  />
                  {errors[`instructor.${index}.photo`] && (
                    <div className={styles.fieldError}>
                      {errors[`instructor.${index}.photo`][0]}
                    </div>
                  )}
                </Col>
                <div className={styles.accGrid} style={{ marginTop: "1em" }}>
                  <div className={styles.accomodationLabel}>Designation</div>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                  >
                    <Input
                      type="tex"
                      placeholder="Eg: Yoga Teacher, Wellness Manager"
                      value={item.designation}
                      onChange={(e) => {
                        handleInstructorDesignationChange(e, index);
                      }}
                    />
                    {errors[`instructor.${index}.designation`] && (
                      <div className={styles.fieldError}>
                        {errors[`instructor.${index}.designation`][0]}
                      </div>
                    )}
                  </Col>
                </div>
                <div className={styles.accGrid}>
                  <div className={styles.accomodationLabel}>Description</div>{" "}
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                  >
                    {/*onChange is used instead of setValue because form item's children need value and onChange as props
description: value depends on text editor(before setValue={setDescription}) 
*/}

                    <TextEditor
                      value={item.description}
                      onChange={(val) => {
                        handleInstructorDescriptionChange(val, index);
                      }}
                    />
                    {errors[`instructor.${index}.description`] && (
                      <div className={styles.fieldError}>
                        {errors[`instructor.${index}.description`][0]}
                      </div>
                    )}
                  </Col>
                </div>
              </div>
            );
          })}
        </div>
      </Form>
    </div>
  );
}

export default InstructorAdd;
