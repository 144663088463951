import React from "react";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.wrapper}>
          <h2 className={styles.title}>
            YOGSEWA INTERNATIONAL PRIVATE LIMITED
          </h2>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>
              PRIVACY POLICY: CUSTOMER AND VENDOR
            </h2>
            <div className={styles.content}>
              <p>
                Welcome to website ("Site") Yogsewa.com operated by Yogsewa
                International Pvt. Ltd (“YOGSEWA”). We YOGSEWA respect user's
                privacy and want to protect user's personal information. By
                using YOGSEWA, user consent to the practices described in this
                policy. YOGSEWA provides platform for conscious travel
                experiences, opens doors to the world of possibilities for yoga
                and spiritual journey. YOGSEWA is elevating the various business
                and connecting with like-minded spiritual travelers seeking
                profound connections and enlightening discoveries. YOGSEWA is
                committed to safeguard the privacy of{" "}
                <span>"Confidential Information”</span>
                (information that directly or indirectly identifies individuals
                who may be clients, staff, agents, vendors or others inside or
                outside the business related to YOGSEWA). This page is used to
                inform Site users using our Privacy Policy ("Policy") of our
                policies with the collection, use, and disclosure of Personal
                Information if anyone decides to use our service or has in the
                past. This Policy explains:
              </p>
              <ul>
                <li>
                  how we collect, use and (under certain conditions) disclose
                  user's Personal Information;{" "}
                </li>
                <li>
                  the steps we have taken to secure user's Personal Information;
                  and
                </li>
                <li>
                  user's options regarding the collection, use and disclosure of
                  user's Personal Information.{" "}
                </li>
              </ul>
              <p>
                By using the Services, user acknowledge that we will store, use
                and otherwise process user's information in Nepal, where we are
                located. Except as otherwise noted in this Policy, YOGSEWA, is a
                data controller responsible for the processing of user's
                Personal Information as described in this Policy. This Policy is
                intended to showcase the YOGSEWA's data protection Policy.
                Please do not send us any of user's information if user do not
                want it to be used in the ways described in the Policy.
              </p>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>COLLECTION OF INFORMATION</h2>
            <div className={styles.content}>
              <p>
                YOGSEWA uses cookies, log files, pixel tags, local storage
                objects and other tracking technologies to automatically collect
                information when users access or use the services or visit our
                Site, such as an IP address, general location information,
                domain name, page views, a date/time stamp, browser type, device
                type, device ID, Internet Service Provider (“ISP”),
                referring/exit URLs, operating system, language, click stream
                data, and other information about the links clicked, features
                used, size of files uploaded, streamed or deleted, and similar
                device and usage information. We refer to this automatically
                collected information as <span>“Device Information”</span>.
              </p>
              <p>
                Additionally, when user contact us by filling out the contact
                forms through the Site, we collect user's name, address, phone
                number, email address and preferences. This also includes user's
                submission of certain material or request for further services.
                When user book for the services, by default user's username will
                be displayed along with user's request. We refer to this
                automatically collected information as{" "}
                <span>“Personal Information”</span>.
              </p>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>
              CATEGORIZED INFORMATION TO BE COLLECTED
            </h2>
            <div className={styles.content}>
              <p>User's Information shall be collected as follows:</p>
              <ul>
                <li>
                  <span>Registration:</span> This Policy covers all user's
                  personal and identifiable information that we collect from
                  user. When user register as a customer, user will provide
                  certain personal information such as user's name, gender,
                  title, date of birth, address, location, contact number, email
                  address, payment details, payment account details, preferences
                  for services etc. We offer user the choice to update or
                  supplement user's account information and preferences at any
                  time.
                </li>
                <li>
                  <span>Services Information:</span>: YOGSEWA shall not sell
                  user's personal and identifiable information. We automatically
                  collect and store information about user's use of the
                  services, such as user's engagement with the particular
                  content including editorial, advertisements, sponsored
                  informational programs from our advertisers, whether user
                  opened a particular email, clicked on a link, user's search
                  queries, and how often and when user engage with the services.
                  We also collect information about user's device when user
                  access the Services including IP address, browser information
                  (including URL), cookie information and advertising ID (which
                  is a unique user-resettable identification number for
                  advertising associated with a mobile device), all of which may
                  be associated with information we have about user's use of the
                  services. We use cookies and other tracking technologies, such
                  as pixels, tags, web beacons and software development kits, to
                  collect the information about the use of the services and
                  user's device.
                </li>
                <li>
                  <span>Sell and/or Payment Information:</span>: We collect
                  payment details including: credit card, bank accounts or
                  similar payment information. We also collect information
                  including the number of bookings done.
                </li>
                <li>
                  <span>Information Forms:</span>: We may offer user the
                  opportunity to receive additional information or services from
                  us time and again. If user wish to receive such information or
                  services, user may be asked to provide personal information to
                  fulfill the request. YOGSEWA has no control over what others
                  may do with user's information.
                </li>
              </ul>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>USE OF INFORMATION</h2>
            <div className={styles.content}>
              <ul>
                <li>
                  <span>Provision of the Services:</span> We may use the
                  information collected about user to provide the Services which
                  includes to facilitate and process user's bookings for yoga
                  retreats and spiritual journeys, to personalize user's
                  experience and provide relevant recommendations based on
                  user's preferences, to send user updates of services,
                  newsletters, and promotional materials (if user's have
                  subscribed), to analyze website usage and trends for improving
                  our services and user experience.
                </li>
                <li>
                  <span>Email Communications:</span>: By booking from YOGSEWA,
                  user agree to use of user's registration information and other
                  information that we collect from the user to send email
                  communications, including details of services and other yoga
                  related information from the Vendors.
                </li>
                <li>
                  <span>Account Management:</span>: We may use user's
                  information to administer user's account, respond to user's
                  inquiries, fulfill user's requests and send user
                  administrative communications about the Services.
                </li>
                <li>
                  <span>Investigations:</span>: We may use user's information to
                  detect, investigate and defend against fraudulent or unlawful
                  activity and violations of the YOGSEWA Terms of Service.
                </li>
              </ul>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>SHARING USER'S INFORMATION</h2>
            <div className={styles.content}>
              <p>
                We value user's trust and do not sell user's personal
                information to third parties. However, to facilitate user's
                bookings and provide the best possible service, we may share
                user's information with the following parties:
              </p>
              <ul>
                <li>
                  Yoga centers, hotels and resorts, and trekking agencies
                  <span>(Collectively known as Vendor)</span> for the purpose of
                  making reservations and managing user's journey.
                </li>
                <li>
                  Trusted third-party service providers who assist us in
                  operating our website and providing our services.
                </li>
                <li>Third-Party Links</li>
                <li>
                  If it is legally required or if it is demanded by any
                  regulatory body, court, tribunal, law enforcement agencies,
                  government bodies;
                </li>
                <li>
                  If it is necessary to respond to claims asserted against us;
                </li>
                <li>To our professional advisers, auditors and insurers; or</li>
                <li>
                  To the ones with whom we have our co-promotional arrangements
                </li>
              </ul>
              <p>
                Our website may contain links to third-party websites, such as
                our partner yoga centers, hotels, and trekking agencies{" "}
                <span>(The Vendor)</span>. Users agree that YOGSEWA shall not be
                responsible for any damage/loss caused against the users after
                sharing information to vendors and third parties. Please note
                that we are also not responsible for the privacy practices of
                these third-party sites. We recommend reviewing their policies
                when user visit them.
              </p>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>PROTECTION OF MINORS</h2>
            <div className={styles.content}>
              <p>
                To respect the privacy of minors, information belonging to
                anyone below eighteen (18) years of age is not collected via the
                medium of internet services.
              </p>
              <p>
                However, YOGSEWA collects personal information on minors in the
                context of one of the purposes mentioned in the Policy, as
                required by the situation/circumstance, we will only do so with
                the appropriate consent or as otherwise permitted under
                applicable laws.
              </p>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>COOKIES</h2>
            <div className={styles.content}>
              <p>
                Cookies are often used in conjunction with other internet
                technologies, such as web beacons, to understand behavior on the
                internet. For example, YOGSEWA uses cookies in conjunction with
                web beacons, which are small sections of code that we may place
                in our internet services to understand the number of unique
                users that have visited a specific page. Although YOGSEWA uses
                the services of third parties in connection with these cookies,
                we do not allow the third-party service provider to use Personal
                Information about the users of our internet services. 
              </p>
              <p>
                User may reset user's browser to refuse all cookies or to allow
                user's browser to alert user when a cookie is set, however,
                certain parts of the internet services may function differently
                and not as well. Unless user have adjusted user's browser
                setting so that it will refuse cookies, our internet services
                will issue cookies when user log in to YOGSEWA's Internet
                Services. For more information about how to manage user's
                cookies preferences, user should use the “help” menu of user's
                web browser or explore the customer support sections of user's
                web browser.
              </p>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>USER'S RIGHTS </h2>
            <div className={styles.content}>
              <p>
                Under{" "}
                <span>
                  Chapter 10 Collection & Protection of Personal Information
                </span>
                of Privacy Act, 2075 (2018), user have the right to access
                Personal Information YOGSEWA holds about user and to ask that
                user's Personal Information be corrected, updated, or modified.
                If user would like to exercise this right, please contact us
                through the contact information below. 
              </p>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>AGE ELIGIBILITY   </h2>
            <div className={styles.content}>
              <p>
                The Site is not intended for individuals under the age of
                eighteen (18), of unsound mind or with intellectual disability. 
              </p>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>CHANGES</h2>
            <div className={styles.content}>
              <p>
                We may update this Policy from time to time in order to reflect,
                for example, changes to our services or for other operational,
                legal or regulatory reasons
              </p>
            </div>
          </section>
          <section className={styles.section}>
            <h2 className={styles.sectionTitle}>CONTACT US </h2>
            <div className={styles.content}>
              <p>
                For more information about our privacy practices, if user have
                questions, or if user would like to make a complaint, please
                contact us by e-mail at{" "}
                <a href="mailto:support@yogsewa.com">support@yogsewa.com</a> or
                call us at 
                <a href="tel:+977 9811568734">+977 9811568734</a>.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
