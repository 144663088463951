import { Col, Row } from "antd";
import cx from "classnames";
import React, { useContext } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../contexts/UserContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NotFound from "../../pages/404/NotFound";
import styles from "./UserLayout.module.css";
import routes from "./userRoutes";

export const notifySuccess = (message) => toast.success(message);
export const notifyError = (message) => toast.error(message);

const sidebarData = [
  {
    title: "Manage My Account",
    path: "/user/profile/",
    subnav: [
      // { title: "My Profile", path: "/user/profile/" },
      { title: "Change Password", path: "/user/change-password/" },
    ],
  },
  {
    title: "My Bookings",
    path: "/user/bookings/",
  },
  {
    title: "My Reviews",
    path: "/user/reviews/",
  },
  {
    title: "My Wishlist",
    path: "/user/wishlist/",
  },
];

const UserLayout = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();

  const { userData, setUserData } = useContext(UserContext);
  const navigate = useNavigate();
  return userData.role !== "user" ? (
    <Navigate to="/login/" />
  ) : (
    <>
      <div className={styles.container}>
        <div className="container">
          <>
            <Row gutter={[40, 40]}>
              <Col
                span={width < 1200 ? 0 : 5}
                style={{ borderRight: "1px dashed #cccbcb" }}
              >
                <div className={styles.sidebar}>
                  {sidebarData.map((sidebarItem, index) => (
                    <div
                      className={cx(
                        styles.sidebarTitle,
                        location.pathname === sidebarItem.path
                          ? styles.active
                          : null
                      )}
                      key={index}
                    >
                      <div
                        onClick={() => {
                          if (sidebarItem.path) {
                            navigate(sidebarItem.path);
                          }
                        }}
                      >
                        {sidebarItem.title}
                      </div>
                      {sidebarItem.subnav &&
                        sidebarItem.subnav.map((subnavItem, index) => (
                          <div
                            className={cx(
                              styles.subnav,
                              location.pathname === subnavItem.path
                                ? styles.active
                                : null
                            )}
                            key={index}
                            onClick={() => navigate(subnavItem.path)}
                          >
                            {subnavItem.title}
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </Col>
              <Col
                span={width < 1200 ? 24 : 19}
                style={{ paddingInlineStart: "40px" }}
              >
                <Routes>
                  {routes.map((route, index) => {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        element={route.element}
                      />
                    );
                  })}
                  <Route path="*" element={<Navigate to="/404" replace />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </>
  );
};

export default UserLayout;
