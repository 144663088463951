import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { notifyError } from "../../App";
import Loading from "../../components/Loading";
import ProgramAccomodation from "../../sections/ProgramDetails/ProgramAccomodation/ProgramAccomodation";
import { ProgramDescription } from "../../sections/ProgramDetails/ProgramDescription";
import { ProgramLocation } from "../../sections/ProgramDetails/ProgramLocation";
import { ProgramPackages } from "../../sections/ProgramDetails/ProgramPackages";
import { ProgramReviews } from "../../sections/ProgramDetails/ProgramReviews";
import { ProgramSeason } from "../../sections/ProgramDetails/ProgramSeason";
import { getProgramById } from "../../services/api/programs";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import styles from "./ProgramDetails.module.css";

function ProgramDetails() {
  const [data, setData] = useState({
    media: [],
    accomodation: [],
  });
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [breadcrumb, setBreadcrumb] = useState([]);

  useEffect(() => {
    const abortCont = new AbortController();
    setLoading(true);
    getProgramById(id, abortCont.signal)
      .then((res) => {
        setData(res.data.program);
        const newBreadcrumb = [
          { title: "Home", to: "/" },
          {
            title: res?.data?.breadcrumbs?.category?.title,
            to: `/search?category=${res?.data?.breadcrumbs?.category?.slug}`,
          },
        ];

        if (res?.data?.breadcrumbs?.location?.title) {
          newBreadcrumb.push({
            title: res?.data?.breadcrumbs?.location?.title,
            to: `/search?location=${res?.data?.breadcrumbs?.location?.slug}`,
          });
        }

        newBreadcrumb.push({ title: res?.data?.program?.title, to: null });

        setBreadcrumb(newBreadcrumb);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      abortCont.abort();
    };
  }, []);

  return (
    <div className={styles.container}>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <ProgramDescription data={data} breadcrumb={breadcrumb} />
          {data.accomodation.length > 0 && (
            <ProgramAccomodation data={data.accomodation} />
          )}
          <ProgramLocation
            area={data.area}
            location={data.location}
            latitude={data.latitude}
            longitude={data.longitude}
          />
          <ProgramSeason data={data} />
          {data.reviews?.length > 0 && <ProgramReviews data={data} />}
          <ProgramPackages />
        </div>
      )}
    </div>
  );
}

export default ProgramDetails;
