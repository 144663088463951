import { Tabs } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import AccomodationEdit from "./AccomodationEdit/AccomodationEdit";
import DetailsEdit from "./DetailsEdit/DetailsEdit";
import InstructorEdit from "./InstructorEdit/InstructorEdit";
import LocationEdit from "./LocationEdit/LocationEdit";
import styles from "./ProgramsEdit.module.css";

const tabItems = [
  { title: "Program Details", key: "1" },
  { title: "Location & Schedule", key: "2" },
  { title: "Accomodation", key: "3" },
  { title: "Instructor", key: "4" },
];

function ProgramsEdit() {
  const location = useLocation();
  var item = location.state.program;
  var id = location.state.id;
  return (
    <div>
      <Tabs defaultActiveKey="1" type="card">
        {tabItems.map((tab) => (
          <Tabs.TabPane tab={tab.title} key={tab.key}>
            <div className={styles.container}>
              {tab.key === "1" ? (
                <DetailsEdit item={item} id={id} />
              ) : tab.key === "2" ? (
                <LocationEdit item={item} id={id} />
              ) : tab.key === "3" ? (
                <AccomodationEdit item={item} id={id} />
              ) : (
                <InstructorEdit item={item} id={id} />
              )}
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default ProgramsEdit;
