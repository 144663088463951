import { Rate } from "antd";
import React from "react";
import { ReviewSlider } from "../../../components/ReviewSlider";
import styles from "./ProgramReviews.module.css";

function ProgramReviews({ data }) {
  return (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.titleContainer}>
          <div className={styles.title}>Reviews</div>
          <div className={styles.reviews}>
            <Rate defaultValue={5} style={{ color: "#FFB020" }} />
            <div className={styles.subtitle}>
              {" "}
              {data.reviews_avg_rating}({data.reviews_count} Reviews)
            </div>
          </div>
          <ReviewSlider cardData={data?.reviews} />
        </div>
      </div>
    </div>
  );
}

export default ProgramReviews;
