import { Button, Table } from "antd";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { BiDollar } from "react-icons/bi";
import { notifyError } from "../../../../App";
import { getPaymentToReceive } from "../../../../services/api/payments";
import { axiosErrorHandler } from "../../../../utils/errorHandler/axiosErrorHandler";
import CustomPagination from "../../../components/Pagination";
import styles from "./AdminReceive.module.css";
import ReceiveModal from "./ReceiveModal";

function AdminReceive() {
  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Payment id",
      dataIndex: "payment_id",
      key: "payment_id",
      width: 100,
    },
    {
      title: "Transaction Charge ($)",
      dataIndex: "transaction_charge",
      key: "transaction_charge",
      width: 100,
    },

    {
      title: "Provider Income ($)",
      dataIndex: "provider_income",
      key: "provider_income",
      width: 100,
    },
    {
      title: "Booking Date",
      dataIndex: "booking_date",
      key: "booking_date",
      render: (obj, item) => <>{item.booking.date}</>,
      width: 100,
    },

    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text, item) => (
        <Button
          type="primary"
          onClick={() => {
            showModal();
            setId(item.id);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const total = useRef(null);
  const pageSize = useRef(null);
  const [id, setId] = useState();
  const [modalData, setModalData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setModalData(data);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchPayments = async (signal) => {
    setLoading(true);
    await getPaymentToReceive(page, signal)
      .then((res) => {
        if (res.status === "Success") {
          total.current = res.data.total;
          pageSize.current = res.data.page_size;
          setData(res.data.payments);
          setStats(res.data.stats);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchPayments(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, [page]);
  return (
    <div>
      <div className={styles.gridContainer}>
        <div className={cx(styles.card, styles.amount)}>
          <div className={cx(styles.iconContainer, styles.amountIconContainer)}>
            <BiDollar className={styles.icon} />
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Amount</div>
            <div className={styles.subtitle}>
              $ {stats?.total_amount?.toFixed(2)}
            </div>
          </div>
        </div>
        <div className={cx(styles.card, styles.commission)}>
          <div
            className={cx(styles.iconContainer, styles.commissionIconContainer)}
          >
            <BiDollar className={styles.icon} />
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Commission</div>
            <div className={styles.subtitle}>
              ${stats?.total_commission?.toFixed(2)}
            </div>
          </div>
        </div>
        <div className={cx(styles.card, styles.income)}>
          <div className={cx(styles.iconContainer, styles.incomeIconContainer)}>
            <BiDollar className={styles.icon} />
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Income</div>
            <div className={styles.subtitle}>
              ${stats?.total_provider_income?.toFixed(2)}
            </div>
          </div>
        </div>
        <div className={cx(styles.card, styles.transaction)}>
          <div
            className={cx(
              styles.iconContainer,
              styles.transactionIconContainer
            )}
          >
            <BiDollar className={styles.icon} />
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Transaction</div>
            <div className={styles.subtitle}>
              ${stats?.total_transaction_charge?.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <Table
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={data.map((payment, index) => {
          return {
            ...payment,
            sn: (page - 1) * pageSize.current + index + 1,
            key: index,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        total={total.current}
        pageSize={pageSize.current}
      />
      <ReceiveModal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        data={modalData}
        id={id}
      />
    </div>
  );
}

export default AdminReceive;
