import { baseRequest } from "./base";

const getBusinessAllDocuments = async (signal) => {
  var response = await baseRequest({
    url: `/api/provider-document`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getBusinessDocuments = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/provider-document/${id}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const updateBusinessDocuments = async (id, uploadData) => {
  var body = { ...uploadData };
  var data = new FormData();
  body.photos.forEach((element) => {
    data.append("media[]", element);
  });

  delete body["photos"];

  body.deletePhotoIds.forEach((element) => {
    data.append("delete_media[]", element);
  });
  delete body["delete_media"];

  Object.keys(body).forEach((key) => {
    if (body[key] && body[key].length !== 0) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: `/api/provider-document/${id}`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });
  return response;
};

const addBusinessDocuments = async (uploadData) => {
  var body = { ...uploadData };
  var data = new FormData();
  data.append("document", body.document);

  delete body["document"];

  Object.keys(body).forEach((key) => {
    if (body[key] && body[key].length !== 0) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: `/api/provider-document`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });
  return response;
};

const deleteDocuments = async (id) => {
  var response = await baseRequest({
    url: `/api/provider-document/${id}`,
    method: "DELETE",
  });
  return response;
};

export {
  getBusinessDocuments,
  addBusinessDocuments,
  deleteDocuments,
  updateBusinessDocuments,
  getBusinessAllDocuments,
};
