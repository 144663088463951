import { useState } from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import * as Showdown from "showdown";
import React from "react";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

const TextEditor = ({ value, onChange }) => {
  const [selectedTab, setSelectedTab] = useState("write");
  return (
    <ReactMde
      value={value}
      onChange={onChange}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      toolbarCommands={[
        ["header", "bold", "italic", "strikethrough"],
        ["link", "quote", "code"],
        ["unordered-list", "ordered-list"],
      ]}
      generateMarkdownPreview={(markdown) =>
        Promise.resolve(converter.makeHtml(markdown))
      }
    />
  );
};

export default TextEditor;
