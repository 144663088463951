import React from "react";
import styles from "./Hero.module.css";
import cx from "classnames";

function Hero({ title = "Hero", subtitle }) {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img className={styles.img} alt="hero" src="/images/hero.png" loading="lazy" />
      </div>

      <div className={styles.headerContainer}>
        <h2 className={cx("section-title", styles.title)}>{title}</h2>
        <p className={cx("section-subtitle", styles.subtitle)}>{subtitle && subtitle}</p>
      </div>
    </div>
  );
}

export default Hero;
