import React from "react";
import styles from "./BlogCard.module.css";
import { Skeleton } from "antd";

function BlogSkeleton() {
  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <Skeleton.Image
          className={styles.image}
          style={{ width: "100%", minHeight: "198px" }}
          active={true}
        ></Skeleton.Image>
      </div>
      <div className={styles.content}>
        <Skeleton />
      </div>
    </div>
  );
}

export default BlogSkeleton;
