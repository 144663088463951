import { baseRequest } from "./base";

const phoneRegex = /^9\d{9}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const login = async (data) => {
  let loginData;
  if (phoneRegex.test(data.email)) {
    loginData = {
      phone: data.email,
      password: data.password,
    };
  } else if (emailRegex.test(data.email)) {
    loginData = {
      email: data.email,
      password: data.password,
    };
  }

  var response = await baseRequest({
    url: "/api/login",
    method: "POST",
    body: loginData,
  });

  return response;
};

const loginFacebook = async (data) => {
  let loginData = {
    token: data.token,
  };

  var response = await baseRequest({
    url: "/api/login-facebook",
    method: "POST",
    body: loginData,
  });

  return response;
};

const loginGoogle = async (data) => {
  let loginData = {
    token: data.token,
  };

  var response = await baseRequest({
    url: "/api/login-google",
    method: "POST",
    body: loginData,
  });

  return response;
};
const logout = async () => {
  var response = await baseRequest({ url: "/api/logout", method: "GET" });
  return response;
};

const isLogged = async (signal) => {
  var response = await baseRequest({
    url: "/api/logged-in",
    method: "GET",
    signal: signal,
  });
  return response;
};

const forgotPassword = async (data) => {
  var response = await baseRequest({
    url: "/api/forgot-password",
    method: "POST",
    body: data,
  });
  return response;
};

const register = async (data) => {
  var response = await baseRequest({
    url: "/api/signup",
    method: "POST",
    body: data,
  });
  return response;
};

const providerRegister = async (data) => {
  var response = await baseRequest({
    url: "/api/signup-provider",
    method: "POST",
    body: data,
  });
  return response;
};

const passwordlessLogin = async (token, signal) => {
  var response = await baseRequest({
    url: `/api/passwordless-login/${token}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const sendLoginLink = async (email) => {
  var response = await baseRequest({
    url: `/api/login-link`,
    method: "POST",
    body: {
      email: email,
    },
  });

  return response;
};

const changePassword = async (data) => {
  var response = await baseRequest({
    url: `/api/change-password`,
    method: "POST",
    body: data,
  });
  return response;
};
export {
  login,
  logout,
  forgotPassword,
  isLogged,
  changePassword,
  register,
  loginFacebook,
  loginGoogle,
  passwordlessLogin,
  sendLoginLink,
  providerRegister,
};
