import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import React from "react";
import "react-calendar/dist/Calendar.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import App from "./App";
import "./index.css";

const webappRootId = "root";
const webappRootElement = document.getElementById(webappRootId);

if (!webappRootElement) {
  // eslint-disable-next-line no-console
  console.error(`Could not find html element with id '${webappRootId}'`);
} else {
  ReactDOM.createRoot(webappRootElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
