import { baseRequest } from "./base";

const sendContact = async (data) => {
  var response = await baseRequest({
    url: "/api/contact-us",
    method: "POST",
    body: data,
  });

  return response;
};

export { sendContact };
