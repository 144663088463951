import { clearCookie } from "../cookie/cookies";

const axiosErrorHandler = (error, notifyError) => {
  if (error.message === "canceled") return;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.data.message === "Unauthenticated.") {
      clearCookie("login-token");
      notifyError("Session Expired!! Please log in again.");
      return;
    }

    if (error.response.status === 404) {
      window.location = "/not-found/";
      return;
    }

    if (error.response.status === 410) {
      window.location = "/resource-not-found/"; 
      return;
    }
    //notify error message from backend
    notifyError(error.response.data.message);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log(error.request);
    notifyError(error.message);
  } else {
    // Something happened in setting up the request that triggered an Error
    notifyError(error.message);
  }

  console.log(error);
};

export { axiosErrorHandler };
