import { Segmented } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./AdminBusiness.module.css";
import AdminInformation from "./AdminInformation";
import AdminRegistration from "./AdminRegistration";

function AdminBusiness() {
  const [segmentedValue, setSegmentedValue] = useState("Business Information");
  const [refresh, setRefresh] = useState(false);

  const handleSegmentedChange = (value) => {
    // Update the segmented value and trigger the necessary actions
    setSegmentedValue(value);
    localStorage.setItem("business", value); // Store in localStorage
    // Perform any other actions based on the new value
  };

  useEffect(() => {
    // Retrieve value from localStorage
    const storedValue = localStorage.getItem("business");
    // Set the stored value as initial state if it exists
    if (storedValue) {
      setSegmentedValue(storedValue);
    }
  }, []);

  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Business Information</div>
          <div className={styles.subtitle}>
            We respect your privacy and treat your personal data with care.
          </div>
          <Segmented
            options={["Business Information", "Business Documents"]}
            value={segmentedValue}
            onChange={handleSegmentedChange}
            className={styles.segment}
          ></Segmented>
          {/* Render the component below based on the segmented value */}
          {segmentedValue === "Business Information" && (
            <AdminInformation refresh={refresh} setRefresh={setRefresh} />
          )}
          {segmentedValue === "Business Documents" && (
            <AdminRegistration refresh={refresh} setRefresh={setRefresh} />
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminBusiness;
