import { baseRequest } from "./base";

const bookProgram = async (id, body) => {
  var response = await baseRequest({
    url: `/api/yoga-programs/${id}/book`,
    method: "POST",
    body: body,
  });

  return response;
};

const getUserBookings = async (signal, status) => {
  var response = await baseRequest({
    url: `/api/user-booking-list?status=${status}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getProviderBookings = async (
  page,
  signal,
  search,
  status,
  start_date,
  end_date
) => {
  var response = await baseRequest({
    url: `/api/provider-booking-list?page=${page}&search=${search}&status=${status}&start_date=${start_date}&end_date=${end_date}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const markAttendedBookings = async (body) => {
  var response = await baseRequest({
    url: `/api/booking/mark-attended`,
    method: "POST",
    body: body,
  });

  return response;
};

const markNotAttendedBookings = async (body) => {
  var response = await baseRequest({
    url: `/api/booking/mark-not-attended`,
    method: "POST",
    body: body,
  });

  return response;
};

const getProgramBookings = async (id, page, search, signal) => {
  var response = await baseRequest({
    url: `/api/yoga-programs/${id}/bookings?page=${page}&search=${search}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getBookingById = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/bookings/${id}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getBookingDates = async (year, month, signal) => {
  var response = await baseRequest({
    url: `/api/booking-dates?year=${year}&month=${month}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getBookingCounts = async (signal) => {
  var response = await baseRequest({
    url: `/api/booking-counts`,
    method: "GET",
    signal: signal,
  });

  return response;
};

export {
  bookProgram,
  getUserBookings,
  getProviderBookings,
  getProgramBookings,
  getBookingById,
  getBookingDates,
  getBookingCounts,
  markAttendedBookings,
  markNotAttendedBookings,
};
