import {
  ArrowLeftOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Input, Radio, Space } from "antd";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./Customization.module.css";
import { useLocation } from "react-router-dom";
import { addCustomization } from "../../services/api/customization";
import { notifyError, notifySuccess } from "../../App";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const steps = [
  {
    current: 0,
    number: 1,
  },
  {
    current: 1,
    number: 2,
  },
  {
    current: 2,
    number: 3,
  },
  {
    current: 3,
    number: 4,
  },
  {
    current: 4,
    number: 5,
  },
  {
    current: 5,
    number: 6,
  },
  {
    current: 6,
    number: 7,
  },
  {
    current: 7,
    number: 8,
  },
  {
    current: 8,
    number: 9,
  },
];

const questions = {
  question1: "How would you describe your yoga experience?",
  question2: "What is your primary goal for your yoga practice on this trip?",
  question3: "What type of environment do you prefer for your yoga practice?",
  question4: "How much time do you have for this journey?",
  question5: "What type of accommodation do you prefer?",
  question6: "Do you have any specific dietary needs or preferences?",
  question7: "Do you prefer group classes or one-on-one sessions?",
  question8: "Any specific requests or comments?",
};

const Customization = () => {
  const [contentKey, setContentKey] = useState(Date.now());
  const [current, setCurrent] = useState(0);
  const [error, setError] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  const location = useLocation();

  const [data, setData] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    question8: "",
    full_name: "",
    email: "",
  });

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = () => {
    let err = {};
    if (current === 0) {
      if (data.question1.length === 0) {
        err["question1"] = ["The field is required"];
      }
    }
    if (current === 1) {
      if (data.question2.length === 0) {
        err["question2"] = ["The field is required"];
      }
    }
    if (current === 2) {
      if (data.question3.length === 0) {
        err["question3"] = ["The field is required"];
      }
    }
    if (current === 3) {
      if (data.question4.length === 0) {
        err["question4"] = ["The field is required"];
      }
    }
    if (current === 4) {
      if (data.question5.length === 0) {
        err["question5"] = ["The field is required"];
      }
    }
    if (current === 5) {
      if (data.question6.length === 0) {
        err["question6"] = ["The field is required"];
      }
    }
    if (current === 6) {
      if (data.question7.length === 0) {
        err["question7"] = ["The field is required"];
      }
    }
    if (current === 7) {
      if (data.question8.length === 0) {
        err["question8"] = ["The field is required"];
      }
    }

    if (current === 8) {
      if (data.full_name.length === 0) {
        err["full_name"] = ["The field is required"];
      }
      if (data.email.length === 0) {
        err["email"] = ["The field is required"];
      } else if (!emailRegex.test(data.email)) {
        err["email"] = ["Invalid email format"];
      }
    }

    setError(err);

    if (Object.keys(err).length > 0) {
      return;
    }
    setCurrent(current + 1);
    if (current < steps.length - 1) {
      return;
    }

    if (Object.keys(err).length === 0) {
      let formData = {
        name: data.full_name,
        email: data.email,
        description: {
          [questions.question1]: data.question1,
          [questions.question2]: data.question2,
          [questions.question3]: data.question3,
          [questions.question4]: data.question4,
          [questions.question5]: data.question5,
          [questions.question6]: data.question6,
          [questions.question7]: data.question7,
          [questions.question8]: data.question8,
        },
      };

      addCustomization(formData)
        .then((res) => {
          if (res.status === "Success") {
            setSuccessMessage(
              "Thank you for submitting your information! We appreciate your time and effort. We'll get back to you soon."
            );
          }
        })
        .catch((err) => axiosErrorHandler(err, notifyError));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [current, location]);

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.imageContainer}>
        <img
          src="/images/personalized/himalayas.jpg"
          alt="recommendations"
          className={styles.image}
          loading="lazy"
        />
      </div> */}
      <div className={styles.container}>
        <div className="container">
          <div className={styles.card}>
            <div className={styles.cardTitle}>
              Customize Your Yoga Experience
            </div>
            <div className={styles.steps}>
              <ul className={styles.programSteps}>
                {steps.map((item) => (
                  <li
                    className={cx(
                      styles.stepItem,
                      item.current === current && styles.stepItemActive
                    )}
                    key={item.current}
                  >
                    <div className={styles.stepIconContainer}>
                      <div
                        className={cx(
                          styles.stepIcon,
                          item.current === current && styles.stepIconActive
                        )}
                      >
                        <div
                          className={cx(
                            styles.stepNumber,
                            item.current === current && styles.stepNumberActive
                          )}
                        >
                          {item.number}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {current === 0 ? (
              <div className={styles.questions}>
                <div className={styles.questionTitle}>
                  {questions.question1}
                  {error.question1 && (
                    <span className={styles.fieldError}>{error.question1}</span>
                  )}
                </div>
                <img
                  src="/images/personalized/1st.png"
                  alt="icon"
                  loading="lazy"
                  width={35}
                  height={35}
                  style={{ margin: "5px auto" }}
                />
                <Radio.Group
                  onChange={(e) =>
                    setData({ ...data, question1: e.target.value })
                  }
                  value={data.question1}
                >
                  <Space direction="vertical">
                    <Radio value={"Beginner"}>
                      Beginner (Just starting out or less than 6 months of
                      practice)
                    </Radio>
                    <Radio value={"Intermediate"}>
                      Intermediate (6 months to 2 years of practice)
                    </Radio>
                    <Radio value={"Advanced"}>
                      Advanced (More than 2 years of practice)
                    </Radio>
                    <Radio value={"Yoga Teacher"}>
                      Yoga Teacher (Certified and teaching yoga)
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
            ) : current === 1 ? (
              <div className={styles.questions}>
                <div className={styles.questionTitle}>
                  {questions.question2}{" "}
                  {error.question2 && (
                    <span className={styles.fieldError}>{error.question2}</span>
                  )}
                </div>
                <img
                  src="/images/personalized/2nd.png"
                  alt="icon"
                  loading="lazy"
                  width={35}
                  height={35}
                  style={{ margin: "5px auto" }}
                />
                <Radio.Group
                  onChange={(e) =>
                    setData({ ...data, question2: e.target.value })
                  }
                  value={data.question2}
                >
                  <Space direction="vertical">
                    <Radio value={"Physical Fitness"}>
                      Physical Fitness (Improve strength, flexibility, and
                      balance)
                    </Radio>
                    <Radio value={"Stress Relief"}>
                      Stress Relief (Calm the mind and release tension)
                    </Radio>
                    <Radio value={"Spiritual Journey"}>
                      Spiritual Journey (Seek self-discovery and enlightenment)
                    </Radio>
                    <Radio value={"Deepen Practice"}>
                      Deepen Practice (Expand understanding of yoga philosophies
                      and techniques)
                    </Radio>
                    <Radio value={"Teacher Training"}>
                      Teacher Training (Gain certification to teach yoga)
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
            ) : current === 2 ? (
              <div className={styles.questions}>
                <div className={styles.questionTitle}>
                  {questions.question3}
                  {error.question3 && (
                    <span className={styles.fieldError}>{error.question3}</span>
                  )}
                </div>
                <img
                  src="/images/personalized/3rd.png"
                  alt="icon"
                  loading="lazy"
                  width={35}
                  height={35}
                  style={{ margin: "5px auto" }}
                />
                <div className={styles.content}>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question3 === "Mountain" && styles.contentCardActive
                    )}
                    onClick={() => setData({ ...data, question3: "Mountain" })}
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/mountain.png"
                        alt="mountain"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>
                      Mountain (Experience the tranquility of Nepal's peaks)
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question3 === "Jungle" && styles.contentCardActive
                    )}
                    onClick={() => setData({ ...data, question3: "Jungle" })}
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/jungle.png"
                        alt="jungle"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>
                      Jungle (Connect with nature in the lush forests)
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question3 === "City" && styles.contentCardActive
                    )}
                    onClick={() => setData({ ...data, question3: "City" })}
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/city.png"
                        alt="city"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>
                      City (Practice amidst the vibrant city life)
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question3 === "Spiritual Sites" &&
                        styles.contentCardActive
                    )}
                    onClick={() =>
                      setData({ ...data, question3: "Spiritual Sites" })
                    }
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/spiritual.png"
                        alt="spiritual"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>
                      Spiritual Sites (Seek inspiration at sacred sites)
                    </div>
                  </div>
                </div>
              </div>
            ) : current === 3 ? (
              <div className={styles.questions}>
                <div className={styles.questionTitle}>
                  {questions.question4}
                  {error.question4 && (
                    <span className={styles.fieldError}>{error.question4}</span>
                  )}
                </div>
                <img
                  src="/images/personalized/4th.png"
                  alt="icon"
                  loading="lazy"
                  width={35}
                  height={35}
                  style={{ margin: "5px auto" }}
                />
                <Radio.Group
                  onChange={(e) =>
                    setData({ ...data, question4: e.target.value })
                  }
                  value={data.question4}
                >
                  <Space direction="vertical">
                    <Radio value={"Less than a week"}>Less than a week</Radio>
                    <Radio value={"1-2 weeks"}>1-2 weeks</Radio>
                    <Radio value={"3-4 weeks"}>3-4 weeks</Radio>
                    <Radio value={"More than a month"}>More than a month</Radio>
                  </Space>
                </Radio.Group>
              </div>
            ) : current === 4 ? (
              <div className={styles.questions}>
                <div className={styles.questionTitle}>
                  {questions.question5}{" "}
                  {error.question5 && (
                    <span className={styles.fieldError}>{error.question5}</span>
                  )}
                </div>
                <img
                  src="/images/personalized/5th.png"
                  alt="icon"
                  loading="lazy"
                  width={35}
                  height={35}
                  style={{ margin: "5px auto" }}
                />
                <Radio.Group
                  onChange={(e) =>
                    setData({ ...data, question5: e.target.value })
                  }
                  value={data.question5}
                >
                  <Space direction="vertical">
                    <Radio value={"Basic"}>Basic (Clean and comfortable)</Radio>
                    <Radio value={"Mid-range"}>
                      Mid-range (A step above the basics with more facilities)
                    </Radio>
                    <Radio value={"Luxury"}>
                      Luxury (High-end accommodation with superior amenities)
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
            ) : current === 5 ? (
              <div className={styles.questions}>
                <div className={styles.questionTitle}>
                  {questions.question6}{" "}
                  {error.question6 && (
                    <span className={styles.fieldError}>{error.question6}</span>
                  )}
                </div>
                <img
                  src="/images/personalized/6th.png"
                  alt="icon"
                  loading="lazy"
                  width={35}
                  height={35}
                  style={{ margin: "5px auto" }}
                />
                <div className={styles.content}>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question6 === "Vegetarian" &&
                        styles.contentCardActive
                    )}
                    onClick={() =>
                      setData({ ...data, question6: "Vegetarian" })
                    }
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/vegetarian.png"
                        alt="Vegetarian"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>Vegetarian</div>
                  </div>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question6 === "Vegan" && styles.contentCardActive
                    )}
                    onClick={() => setData({ ...data, question6: "Vegan" })}
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/vegan.png"
                        alt="Vegan"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>Vegan</div>
                  </div>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question6 === "Gluten-free" &&
                        styles.contentCardActive
                    )}
                    onClick={() =>
                      setData({ ...data, question6: "Gluten-free" })
                    }
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/gluten-free.png"
                        alt="Gluten-free"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>Gluten-free</div>
                  </div>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question6 === "No specific references" &&
                        styles.contentCardActive
                    )}
                    onClick={() =>
                      setData({ ...data, question6: "No specific references" })
                    }
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/no-specific-references.png"
                        alt="No specific references"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>
                      No specific references
                    </div>
                  </div>
                </div>
              </div>
            ) : current === 6 ? (
              <div className={styles.questions}>
                <div className={styles.questionTitle}>
                  {questions.question7}{" "}
                  {error.question7 && (
                    <span className={styles.fieldError}>{error.question7}</span>
                  )}
                </div>
                <img
                  src="/images/personalized/7th.png"
                  alt="icon"
                  loading="lazy"
                  width={35}
                  height={35}
                  style={{ margin: "5px auto" }}
                />
                <div className={styles.content}>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question7 === "Group classes" &&
                        styles.contentCardActive
                    )}
                    onClick={() =>
                      setData({
                        ...data,
                        question7: "Group classes",
                      })
                    }
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/group-classes.png"
                        alt="Group classes"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>
                      Group classes (Enjoy the community spirit)
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question7 === "One-on-one sessions" &&
                        styles.contentCardActive
                    )}
                    onClick={() =>
                      setData({ ...data, question7: "One-on-one sessions" })
                    }
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/one-on-one.png"
                        alt="One-on-one"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>
                      One-on-one sessions (Receive personal attention and
                      guidance)
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.contentCard,
                      data.question7 === "No preference" &&
                        styles.contentCardActive
                    )}
                    onClick={() =>
                      setData({ ...data, question7: "No preference" })
                    }
                  >
                    <div className={styles.contentImageContainer}>
                      <img
                        className={styles.contentImage}
                        src="/images/personalized/no-preferences.png"
                        alt="No preference"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.contentTitle}>No preference</div>
                  </div>
                </div>
              </div>
            ) : current === 7 ? (
              <div className={styles.questions}>
                <div className={styles.questionTitle}>
                  {questions.question8}{" "}
                  {error.question8 && (
                    <span className={styles.fieldError}>{error.question8}</span>
                  )}
                </div>
                <img
                  src="/images/personalized/8th.png"
                  alt="icon"
                  loading="lazy"
                  width={35}
                  height={35}
                  style={{ margin: "5px auto" }}
                />
                <textarea
                  style={{ padding: "5px" }}
                  onChange={(e) =>
                    setData({ ...data, question8: e.target.value })
                  }
                  value={data.question8}
                  placeholder="Write your request or comments here"
                ></textarea>
              </div>
            ) : current === 8 ? (
              <div className={styles.questions}>
                <div className={styles.questionTitle}>
                  Please provide your contact information so we can send you our
                  personalized recommendations.
                </div>
                <img
                  src="/images/personalized/9th.png"
                  alt="icon"
                  loading="lazy"
                  width={35}
                  height={35}
                  style={{ margin: "5px auto" }}
                />
                <div className={styles.form}>
                  <div>
                    <Input
                      type="text"
                      placeholder="Full Name"
                      prefix={<UserOutlined />}
                      value={data.full_name}
                      className={styles.input}
                      onChange={(e) =>
                        setData({
                          ...data,
                          full_name: e.target.value,
                        })
                      }
                    />
                    {error.full_name && (
                      <span className={styles.fieldError}>
                        {error.full_name}
                      </span>
                    )}
                  </div>
                  <div>
                    <Input
                      type="email"
                      placeholder="Email"
                      prefix={<MailOutlined />}
                      value={data.email}
                      className={styles.input}
                      onChange={(e) =>
                        setData({
                          ...data,
                          email: e.target.value,
                        })
                      }
                    />{" "}
                    {error.email && (
                      <span className={styles.fieldError}>{error.email}</span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.delivered}>{successMessage}</div>
            )}

            <div className={styles.buttonContainer}>
              {current > 0 && current < 9 && (
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  style={{
                    margin: "0 8px",
                    paddingInline: "0",
                  }}
                  onClick={() => {
                    prev();
                  }}
                >
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  className={styles.button}
                  type="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Next Step
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  className={styles.button}
                  onClick={() => {
                    handleSubmit();
                    setContentKey(Date.now());
                  }}
                >
                  Done
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customization;
