import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
} from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { React } from "react";
import { getAllCountries } from "../../../../services/api/country";
import CustomLocationPicker from "../../../components/LocationPicker/CustomLocationPicker";
import TextEditor from "../../../components/TextEditor";
import styles from "../Program.module.css";
import cx from "classnames";

const programDays = [
  { id: 1, name: "Everyday" },
  { id: 2, name: "Sun - Fri" },
  { id: 3, name: "Mon - Fri" },
];

const status = [
  { name: "active", id: 1, value: 1 },
  { name: "inactive", id: 2, value: 0 },
];

const booking = [
  { name: "open", id: 1, value: 1 },
  { name: "closed", id: 2, value: 0 },
];

const scheduleData = [
  {
    value: 0,
    title: "Fixed Date",
    description: "Program to be organised on a fixed date.",
  },
  {
    value: 1,
    title: "All Year",
    description: "Program available any time on the request of user.",
  },
  {
    value: 2,
    title: "Seasonal",
    description: "Program available on certain season or months of every year.",
  },
  {
    value: 3,
    title: "Daily basis",
    description: "Program that runs on daily basis.",
  },
];

function LocationAdd({
  data,
  setData,
  errors,
  handleDateChange,
  removeDate,
  pointVals,
  setPointVals,
}) {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  const [country, setCountry] = useState([]);

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().endOf("day");
  };

  useEffect(() => {
    data.schedules.forEach((item, index) => {
      form.setFieldValue(`Date-${index}`, [
        item.start_date.length > 0 ? moment(item.start_date, "YYYY-MM-DD") : "",
        item.end_date.length > 0 ? moment(item.end_date, "YYYY-MM-DD") : "",
      ]);
    });
    form.setFieldsValue({
      num_of_days: data.num_of_days,
      ...(data.country_id && { Country: data.country_id }),
      Area: data.area,
      Location: data.location,
      SeasonalInfo: data.seasonal_info,
      WeatherInfo: data.weather_info,
      VisaStuff: data.visa_stuff,
      startTime: data.start_time,
      endTime: data.end_time,
      ...(data.status && { Status: data.status }),
      ...(data.booking_status && { BookingStatus: data.booking_status }),
      area: data.area,
      SeatCapacity: data.seat_capacity,
      ...(data.program_days && { programDays: data.program_days }),
    });
  }, [data]);

  useEffect(() => {
    const abortCont = new AbortController();
    getAllCountries(abortCont.signal).then((res) => {
      setCountry(res.data.countries);
    });
    return () => {
      abortCont.abort();
    };
  }, []);

  return (
    <div className={styles.container}>
      <Form
        form={form}
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onSubmit={(e) => e.preventDefault()}
        id="program-form"
      >
        <Row>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <div className={styles.section}>
              <div className={styles.optionTitle}>
                Program Schedule options
                {errors.scheduling && (
                  <span className={styles.fieldError}>
                    {" "}
                    {errors.scheduling}
                  </span>
                )}
              </div>
              <div className={styles.optionGrid}>
                {scheduleData.map((item) => (
                  <div
                    key={item.value}
                    className={styles.optionContainer}
                    onClick={() => {
                      if (item.value !== data.scheduling) {
                        setData((prev) => {
                          var newData = {
                            ...prev,
                            scheduling: item.value,
                            schedules: [
                              {
                                start_date: "",
                                end_date: "",
                              },
                            ],
                          };
                          return newData;
                        });
                      }
                    }}
                  >
                    <Tooltip title={<div>{item.description}</div>}>
                      <div
                        className={cx(
                          styles.subOption,
                          data?.scheduling === item?.value
                            ? styles.subActive
                            : null
                        )}
                      >
                        {item.title}
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {/* Seasonal  */}
          {data.scheduling === 2 ? (
            <>
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
              >
                <Form.Item label="Date for programs">
                  <Button
                    type="primary"
                    onClick={() => {
                      setData({
                        ...data,
                        schedules: [
                          ...data.schedules,
                          { start_date: "", end_date: "" },
                        ],
                      });
                    }}
                  >
                    Add
                  </Button>
                </Form.Item>
              </Col>

              {data.schedules.map((item, index) => (
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  key={index}
                >
                  <Form.Item
                    label={`Date ${index + 1}`}
                    name={`Date-${index}`}
                    labelCol={{ span: 22 }}
                    wrapperCol={{ span: 24 }}
                    validateStatus={
                      errors[`schedules.${index}.start_date`] ||
                      errors[`schedules.${index}.end_date`]
                        ? "error"
                        : "success"
                    }
                    help={
                      errors[`schedules.${index}.start_date`]
                        ? errors[`schedules.${index}.start_date`][0]
                        : errors[`schedules.${index}.end_date`]
                        ? errors[`schedules.${index}.end_date`][0]
                        : ""
                    }
                  >
                    <div className={styles.dateWrapper}>
                      <RangePicker
                        disabledDate={disabledDate}
                        value={[
                          item.start_date.length > 0
                            ? moment(item.start_date, "YYYY-MM-DD")
                            : "",
                          item.end_date.length > 0
                            ? moment(item.end_date, "YYYY-MM-DD")
                            : "",
                        ]}
                        onChange={(date, dateString) => {
                          handleDateChange(index, dateString);
                        }}
                      />
                      <Button
                        shape="circle"
                        size="small"
                        icon={<CloseOutlined style={{ fontSize: "10px" }} />}
                        className={styles.dateBtn}
                        type="danger"
                        onClick={() => {
                          removeDate(index);
                        }}
                      ></Button>
                    </div>
                  </Form.Item>
                </Col>
              ))}
            </>
          ) : // Fixed Date
          data.scheduling === 0 ? (
            <>
              {data.schedules.map((item, index) => (
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  key={index}
                >
                  <Form.Item
                    label={`Date for program`}
                    name={`Date-${index}`}
                    labelCol={{ span: 22 }}
                    wrapperCol={{ span: 24 }}
                    validateStatus={
                      errors[`schedules.${index}.start_date`] ||
                      errors[`schedules.${index}.end_date`]
                        ? "error"
                        : "success"
                    }
                    help={
                      errors[`schedules.${index}.start_date`]
                        ? errors[`schedules.${index}.start_date`][0]
                        : errors[`schedules.${index}.end_date`]
                        ? errors[`schedules.${index}.end_date`][0]
                        : ""
                    }
                  >
                    <RangePicker
                      disabledDate={disabledDate}
                      value={[
                        item.start_date.length > 0
                          ? moment(item.start_date, "YYYY-MM-DD")
                          : "",
                        item.end_date.length > 0
                          ? moment(item.end_date, "YYYY-MM-DD")
                          : "",
                      ]}
                      onChange={(date, dateString) => {
                        setData({
                          ...data,
                          schedules: [
                            {
                              start_date: dateString[0],
                              end_date: dateString[1],
                            },
                          ],
                        });
                        //if state is dictionary, set data like this
                      }}
                    />
                  </Form.Item>
                </Col>
              ))}
            </>
          ) : // Daily Basis
          data.scheduling === 3 ? (
            <>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
              >
                <Form.Item
                  label={`Program Days in a week`}
                  name={`programDays`}
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={errors[`program_days`] ? "error" : "success"}
                  help={errors[`program_days`] ? errors[`program_days`][0] : ""}
                >
                  <Select
                    placeholder="Select routine"
                    style={{ width: 200 }}
                    value={data.program_days}
                    onChange={(value) =>
                      setData({ ...data, program_days: value })
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {programDays.map((item, index) => (
                      <Option key={item.id} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
              >
                <Form.Item
                  label={`Program available from`}
                  name={`startTime`}
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={errors[`start_time`] ? "error" : "success"}
                  help={errors[`start_time`] ? errors[`start_time`][0] : ""}
                >
                  <Input
                    type="time"
                    value={data.start_time}
                    onChange={(e) => {
                      setData({ ...data, start_time: e.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
              >
                <Form.Item
                  label={`Program available to`}
                  name={`endTime`}
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={errors[`end_time`] ? "error" : "success"}
                  help={errors[`end_time`] ? errors[`end_time`][0] : ""}
                >
                  <Input
                    type="time"
                    value={data.end_time}
                    onChange={(e) => {
                      setData({ ...data, end_time: e.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}
          {data.scheduling !== 3 && (
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Total Days of Program"
                name="num_of_days"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors["num_of_days"] ? "error" : "success"}
                help={errors["num_of_days"] ? errors["num_of_days"][0] : ""}
              >
                <Input
                  type="number"
                  placeholder="Total days of program"
                  value={data.num_of_days}
                  onChange={(e) => {
                    setData({ ...data, num_of_days: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          )}
          {/* {config.seatCapacity && (
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Seat Capacity"
                name="SeatCapacity"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors["seat_capacity"] ? "error" : "success"}
                help={errors["seat_capacity"] ? errors["seat_capacity"][0] : ""}
              >
                <Input
                  type="number"
                  placeholder="Seat Capacity"
                  value={data.seat_capacity}
                  onChange={(e) => {
                    setData({ ...data, seat_capacity: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          )} */}
        </Row>

        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 20 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Country"
              name="Country"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              validateStatus={errors["country_id"] ? "error" : "success"}
              help={errors["country_id"] ? errors["country_id"][0] : ""}
            >
              <Select
                placeholder="Select country"
                style={{ width: 200 }}
                value={data.country_id}
                onChange={(value) => setData({ ...data, country_id: value })}
                getPopupContainer={(trigger) => trigger.parentNode}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {country.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[0, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Area"
              name="area"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["area"] ? "error" : "success"}
              help={errors["area"] ? errors["area"][0] : ""}
            >
              <Input
                placeholder="City or place name"
                value={data.area}
                onChange={(e) => {
                  setData({ ...data, area: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <CustomLocationPicker
              pointVals={pointVals}
              setPointVals={setPointVals}
            />
            {(errors[`latitude`] || errors["longitude"]) && (
              <div className={styles.fieldError}>{errors[`latitude`][0]}</div>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Location"
              name="Location"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["location"] ? "error" : "success"}
              help={errors["location"] ? errors["location"][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.location}
                onChange={(value) => setData({ ...data, location: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Seasonal Info"
              name="SeasonalInfo"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["seasonal_info"] ? "error" : "success"}
              help={errors["seasonal_info"] ? errors["seasonal_info"][0] : ""}
            >
              <TextEditor
                value={data.seasonal_info}
                onChange={(value) => {
                  setData({ ...data, seasonal_info: value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Weather Info"
              name="WeatherInfo"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["weather_info"] ? "error" : "success"}
              help={errors["weather_info"] ? errors["weather_info"][0] : ""}
            >
              <TextEditor
                value={data.weather_info}
                onChange={(value) => {
                  setData({ ...data, weather_info: value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Visa Stuff"
              name="VisaStuff"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["visa_stuff"] ? "error" : "success"}
              help={errors["visa_stuff"] ? errors["visa_stuff"][0] : ""}
            >
              <TextEditor
                value={data.visa_stuff}
                onChange={(value) => {
                  setData({ ...data, visa_stuff: value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Status"
              name="Status"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["status"] ? "error" : "success"}
              help={errors["status"] ? errors["status"][0] : ""}
            >
              <Select
                placeholder="Status"
                style={{ width: 200 }}
                value={data.status}
                onChange={(value) => setData({ ...data, status: value })}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {status.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Booking Status"
              name="BookingStatus"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["booking_status"] ? "error" : "success"}
              help={errors["booking_status"] ? errors["booking_status"][0] : ""}
            >
              <Select
                placeholder="Select booking status"
                style={{ width: 200 }}
                value={data.booking_status}
                onChange={(value) =>
                  setData({ ...data, booking_status: value })
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {booking.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default LocationAdd;
