import { baseRequest } from "./base";

const getAllBlogs = async (page, category, signal) => {
  var response = await baseRequest({
    url: `/api/blogs?page=${page}&filter_category=${category}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getBlogsCategory = async (signal) => {
  var response = await baseRequest({
    url: `/api/blog-categories`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getBlogsById = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/blogs/${id}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const downloadEbook = async (data) => {
  var response = await baseRequest({
    url: "/api/send-pdf",
    method: "POST",
    body: data,
  });

  return response;
};

export { getAllBlogs, getBlogsById, getBlogsCategory, downloadEbook };
