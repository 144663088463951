import React, { useState } from "react";
import styles from "./Contact.module.css";
import { Hero } from "../../components/Hero";
import cx from "classnames";
import { sendContact } from "../../services/api/contact";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import { notifyError, notifySuccess } from "../../App";

function Contact() {
  const [data, setData] = useState({
    full_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    setError({});
    e.preventDefault();
    let err = {};
    if (data.full_name === "") {
      err["full_name"] = "Name is required";
    }
    if (data.email === "") {
      err["email"] = "Email is required";
    }
    if (data.phone === "") {
      err["phone"] = "Phone number is required";
    }
    if (data.message === "") {
      err["message"] = "Message is required";
    }

    setError(err);

    if (data.full_name && data.email && data.phone && data.message) {
      setLoading(true);
      sendContact(data)
        .then((res) => {
          if (res.status === "Success") {
            notifySuccess(res.data);
            setLoading(false);
            setData({ full_name: "", email: "", phone: "", message: "" });
          }
        })
        .catch((err) => {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        });
    }
  };
  return (
    <>
      <Hero title="Contact Us" />
      <div className={styles.container}>
        <div className="container">
          <div className={styles.gridContainer}>
            <div className={styles.formContainer}>
              <div className={cx("section-title", styles.sectionTitle)}>
                Send us a Message
              </div>
              <form className={styles.form} onSubmit={handleSubmit}>
                <div>
                  {error.full_name && (
                    <div className={styles.error}>{error.full_name}</div>
                  )}
                  <div className={styles.inputContainer}>
                    <div className={styles.icon}>
                      <ion-icon name="person-outline"></ion-icon>
                    </div>
                    <input
                      type="text"
                      placeholder="Full Name"
                      className={styles.input}
                      value={data.full_name}
                      onChange={(e) =>
                        setData({ ...data, full_name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  {error.email && (
                    <div className={styles.error}>{error.email}</div>
                  )}
                  <div className={styles.inputContainer}>
                    <div className={styles.icon}>
                      <ion-icon name="mail-outline"></ion-icon>
                    </div>
                    <input
                      type="email"
                      placeholder="Email"
                      className={styles.input}
                      value={data.email}
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  {error.phone && (
                    <div className={styles.error}>{error.phone}</div>
                  )}
                  <div className={styles.inputContainer}>
                    <div className={styles.icon}>
                      <ion-icon name="call-outline"></ion-icon>
                    </div>
                    <input
                      type="number"
                      placeholder="Phone"
                      className={styles.input}
                      value={data.phone}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        setData({ ...data, phone: sanitizedValue });
                      }}
                    />
                  </div>
                </div>
                <div>
                  {error.message && (
                    <div className={styles.error}>{error.message}</div>
                  )}
                  <div className={styles.inputContainer}>
                    <div className={styles.icon}>
                      <ion-icon name="mail-outline"></ion-icon>
                    </div>
                    <textarea
                      rows={10}
                      type="text"
                      placeholder="Message"
                      className={styles.input}
                      value={data.message}
                      onChange={(e) =>
                        setData({ ...data, message: e.target.value })
                      }
                    />
                  </div>
                </div>

                <button className={cx("light-btn", styles.btn)}>
                  Send Message
                </button>
              </form>
            </div>
            <div className={styles.widgetContainer}>
              <div className={styles.detailsContainer}>
                <div className={styles.detailIcon}>
                  <ion-icon name="location-outline"></ion-icon>
                </div>
                <div className={styles.details}>
                  <div className={styles.title}>New Road Office</div>
                  <div className={styles.subtitle}>
                    Maypole Crescent 70-80 Upper St Norwich NR2 1LT, New York
                    City
                  </div>
                </div>
              </div>
              <div className={styles.detailsContainer}>
                <div className={styles.detailIcon}>
                  <ion-icon name="mail-outline"></ion-icon>
                </div>
                <div className={styles.details}>
                  <div className={styles.title}>Email us directly</div>
                  <div className={styles.subtitle}>support@yogsewa.com</div>
                  <div className={styles.subtitle}>info@yogsewa.com</div>
                </div>
              </div>
              <div className={styles.detailsContainer}>
                <div className={styles.detailIcon}>
                  <ion-icon name="call-outline"></ion-icon>
                </div>
                <div className={styles.details}>
                  <div className={styles.title}>Phone</div>
                  <div className={styles.subtitle}>+977 061-123456</div>
                  <div className={styles.subtitle}>+977 9812345678</div>
                </div>
              </div>
              <div className={styles.widgetFooter}>
                <div className={styles.title}>Social Title</div>
                <div className={styles.socialList}>
                  <div className={styles.socialIcon}>
                    <ion-icon name="logo-facebook"></ion-icon>
                  </div>
                  <div className={styles.socialIcon}>
                    <ion-icon name="logo-instagram"></ion-icon>
                  </div>
                  <div className={styles.socialIcon}>
                    <ion-icon name="logo-tiktok"></ion-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
