import React from "react";
import { getProviderBookings } from "../../../../services/api/bookings";
import { BookingsTable } from "../BookingsTable";

function CancelledBookings() {
  return (
    <div>
      <BookingsTable fetchData={getProviderBookings} status="canceled" />
    </div>
  );
}

export default CancelledBookings;
