import cx from "classnames";
import React, { useState } from "react";
import { ImageCarousel } from "../../../components/ImageCarousel";
import { converter } from "../../../utils/htmlConverter/htmlConverter";
import styles from "./ProgramAccomodation.module.css";

const ProgramAccomodationItem = ({ accItem }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.card}>
      <div style={{ margin: "0 auto" }}>
        <div className={styles.sliderWrapper}>
          <ImageCarousel
            data={accItem.images.map((item) => ({
              id: item.id,
              image: item.image,
            }))}
            customPage={false}
          />
          <div className={styles.cardCost}>USD ${accItem.price}</div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.cardTitle}>{accItem.title}</div>
        <div
          className={cx(
            styles.cardSubtitle,
            isOpen ? styles.descriptionOpen : null
          )}
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(accItem.description),
          }}
        />
        <div
          className={styles.viewMore}
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <div className={styles.viewIconContainer}>
            {isOpen ? "View Less" : "View More"}
            <div
              className={styles.viewIcon}
              style={!isOpen ? { transform: "rotateZ(-180deg)" } : null}
            >
              <ion-icon name="chevron-up-outline"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={cx("light-btn", styles.button)}>Book Now</div> */}
    </div>
  );
};

function ProgramAccomodation({ data }) {
  return (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.title}>Accomodation</div>
          <div className={styles.cardContainer}>
            {data.map((accItem, index) => (
              <ProgramAccomodationItem accItem={accItem} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramAccomodation;
