import React from "react";
import styles from "./BlogCard.module.css";
import { getDateText } from "../../utils/dateTime/dateAndTime";
import { converter } from "../../utils/htmlConverter/htmlConverter";
import { useNavigate } from "react-router-dom";

function BlogCard({ item }) {
  const navigate = useNavigate();
  return (
    <div className={styles.card} onClick={() => navigate(`/blogs/${item.id}`)}>
      <div className={styles.imageContainer}>
        <img
          src={process.env.REACT_APP_SERVER_URL + item.media[0].media}
          className={styles.image}
          alt="card"
          loading="lazy"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.subtitleContainer}>
          <div className={styles.category}>{item.category.title}</div>
          <div className={styles.date}>{getDateText(item.created_at)}</div>
        </div>
        <div className={styles.cardTitle}>{item.title}</div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(item.description),
          }}
        ></div>
      </div>
    </div>
  );
}

export default BlogCard;
