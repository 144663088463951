import { Input } from "antd";
import debounce from "lodash.debounce";
import { useMemo } from "react";

function SearchBox({ setSearch, placeholder = "" }) {
  const { Search } = Input;

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleSearch, 500), []);

  return (
    <div>
      <Search placeholder={placeholder} onChange={debouncedChangeHandler} />
    </div>
  );
}

export default SearchBox;
