import cx from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import styles from "./Programs.module.css";

const data = [
  { image: "/images/programs/1-min.webp" },
  { image: "/images/programs/2-min.webp" },
  { image: "/images/programs/3-min.webp" },
  { image: "/images/programs/4-min.webp" },
  { image: "/images/programs/5-min.webp" },
];

function Programs() {
  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className="container">
          <div className={styles.gridContainer}>
            <div className={styles.imageGrid}>
              {data.map((item, index) => (
                <div className={styles.imageContainer} key={index}>
                  <img
                    src={item.image}
                    className={styles.image}
                    alt="program"
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
            <div className={styles.content}>
              <div className={cx(styles.title, "section-title")}>
                Customize Your <span>Yoga Experience</span>
              </div>
              <div className={cx(styles.subtitle, "section-subtitle")}>
                Tell us what you're looking for, and we'll craft a yoga journey
                that aligns with your spiritual goals. Start your personalized
                yoga journey now.
              </div>
              <button
                className={cx(styles.button, "light-btn")}
                onClick={() => navigate("/customized-recommendations/")}
              >
                Get Personalised Recommendations
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Programs;
