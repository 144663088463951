import { CalendarOutlined } from "@ant-design/icons";
import { loadStripe } from "@stripe/stripe-js";
import { DatePicker, Form, Input, Radio, Select, Space } from "antd";
import { Option } from "antd/lib/mentions";
import cx from "classnames";
import moment from "moment";
import React, { useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { Navigate, useLocation } from "react-router-dom";
import { bookProgram } from "../../services/api/bookings";
import {
  addDays,
  getDateRangeSeasonal,
  getFixedScheduleDate,
} from "../../utils/dateTime/dateAndTime";
import styles from "./Bookings.module.css";
import countries from "./country.json";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};

function Bookings() {
  const location = useLocation();

  const [dateOpen, setDateOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formattedDate, setFormattedDate] = useState("");
  const [selectedRange, setSelectedRange] = useState({});
  const [errors, setErrors] = useState({});
  var [data, setData] = useState(location.state?.data || {});
  const handleBook = () => {
    let formData = {
      accomodation_id: data.accomodation_id,
      num_of_seats: data.num_of_seats,
      name: data.name,
      date: data.date,
      email: data.email || null,
      country_code: data.country_code,
      phone: data.phone,
      payment_amount: data.payment_amount,
    };
    if (data.scheduling === 0) {
      formData.date = data.schedules[0].start_date;
    }

    makePayment(formData);
    // bookProgram(data.id, formData)
    //   .then((res) => {
    //     notifySuccess("Booked");
    //     navigate("/bookings/result/", {
    //       state: {
    //         data: data,
    //       },
    //     });
    //   })
    //   .catch((err) => {
    //     axiosErrorHandler(err, notifyError);
    //   });
  };

  const datePickerOptions = {
    format: "MMMM D, YYYY",
  };

  const getPrice = () => {
    if (data.accomodation.length === 0 && data.payment_amount === "full") {
      return data?.price * data?.num_of_seats;
    } else if (
      data.accomodation.length === 0 &&
      data.payment_amount === "deposit"
    ) {
      return 0.2 * (data?.price * data?.num_of_seats);
    }
    var currentAccomodation = data?.accomodation.filter(
      (item) => item.id === data?.accomodation_id
    )[0];
    if (
      currentAccomodation &&
      data.num_of_seats &&
      data.payment_amount === "full"
    ) {
      return currentAccomodation.price * data?.num_of_seats;
    } else if (
      currentAccomodation &&
      data.num_of_seats &&
      data.payment_amount === "deposit"
    ) {
      return 0.2 * (currentAccomodation.price * data?.num_of_seats);
    }
  };

  async function makePayment(formData) {
    setLoading(true);
    try {
      const stripe = await stripePromise;
      const session = await bookProgram(data.id, formData);
      const result = await stripe.redirectToCheckout({
        sessionId: session.data.stripe_session,
      });
      if (result.error) {
        console.error("Stripe error:", result.error.message);
      }
    } catch (error) {
      console.error("Payment error:", error);
    }
    setLoading(false);
  }

  return !location.state ? (
    <Navigate to="/" />
  ) : (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.mainTitle}>Your Reservation</div>
        <div className={styles.gridContainer}>
          <div className={styles.contentContainer}>
            <Form
              className={styles.formContainer}
              id="booking-form"
              onFinish={() => {
                let err = {};
                if (data.scheduling !== 0 && data.date.length === 0) {
                  err.date = ["Date is required"];
                }
                if (Object.keys(err).length > 0) {
                  setErrors(err);
                  return;
                }
                handleBook();
              }}
              onSubmit={(e) => e.preventDefault()}
            >
              <div className={styles.formGroup}>
                <div className={styles.section}>
                  <div className={styles.sectionTitle}>Your Trip</div>
                  <div className={styles.topFormGroup}>
                    {/* scheduling 0 => fixed date, 1 => all year, 2=> seasonal, 3=>daily basis  */}
                    {data.scheduling === 0 ? (
                      <div className={styles.gridWrapper}>
                        <div className={styles.formItemWrapper}>
                          <div className={styles.formItemTitle}>Date</div>
                          <div className={styles.fixedFormItem}>
                            {getFixedScheduleDate(data.schedules[0])}
                          </div>
                        </div>
                      </div>
                    ) : data.scheduling === 1 ? (
                      <div className={styles.gridWrapper}>
                        <div className={styles.formItemWrapper}>
                          <div className={styles.formItemTitle}>Date</div>
                          <Form.Item
                            name="DateAllYear"
                            className={styles.formItem}
                            validateStatus={
                              errors["date"] ? "error" : "success"
                            }
                            help={errors[`date`] ? errors[`date`][0] : ""}
                          >
                            <span>
                              <DatePicker
                                className={cx(styles.date, styles.bookingDate)}
                                popupClassName={styles.dateDropdown}
                                open={dateOpen}
                                style={
                                  data.date
                                    ? {
                                        visibility: "hidden",
                                        width: 0,
                                        height: "64px",
                                      }
                                    : {
                                        visibility: "hidden",
                                        width: 0,
                                        height: "42px",
                                      }
                                }
                                onOpenChange={(open) => setDateOpen(open)}
                                disabledDate={(current) => {
                                  return (
                                    current && current.isBefore(new Date())
                                  );
                                }}
                                onChange={(date, dateString) => {
                                  setData({ ...data, date: dateString });
                                }}
                              />
                              <div
                                onClick={() => setDateOpen(!dateOpen)}
                                className={styles.datePicker}
                                id="booking-date-picker"
                              >
                                {data.date ? (
                                  <div className={styles.bookingDateContainer}>
                                    {formatDate(data.date)}
                                    {" - "}
                                    {formatDate(
                                      addDays(data.date, data.num_of_days - 1)
                                    )}
                                  </div>
                                ) : (
                                  <div className={styles.bookingDateWrapper}>
                                    <CalendarOutlined />
                                    <div className={styles.bookingDateTitle}>
                                      Select Date
                                    </div>
                                  </div>
                                )}
                              </div>
                            </span>
                          </Form.Item>
                        </div>
                      </div>
                    ) : data.scheduling === 2 ? (
                      <div className={styles.gridWrapper}>
                        <div className={styles.formItemWrapper}>
                          <div className={styles.formItemTitle}>
                            When are you going?
                          </div>
                          <Form.Item
                            name="when"
                            rules={[
                              {
                                required: true,
                                message: `Please select date`,
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Date"
                              onChange={(id) => {
                                let val = data.schedules.find(
                                  (item) => item.id === id
                                );
                                setData({ ...data, date: "" });
                                setSelectedRange(val);
                              }}
                              value={selectedRange.id}
                            >
                              <Option value={""} disabled>
                                Select a date
                              </Option>
                              {data.schedules.map((item) => (
                                <Option value={item.id}>
                                  {getDateRangeSeasonal(item)}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>

                        <Form.Item
                          name="when"
                          help={errors[`date`] ? errors[`date`][0] : ""}
                          validateStatus={errors["date"] ? "error" : "success"}
                        >
                          <DatePicker
                            disabled={selectedRange.start_date === undefined}
                            className={cx(styles.date, styles.bookingDate)}
                            popupClassName={styles.dateDropdown}
                            open={dateOpen}
                            style={
                              data.date
                                ? {
                                    visibility: "hidden",
                                    width: 0,
                                    height: "64px",
                                  }
                                : { visibility: "hidden", width: 0 }
                            }
                            onOpenChange={(open) => setDateOpen(open)}
                            defaultPickerValue={
                              selectedRange.start_date !== undefined
                                ? moment(selectedRange.start_date)
                                : ""
                            }
                            key={moment(selectedRange.start_date)}
                            disabledDate={(current) => {
                              let today = moment().format("YYYY-MM-DD");
                              let customDate = current.format("YYYY-MM-DD");

                              if (customDate <= today) {
                                return true;
                              }

                              let res =
                                customDate >=
                                  moment(selectedRange.start_date).format(
                                    "YYYY-MM-DD"
                                  ) &&
                                customDate <=
                                  moment(selectedRange.end_date).format(
                                    "YYYY-MM-DD"
                                  );

                              return !res;
                            }}
                            onChange={(date, dateString) => {
                              setData({ ...data, date: dateString });
                            }}
                          />
                          <div
                            onClick={() => setDateOpen(!dateOpen)}
                            className={styles.datePicker}
                            id="booking-date-picker"
                          >
                            {data.date ? (
                              <div className={styles.bookingDateContainer}>
                                {formatDate(data.date)}
                                {" - "}
                                {formatDate(
                                  addDays(data.date, data.num_of_days - 1)
                                )}
                              </div>
                            ) : (
                              <div className={styles.bookingDateWrapper}>
                                <CalendarOutlined />
                                <div className={styles.bookingDateTitle}>
                                  Select Date
                                </div>
                              </div>
                            )}
                          </div>
                        </Form.Item>
                      </div>
                    ) : (
                      <div className={styles.gridWrapper}>
                        <div className={styles.formItemWrapper}>
                          <div className={styles.formItemTitle}>Date</div>
                          <Form.Item
                            name="Date"
                            className={styles.formItem}
                            validateStatus={
                              errors["date"] ? "error" : "success"
                            }
                            help={errors[`date`] ? errors[`date`][0] : ""}
                          >
                            <DatePicker
                              className={styles.date}
                              disabledDate={(current) => {
                                return current && current.isBefore(new Date());
                              }}
                              value={
                                formattedDate
                                  ? moment(formattedDate, "MMMM D, YYYY")
                                  : null
                              }
                              onChange={(date, dateString) => {
                                setFormattedDate(dateString);
                                const formattedDateString = moment(
                                  dateString,
                                  "MMMM D, YYYY"
                                ).format("YYYY-MM-DD");
                                setData({ ...data, date: formattedDateString });
                              }}
                              {...datePickerOptions}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    )}
                    <div id="person" className={styles.gridWrapper}>
                      <div className={styles.formItemWrapper}>
                        <div className={styles.formItemTitle}>
                          Number of people
                        </div>
                        <Form.Item
                          name="Person"
                          initialValue={data.num_of_seats}
                          className={styles.formItem}
                          rules={[
                            {
                              required: true,
                              message: `Please enter number of persons`,
                            },
                            {
                              validator: (_, value) => {
                                const intValue = Number(value);
                                if (intValue < 0) {
                                  return Promise.reject(
                                    "Number of persons cannot be negative"
                                  );
                                }
                                if (!Number.isInteger(intValue)) {
                                  return Promise.reject(
                                    "Please enter a valid integer"
                                  );
                                }
                                if (intValue < 1) {
                                  return Promise.reject(
                                    "Minimum number of persons is 1"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder="Number of people"
                            value={data.num_of_seats}
                            onChange={(e) => {
                              setData({
                                ...data,
                                num_of_seats: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  {data.accomodation && data.accomodation.length > 0 && (
                    <div id="accomodation" className={styles.gridWrapper}>
                      <div className={styles.formItemWrapper}>
                        <div className={styles.formItemTitle}>Accomodation</div>
                        <Form.Item
                          name="Accomodation"
                          className={styles.formItem}
                          rules={[
                            {
                              required: true,
                              message: `Please select accomodation`,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Accomodation"
                            className={styles.select}
                            onChange={(val) => {
                              setData({ ...data, accomodation_id: val });
                            }}
                          >
                            {data?.accomodation?.map((accomodation, index) => (
                              <Option key={index} value={accomodation.id}>
                                {accomodation.title + " " + accomodation.price}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.section}>
                  <div className={styles.sectionTitle}>Personal Details</div>
                  <div id="name" className={styles.gridWrapper}>
                    <Form.Item
                      name="name"
                      initialValue={data.name}
                      className={styles.formItem}
                      rules={[
                        {
                          required: true,
                          message: `Please enter your name`,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Name"
                        value={data.name}
                        onChange={(e) => {
                          setData({
                            ...data,
                            name: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div id="email" className={styles.gridWrapper}>
                    <Form.Item name="email" className={styles.formItem}>
                      <Input
                        placeholder="Email"
                        value={data.name}
                        type="email"
                        onChange={(e) => {
                          setData({
                            ...data,
                            email: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div id="phone" className={styles.gridWrapper}>
                    <div className={styles.formGroupItem}>
                      {" "}
                      <Form.Item
                        name="country"
                        style={{ width: "100%" }}
                        className={styles.formItem}
                        rules={[
                          {
                            required: true,
                            message: `Please enter your country code`,
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select country code"
                          className={styles.select}
                          onChange={(val) => {
                            setData({ ...data, country_code: val });
                          }}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                        >
                          {countries?.countries.map((country, index) => (
                            <Option key={index} value={country.code}>
                              {country.name + " " + country.code}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        className={styles.formItem}
                        rules={[
                          {
                            required: true,
                            message: `Please enter your phone number`,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Phone"
                          value={data.name}
                          onChange={(e) => {
                            setData({
                              ...data,
                              phone: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.sectionTitle}>Pay Now</div>
                <div className={styles.gridWrapper} id="radio">
                  <Form.Item
                    name="payment"
                    rules={[
                      {
                        required: true,
                        message: `Please select payment`,
                      },
                    ]}
                  >
                    <Radio.Group
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setData({ ...data, payment_amount: e.target.value });
                      }}
                      value={data.payment_amount}
                    >
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <div
                          className={cx(
                            styles.radio,
                            data.payment_amount === "deposit" && styles.active
                          )}
                        >
                          <Radio value={"deposit"}>Deposit</Radio>
                        </div>
                        <div
                          className={cx(
                            styles.radio,
                            data.payment_amount === "full" && styles.active
                          )}
                        >
                          <Radio value={"full"}>Full Price</Radio>
                        </div>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
                {data.num_of_seats && data.payment_amount && (
                  <div className={styles.priceContainer}>
                    <div className={styles.priceTitle}>Price:</div>
                    <div className={styles.price}>${getPrice().toFixed(2)}</div>
                  </div>
                )}
              </div>
              <p className={styles.footerStripe}>
                Powered by{" "}
                <svg
                  class="InlineSVG Icon Footer-PoweredBy-Icon Icon--md"
                  focusable="false"
                  width="33"
                  height="15"
                  opacity={0.5}
                  role="img"
                  aria-labelledby="stripe-title"
                >
                  <title id="stripe-title">Stripe</title>
                  <g fill-rule="evenodd">
                    <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
                  </g>
                </svg>
              </p>

              <div id="booking-btn">
                <button className="light-btn" htmlType="submit">
                  Book Instantly
                </button>
              </div>
            </Form>
          </div>
          <div className={styles.widget}>
            <div className={styles.widgetCard}>
              <div className={styles.imageWrapper}>
                <div className={styles.imageContainer}>
                  <img
                    className={styles.image}
                    src={process.env.REACT_APP_SERVER_URL + data?.media[0].link}
                    alt="booking"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className={styles.title}>{data?.title}</div>
              <div className={styles.locationContainer}>
                <IoLocationOutline className={styles.icon} />
                <div className={styles.location}>
                  {data.area + " , " + data.country?.name}
                </div>
              </div>
            </div>

            {/* <div className={styles.widgetFooter}>
              <div className={styles.widgetFooterTitle}>
                <div>
                  <BsCheck2Circle style={{ fontSize: "20px" }} />
                </div>
                <div>Free Cancellation Available</div>
              </div>
              <div className={styles.widgetFooterTitle}>
                <div>
                  <BsCheck2Circle style={{ fontSize: "20px" }} />
                </div>
                <div>No booking or credit card fees</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bookings;
