import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError } from "../../../../App";
import { getAllPrograms } from "../../../../services/api/programs";
import { axiosErrorHandler } from "../../../../utils/errorHandler/axiosErrorHandler";
import { SearchBox } from "../../../components/SearchBox";
import styles from "./ProgramList.module.css";
import ProgramTable from "./ProgramTable";

function ProgramList() {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(
    location.state?.page ? location.state.page : 1
  );
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const totalProducts = useRef(null);
  const pageSize = useRef(null);

  //   const handleDelete = (id) => {
  //     deleteProduct(id)
  //       .then((res) => {
  //         if (res.status === "Success") {
  //           setRefresh((prev) => !prev);
  //           notifySuccess(res.message);
  //         }
  //       })
  //       .catch((err) => {
  //         axiosErrorHandler(err, notifyError);
  //       });
  //   };

  const onEditClick = (id, data) => {
    navigate("/partner/yoga-programs/edit/", {
      state: {
        id: id,
        program: data,
      },
    });
  };

  const onBookingClick = (id, data, slug) => {
    navigate("/partner/yoga-programs/bookings/", {
      state: {
        id: id,
        program: data,
        slug: slug,
      },
    });
  };

  const fetchData = async (signal, search = "") => {
    setLoading(true);
    await getAllPrograms(page, signal, search)
      .then((res) => {
        if (res.status === "Success") {
          totalProducts.current = res.data.total;
          pageSize.current = res.data.page_size;
          setData(res.data.yoga_programs);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchData(abortCont.signal, search);
    return () => {
      abortCont.abort();
    };
  }, [page, search, refresh]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Program List</div>
        <Button
          type="primary"
          onClick={() => navigate("/partner/yoga-programs/add/")}
        >
          Add Program
        </Button>
      </div>

      <div className={styles.headerContainer}>
        <div>
          <SearchBox
            setSearch={(value) => {
              setSearch(value);
              setPage(1);
            }}
            placeholder="Search Program"
          />
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <ProgramTable
          data={data}
          loading={loading}
          page={page}
          setPage={setPage}
          total={totalProducts.current}
          pageSize={pageSize.current}
          onEditClick={onEditClick}
          onBookingClick={onBookingClick}
          handleDelete={() => {}}
        />
      </div>
    </div>
  );
}

export default ProgramList;
