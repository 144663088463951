import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
} from "antd";
import { Option } from "antd/lib/mentions";
import cx from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../../../../../App";
import { getAllCountries } from "../../../../../../services/api/country";
import {
  getProgramById,
  updateSchedule,
} from "../../../../../../services/api/programs";
import { axiosErrorHandler } from "../../../../../../utils/errorHandler/axiosErrorHandler";
import CustomLocationPicker from "../../../../../components/LocationPicker/CustomLocationPicker";
import TextEditor from "../../../../../components/TextEditor";
import styles from "./LocationEdit.module.css";

const scheduleData = [
  {
    value: 0,
    title: "Fixed Date",
    description: "Program to be organised on a fixed date.",
  },
  {
    value: 1,
    title: "All Year",
    description: "Program available any time on the request of user.",
  },
  {
    value: 2,
    title: "Seasonal",
    description: "Program available on certain season or months of every year.",
  },
  {
    value: 3,
    title: "Daily basis",
    description: "Program that runs on daily basis.",
  },
];
const programDays = [
  { id: 1, name: "Everyday" },
  { id: 2, name: "Sun - Fri" },
  { id: 3, name: "Mon - Fri" },
];
function LocationEdit({ item, id }) {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [errors, setErrors] = useState([]);
  const [pointVals, setPointVals] = useState([]);
  const [mapCenter, setMapCenter] = useState([]);
  const [data, setData] = useState({
    scheduling: "",
    schedules: [],
    start_date: "",
    end_date: "",
    months: [],
    country_id: "",
    area: "",
    num_of_days: "",
    location: "",
    latitude: null,
    longitude: null,
    seasonal_info: "",
    weather_info: "",
    visa_stuff: "",
  });

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().endOf("day");
  };

  // const filteredMonthss = months.filter((o) => !data.months.includes(o));
  const handleSubmit = () => {
    let err = {};
    if (pointVals.length === 0) {
      err["latitude"] = ["Select program location in the map"];
    }
    if (data.scheduling === 0 || data.scheduling === 2) {
      data.schedules.forEach((item, index) => {
        if (item.start_date.length === 0) {
          err[`schedules.${index}.start_date`] = ["Start date is required"];
        }
        if (item.end_date.length === 0) {
          err[`schedules.${index}.end_date`] = ["End date is required"];
        }
      });
    }
    if (data.scheduling !== 3 && data.num_of_days.length === 0) {
      err["num_of_days"] = ["Num of days is required"];
    }
    if (data.scheduling === 3 && data.schedules[0].start_time.length === 0) {
      err["start_time"] = ["Available from is required."];
    }
    if (data.scheduling === 3 && data.schedules[0].end_time.length === 0) {
      err["end_time"] = ["Available to is required."];
    }
    if (data.scheduling === 3 && data.schedules[0].program_days.length === 0) {
      err["program_days"] = ["Program days is required."];
    }
    setErrors(err);
    if (Object.keys(err).length > 0) {
      return;
    }
    var formData = {
      scheduling: data.scheduling,
      start_date: data.start_date,
      end_date: data.end_date,
      months: data.months,
      num_of_days: data.num_of_days,
      country_id: data.country_id,
      area: data.area,
      location: data.location,
      latitude: null,
      longitude: null,
      seasonal_info: data.seasonal_info,
      weather_info: data.weather_info,
      visa_stuff: data.visa_stuff,
      schedules: data.schedules || [],
      latitude: pointVals[0][0],
      longitude: pointVals[0][1],
    };

    setLoading(true);
    updateSchedule(id, formData)
      .then((res) => {
        notifySuccess(res.message);
        setLoading(false);
        setRefresh((prev) => !prev);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  const handleDateChange = (index, dateString) => {
    // setData({
    //   ...data,
    //   scheduling: [{ start_date: dateString[0], end_date: dateString[1] }],
    // });
    setData((prev) => {
      let p = [...prev.schedules];
      p.forEach((item, i) => {
        if (i === index) {
          item.start_date = dateString[0];
          item.end_date = dateString[1];
        }
      });
      return { ...prev, schedules: p };
    });
  };

  const removeDate = (index) => {
    setData((prev) => {
      let n = prev.schedules.filter((item, i) => {
        if (i !== index) {
          return item;
        }
      });
      if (n.length === 0) {
        n = [
          {
            start_date: "",
            end_date: "",
          },
        ];
      }
      return { ...prev, schedules: n };
    });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getAllCountries(abortCont.signal).then((res) => {
      setCountry(res.data.countries);
    });
    return () => {
      abortCont.abort();
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      num_of_days: "",
      Scheduling: "", //for showing intial value after fetching data (name is required from Form.Item)
      Date: "",
      Months: "", //for select initial value placeholder
      Country: "",
      Area: "",
      Location: "",
      SeasonalInfo: "",
      WeatherInfo: "",
      VisaStuff: "",
      programDays: "",
      startTime: "",
      endTime: "",
      //for showing intial value after fetching data
    });
  }, []);

  useEffect(() => {
    const abortCont = new AbortController();

    getProgramById(id, abortCont.signal)
      .then((res) => {
        if (res.status === "Success") {
          let program = { ...res.data.program };
          if (program.scheduling === 3) {
            program["start_time"] = program.schedules[0].start_time;
            program["end_time"] = program.schedules[0].end_time;
            program["program_days"] = program.schedules[0].program_days;
            program.schedules = [];
          }
          setData(program);
          setPointVals([
            [res.data.program.latitude, res.data.program.longitude],
          ]);
          setMapCenter([res.data.program.latitude, res.data.program.longitude]);
          form.setFieldsValue({
            num_of_days: res.data.program.num_of_days,
            Country: res.data.program.country_id,
            Area: res.data.program.area,
            Location: res.data.program.location,
            SeasonalInfo: res.data.program.seasonal_info,
            WeatherInfo: res.data.program.weather_info,
            VisaStuff: res.data.program.visa_stuff,
            startTime: program.scheduling === 3 ? program.start_time : "",
            endTime: program.scheduling === 3 ? program.end_time : "",
            programDays: program.scheduling === 3 ? program.program_days : "",
          });
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
    return () => {
      abortCont.abort();
    };
  }, [id, refresh]);

  return (
    <div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmit}
        onSubmit={(e) => e.preventDefault()}
        form={form}
      >
        <Row>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <div className={styles.section}>
              <div className={styles.optionTitle}>
                Program Schedule options
                {errors.scheduling && (
                  <span className={styles.fieldError}>
                    {" "}
                    {errors.scheduling}
                  </span>
                )}
              </div>
              <div className={styles.optionGrid}>
                {scheduleData.map((item) => (
                  <div
                    key={item.value}
                    className={styles.optionContainer}
                    onClick={() => {
                      if (item.value !== data.scheduling) {
                        setData((prev) => {
                          var newData = {
                            ...prev,
                            scheduling: item.value,
                            schedules:
                              item.value === 3
                                ? [
                                    {
                                      start_time: "",
                                      end_time: "",
                                      program_days: "",
                                    },
                                  ]
                                : item.value === 1
                                ? []
                                : [
                                    {
                                      start_date: "",
                                      end_date: "",
                                    },
                                  ],
                          };
                          return newData;
                        });
                      }
                    }}
                  >
                    <Tooltip title={<div>{item.description}</div>}>
                      <div
                        className={cx(
                          styles.subOption,
                          data?.scheduling === item?.value
                            ? styles.subActive
                            : null
                        )}
                      >
                        {item.title}
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        {/* seasonal */}
        {data.scheduling === 2 ? (
          <Row>
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item label="Date for programs">
                <Button
                  type="primary"
                  onClick={() => {
                    setData({
                      ...data,
                      schedules: [
                        ...data.schedules,
                        { start_date: "", end_date: "" },
                      ],
                    });
                  }}
                >
                  Add
                </Button>
              </Form.Item>
            </Col>

            {data.schedules.map((item, index) => (
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                key={index}
              >
                <Form.Item
                  label={`Date ${index + 1}`}
                  name={`Date-${index}`}
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={
                    errors[`schedules.${index}.start_date`] ||
                    errors[`schedules.${index}.end_date`]
                      ? "error"
                      : "success"
                  }
                  help={
                    errors[`schedules.${index}.start_date`]
                      ? errors[`schedules.${index}.start_date`][0]
                      : errors[`schedules.${index}.end_date`]
                      ? errors[`schedules.${index}.end_date`][0]
                      : ""
                  }
                >
                  <div className={styles.dateWrapper}>
                    <RangePicker
                      disabledDate={disabledDate}
                      value={[
                        item.start_date.length > 0
                          ? moment(item.start_date, "YYYY-MM-DD")
                          : "",
                        item.end_date.length > 0
                          ? moment(item.end_date, "YYYY-MM-DD")
                          : "",
                      ]}
                      onChange={(date, dateString) => {
                        handleDateChange(index, dateString);
                      }}
                    />
                    <Button
                      className={styles.dateBtn}
                      icon={<CloseOutlined style={{ fontSize: "10px" }} />}
                      shape="circle"
                      size="small"
                      type="danger"
                      onClick={() => {
                        removeDate(index);
                      }}
                    ></Button>
                  </div>
                </Form.Item>
                {errors[`schedules.${index}.start_date`] ? (
                  <div className={styles.fieldError}>
                    {errors[`schedules.${index}.start_date`][0]}
                  </div>
                ) : errors[`schedules.${index}.end_date`] ? (
                  <div className={styles.fieldError}>
                    {errors[`schedules.${index}.end_date`][0]}
                  </div>
                ) : (
                  ""
                )}
              </Col>
            ))}
          </Row>
        ) : data.scheduling === 0 ? ( //fixed date
          <Row>
            {data.schedules.map((item, index) => (
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                key={index}
              >
                <Form.Item
                  label={`Date for program`}
                  name={`Date-${index}`}
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={
                    errors[`schedules.${index}.start_date`] ||
                    errors[`schedules.${index}.end_date`]
                      ? "error"
                      : "success"
                  }
                  help={
                    errors[`schedules.${index}.start_date`]
                      ? errors[`schedules.${index}.start_date`][0]
                      : errors[`schedules.${index}.end_date`]
                      ? errors[`schedules.${index}.end_date`][0]
                      : ""
                  }
                >
                  <div className={styles.dateWrapper}>
                    <RangePicker
                      disabledDate={disabledDate}
                      value={[
                        item.start_date.length > 0
                          ? moment(item.start_date, "YYYY-MM-DD")
                          : "",
                        item.end_date.length > 0
                          ? moment(item.end_date, "YYYY-MM-DD")
                          : "",
                      ]}
                      onChange={(date, dateString) => {
                        setData({
                          ...data,
                          schedules: [
                            {
                              start_date: dateString[0],
                              end_date: dateString[1],
                            },
                          ],
                        });
                        //if state is dictionary, set data like this
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            ))}
          </Row>
        ) : data.scheduling === 3 ? ( //daily basis
          <Row gutter={[16, 16]}>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label={`Program Days in a week`}
                name={`programDays`}
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors[`program_days`] ? "error" : "success"}
                help={errors[`program_days`] ? errors[`program_days`][0] : ""}
              >
                <Select
                  placeholder="Select routine"
                  style={{ width: 200 }}
                  value={data.program_days}
                  onChange={(value) =>
                    setData({
                      ...data,
                      schedules: [
                        {
                          ...data.schedules[0],
                          program_days: value,
                        },
                      ],
                    })
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {programDays.map((item, index) => (
                    <Option key={item.id} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label={`Program available from`}
                name={`startTime`}
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors[`start_time`] ? "error" : "success"}
                help={errors[`start_time`] ? errors[`start_time`][0] : ""}
              >
                <Input
                  type="time"
                  value={data.start_time}
                  onChange={(e) => {
                    setData({
                      ...data,
                      schedules: [
                        {
                          ...data.schedules[0],
                          start_time: e.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 22 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label={`Program available to`}
                name={`endTime`}
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors[`end_time`] ? "error" : "success"}
                help={errors[`end_time`] ? errors[`end_time`][0] : ""}
              >
                <Input
                  type="time"
                  value={data.end_time}
                  onChange={(e) => {
                    setData({
                      ...data,
                      schedules: [
                        {
                          ...data.schedules[0],
                          end_time: e.target.value,
                        },
                      ],
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {data.scheduling !== 3 && (
          <Row>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Total Days of Program"
                name="num_of_days"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors["num_of_days"] ? "error" : "success"}
                help={errors["num_of_days"] ? errors["num_of_days"][0] : ""}
              >
                <Input
                  type="number"
                  placeholder="Total days of program"
                  value={data.num_of_days}
                  onChange={(e) => {
                    setData({ ...data, num_of_days: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 20 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Country"
              name="Country"
              rules={[{ required: true, message: "Please enter country" }]}
            >
              <Select
                placeholder="Select country"
                style={{ width: 200 }}
                value={data.country_id}
                onChange={(value) => setData({ ...data, country_id: value })}
                getPopupContainer={(trigger) => trigger.parentNode}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {country.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Area"
              name="Area"
              rules={[{ required: true, message: "Please enter area" }]}
            >
              <Input
                placeholder="City or place name"
                value={data.area}
                onChange={(e) => {
                  setData({ ...data, area: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <CustomLocationPicker
              pointVals={pointVals}
              setPointVals={setPointVals}
              mapCenter={mapCenter}
            />
            {(errors[`latitude`] || errors["longitude"]) && (
              <div className={styles.fieldError}>{errors[`latitude`][0]}</div>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Location"
              name="Location"
              rules={[{ required: true, message: "Please enter location" }]}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.location}
                onChange={(value) => setData({ ...data, location: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Seasonal Info"
              name="SeasonalInfo"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `Please enter seasonal info`,
                },
              ]}
            >
              <TextEditor
                value={data.seasonal_info}
                onChange={(value) => setData({ ...data, seasonal_info: value })}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Weather Info"
              name="WeatherInfo"
              rules={[
                {
                  required: true,
                  message: `Please enter weather info`,
                },
              ]}
            >
              <TextEditor
                value={data.weather_info}
                onChange={(value) => setData({ ...data, weather_info: value })}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Visa Stuff"
              name="VisaStuff"
              rules={[{ required: true, message: "Please enter visa stuff" }]}
            >
              <TextEditor
                value={data.visa_stuff}
                onChange={(value) => setData({ ...data, visa_stuff: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button
                type="primary"
                size="large"
                loading={loading}
                disabled={loading}
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default LocationEdit;
