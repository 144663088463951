import { baseRequest } from "./base";

const getPaymentToReceive = async (page, signal) => {
  var response = await baseRequest({
    url: `/api/provider-payments?page=${page}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getPaymentHistory = async (page, signal) => {
  var response = await baseRequest({
    url: `/api/payment-history?page=${page}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getPaymentHistoryById = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/payment-history/${id}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getAllBanks = async (signal) => {
  var response = await baseRequest({
    url: `/api/banks`,
    method: "GET",
    signal: signal,
  });

  return response;
};

export {
  getPaymentToReceive,
  getPaymentHistory,
  getPaymentHistoryById,
  getAllBanks,
};
