import { CloudTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Modal, Spin, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../../App";
import FileUpload from "../../../components/FileUpload/FileUpload";
import {
  addBusinessDocuments,
  deleteDocuments,
  getBusinessAllDocuments,
} from "../../../services/api/registration";
import { confirmDelete } from "../../../utils/confirmDelete/confirmDelete";
import { getDateText } from "../../../utils/dateTime/dateAndTime";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import styles from "./AdminBusiness.module.css";

const AdminRegistration = () => {
  const [form] = Form.useForm();

  const [data, setData] = useState({
    title: "",
    license_number: "",
    expiry_date: "",
  });

  const [date, setDate] = useState(moment.now());
  const [loading, setLoading] = useState(false);

  const [registration, setRegistration] = useState([]);

  const [documentLoading, setDocumentLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [photo, setPhoto] = useState([]);
  const [photoError, setPhotoError] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.fileList);
    }
    return e && e.fileList;
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleSubmit();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchDocuments = async (signal) => {
    setDocumentLoading(true);
    await getBusinessAllDocuments(signal)
      .then((res) => {
        if (res.status === "Success") {
          setRegistration(res.data);
          setDocumentLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setDocumentLoading(false);
        }
      })
      .finally(() => {});
  };

  const handleSubmitFailed = () => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }
  };

  const handleSubmit = () => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }

    form.validateFields().then((values) => {
      const file = photo[0].originFileObj;
      if (file.size / 1024 > 500) {
        setPhotoError("Image size should be less than 500KB!");
        return;
      }
      let formData = { ...values, document: photo[0].originFileObj };
      setRefresh(true);
      setLoading(true);
      addBusinessDocuments(formData)
        .then((res) => {
          if (res.status === "Success") {
            notifySuccess(res.message);
            setLoading(false);
            setIsModalOpen(false);
            setRefresh(false);
            form.resetFields();
            setPhoto([]);
            setPhotoError("");
          }
        })
        .catch((err) => {
          message.error(err.response.data.data.document[0]);
          // axiosErrorHandler(err, notifyError);
          setLoading(false);
          setRefresh(false);
        });
    });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchDocuments(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, [refresh]);

  const handleDelete = (id) => {
    deleteDocuments(id)
      .then((res) => {
        if (res.status === "Success") {
          setRefresh((prev) => !prev);
          notifySuccess(res.message);
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
  };

  return (
    <div>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          Business registration documents
        </div>
        <div className={styles.sectionContent}>
          <div className={styles.content}>
            <div>
              <div className={styles.label}>Business registrations</div>
              <div className={styles.sublabel}>
                Please upload a scan of your business registrations
              </div>
            </div>
          </div>
          <Form.Item>
            <Button onClick={showModal}>Add Registration</Button>
          </Form.Item>
          <div className={styles.tableContainer}>
            <table>
              {documentLoading ? (
                <Spin />
              ) : (
                <tbody>
                  <tr>
                    <th>Title</th>
                    <th>License Number</th>
                    <th>Number</th>
                    <th>Expires at</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                  {registration.map((registration) => (
                    <tr key={registration.id}>
                      <td>{registration.title}</td>
                      <td>
                        {registration?.license_number
                          ? registration?.license_number
                          : "No data"}
                      </td>
                      <td>{registration.document.split("/").pop()}</td>
                      <td>
                        {registration?.expiry_date
                          ? getDateText(registration?.expiry_date)
                          : "No data "}
                      </td>
                      <td>
                        <a
                          href={
                            process.env.REACT_APP_SERVER_URL +
                            registration.document
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() =>
                            window.open(
                              process.env.REACT_APP_SERVER_URL +
                                registration.document,
                              "_blank"
                            )
                          }
                        >
                          <CloudTwoTone />
                        </a>
                      </td>
                      <td>
                        <DeleteTwoTone
                          twoToneColor="#FD4D31"
                          onClick={() =>
                            confirmDelete(
                              () => handleDelete(registration.id),
                              () => {}
                            )
                          }
                        />
                      </td>

                      {/* <td>
                      <Switch
                        style={{ width: "90px" }}
                        defaultChecked
                        checkedChildren="verified"
                        unCheckedChildren="unverified"
                      />
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>

      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onSubmit={(e) => e.preventDefault()}
        form={form}
        onFinishFailed={handleSubmitFailed}
      >
        <Modal
          title="Add Registrations"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          data={data}
          setData={setData}
          width={900}
        >
          <div className={styles.sectionContent}>
            <div className={styles.content}>
              <div className={styles.label}>Title</div>
            </div>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input your business title",
                },
              ]}
            >
              <Input
                className={styles.input}
                value={data.title}
                onChange={(e) => {
                  setData({
                    ...data,
                    title: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <div className={styles.content}>
              <div className={styles.label}>License Number</div>
            </div>
            <Form.Item name="license_number">
              <Input
                className={styles.input}
                value={data.license_number}
                onChange={(e) => {
                  setData({
                    ...data,
                    license_number: e.target.value,
                  });
                }}
              />
            </Form.Item>

            <div className={styles.content}>
              <div className={styles.label}>License Expiration Date</div>
            </div>
            <Form.Item name="expiry_date">
              <DatePicker
                valuePropName="expiry_date"
                value={date}
                onChange={(date, dateString) => {
                  setData({ ...data, expiry_date: dateString });
                  setDate(date);
                }}
              />
            </Form.Item>
            <div className={styles.content}>
              <div className={styles.label}>Image</div>
            </div>
            <Form.Item
              name="dragger"
              listType="picture"
              getValueFromEvent={normFile}
              validateStatus={photoError ? "error" : ""}
              help={photoError}
            >
              <FileUpload
                fileList={photo}
                setFileList={setPhoto}
                maxUpload={1}
              />
            </Form.Item>
          </div>
        </Modal>
      </Form>
    </div>
  );
};

export default AdminRegistration;
