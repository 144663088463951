import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import React from "react";
import { useState } from "react";
import ImageUpload from "../../../../../components/ImageUpload";
import TextEditor from "../../../../../components/TextEditor";
import styles from "./AccomodationCard.module.css";
import { useEffect } from "react";
import { addAccomodation, updateAccomodation } from "../../../../../../services/api/programs";
import { notifySuccess } from "../../../../../../App";

const AccomodationCard = ({
  isModalOpen,
  setIsModalOpen,
  accomodationData,
  programId,
  setRefresh,
}) => {
  const [form] = Form.useForm();

  const [data, setData] = useState(accomodationData);
  const [photo, setPhoto] = useState([]);
  const [oldPhotos, setOldPhotos] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageSizeError, setImageSizeError] = useState("");

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.fileList);
    }
    return e && e.fileList;
  };
  const handleOk = () => {
    if (loading) {
      return;
    }
    let err = {};
    if (data.title.length === 0) {
      err["title"] = ["Title is required."];
    }
    if (data.description.length === 0) {
      err["description"] = ["Description is required."];
    }
    if (photo.length === 0) {
      err["images"] = ["Photo is required."];
    }
    if (data.price.length === 0) {
      err["price"] = ["Price is required."];
    }
    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }
    let newPhotos = [];
    photo.forEach((photo) => {
      if (photo.originFileObj) {
        newPhotos.push(photo.originFileObj); // add new photos
      }
    });
    setLoading(true);

    if (accomodationData.id) {
      var deletedPhotoIds = [];

      oldPhotos.forEach((photo) => {
        if (photo.status === "removed") {
          deletedPhotoIds.push(photo.id); // deleted photos id
        }
      });

      let formData = {
        title: data.title,
        description: data.description,
        images: newPhotos,
        price: data.price,
        deletePhotoIds: deletedPhotoIds,
      };
      updateAccomodation(accomodationData.id, formData)
        .then((res) => {
          setRefresh((prev) => !prev);
          setIsModalOpen(false);
          notifySuccess("Updated successfully.");
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let formData = {
        title: data.title,
        description: data.description,
        images: newPhotos,
        price: data.price,
      };
      addAccomodation(programId, formData)
        .then((res) => {
          setRefresh((prev) => !prev);
          setIsModalOpen(false);
          notifySuccess("Added successfully.");
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      title: accomodationData.title, //for showing intial value after fetching data (name is required from Form.Item)
      description: accomodationData.description, //for select initial value placeholder
      price: accomodationData.price,
      //for showing intial value after fetching data
    });
    if (accomodationData.images.length > 0) {
      const fileList = accomodationData.images?.map((photo, index) => {
        return {
          url: process.env.REACT_APP_SERVER_URL + photo.image,
          id: photo.id, // to get removed photo id
        };
      });
      setPhoto([...fileList]); // it just removes photo from array
      setOldPhotos([...fileList]); // it contains status(removed)
    } else {
      setPhoto([]);
      setOldPhotos([]);
    }
    setData(accomodationData);
    setErrors([]);
  }, [accomodationData]);

  useEffect(() => {
    if (imageSizeError) {
      message.error(imageSizeError);
    }
  }, [imageSizeError]);

  return (
    <Modal title="Accomodation Details" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <div className={styles.accomodationContainer}>
        <Form
          className={styles.formContainer}
          name="basic"
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Title"
              name={`title`}
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors[`title`] ? "error" : "success"}
              help={errors[`title`] ? errors[`title`][0] : ""}
            >
              <Input
                placeholder="Title"
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
            <Form.Item
              name={`photos`}
              listType="picture"
              getValueFromEvent={normFile}
              validateStatus={errors[`images`] ? "error" : "success"}
              help={errors[`images`] ? errors[`images`][0] : ""}
            >
              <ImageUpload
                fileList={photo}
                setFileList={setPhoto}
                multiple={true}
                photoWidth="625"
                photoHeight="348"
                setError={(msg) => {
                  setImageSizeError(msg);
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Price"
              name={`price`}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 22 }}
              validateStatus={errors[`price`] ? "error" : "success"}
              help={errors[`price`] ? errors[`price`][0] : ""}
            >
              <Input
                type="number"
                placeholder="Price"
                value={data.price}
                onChange={(e) => {
                  setData({ ...data, price: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Description"
              name={`description`}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors[`description`] ? "error" : "success"}
              help={errors[`description`] ? errors[`description`][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
description: value depends on text editor(before setValue={setDescription}) 
*/}

              <TextEditor
                value={data.description}
                onChange={(val) => {
                  setData({ ...data, description: val });
                }}
              />
            </Form.Item>
          </Col>
        </Form>
      </div>
    </Modal>
  );
};

export default AccomodationCard;
