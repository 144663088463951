import {
  ContainerOutlined,
  EuroCircleOutlined,
  GroupOutlined,
  HomeOutlined,
  IdcardOutlined,
  InfoCircleOutlined,
  MoneyCollectOutlined,
  PlusOutlined,
  ProfileOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  VerifiedOutlined,
} from "@ant-design/icons";
import React from "react";

const menuItems = [
  {
    title: "Dashboard",
    icon: <HomeOutlined />,
    path: "/partner/",
    parentKey: null,
  },
  {
    title: "Partner Profile",
    icon: <UsergroupAddOutlined />,
    parentKey: null,
    subNav: [
      {
        title: "Profile Information",
        icon: <ProfileOutlined />,
        path: "/partner/profile/",
      },
      {
        title: "Business Information",
        path: "/partner/business/",
        icon: <InfoCircleOutlined />,
      },
      {
        title: "Payment Information",
        icon: <MoneyCollectOutlined />,
        path: "/partner/payment/",
      },
    ],
  },
  {
    title: "Operators",
    path: "/partner/operators/",
    icon: <IdcardOutlined />,
    parentKey: null,
  },
  {
    title: "Programs",
    icon: <GroupOutlined />,
    parentKey: null,
    subNav: [
      {
        title: "Add Programs",
        path: "/partner/yoga-programs/add/",
        icon: <PlusOutlined />,
      },
      {
        title: "Verified Programs",
        path: "/partner/yoga-programs/verified/",
        icon: <VerifiedOutlined />,
      },
      {
        title: "Not Verified Programs",
        path: "/partner/yoga-programs/not-verified/",
        icon: <UnorderedListOutlined />,
      },
    ],
  },
  {
    title: "Finance",
    icon: <EuroCircleOutlined />,
    parentKey: null,
    subNav: [
      {
        title: "To receive",
        path: "/partner/accounting/receive/",
        icon: <PlusOutlined />,
      },
      {
        title: "Payment History",
        path: "/partner/accounting/history/",
        icon: <UnorderedListOutlined />,
      },
    ],
  },

  {
    title: "Manage Bookings",
    icon: <ContainerOutlined />,
    parentKey: null,
    subNav: [
      {
        title: "Completed Bookings",
        path: "/partner/bookings/completed/",
        icon: <UnorderedListOutlined />,
      },
      {
        title: "Cancelled Bookings",
        path: "/partner/bookings/cancelled/",
        icon: <UnorderedListOutlined />,
      },
    ],
  },
];

export default menuItems;
