import React from "react";
import styles from "./ProviderSignUp.module.css";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserOutlined, PhoneOutlined, HomeOutlined } from "@ant-design/icons";
import { getAllCountries } from "../../../services/api/country";
import { Option } from "antd/lib/mentions";
import { register } from "../../../services/api/auth";
import { notifyError, notifySuccess } from "../../../App";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import TextArea from "antd/lib/input/TextArea";

function ProviderSignUp() {
  const [country, setCountry] = useState([]);
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    address: "",
    country_id: "",
    dob: "",
    info_text: "",
    additional_contact: "",
  });
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = () => {
    var formData = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      password: data.password,
      address: data.address,
      country_id: data.country_id,
      role: location.state.value,
      info_text: data.info_text,
      additional_contact: data.additional_contact,
    };
    setLoading(true);
    register(formData)
      .then((res) => {
        notifySuccess(res.message);
        setLoading(true);
        navigate("/login/");
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getAllCountries(abortCont.signal).then((res) => {
      setCountry(res.data.countries);
    });

    return () => {
      abortCont.abort();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.card}>
          <div className={styles.title}>
            Join{" "}
            <img src="/images/logo1.png" width={100} height={100} alt="sign up" loading="lazy" />{" "}
            Today
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.cardTitle}>Sign Up</div>
          </div>
          <Form
            className={styles.formContainer}
            name="basic"
            autoComplete="off"
            layout="vertical"
            onFinish={handleSubmit}
            onSubmit={(e) => e.preventDefault()}
            id="signupform"
          >
            <div className={styles.formGroup}>
              <Form.Item
                name="Name"
                label="Name"
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `Please enter your name`,
                  },
                ]}
              >
                <Input
                  placeholder="Name"
                  value={data.name}
                  suffix={<UserOutlined />}
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>

              <Form.Item
                name="Phone Number"
                label="Phone Number"
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `Please enter your phone number`,
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Phone"
                  value={data.phone}
                  suffix={<PhoneOutlined />}
                  onChange={(e) => {
                    setData({ ...data, phone: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </div>
            <div className={styles.formGroup}>
              <Form.Item
                name="Email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: `Please enter your email`,
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Email"
                  autocomplete="new-email"
                  value={data.email}
                  suffix="@"
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="Password"
                autocomplete="new-password"
                rules={[
                  {
                    required: true,
                    message: `Please enter your password`,
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  value={data.password}
                  onChange={(e) => {
                    setData({ ...data, password: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </div>
            <div className={styles.formGroupNext}>
              <Form.Item
                name="Address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: `Please enter your address`,
                  },
                ]}
              >
                <Input
                  placeholder="Address"
                  suffix={<HomeOutlined />}
                  value={data.address}
                  onChange={(e) => {
                    setData({ ...data, address: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
              <Form.Item
                name="Country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Please select country",
                  },
                ]}
              >
                <Select
                  placeholder="Select country"
                  value={data.country_id}
                  onChange={(value) => setData({ ...data, country_id: value })}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {country.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="AdditionalContact"
                label="Additional Contact"
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `Please enter your additional number`,
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Additional Number"
                  value={data.additional_contact}
                  suffix={<PhoneOutlined />}
                  onChange={(e) => {
                    setData({ ...data, additional_contact: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="Summary"
                label="Tell us something about you"
                rules={[
                  {
                    required: true,
                    message: `Please enter your summary`,
                  },
                ]}
              >
                <TextArea
                  placeholder="About You"
                  value={data.info_text}
                  rows={6}
                  onChange={(e) => {
                    setData({ ...data, info_text: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                disabled={loading}
                style={{ width: "100%", borderRadius: "10px" }}
              >
                Create an Account
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ProviderSignUp;
