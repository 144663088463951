import cx from "classnames";
import React, { useState } from "react";
import styles from "./ProgramSeason.module.css";
import { converter } from "../../../utils/htmlConverter/htmlConverter";

function ProgramSeason({ data }) {
  const [collapse, setCollapse] = useState({
    season: false,
    weather: false,
    visa: false,
  });

  return (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={cx(styles.content, collapse.season ? styles.contentOpen : null)}>
              <div className={styles.titleContainer}>
                <div className={styles.icon}>
                  <ion-icon name="information-outline"></ion-icon>
                </div>
                <div className={styles.title}>Seasonal Information</div>
              </div>
              <div
                className={cx(styles.subtitle, collapse.season ? styles.subtitleOpen : null)}
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(data.seasonal_info),
                }}
              />
              <div
                className={cx(styles.viewMore)}
                onClick={() =>
                  setCollapse({
                    ...collapse,
                    season: !collapse.season,
                  })
                }
              >
                {collapse.season ? "View Less" : "View More"}
                <div
                  className={styles.viewIcon}
                  style={!collapse.season ? { transform: "rotateZ(-180deg)" } : null}
                >
                  <ion-icon name="chevron-up-outline"></ion-icon>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={cx(styles.content, collapse.weather ? styles.contentOpen : null)}>
              <div className={styles.titleContainer}>
                <div className={styles.icon}>
                  <ion-icon name="cloud-outline"></ion-icon>
                </div>
                <div className={styles.title}>Weather Information</div>
              </div>
              <div
                className={cx(styles.subtitle, collapse.weather ? styles.subtitleOpen : null)}
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(data.weather_info),
                }}
              />
              <div
                className={styles.viewMore}
                onClick={() =>
                  setCollapse({
                    ...collapse,
                    weather: !collapse.weather,
                  })
                }
              >
                {collapse.weather ? "View Less" : "View More"}
                <div
                  className={styles.viewIcon}
                  style={!collapse.weather ? { transform: "rotateZ(-180deg)" } : null}
                >
                  <ion-icon name="chevron-up-outline"></ion-icon>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={cx(styles.content, collapse.visa ? styles.contentOpen : null)}>
              <div className={styles.titleContainer}>
                <div className={styles.icon}>
                  <ion-icon name="airplane-outline"></ion-icon>
                </div>
                <div className={styles.title}>Visa Stuff</div>
              </div>
              <div
                className={cx(styles.subtitle, collapse.visa ? styles.subtitleOpen : null)}
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(data.visa_stuff),
                }}
              />
              <div
                className={styles.viewMore}
                onClick={() =>
                  setCollapse({
                    ...collapse,
                    visa: !collapse.visa,
                  })
                }
              >
                {collapse.visa ? "View Less" : "View More"}
                <div
                  className={styles.viewIcon}
                  style={!collapse.visa ? { transform: "rotateZ(-180deg)" } : null}
                >
                  <ion-icon name="chevron-up-outline"></ion-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramSeason;
