import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../App";
import { ImageCarousel } from "../../components/ImageCarousel";
import Loading from "../../components/Loading";
import { getAllBlogs, getBlogsById } from "../../services/api/blogs";
import { getDateText } from "../../utils/dateTime/dateAndTime";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import { converter } from "../../utils/htmlConverter/htmlConverter";
import styles from "./BlogDetails.module.css";

function BlogDetails() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [recentBlogs, setRecentBlogs] = useState([]);
  const [blog, setBlog] = useState();

  const [loading, setLoading] = useState(false);
  const [widgetLoading, setWidgetLoading] = useState(false);

  const fetchAllBlogs = async (signal) => {
    setLoading(true);
    await getAllBlogs(1, "", signal)
      .then((res) => {
        if (res.status === "Success") {
          setRecentBlogs(res.data.blogs);
          setWidgetLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setWidgetLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchAllBlogs(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, [id]);

  const fetchBlogs = async (signal) => {
    setLoading(true);
    await getBlogsById(id, signal)
      .then((res) => {
        if (res.status === "Success") {
          setBlog(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchBlogs(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, [id]);

  return loading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.title}>{blog?.title}</div>
        <div className={styles.subtitleContainer}>
          <div className={styles.category}>{blog?.category.title}</div>
          <div className={styles.date}>{getDateText(blog?.created_at)}</div>
        </div>
        <div className={styles.gridContainer}>
          <div>
            <div style={{ margin: "0 auto" }}>
              <div className={styles.sliderWrapper}>
                {blog && (
                  <ImageCarousel
                    data={blog.media.map((item) => ({
                      id: item.id,
                      image: item.media,
                    }))}
                  />
                )}
              </div>
            </div>
            <div className={styles.content}>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(blog?.description),
                }}
              ></div>
            </div>
          </div>
          <div className={styles.widgetContainer}>
            <div className={styles.widgetTitle}>Recent Blogs</div>
            <div className={styles.widgetCardContainer}>
              {widgetLoading ? (
                <Spin />
              ) : (
                recentBlogs.slice(0, 4).map((blog) => (
                  <div className={styles.widgetCard}>
                    <div className={styles.widgetImageContainer}>
                      <img
                        src={
                          process.env.REACT_APP_SERVER_URL +
                          blog?.media[0].media
                        }
                        alt={blog?.title}
                        loading="lazy"
                        className={styles.widgetImage}
                      />
                    </div>

                    <div
                      className={styles.widgetCardTitle}
                      onClick={() => navigate(`/blogs/${blog.id}`)}
                    >
                      {blog?.title}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
