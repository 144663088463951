import React, { useContext, useState } from "react";
import styles from "./SearchCard.module.css";
import { toggleFav } from "../../services/api/programs";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { SignInModal } from "../SignInModal";
import { converter } from "../../utils/htmlConverter/htmlConverter";
import { getFixedScheduleDate, getSeasonalDate } from "../../utils/dateTime/dateAndTime";
import { Rate } from "antd";

const SearchCard = ({ cardItem, setRefresh }) => {
  const [liked, setLiked] = useState(cardItem.is_favourite === 1);
  const [openModal, setOpenModal] = useState(false);

  const { userData } = useContext(UserContext);

  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const navigate = useNavigate();
  return (
    <div className={styles.card}>
      <figure className={styles.cardBanner}>
        <img
          className={styles.image}
          src={process.env.REACT_APP_SERVER_URL + cardItem.media[0]?.link}
          alt="card"
          loading="lazy"
        />
        {userData.role === "user" ? (
          <div className={styles.likeContainer}>
            <div
              className={styles.likeIcon}
              onClick={(e) => {
                setLiked((prev) => !prev);
                setRefresh(true);
                toggleFav(cardItem.id)
                  .then((res) => {
                    setRefresh(false);
                  })
                  .catch((err) => {
                    setLiked(!liked);
                  });
              }}
            >
              {liked ? (
                <ion-icon name="heart"></ion-icon>
              ) : (
                <ion-icon name="heart-outline"></ion-icon>
              )}
            </div>
          </div>
        ) : userData.role !== "provider" ? (
          <div className={styles.likeContainer}>
            <div
              className={styles.likeIcon}
              onClick={(e) => {
                setOpenModal(true);
              }}
            >
              {liked ? (
                <ion-icon name="heart"></ion-icon>
              ) : (
                <ion-icon name="heart-outline"></ion-icon>
              )}
            </div>
          </div>
        ) : null}
      </figure>
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{cardItem.title}</h3>
        <p
          className={styles.cardText}
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(cardItem.description),
          }}
        ></p>
        <ul className={styles.cardMetaList}>
          <li className={styles.cardMetaItem}>
            <div className={styles.metaBox}>
              <ion-icon name="time"></ion-icon>
              <p className={styles.text}>
                {cardItem.scheduling === 1 ? (
                  "Available All Year Around"
                ) : cardItem.scheduling === 0 ? (
                  getFixedScheduleDate(cardItem.schedules[0])
                ) : cardItem.scheduling === 2 ? (
                  <>{getSeasonalDate(cardItem.schedules)}</>
                ) : (
                  <>{cardItem.schedules[0].program_days}</>
                )}
              </p>
            </div>
          </li>
          <li className={styles.cardMetaItem}>
            <div class={styles.metaBox}>
              <ion-icon name="location"></ion-icon>
              <p class={styles.text}>{cardItem.country.name}</p>
            </div>
          </li>
        </ul>
      </div>
      <div className={styles.cardPrice}>
        <div className={styles.wrapper}>
          <p className={styles.reviews}>
            ({cardItem.reviews_count} {cardItem.reviews_count === 1 ? "review" : "reviews"})
          </p>
          <div className={styles.cardRating}>
            <Rate
              defaultValue={cardItem.reviews_avg_rating}
              disabled
              style={{ fontSize: "12px" }}
            />
          </div>
        </div>
        <p className={styles.price}>
          ${cardItem.accomodation_min_price || cardItem.price} <span>/ per person</span>
        </p>
        <button
          className={styles.button}
          onClick={() => {
            navigate(`/programs/${cardItem.slug}/`);
          }}
        >
          Book Now
        </button>
      </div>
      <SignInModal openModal={openModal} closeModal={closeModal} showModal={showModal} />
    </div>
  );
};

export default SearchCard;
