import cx from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getPopularDestination } from "../../../services/api/featured";
import styles from "./Destinations.module.css";

function Destinations() {
  const { width } = useWindowDimensions();

  let sideItems = 1;
  if (width > 1900) {
    sideItems = 2;
  }
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const handleSubmit = () => {
    let formData = {
      location: { id: null, name: "Anywhere" },
      category: { id: null, title: "All" },
      date: { type: "nodate", text: "No set dates" },
    };

    navigate("/search");
  };

  const handleClick = (item) => {
    navigate(`/search?location=${item.slug}`);
  };

  useState(() => {
    let abortCont = new AbortController();
    getPopularDestination(abortCont.signal)
      .then((res) => {
        setData(res.data.destinations);
      })
      .catch((err) => {});
    return () => {
      abortCont.abort();
    };
  }, []);

  var settings = {
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 1,
    // dots: true,
    autoplay: true,
    // dotsClass: "button__bar",
    centerMode: true,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          //   centerMode: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          //   centerMode: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          //   centerMode: true,
        },
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          //   centerMode: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          //   centerMode: true,
        },
      },
    ],
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img
          className={styles.bgImg}
          src="/images/background/bg7.png"
          alt="bg"
          loading="lazy"
        />
        <img
          className={styles.bgImgTwo}
          src="/images/background/himal.png"
          alt="bg"
          loading="lazy"
        />
        <div className="container">
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <div className={cx("section-title", styles.title)}>
                Explore The Most Loved <span> Yoga Destination</span>
              </div>
              <div className={cx("section-subtitle", styles.subtitle)}>
                Ready to weave yoga into your next adventure, or deepen your
                existing practice amidst Nepal's breathtaking landscapes?
                Explore our handpicked destinations for an unforgettable
                experience.
              </div>
            </div>
            <div
              className={cx("light-btn", styles.button)}
              onClick={handleSubmit}
            >
              Start Exploring
            </div>
          </div>
        </div>
        <Swiper
          className={styles.swiperContainer}
          modules={[Navigation, EffectCoverflow, Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          centeredSlides={true}
          roundLengths={true}
          loop={true}
          loopAdditionalSlides={30}
          effect={"coverflow"}
          grabCursor={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.75,
            slideShadows: true,
          }}
          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide className={styles.slide} key={index}>
              <div className={styles.headerContainer}>
                <div className={styles.icon}>
                  <ion-icon name="location-outline"></ion-icon>
                </div>
                <div
                  className={styles.carouselTitleContainer}
                  onClick={() => handleClick(item)}
                >
                  <div className={styles.carouselSideTitle}>{item.title}</div>
                  <p className={styles.carouselSideSubtitle}>
                    {item.no_of_programs} yoga programs
                  </p>
                </div>
              </div>
              <img
                src={process.env.REACT_APP_SERVER_URL + item.photo}
                className={styles.swiperImage}
                alt="slider"
                loading="lazy"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Destinations;
