import { baseRequest } from "./base";

const addCustomization = async (data) => {
  var response = await baseRequest({
    url: "/api/yoga-customizations",
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });

  return response;
};

export { addCustomization };
