import React from "react";
import styles from "./JoinUs.module.css";
import { Hero } from "../../components/Hero";
import cx from "classnames";

const data = [
  {
    img: "/images/careers/bulb.png",
    title: "Your ideas work here",
    subtitle: "Lorem ipsum dolor sit amet consectetur. Tristique nec erat ultrices molestie mi eu ",
  },
  {
    img: "/images/careers/graph.png",
    title: "Growthaholic",
    subtitle: "Lorem ipsum dolor sit amet consectetur. Tristique nec erat ultrices molestie mi eu ",
  },
  {
    img: "/images/careers/ear.png",
    title: "We hear you",
    subtitle: "Lorem ipsum dolor sit amet consectetur. Tristique nec erat ultrices molestie mi eu ",
  },
  {
    img: "/images/careers/up.png",
    title: "Upgrade Skills",
    subtitle: "Lorem ipsum dolor sit amet consectetur. Tristique nec erat ultrices molestie mi eu ",
  },
];

const positions = [
  {
    title: "Yoga Trainer",
    year: "2 Years",
    date: "2023-05-08",
  },
  {
    title: "Yoga Trainer",
    year: "2 Years",
    date: "2023-05-08",
  },
  {
    title: "Yoga Trainer",
    year: "2 Years",
    date: "2023-05-08",
  },
  {
    title: "Yoga Trainer",
    year: "2 Years",
    date: "2023-05-08",
  },
];

function JoinUs() {
  return (
    <>
      <Hero
        title="Join Us"
        subtitle="Join our team for Digital Marketing jobs in Nepal and help shape the future of digital marketing. We believe in learning, growing, and celebrating diversity together at Yogsewa to create amazing things."
      />
      <div className={styles.container}>
        <div className="container">
          <div className={styles.gridContainer}>
            <div className={styles.headerContainer}>
              <div className={styles.subtitle}>Benefits</div>
              <div className={styles.title}>Why you Should Join Our Awesome Team</div>
              <div className={styles.details}>
                Being a leading digital marketing agency in Nepal, we value our employees. With a
                strong team and a fantastic working environment, Feat has much more to offer!
              </div>
            </div>
            <div className={styles.typeContainer}>
              {data.map((type) => (
                <div className={styles.card} key={type.title}>
                  <img src={type.img} alt={type.title} className={styles.img} />
                  <div className={styles.typeTitle}>{type.title}</div>
                  <div className={styles.typeSubtitle}>{type.subtitle}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.openings}>
        <div className="container">
          <div className={cx(styles.subtitle, styles.joinSubtitle)}>COME JOIN US</div>
          <div className={styles.title}>Opening Positions</div>
          <div className={cx(styles.details, styles.joinDetails)}>
            We offer digital marketing jobs in Nepal for those who want to build a strong career.
            Join us to help create a powerful platform for the next generation of creators.
          </div>
          <div className={styles.positionContainer}>
            {positions.map((position) => (
              <div className={styles.positionCard} key={position.title}>
                <div className={styles.positionTitle}>{position.title}</div>
                <div className={styles.positionHeaderContainer}>
                  <div className={styles.positionHeader}>Experience</div>
                  <div className={styles.years}>{position.year}</div>
                </div>
                <div className={styles.positionHeaderContainer}>
                  <div className={styles.positionHeader}>Dealine</div>
                  <div className={styles.years}>{position.date}</div>
                </div>
                <div className={styles.icon}>
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default JoinUs;
