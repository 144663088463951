import React, { useState } from "react";
import Slider from "react-slick";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import cx from "classnames";
import styles from "./ImageCarousel.module.css";

function SampleNextArrow(props) {
  const { className, style, onClick, data } = props;
  return (
    <div
      className={cx(styles.sliderNextArrow, className.includes("disabled") && styles.disabled)}
      style={{ ...style }}
      onClick={onClick}
    >
      <MdKeyboardArrowRight className={styles.arrowIcon} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={cx(styles.sliderPrevArrow, className.includes("disabled") && styles.disabled)}
      style={{
        ...style,
      }}
      onClick={onClick}
    >
      <MdKeyboardArrowLeft className={styles.arrowIcon} />
    </div>
  );
}

function ImageCarousel({ data, customPage = true }) {
  const [selectedSlide, setSelectedSlide] = useState(0);
  const settings = {
    dots: customPage && true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div>
        <svg
          width="41"
          height="4"
          viewBox="0 0 41 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="41" height="4" rx="2" fill={selectedSlide === i ? "#FE8208" : "#EDF1F4"} />
        </svg>
      </div>
    ),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className={cx(styles.sliderContainer, "program-detail-slider")}>
      <Slider {...settings} className="program-slider" afterChange={setSelectedSlide}>
        {data.map((item, index) => {
          return (
            <div>
              <div key={item.id} className={styles.imageContainer}>
                <img
                  src={process.env.REACT_APP_SERVER_URL + item.image}
                  alt="details"
                  className={styles.image}
                  loading="lazy"
                />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default ImageCarousel;
