import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBookingById } from "../../../services/api/bookings";
import { getDateText } from "../../../utils/dateTime/dateAndTime";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import { converter } from "../../../utils/htmlConverter/htmlConverter";
import styles from "./BookingDetails.module.css";
import { Spin } from "antd";

const BookingDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    let abortCont = new AbortController();
    setLoading(true);
    getBookingById(id, abortCont.signal)
      .then((res) => {
        setData(res.data.booking);
      })
      .catch((err) => {
        axiosErrorHandler(err, () => {});
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      abortCont.abort();
    };
  }, [id]);
  return loading ? (
    <div className={styles.container}>
      <Spin />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.title}>Booking Details</div>
      <div className={styles.body}>
        <div className={styles.orderWrapper}>
          <div className={styles.uniqueIdWrapper}>
            Order <div className={styles.uniqueId}>{data.unique_id}</div>
          </div>
          <div className={styles.placedDate}>Placed on {getDateText(data.created_at)}</div>
        </div>
        <div className={styles.programWrapper}>
          <div className={styles.accomodation}>Program Details</div>
          <div className={styles.programContent}>
            <div className={styles.imageWrapper}>
              <img
                src={process.env.REACT_APP_SERVER_URL + data.yoga_program?.media[0].link}
                className={styles.image}
                alt="booking"
                loading="lazy"
              />
            </div>
            <div className={styles.programTitleContainer}>
              <div
                className={styles.programTitle}
                onClick={() => {
                  navigate(`/programs/${data.yoga_program?.id}/`);
                }}
              >
                {data.yoga_program?.title}
              </div>
              <div className={styles.location}>
                {data.yoga_program?.area}, {data.yoga_program?.country?.name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bookingWrapper}>
          <div className={styles.item}>
            <div className={styles.label}>Name:</div>
            <div className={styles.value}>{data.name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Program Start:</div>
            <div className={styles.value}>{data.date}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Program Duration:</div>
            <div className={styles.value}>{data.num_of_days} days</div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Seats:</div>
            <div className={styles.value}>{data.num_of_seats}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Total:</div>
            <div className={styles.value}>{data.total}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Payment:</div>
            <div className={styles.value}>{data.payment_status === 0 ? "Not-paid" : "Paid"}</div>
          </div>
        </div>
        <div className={styles.accomodationWrapper}>
          <div className={styles.accomodation}>Accomodation Details</div>
          <div className={styles.accomodationContent}>
            <div className={styles.imageWrapper}>
              <img
                src={process.env.REACT_APP_SERVER_URL + data.accomodation?.images[0].image}
                className={styles.image}
                loading="lazy"
                alt="accomodation"
              />
            </div>
            <div className={styles.acommodationTitleContainer}>
              {" "}
              <div className={styles.accomodationTitle}>{data.accomodation?.title}</div>
              <div
                className={styles.accomodationDescription}
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(data.accomodation?.description),
                }}
              />
            </div>
          </div>
          {/* images */}
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
