import cx from "classnames";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { toggleFav } from "../../services/api/programs";
import {
  getFixedScheduleDate,
  getSeasonalDate,
} from "../../utils/dateTime/dateAndTime";
import { SignInModal } from "../SignInModal";
import styles from "./PackageCard.module.css";

function PackageCard({ item, programDetails = false }) {
  const [liked, setLiked] = useState(item.is_favourite === 1);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const { userData } = useContext(UserContext);

  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        {item.reviews_avg_rating && (
          <div className={styles.ratingContainer}>
            <div className={styles.ratingIcon}>
              <ion-icon name="star"></ion-icon>
            </div>
            <div className={styles.rate}>
              {item.reviews_avg_rating.toFixed(1)}
            </div>
          </div>
        )}
        {userData.role === "user" ? (
          <div className={styles.likeContainer}>
            <div
              className={styles.likeIcon}
              onClick={(e) => {
                setLiked((prev) => !prev);
                toggleFav(item.id)
                  .then((res) => {})
                  .catch((err) => {
                    setLiked(!liked);
                  });
              }}
            >
              {liked ? (
                <ion-icon name="heart"></ion-icon>
              ) : (
                <ion-icon name="heart-outline"></ion-icon>
              )}
            </div>
          </div>
        ) : userData.role !== "provider" ? (
          <div className={styles.likeContainer}>
            <div
              className={styles.likeIcon}
              onClick={(e) => {
                setOpenModal(true);
              }}
            >
              {liked ? (
                <ion-icon name="heart"></ion-icon>
              ) : (
                <ion-icon name="heart-outline"></ion-icon>
              )}
            </div>
          </div>
        ) : null}
        <img
          className={styles.image}
          src={process.env.REACT_APP_SERVER_URL + item.media[0].link}
          alt="card"
          loading="lazy"
        />
      </div>
      {programDetails ? (
        <a
          className={styles.title}
          title={item.title}
          href={`/programs/${item.slug}`}
        >
          {item.title}
        </a>
      ) : (
        <h3
          className={styles.title}
          title={item.title}
          onClick={() => {
            navigate(`/programs/${item.slug}`);
          }}
        >
          {item.title}
        </h3>
      )}
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <div className={styles.detailsContainer}>
            <div className={styles.flexbox}>
              <div className={styles.icon}>
                <ion-icon name="calendar-outline"></ion-icon>
              </div>
              <div className={styles.detail}>
                {item.scheduling === 1 ? (
                  "Available All Year Around"
                ) : item.scheduling === 0 ? (
                  getFixedScheduleDate(item.schedules[0])
                ) : item.scheduling === 2 ? (
                  <>{getSeasonalDate(item.schedules)}</>
                ) : (
                  <>
                    {"Daily "}
                    {"(" +
                      item.schedules[0]?.start_time +
                      "-" +
                      item.schedules[0]?.end_time +
                      ")"}
                  </>
                )}
              </div>
            </div>
            <div className={styles.flexbox}>
              <div className={styles.icon}>
                <ion-icon name="location-outline"></ion-icon>
              </div>
              <div className={styles.detail}>{item.country.name}</div>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.priceContainer}>
              <h4 className={styles.priceTitle}>From</h4>
              <h4 className={styles.price}>
                ${" "}
                {item.accomodation_min_price
                  ? item.accomodation_min_price
                  : item.price}
              </h4>
            </div>
            {programDetails ? (
              <a
                className={cx("light-btn", styles.btn)}
                href={`/programs/${item.slug}`}
              >
                Book Now
              </a>
            ) : (
              <button
                className={cx("light-btn", styles.btn)}
                onClick={() => {
                  navigate(`/programs/${item.slug}`);
                }}
              >
                Book Now
              </button>
            )}
          </div>
        </div>
      </div>
      <SignInModal
        openModal={openModal}
        closeModal={closeModal}
        showModal={showModal}
      />
    </div>
  );
}

export default PackageCard;
