import { FrownOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CustomPagination from "../../../components/Pagination";
import { getUserReviews } from "../../../services/api/reviews";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import styles from "./ReviewHistory.module.css";
import ReviewHistoryCard from "./ReviewHistoryCard";

const ReviewHistory = ({ refresh, setRefresh }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const total = useRef(1);
  const lastPage = useRef(1);
  const pageSize = useRef(1);

  useEffect(() => {
    const abortCont = new AbortController();
    setLoading(true);
    getUserReviews(page, abortCont.signal)
      .then((res) => {
        total.current = res.data.total;
        lastPage.current = res.data.last_page;
        pageSize.current = res.data.page_size;
        setLoading(false);
        setData(res.data.reviews);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err);
      });
    return () => {
      abortCont.abort();
    };
  }, [refresh, page]);

  return (
    <div>
      {loading ? (
        <div className={styles.container}>
          <Spin />
        </div>
      ) : data.length <= 0 ? (
        <div className={styles.emptyContainer}>
          <FrownOutlined style={{ fontSize: "40px" }} className={styles.icon} />
          <div className={styles.message}>You haven't written any review.</div>
        </div>
      ) : (
        <div>
          {data.map((item, index) => (
            <ReviewHistoryCard review={item} key={item.id} />
          ))}
        </div>
      )}

      {lastPage.current > 1 && (
        <div>
          <CustomPagination
            page={page}
            setPage={setPage}
            total={total.current}
            pageSize={pageSize.current}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewHistory;
