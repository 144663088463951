import { LogoutOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import cx from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import menuItems from "./menuItems";
import styles from "./styles.module.css";

function Sidebar({ isCollapsed, handleLogout }) {
  const { SubMenu } = Menu;
  // const { width } = useWindowDimensions();

  const navigate = useNavigate();
  const location = useLocation();

  const getMenuItemClassName = (menuItemPath) => {
    return cx(styles.menuTitle, {
      [styles.active]: location.pathname === menuItemPath,
    });
  };

  return (
    <div className={styles.container}>
      <Menu
        className={styles.menu}
        mode="inline"
        selectedKeys={[location.pathname]}
        defaultOpenKeys={["sub1"]}
      >
        <figure className={styles.imageContainer}>
          <img
            src="/images/logo-with-tagline.png"
            alt="logo"
            className={styles.logo}
            style={
              !isCollapsed
                ? { width: "fit-content", transition: "all 1s" }
                : { display: "none" }
            }
            onClick={() => navigate("/partner/")}
          />
        </figure>

        {menuItems.map((menuItem, index) =>
          menuItem.subNav ? (
            <SubMenu
              icon={menuItem.icon}
              title={menuItem.title}
              id="sidebar"
              key={index}
            >
              {menuItem.subNav.map((subNavItem, index) => (
                <Menu.Item
                  key={subNavItem.path}
                  icon={subNavItem.icon}
                  className={getMenuItemClassName(subNavItem.path)}
                  onClick={() => navigate(subNavItem.path)}
                >
                  {subNavItem.title}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              icon={menuItem.icon}
              key={menuItem.path}
              className={getMenuItemClassName(menuItem.path)}
              onClick={() => navigate(menuItem.path)}
            >
              {menuItem.title}
            </Menu.Item>
          )
        )}
        <Menu.Item icon={<LogoutOutlined />} onClick={handleLogout}>
          Log Out
        </Menu.Item>
      </Menu>
      <div className={styles.copyright}>
        {" "}
        Copyright © 2022 Yogsewa | All Rights Reserved
      </div>
    </div>
  );
}

export default Sidebar;
