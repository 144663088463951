import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Popover,
  Row,
  Select,
  TimePicker,
} from "antd";
import { Option } from "antd/lib/mentions";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import ImageUpload from "../../../components/ImageUpload";
import TextEditor from "../../../components/TextEditor";
import styles from "./ProgramUpdate.module.css";
import { addProgram, getAllCategories } from "../../../../services/api/programs";
import { getAllCountries } from "../../../../services/api/country";
import { notifyError, notifySuccess } from "../../../../App";
import { axiosErrorHandler } from "../../../../utils/errorHandler/axiosErrorHandler";
import { CloseOutlined, ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import CustomLocationPicker from "../../../components/LocationPicker/CustomLocationPicker";

const status = [
  { name: "active", id: 1, value: 1 },
  { name: "inactive", id: 2, value: 0 },
];
const booking = [
  { name: "open", id: 1, value: 1 },
  { name: "closed", id: 2, value: 0 },
];

const programDays = [
  { id: 1, name: "Everyday" },
  { id: 2, name: "Sun - Fri" },
  { id: 3, name: "Mon - Fri" },
];

function ProgramUpdate({ mode }) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const [country, setCountry] = useState([]);

  const [photo, setPhoto] = useState([]);

  const [data, setData] = useState({
    title: "",
    description: "",
    summary: "",
    program: "",
    feature_style: "",
    feature_skill_level: "",
    feature_food: "",
    benefits: "",
    price: "",
    scheduling: "",
    schedules: [{ start_date: "", end_date: "" }],
    start_time: "",
    end_time: "",
    program_days: "",
    num_of_days: "",
    seat_capacity: "",
    country_id: "",
    location: "",
    area: "",
    seasonal_info: "",
    weather_info: "",
    visa_stuff: "",
    status: "",
    booking_status: "",
    num_of_days: "",
    yoga_instructors_id: [],
    accomodation: [],
    instructor: [],
  });

  // const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [imageSizeError, setImageSizeError] = useState("");
  const [pointVals, setPointVals] = useState([]);

  const location = useLocation();
  const config = location.state ? location.state.config : null;
  const navigate = useNavigate();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.fileList);
    }
    return e && e.fileList;
  };
  const handleSubmit = () => {
    let err = {};
    var accomodation = [];
    var instructor = [];

    if (photo.length === 0) {
      err["photo"] = ["Upload atleast one photo"];
    }
    if (data.title.length === 0) {
      err["title"] = ["Title is required."];
    }
    if (data.description.length === 0) {
      err["description"] = ["Description is required."];
    }
    if (data.summary.length === 0) {
      err["summary"] = ["Summary is required."];
    }
    if (data.program.length === 0) {
      err["program"] = ["Program Details is required."];
    }

    if (data.feature_style.length === 0) {
      err["feature_style"] = ["Feature Style is required."];
    }
    if (data.feature_skill_level.length === 0) {
      err["feature_skill_level"] = ["Feature Skill Level is required."];
    }
    if (data.benefits.length === 0) {
      err["benefits"] = ["Benefits is required."];
    }
    if (!config.accomodation && data.price.length === 0) {
      err["price"] = ["Price is required."];
    }
    if (config.seatCapacity && data.seat_capacity.length === 0) {
      err["seat_capacity"] = ["Seat Capacity is required."];
    }
    if (data.country_id.length === 0) {
      err["country_id"] = ["Country is required."];
    }
    if (data.area.length === 0) {
      err["area"] = ["Area is required."];
    }
    if (data.location.length === 0) {
      err["location"] = ["Location is required."];
    }
    if (config.scheduling !== 3 && data.num_of_days.length === 0) {
      err["num_of_days"] = ["Num of days is required."];
    }
    if (config.scheduling === 3 && data.start_time.length === 0) {
      err["start_time"] = ["Available from is required."];
    }
    if (config.scheduling === 3 && data.end_time.length === 0) {
      err["end_time"] = ["Available to is required."];
    }
    if (config.scheduling === 3 && data.program_days.length === 0) {
      err["program_days"] = ["Program days is required."];
    }

    if (data.seasonal_info.length === 0) {
      err["seasonal_info"] = ["Seasonal Info is required."];
    }
    if (data.weather_info.length === 0) {
      err["weather_info"] = ["Weather Info is required."];
    }
    if (data.visa_stuff.length === 0) {
      err["visa_stuff"] = ["Visa Stuff is required."];
    }
    if (data.status.length === 0) {
      err["status"] = ["Status is required."];
    }
    if (data.booking_status.length === 0) {
      err["booking_status"] = ["Booking Status is required."];
    }
    if (pointVals.length === 0) {
      err["latitude"] = ["Select program location in the map"];
    }
    data.accomodation.forEach((item, index) => {
      if (item.title.length === 0) {
        err[`accomodation.${index}.title`] = ["Title is required."];
      }
      if (item.description.length === 0) {
        err[`accomodation.${index}.description`] = ["Description is required."];
      }
      if (item.price.length === 0) {
        err[`accomodation.${index}.price`] = ["Price is required."];
      }
      if (item.photo.length === 0) {
        err[`accomodation.${index}.photo`] = ["Photo is required."];
      }
      accomodation.push({
        title: item.title,
        image: item.photo.map((i) => i.originFileObj),
        price: item.price,
        description: item.description,
      });
    });
    data.instructor.forEach((item, index) => {
      if (item.name.length === 0) {
        err[`instructor.${index}.name`] = ["Name is required."];
      }
      if (item.description.length === 0) {
        err[`instructor.${index}.description`] = ["Description is required."];
      }
      if (item.designation.length === 0) {
        err[`instructor.${index}.designation`] = ["Designation is required."];
      }
      if (item.photo.length === 0) {
        err[`instructor.${index}.photo`] = ["Photo is required."];
      }
      instructor.push({
        name: item.name,
        image: item.photo[0] ? item.photo[0].originFileObj : null,
        designation: item.designation,
        description: item.description,
      });
    });

    if (config.scheduling === 0 || config.scheduling === 2) {
      data.schedules.forEach((item, index) => {
        if (item.start_date.length === 0) {
          err[`schedules.${index}.start_date`] = ["Date is required."];
        }
        if (item.end_date.length === 0) {
          err[`schedules.${index}.end_date`] = ["End date is required."];
        }
      });
    }
    setErrors(err);
    if (Object.keys(err).length > 0) {
      return;
    }

    var newPhotos = [];
    var deletedPhotoIds = [];

    // oldPhotos.forEach((photo) => {
    //   if (photo.status === "removed") {
    //     deletedPhotoIds.push(photo.id); // deleted photos id
    //   }
    // });

    photo.forEach((photo) => {
      if (photo.originFileObj) {
        newPhotos.push(photo.originFileObj); // add new photos
      }
    });

    var formData = {
      yoga_category_id: config.category.id,
      title: data.title,
      description: data.description,
      summary: data.summary,
      program: data.program,
      program_morning: data.program_morning,
      program_afternoon: data.program_afternoon,
      program_evening: data.program_evening,
      feature_style: data.feature_style,
      feature_skill_level: data.feature_skill_level,
      feature_food: data.feature_food,
      benefits: data.benefits,
      price: data.price,
      scheduling: config.scheduling,
      schedules: data.schedules,
      num_of_days: data.num_of_days,
      start_date: data.start_date,
      end_date: data.end_date,
      seat_capacity: data.seat_capacity,
      country_id: data.country_id,
      area: data.area,
      location: data.location,
      seasonal_info: data.seasonal_info,
      weather_info: data.weather_info,
      visa_stuff: data.visa_stuff,
      status: data.status,
      booking_status: data.booking_status,
      photos: newPhotos,
      accomodation: accomodation,
      num_of_days: 1,
      latitude: pointVals[0][0],
      longitude: pointVals[0][1],
      instructor: instructor,
      start_time: data.start_time,
      end_time: data.end_time,
      program_days: data.program_days,
    };

    setLoading(true);
    if (mode === "add") {
      addProgram(formData)
        .then((res) => {
          if (res.status === "Success") {
            notifySuccess(res.message);
            setLoading(true);
            navigate("/partner/yoga-programs", {
              state: {
                config: config,
              },
            });
          }
        })
        .catch((err) => {
          axiosErrorHandler(err, notifyError);
          if (err.response && err.response.data) {
            setErrors(err.response.data.data);
          }
          setLoading(false);
        });
    }
  };

  const handleDateChange = (index, dateString) => {
    // setData({
    //   ...data,
    //   scheduling: [{ start_date: dateString[0], end_date: dateString[1] }],
    // });
    setData((prev) => {
      let p = [...prev.schedules];
      p.forEach((item, i) => {
        if (i === index) {
          item.start_date = dateString[0];
          item.end_date = dateString[1];
        }
      });
      return { ...prev, schedules: p };
    });
  };
  const removeDate = (index) => {
    setData((prev) => {
      let n = prev.schedules.filter((item, i) => {
        if (i !== index) {
          return item;
        }
      });
      if (n.length === 0) {
        n = [
          {
            start_date: "",
            end_date: "",
          },
        ];
      }
      return { ...prev, schedules: n };
    });
  };

  const addAccomodation = () => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc.push({
        title: "",
        description: "",
        price: "",
        photo: [],
      });
      return { ...data, accomodation: prevAcc };
    });
  };

  const handleAccomodationTitleChange = (e, index) => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc[index].title = e.target.value;
      return { ...data, accomodation: prevAcc };
    });
  };
  const handleAccomodationDescriptionChange = (val, index) => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc[index].description = val;
      return { ...data, accomodation: prevAcc };
    });
  };
  const handleAccomodationPriceChange = (e, index) => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc[index].price = e.target.value;
      return { ...data, accomodation: prevAcc };
    });
  };

  const handleAccomodationPhotoChange = (list, index) => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc[index].photo = list;
      return { ...data, accomodation: prevAcc };
    });
  };

  const removeAccomodation = (index) => {
    setData((prev) => {
      let newData = prev.accomodation.filter((item, ind) => {
        if (index !== ind) {
          return item;
        }
      });
      return { ...data, accomodation: newData };
    });
  };

  const addInstructor = () => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc.push({
        name: "",
        designation: "",
        description: "",
        photo: [],
      });
      return { ...data, instructor: prevAcc };
    });
  };

  const handleInstructorNameChange = (e, index) => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc[index].name = e.target.value;
      return { ...data, instructor: prevAcc };
    });
  };

  const handleInstructorDesignationChange = (e, index) => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc[index].designation = e.target.value;
      return { ...data, instructor: prevAcc };
    });
  };

  const handleInstructorDescriptionChange = (val, index) => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc[index].description = val;
      return { ...data, instructor: prevAcc };
    });
  };

  const handleInstructorPhotoChange = (list, index) => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc[index].photo = list;
      return { ...data, instructor: prevAcc };
    });
  };

  const removeInstructor = (index) => {
    setData((prev) => {
      let newData = prev.instructor.filter((item, ind) => {
        if (index !== ind) {
          return item;
        }
      });
      return { ...data, instructor: newData };
    });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getAllCountries(abortCont.signal).then((res) => {
      setCountry(res.data.countries);
    });
    return () => {
      abortCont.abort();
    };
  }, []);

  useEffect(() => {
    if (imageSizeError) {
      message.error(imageSizeError);
    }
  }, [imageSizeError]);

  return config === null ? (
    <Navigate to="/partner/yoga-programs/config/" />
  ) : (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>{mode === "add" ? "Add" : "Edit"} Program</h2>
        </div>
      </div>

      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onSubmit={(e) => e.preventDefault()}
        form={form}
        id="program-form"
      >
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            Yoga Category: {config.category.title}
          </Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Title"
              name="Title"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["title"] ? "error" : "success"}
              help={errors["title"] ? errors["title"][0] : ""}
            >
              <Input
                placeholder="Title"
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col
          xxl={{ span: 12 }}
          xl={{ span: 12 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
        >
          <Form.Item
            name="dragger"
            listType="picture"
            getValueFromEvent={normFile}
            validateStatus={errors["photo"] ? "error" : "success"}
            help={errors["photo"] ? errors["photo"][0] : ""}
          >
            <ImageUpload
              fileList={photo}
              setFileList={setPhoto}
              multiple={true}
              photoWidth="625"
              photoHeight="348"
              setError={(msg) => {
                setImageSizeError(msg);
              }}
            />
          </Form.Item>
        </Col>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Summary"
              name="Summary"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["summary"] ? "error" : "success"}
              help={errors["summary"] ? errors["summary"][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.summary}
                onChange={(value) => setData({ ...data, summary: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Description"
              name="Description"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["description"] ? "error" : "success"}
              help={errors["description"] ? errors["description"][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.description}
                onChange={(value) => setData({ ...data, description: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Program Details"
              name="Program"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["program"] ? "error" : "success"}
              help={errors["program"] ? errors["program"][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.program}
                onChange={(value) => setData({ ...data, program: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Morning Program{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img src="/images/program/morning.gif" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo <ExclamationCircleOutlined style={{ color: "#108ee9" }} />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img src="/images/program/morning.jpg" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="MorningProgram"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["program_morning"] ? "error" : "success"}
              help={errors["program_morning"] ? errors["program_morning"][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.program_morning}
                onChange={(value) => setData({ ...data, program_morning: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Afternoon Program{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img src="/images/program/morning.gif" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo <ExclamationCircleOutlined style={{ color: "#108ee9" }} />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img src="/images/program/morning.jpg" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="AfternoonProgram"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["program_afternoon"] ? "error" : "success"}
              help={errors["program_afternoon"] ? errors["program_afternoon"][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.program_afternoon}
                onChange={(value) => setData({ ...data, program_afternoon: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Evening Program{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img src="/images/program/morning.gif" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo <ExclamationCircleOutlined style={{ color: "#108ee9" }} />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img src="/images/program/morning.jpg" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="EveningProgram"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["program_evening"] ? "error" : "success"}
              help={errors["program_evening"] ? errors["program_evening"][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.program_evening}
                onChange={(value) => setData({ ...data, program_evening: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Feature Style"
              name="FeatureStyle"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["feature_style"] ? "error" : "success"}
              help={errors["feature_style"] ? errors["feature_style"][0] : ""}
            >
              <TextArea
                placeholder="Feature Styles(General Yoga, Hatha Yoga, Restorative Yoga, Vipassana & Yoga)"
                value={data.feature_style}
                rows={3}
                onChange={(e) => {
                  setData({ ...data, feature_style: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
          {config.food && (
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Feature Food"
                name="FeatureFood"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors["feature_food"] ? "error" : "success"}
                help={errors["feature_food"] ? errors["feature_food"][0] : ""}
              >
                <TextArea
                  placeholder="Feature Food(Vegetarian, Organic, Low Fat, Diabetic-Friendly, Sugar Consciuos)"
                  value={data.feature_food}
                  rows={3}
                  onChange={(e) => {
                    setData({ ...data, feature_food: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Feature Skill Level"
              name="FeatureSkillLevel"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["feature_skill_level"] ? "error" : "success"}
              help={errors["feature_skill_level"] ? errors["feature_skill_level"][0] : ""}
            >
              <TextArea
                placeholder="Feature Skill Level(General Yoga, Hatha Yoga, Restorative Yoga, Vipassana & Yoga)"
                value={data.feature_skill_level}
                rows={3}
                onChange={(e) => {
                  setData({ ...data, feature_skill_level: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Benefits{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img src="/images/program/morning.gif" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo <ExclamationCircleOutlined style={{ color: "#108ee9" }} />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img src="/images/program/morning.jpg" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="Benefits"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["benefits"] ? "error" : "success"}
              help={errors["benefits"] ? errors["benefits"][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.benefits}
                onChange={(value) => setData({ ...data, benefits: value })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {!config.accomodation && (
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Price"
                name="Price"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors["price"] ? "error" : "success"}
                help={errors["price"] ? errors["price"][0] : ""}
              >
                <Input
                  type="number"
                  placeholder="Price"
                  value={data.price}
                  onChange={(e) => {
                    setData({ ...data, price: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          )}

          {config.scheduling !== 3 && (
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Total Days of Program"
                name="num_of_days"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors["num_of_days"] ? "error" : "success"}
                help={errors["num_of_days"] ? errors["num_of_days"][0] : ""}
              >
                <Input
                  type="number"
                  placeholder="Total days of program"
                  value={data.num_of_days}
                  onChange={(e) => {
                    setData({ ...data, num_of_days: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          )}

          {config.scheduling === 2 ? (
            <>
              <Col
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
              >
                <Form.Item label="Date for programs">
                  <Button
                    type="primary"
                    onClick={() => {
                      setData({
                        ...data,
                        schedules: [...data.schedules, { start_date: "", end_date: "" }],
                      });
                    }}
                  >
                    Add
                  </Button>
                </Form.Item>
              </Col>

              {data.schedules.map((item, index) => (
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  key={index}
                >
                  <Form.Item
                    label={`Date ${index + 1}`}
                    name={`Date-${index}`}
                    labelCol={{ span: 22 }}
                    wrapperCol={{ span: 24 }}
                    validateStatus={
                      errors[`schedules.${index}.start_date`] ||
                      errors[`schedules.${index}.end_date`]
                        ? "error"
                        : "success"
                    }
                    help={
                      errors[`schedules.${index}.start_date`]
                        ? errors[`schedules.${index}.start_date`][0]
                        : errors[`schedules.${index}.end_date`]
                        ? errors[`schedules.${index}.end_date`][0]
                        : ""
                    }
                  >
                    <div className={styles.dateWrapper}>
                      <RangePicker
                        value={[
                          item.start_date.length > 0 ? moment(item.start_date, "YYYY-MM-DD") : "",
                          item.end_date.length > 0 ? moment(item.end_date, "YYYY-MM-DD") : "",
                        ]}
                        onChange={(date, dateString) => {
                          handleDateChange(index, dateString);
                        }}
                      />
                      <Button
                        shape="circle"
                        size="small"
                        icon={<CloseOutlined style={{ fontSize: "10px" }} />}
                        className={styles.dateBtn}
                        type="danger"
                        onClick={() => {
                          removeDate(index);
                        }}
                      ></Button>
                    </div>
                  </Form.Item>
                </Col>
              ))}
            </>
          ) : config.scheduling === 0 ? (
            <>
              {data.schedules.map((item, index) => (
                <Col
                  xxl={{ span: 8 }}
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  key={index}
                >
                  <Form.Item
                    label={`Date for program`}
                    name={`Date-${index}`}
                    labelCol={{ span: 22 }}
                    wrapperCol={{ span: 24 }}
                    validateStatus={
                      errors[`schedules.${index}.start_date`] ||
                      errors[`schedules.${index}.end_date`]
                        ? "error"
                        : "success"
                    }
                    help={
                      errors[`schedules.${index}.start_date`]
                        ? errors[`schedules.${index}.start_date`][0]
                        : errors[`schedules.${index}.end_date`]
                        ? errors[`schedules.${index}.end_date`][0]
                        : ""
                    }
                  >
                    <RangePicker
                      value={[
                        item.start_date.length > 0 ? moment(item.start_date, "YYYY-MM-DD") : "",
                        item.end_date.length > 0 ? moment(item.end_date, "YYYY-MM-DD") : "",
                      ]}
                      onChange={(date, dateString) => {
                        setData({
                          ...data,
                          schedules: [
                            {
                              start_date: dateString[0],
                              end_date: dateString[1],
                            },
                          ],
                        });
                        //if state is dictionary, set data like this
                      }}
                    />
                  </Form.Item>
                </Col>
              ))}
            </>
          ) : config.scheduling === 3 ? (
            <>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
              >
                <Form.Item
                  label={`Program Days in a week`}
                  name={`programDays`}
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={errors[`program_days`] ? "error" : "success"}
                  help={errors[`program_days`] ? errors[`program_days`][0] : ""}
                >
                  <Select
                    placeholder="Select routine"
                    style={{ width: 200 }}
                    value={data.program_days}
                    onChange={(value) => setData({ ...data, program_days: value })}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {programDays.map((item, index) => (
                      <Option key={item.id} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
              >
                <Form.Item
                  label={`Program available from`}
                  name={`startTime`}
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={errors[`start_time`] ? "error" : "success"}
                  help={errors[`start_time`] ? errors[`start_time`][0] : ""}
                >
                  <input
                    type="time"
                    value={data.start_time}
                    onChange={(e) => {
                      setData({ ...data, start_time: e.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
              >
                <Form.Item
                  label={`Program available to`}
                  name={`endTime`}
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={errors[`end_time`] ? "error" : "success"}
                  help={errors[`end_time`] ? errors[`end_time`][0] : ""}
                >
                  <input
                    type="time"
                    value={data.end_time}
                    onChange={(e) => {
                      setData({ ...data, end_time: e.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}

          {config.seatCapacity && (
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Seat Capacity"
                name="Seat Capacity"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors["seat_capacity"] ? "error" : "success"}
                help={errors["seat_capacity"] ? errors["seat_capacity"][0] : ""}
              >
                <Input
                  type="number"
                  placeholder="Seat Capacity"
                  value={data.seat_capacity}
                  onChange={(e) => {
                    setData({ ...data, seat_capacity: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 20 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Country"
              name="Country"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              validateStatus={errors["country_id"] ? "error" : "success"}
              help={errors["country_id"] ? errors["country_id"][0] : ""}
            >
              <Select
                placeholder="Select country"
                style={{ width: 200 }}
                value={data.country_id}
                onChange={(value) => setData({ ...data, country_id: value })}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {country.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[0, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Area"
              name="area"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["area"] ? "error" : "success"}
              help={errors["area"] ? errors["area"][0] : ""}
            >
              <Input
                placeholder="City or place name"
                value={data.area}
                onChange={(e) => {
                  setData({ ...data, area: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <CustomLocationPicker pointVals={pointVals} setPointVals={setPointVals} />
            {(errors[`latitude`] || errors["longitude"]) && (
              <div className={styles.fieldError}>{errors[`latitude`][0]}</div>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Location"
              name="Location"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["location"] ? "error" : "success"}
              help={errors["location"] ? errors["location"][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
        description: value depends on text editor(before setValue={setDescription}) 
      */}

              <TextEditor
                value={data.location}
                onChange={(value) => setData({ ...data, location: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Seasonal Info"
              name="SeasonalInfo"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `Please seasonal info`,
                },
              ]}
            >
              <TextArea
                row={6}
                placeholder="Seasonal Info"
                value={data.seasonal_info}
                onChange={(e) => {
                  setData({ ...data, seasonal_info: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Weather Info"
              name="WeatherInfo"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["weather_info"] ? "error" : "success"}
              help={errors["weather_info"] ? errors["weather_info"][0] : ""}
            >
              <TextArea
                row={6}
                placeholder="Weather Info"
                value={data.weather_info}
                onChange={(e) => {
                  setData({ ...data, weather_info: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Visa Stuff"
              name="VisaStuff"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["visa_stuff"] ? "error" : "success"}
              help={errors["visa_stuff"] ? errors["visa_stuff"][0] : ""}
            >
              <TextArea
                row={6}
                placeholder="Visa Stuff"
                value={data.visa_stuff}
                onChange={(e) => {
                  setData({ ...data, visa_stuff: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Status"
              name="Status"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["status"] ? "error" : "success"}
              help={errors["status"] ? errors["status"][0] : ""}
            >
              <Select
                placeholder="Status"
                style={{ width: 200 }}
                value={data.status}
                onChange={(value) => setData({ ...data, status: value })}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {status.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Booking Status"
              name="BookingStatus"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["booking_status"] ? "error" : "success"}
              help={errors["booking_status"] ? errors["booking_status"][0] : ""}
            >
              <Select
                placeholder="Select booking status"
                style={{ width: 200 }}
                value={data.booking_status}
                onChange={(value) => setData({ ...data, booking_status: value })}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {booking.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {config.accomodation && (
          <>
            <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
              <Form.Item wrapperCol={{ span: 16 }} label="Accomodation">
                <Button size="medium" onClick={addAccomodation}>
                  Add
                </Button>
              </Form.Item>
            </Col>
            <div className={styles.accomodationGrid}>
              {data.accomodation.map((item, index) => {
                return (
                  <div className={styles.accomodationContainer} key={index}>
                    <div className={styles.removeBtnContainer}>
                      <Button
                        size="small"
                        icon={<CloseOutlined />}
                        shape="circle"
                        onClick={() => {
                          removeAccomodation(index);
                        }}
                        className={styles.removeBtn}
                      />
                    </div>
                    <div className={styles.accGrid}>
                      <div className={styles.accomodationLabel}>Title</div>
                      <Col
                        key={index}
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                      >
                        <Input
                          placeholder="Title"
                          value={item.title}
                          onChange={(e) => {
                            handleAccomodationTitleChange(e, index);
                          }}
                        />
                        {errors[`accomodation.${index}.title`] && (
                          <div className={styles.fieldError}>
                            {errors[`accomodation.${index}.title`][0]}
                          </div>
                        )}
                      </Col>
                    </div>
                    <Col
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      className={styles.accGrid}
                    >
                      <ImageUpload
                        fileList={item.photo}
                        photoWidth="625"
                        photoHeight="348"
                        setFileList={(list) => {
                          handleAccomodationPhotoChange(list, index);
                        }}
                        multiple={true}
                        setError={(msg) => {
                          setImageSizeError(msg);
                        }}
                      />
                      {errors[`accomodation.${index}.photo`] && (
                        <div className={styles.fieldError}>
                          {errors[`accomodation.${index}.photo`][0]}
                        </div>
                      )}
                    </Col>
                    <div className={styles.accGrid}>
                      <div className={styles.accomodationLabel}>Price</div>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 12 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                      >
                        <Input
                          type="number"
                          placeholder="Price"
                          value={item.price}
                          onChange={(e) => {
                            handleAccomodationPriceChange(e, index);
                          }}
                        />
                        {errors[`accomodation.${index}.price`] && (
                          <div className={styles.fieldError}>
                            {errors[`accomodation.${index}.price`][0]}
                          </div>
                        )}
                      </Col>
                    </div>
                    <div className={styles.accGrid}>
                      <div className={styles.accomodationLabel}>Description</div>
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                      >
                        {/*onChange is used instead of setValue because form item's children need value and onChange as props
      description: value depends on text editor(before setValue={setDescription}) 
    */}

                        <TextEditor
                          value={item.description}
                          onChange={(val) => {
                            handleAccomodationDescriptionChange(val, index);
                          }}
                        />
                        {errors[`accomodation.${index}.description`] && (
                          <div className={styles.fieldError}>
                            {errors[`accomodation.${index}.description`][0]}
                          </div>
                        )}
                      </Col>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
          <Form.Item wrapperCol={{ span: 16 }} label="Instructors">
            <Button size="medium" onClick={addInstructor}>
              Add
            </Button>
          </Form.Item>
        </Col>
        <div className={styles.accomodationGrid}>
          {data.instructor.map((item, index) => {
            return (
              <div className={styles.accomodationContainer} key={index}>
                <div className={styles.removeBtnContainer}>
                  <Button
                    size="small"
                    icon={<CloseOutlined />}
                    shape="circle"
                    onClick={() => {
                      removeInstructor(index);
                    }}
                    className={styles.removeBtn}
                  />
                </div>
                <div className={styles.accGrid}>
                  <div className={styles.accomodationLabel}>Name</div>
                  <Col
                    key={index}
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                  >
                    <Input
                      placeholder="Name"
                      value={item.name}
                      onChange={(e) => {
                        handleInstructorNameChange(e, index);
                      }}
                    />
                    {errors[`instructor.${index}.name`] && (
                      <div className={styles.fieldError}>
                        {errors[`instructor.${index}.name`][0]}
                      </div>
                    )}
                  </Col>
                </div>

                <Col
                  xl={{ span: 24 }}
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  className={styles.accGrid}
                >
                  <ImageUpload
                    fileList={item.photo}
                    photoWidth="280"
                    photoHeight="300"
                    setFileList={(list) => {
                      handleInstructorPhotoChange(list, index);
                    }}
                    multiple={false}
                    maxUpload={1}
                    setError={(msg) => {
                      setImageSizeError(msg);
                    }}
                  />
                  {errors[`instructor.${index}.photo`] && (
                    <div className={styles.fieldError}>
                      {errors[`instructor.${index}.photo`][0]}
                    </div>
                  )}
                </Col>
                <div className={styles.accGrid}>
                  <div className={styles.accomodationLabel}>Designation</div>
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                  >
                    <Input
                      type="tex"
                      placeholder="Eg: Yoga Teacher, Wellness Manager"
                      value={item.designation}
                      onChange={(e) => {
                        handleInstructorDesignationChange(e, index);
                      }}
                    />
                    {errors[`instructor.${index}.designation`] && (
                      <div className={styles.fieldError}>
                        {errors[`instructor.${index}.designation`][0]}
                      </div>
                    )}
                  </Col>
                </div>
                <div className={styles.accGrid}>
                  <div className={styles.accomodationLabel}>Description</div>{" "}
                  <Col
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                  >
                    {/*onChange is used instead of setValue because form item's children need value and onChange as props
      description: value depends on text editor(before setValue={setDescription}) 
    */}

                    <TextEditor
                      value={item.description}
                      onChange={(val) => {
                        handleInstructorDescriptionChange(val, index);
                      }}
                    />
                    {errors[`instructor.${index}.description`] && (
                      <div className={styles.fieldError}>
                        {errors[`instructor.${index}.description`][0]}
                      </div>
                    )}
                  </Col>
                </div>
              </div>
            );
          })}
        </div>
        <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
          <Form.Item wrapperCol={{ span: 16 }}>
            <Button
              type="primary"
              size="large"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </div>
  );
}

export default ProgramUpdate;
