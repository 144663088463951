import { baseRequest } from "./base";

const getAllCountries = async (signal) => {
  var response = await baseRequest({
    url: `/api/countries`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const programCountries = async (signal) => {
  var response = await baseRequest({
    url: `/api/programs-country`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getAllDestinations = async (signal) => {
  var response = await baseRequest({
    url: `/api/destinations`,
    method: "GET",
    signal: signal,
  });

  return response;
};
export { getAllCountries, programCountries, getAllDestinations };
