import React from "react";
import {
  getProviderBookings,
  markAttendedBookings,
  markNotAttendedBookings,
} from "../../../../services/api/bookings";
import { BookingsTable } from "../BookingsTable";
import { toast } from "react-toastify";
import { axiosErrorHandler } from "../../../../utils/errorHandler/axiosErrorHandler";

function CompletedBookings() {
  const handleCheckBox = (e, item, setData) => {
    if (e.target.checked) {
      markAttendedBookings({ bookingId: item.id }).then((res) => {
        setData((prevData) =>
          prevData.map((booking) =>
            booking.id === item.id ? { ...booking, attended: true } : booking
          )
        );
        toast.success("Booking Marked as Attended");
      });
    } else {
      markNotAttendedBookings({ bookingId: item.id })
        .then((res) => {
          setData((prevData) =>
            prevData.map((booking) =>
              booking.id === item.id ? { ...booking, attended: false } : booking
            )
          );
          toast.success("Booking Marked as Not Attended");
        })
        .catch((err) => axiosErrorHandler(err.message));
    }
  };

  return (
    <div>
      <BookingsTable
        fetchData={getProviderBookings}
        status="new"
        handleCheckBox={handleCheckBox}
      />
    </div>
  );
}

export default CompletedBookings;
