import { Skeleton } from "antd";
import React from "react";
import styles from "./PackageCard.module.css";

function PackageSkeleton() {
  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <Skeleton.Image
          className={styles.image}
          style={{ width: "100%", height: "100%" }}
          active={true}
        ></Skeleton.Image>
      </div>
      <div className={styles.cardContent}>
        <Skeleton />
      </div>
      <div className={styles.footer}>
        <Skeleton.Input size="small" />
        <Skeleton.Button size="small" />
      </div>
    </div>
  );
}

export default PackageSkeleton;
