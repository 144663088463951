import React, { useEffect } from "react";
import styles from "../Program.module.css";
import ImageUpload from "../../../components/ImageUpload";
import { Button, Col, Form, Input, Radio, Row } from "antd";
import TextEditor from "../../../components/TextEditor";
import { CloseOutlined } from "@ant-design/icons";

function AccomodationAdd({
  addAccomodation,
  data,
  removeAccomodation,
  handleAccomodationTitleChange,
  handleAccomodationDescriptionChange,
  handleAccomodationPhotoChange,
  handleAccomodationPriceChange,
  errors,
  setImageSizeError,
  setData,
  handleAccomodationAvailable,
  hasAccomodation,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      Price: data.price,
    });
  }, [data]);

  return (
    <div className={styles.container}>
      {" "}
      <>
        <Form
          form={form}
          className={styles.formContainer}
          name="basic"
          autoComplete="off"
          layout="vertical"
          onSubmit={(e) => e.preventDefault()}
          id="program-form"
        >
          <Row>
            <Col>
              <Form.Item
                wrapperCol={{ span: 24 }}
                label="Does your retreat have accomodation or not? If not you have to enter the price."
                validateStatus={errors["accomodation"] ? "error" : "success"}
                help={errors["accomodation"] ? errors["accomodation"][0] : ""}
              >
                <Radio.Group onChange={handleAccomodationAvailable} value={hasAccomodation}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {hasAccomodation ? (
            <Row>
              <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item wrapperCol={{ span: 16 }} label="Accomodation">
                  <Button size="medium" onClick={addAccomodation}>
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
              >
                <Form.Item
                  label="Price"
                  name="Price"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={errors["price"] ? "error" : "success"}
                  help={errors["price"] ? errors["price"][0] : ""}
                >
                  <Input
                    type="number"
                    placeholder="Price"
                    value={data.price}
                    onChange={(e) => {
                      setData({ ...data, price: e.target.value }); //if state is dictionary, set data like this
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {hasAccomodation && (
            <div className={styles.accomodationGrid}>
              {data.accomodation.map((item, index) => {
                return (
                  <div className={styles.accomodationContainer} key={index}>
                    <div className={styles.removeBtnContainer}>
                      <Button
                        size="small"
                        icon={<CloseOutlined />}
                        shape="circle"
                        onClick={() => {
                          removeAccomodation(index);
                        }}
                        className={styles.removeBtn}
                      />
                    </div>
                    <div className={styles.accGrid}>
                      <div className={styles.accomodationLabel}>Title</div>
                      <Col
                        key={index}
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                      >
                        <Input
                          placeholder="Title"
                          value={item.title}
                          onChange={(e) => {
                            handleAccomodationTitleChange(e, index);
                          }}
                        />
                        {errors[`accomodation.${index}.title`] && (
                          <div className={styles.fieldError}>
                            {errors[`accomodation.${index}.title`][0]}
                          </div>
                        )}
                      </Col>
                    </div>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <ImageUpload
                        fileList={item.photo}
                        photoWidth="625"
                        photoHeight="348"
                        setFileList={(list) => {
                          handleAccomodationPhotoChange(list, index);
                        }}
                        multiple={true}
                        setError={(msg) => {
                          setImageSizeError(msg);
                        }}
                      />
                      {errors[`accomodation.${index}.photo`] && (
                        <div className={styles.fieldError}>
                          {errors[`accomodation.${index}.photo`][0]}
                        </div>
                      )}
                    </Col>
                    <div className={styles.accGrid} style={{ marginTop: "1em" }}>
                      <div className={styles.accomodationLabel}>Price</div>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 12 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                      >
                        <Input
                          type="number"
                          placeholder="Price"
                          value={item.price}
                          onChange={(e) => {
                            handleAccomodationPriceChange(e, index);
                          }}
                        />
                        {errors[`accomodation.${index}.price`] && (
                          <div className={styles.fieldError}>
                            {errors[`accomodation.${index}.price`][0]}
                          </div>
                        )}
                      </Col>
                    </div>
                    <div className={styles.accGrid}>
                      <div className={styles.accomodationLabel}>Description</div>
                      <Col
                        xxl={{ span: 24 }}
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                      >
                        {/*onChange is used instead of setValue because form item's children need value and onChange as props
description: value depends on text editor(before setValue={setDescription}) 
*/}

                        <TextEditor
                          value={item.description}
                          onChange={(val) => {
                            handleAccomodationDescriptionChange(val, index);
                          }}
                        />
                        {errors[`accomodation.${index}.description`] && (
                          <div className={styles.fieldError}>
                            {errors[`accomodation.${index}.description`][0]}
                          </div>
                        )}
                      </Col>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Form>
      </>
    </div>
  );
}

export default AccomodationAdd;
