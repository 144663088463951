import { Button, Divider, Form, Input, Spin } from "antd";
import { gapi } from "gapi-script";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { notifyError } from "../../App";
import { UserContext } from "../../contexts/UserContext";
import { login } from "../../services/api/auth";
import { setCookie } from "../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import styles from "./LoginAsPartner.module.css";

function LoginAsPartner() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext);

  useEffect(() => {
    function start(param) {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_APP_ID,

        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const handleSubmit = () => {
    if (data.email.length === 0 || data.password.length === 0) {
      return;
    }
    // setLoading(true);
    login(data)
      .then((res) => {
        // navigate("/");

        if (
          res.data.user.role === "provider" ||
          res.data.user.role === "operator"
        ) {
          setCookie("token", res.data.token);
          setCookie("user", res.data.user);
          setUserData(res.data.user);
          navigate("/partner/");
        } else if (res.data.user.role === "user") {
          setCookie("token", res.data.token);
          setCookie("user", res.data.user);
          setUserData(res.data.user);
          navigate("/user/");
        } else {
          notifyError("Invalid User.");
          setUserData({});
        }
        setLoading(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  return loading ? (
    <Spin />
  ) : (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        src="/images/login-bg.png"
        alt="bg"
        loading="lazy"
      />
      <div className={styles.container}>
        <div className="container">
          <h1 className={styles.title}>Login As Partner</h1>
          <div className={styles.register}>
            Don't have an account?
            <Link to="/signup/user/">Sign Up</Link>
          </div>
          <div className={styles.formContainer}>
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              onSubmitCapture={handleSubmit}
            >
              <Form.Item
                className={styles.formItem}
                label="Email/Phone"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email or phone number",
                  },
                ]}
              >
                <Input
                  type="text"
                  value={data.email}
                  className={styles.input}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="Password"
                className={styles.formItem}
                rules={[
                  { required: true, message: "Please input your password" },
                ]}
              >
                <Input.Password
                  className={styles.input}
                  value={data.password}
                  onChange={(e) => {
                    setData({ ...data, password: e.target.value });
                  }}
                />
              </Form.Item>
              <div className={styles.forgotPassword}>
                <Link to="/forgot-password/">Forgot Password?</Link>
              </div>
              <Form.Item className={styles.formItem}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.button}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>

          <Divider>OR</Divider>
          <div className={styles.footerContainer}>
            <div className={styles.footerTitle}>Login without a password</div>
            <div className={styles.footerSubtitle}>
              We will send you an email with a temporary login link.
            </div>
            <div
              className={styles.partner}
              onClick={() => navigate("/login-without-password/")}
            >
              Get Quick Email Access
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginAsPartner;
