import { Segmented } from "antd";
import React, { useEffect, useState } from "react";
import { getUserBookings } from "../../../services/api/bookings";
import styles from "./Bookings.module.css";
import BookingsList from "./BookingsList";

const Bookings = () => {
  const [segmentedValue, setSegmentedValue] = useState("To Attend");

  const handleSegmentedChange = (value) => {
    // Update the segmented value and trigger the necessary actions
    setSegmentedValue(value);
    localStorage.setItem("bookings", value); // Store in localStorage
    // Perform any other actions based on the new value
  };

  useEffect(() => {
    // Retrieve value from localStorage
    const storedValue = localStorage.getItem("bookings");
    // Set the stored value as initial state if it exists
    if (storedValue) {
      setSegmentedValue(storedValue);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>My Bookings</div>
      <Segmented
        options={["To Attend", "Attended"]}
        value={segmentedValue}
        onChange={handleSegmentedChange}
        className={styles.segment}
      ></Segmented>
      {/* Render the component below based on the segmented value */}
      {segmentedValue === "To Attend" && (
        <BookingsList fetchData={getUserBookings} status="0" />
      )}
      {segmentedValue === "Attended" && (
        <BookingsList fetchData={getUserBookings} status="1" />
      )}
    </div>
  );
};

export default Bookings;
