import { AppstoreOutlined } from "@ant-design/icons";
import { Button, Empty, Modal, Slider } from "antd";
import cx from "classnames";
import { addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { AiOutlineCalendar, AiOutlineSearch } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchCard from "../../../components/SearchCard/SearchCard";
import SearchFilterSkeleton from "../../../components/SearchCard/SearchFilterSkeleton";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getAllDestinations } from "../../../services/api/country";
import { getAllCategories, searchProgram } from "../../../services/api/programs";
import { dateFormat } from "../../../utils/dateTime/dateAndTime";
import styles from "./SearchFilter.module.css";
import CustomPagination from "../../../components/Pagination";

const filterItemTitle = [
  { name: "Recommended First", order: "", direction: "" },
  { name: "Price, cheapest first", order: "price", direction: 1 },
  { name: "Price, highest first", order: "price", direction: 2 },
  { name: "Duration, shortest first", order: "duration", direction: 1 },
  { name: "Duration, longest first", order: "duration", direction: 2 },
];

const months = [
  { month: "January", id: 1 },
  { month: "February", id: 2 },
  { month: "March", id: 3 },
  { month: "April", id: 4 },
  { month: "May", id: 5 },
  { month: "June", id: 6 },
  { month: "July", id: 7 },
  { month: "August", id: 8 },
  { month: "September", id: 9 },
  { month: "October", id: 10 },
  { month: "November", id: 11 },
  { month: "December", id: 12 },
];

const getMonthById = (id) => {
  if (!id) {
    return "";
  }
  let obj = months.find((o) => o.id === parseInt(id));
  return obj;
};

const SearchFilter = ({ setCategoryDetails, setFaq }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentYear, setCurrentYear] = useState(
    searchParams.get("start_date")
      ? new Date(searchParams.get("start_date")).getFullYear()
      : new Date().getFullYear()
  );
  const [selectedYear, setSelectedYear] = useState(null);
  const [month, setMonth] = useState(
    searchParams.get("date_type") === "flexible"
      ? getMonthById(new Date(searchParams.get("start_date")).getMonth() + 1)
      : ""
  );
  const [cardData, setCardData] = useState([]);
  const [location, setLocation] = useState();
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  // const [page, setPage] = useState(searchParams.get("page_number"));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [pageConf, setPageConf] = useState({
    total: 0,
    lastPage: 1,
    pageSize: 1,
    from: 1,
    to: 1,
  });
  const [date, setDate] = useState(
    searchParams.get("date_type") === "specific"
      ? {
          type: "specific",
          text: `${new Date(searchParams.get("start_date")).toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
          })} - ${new Date(searchParams.get("end_date")).toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
          })}`,
        }
      : searchParams.get("date_type") === "flexible"
      ? { type: "flexible", text: `${month.month}, ${currentYear}` }
      : { type: "nodate", text: "No set dates" }
  );
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterTitle, setFilterTitle] = useState({
    name: "Recommended First",
    order: "",
    direction: "",
  });
  const [selected, setSelected] = useState("");
  const [lowerPriceRange, setLowerPriceRange] = useState(null);
  const [upperPriceRange, setUpperPriceRange] = useState(null);
  const [selectedPriceRange, setSelectedPriceRange] = useState([lowerPriceRange, upperPriceRange]);
  const [lowerDurationRange, setLowerDurationRange] = useState(null);
  const [upperDurationRange, setUpperDurationRange] = useState(null);
  const [selectedDurationRange, setSelectedDurationRange] = useState([
    lowerDurationRange,
    upperDurationRange,
  ]);
  const [dateSelected, setDateSelected] = useState("nodate");
  const [openRangePicker, setOpenRangePicker] = useState(false);
  const [currentRangeSelection, setCurrentRangeSelection] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: searchParams.get("start_date")
        ? new Date(searchParams.get("start_date"))
        : new Date(),
      endDate: searchParams.get("end_date")
        ? new Date(searchParams.get("end_date"))
        : addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const [slider, setSlider] = useState("");

  const { width } = useWindowDimensions();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFilter = () => {
    let formData = {
      location: searchParams.get("location") || "",
      category: searchParams.get("category") || "",
      start_date: searchParams.get("start_date") || "",
      end_date: searchParams.get("end_date") || "",
      price_min: (selectedPriceRange && selectedPriceRange[0]) || "",
      price_max: (selectedPriceRange && selectedPriceRange[1]) || "",
      duration_min: (selectedDurationRange && selectedDurationRange[0]) || "",
      duration_max: (selectedDurationRange && selectedDurationRange[1]) || "",
      order: (filterTitle && filterTitle.order) || "",
      direction: (filterTitle && filterTitle.direction) || "",
    };

    let url = Object.keys(formData).length > 0 ? "/search?" : "/search";
    Object.keys(formData).forEach((element, index) => {
      if (formData[element] !== null && formData[element] !== "") {
        url += `${element}=${formData[element]}&`;
      }
    });
    url += `page_number=1`;
    navigate(url);
  };

  const handlePageChange = (page) => {
    let formData = {
      location: searchParams.get("location") || "",
      category: searchParams.get("category") || "",
      start_date: searchParams.get("start_date") || "",
      end_date: searchParams.get("end_date") || "",
      price_min: searchParams.get("price_min"),
      price_max: searchParams.get("price_max"),
      duration_min: searchParams.get("duration_min"),
      duration_max: searchParams.get("duration_max"),
      order: searchParams.get("order"),
      direction: searchParams.get("direction"),
    };

    let url = "/search?";
    Object.keys(formData).forEach((element, index) => {
      if (formData[element] !== null && formData[element] !== "") {
        url += `${element}=${formData[element]}&`;
      }
    });
    url += `page_number=${page}`;
    navigate(url);
  };

  const handleSubmit = () => {
    let formData = {};
    if (location) {
      formData.location = location.slug;
    }
    if (category) {
      formData.category = category.slug;
    }

    if (date.type === "specific") {
      formData.start_date = dateFormat(dateRange[0]?.startDate);
      formData.end_date = dateFormat(dateRange[0]?.endDate);
      formData.date_type = "specific";
    } else if (date.type === "flexible") {
      var lastDay = new Date(selectedYear, month.id, 0).getDate();
      formData["start_date"] = `${selectedYear}-${month.id}-1`;
      formData["end_date"] = `${selectedYear}-${month.id}-${lastDay}`;
      formData.date_type = "flexible";
    }

    formData.page_number = 1;
    let url = Object.keys(formData).length > 0 ? "/search?" : "/search";
    Object.keys(formData).forEach((element, index) => {
      if (formData[element]) {
        url += `${element}=${formData[element]}`;
        url += index !== Object.keys(formData).length - 1 ? `&` : "";
      }
    });
    navigate(url);
  };

  const handleSubmitSmallScreen = ({
    date,
    dateRange = null,
    selectedYear = null,
    month = null,
  }) => {
    let formData = {};
    if (location) {
      formData.location = location.slug;
    }
    if (category) {
      formData.category = category.slug;
    }

    if (date.type === "specific") {
      formData.start_date = dateFormat(dateRange[0]?.startDate);
      formData.end_date = dateFormat(dateRange[0]?.endDate);
      formData.date_type = "specific";
    } else if (date.type === "flexible") {
      var lastDay = new Date(selectedYear, month.id, 0).getDate();
      formData["start_date"] = `${selectedYear}-${month.id}-1`;
      formData["end_date"] = `${selectedYear}-${month.id}-${lastDay}`;
      formData.date_type = "flexible";
    }

    formData.page_number = 1;
    let url = Object.keys(formData).length > 0 ? "/search?" : "/search";
    Object.keys(formData).forEach((element, index) => {
      if (formData[element]) {
        url += `${element}=${formData[element]}`;
        url += index !== Object.keys(formData).length - 1 ? `&` : "";
      }
    });
    navigate(url);
  };

  useEffect(() => {
    if (slider !== "") {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }
  }, [slider]);

  useEffect(() => {
    let abort = new AbortController();

    getAllCategories(abort.signal)
      .then((res) => {
        setCategoryData([{ id: null, title: "All" }, ...res.data]);
        if (searchParams.get("category")) {
          let obj = res.data.find((o) => o.slug === searchParams.get("category"));
          setCategory(obj);
        }
      })
      .catch((err) => {});
    getAllDestinations(abort.signal)
      .then((res) => {
        setLocationData([{ id: null, title: "Anywhere" }, ...res.data.destinations]);
        let obj = res.data.destinations.find((o) => o.slug === searchParams.get("location"));
        setLocation(obj);
      })
      .catch((err) => {});

    return () => {
      abort.abort();
    };
  }, []);

  useEffect(() => {
    handleFilter();
  }, [filterTitle.name, filterTitle.order, filterTitle.direction]);

  useEffect(() => {
    window.scrollTo(0, 0);

    let abort = new AbortController();

    setLoading(true);

    if (searchParams.get("category")) {
      let obj = categoryData.find((o) => o.slug === searchParams.get("category"));
      setCategory(obj);
    }
    if (searchParams.get("location")) {
      let obj = locationData.find((o) => o.slug === searchParams.get("location"));
      setLocation(obj);
    }
    searchProgram(
      searchParams.get("page_number") || 1,
      searchParams.get("location") || "",
      searchParams.get("category") || "",
      searchParams.get("start_date") || "",
      searchParams.get("end_date") || "",
      abort.signal,
      searchParams.get("price_min") || "",
      searchParams.get("price_max") || "",
      searchParams.get("duration_min") || "",
      searchParams.get("duration_max") || "",
      searchParams.get("order") || "",
      searchParams.get("direction") || ""
    )
      .then((res) => {
        setPageConf({
          total: res.data.total,
          lastPage: res.data.last_page,
          pageSize: res.data.page_size,
          to: res.data.to,
          from: res.data.from,
        });
        setCardData(res.data.yoga_programs);
        setCategoryDetails(res.data.yoga_category?.description);
        setFaq(res.data.yoga_category?.faq);
        setLowerPriceRange(res.data.filter.price.min);
        setUpperPriceRange(res.data.filter.price.max);
        setLowerDurationRange(res.data.filter.duration.min);
        setUpperDurationRange(res.data.filter.duration.max);
        setSelectedPriceRange([res.data.filter.price.min, res.data.filter.price.max]);
        setSelectedDurationRange([res.data.filter.duration.min, res.data.filter.duration.max]);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });

    return () => {
      abort.abort();
    };
  }, [searchParams]);

  useEffect(() => {
    if (selected) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }
  }, [selected]);

  return (
    <section className={styles.wrapper}>
      {selected && <div className={cx(styles.overlay)} onClick={() => setSelected(null)}></div>}
      <div className="container">
        <div className={styles.container}>
          <div className={styles.filterLocationWrapper}>
            <div className={styles.filterContent} onClick={() => setSelected("location")}>
              <div className={styles.searchFilterIcon}>
                <ion-icon name="location-outline"></ion-icon>
              </div>
              <div>
                <div className={styles.filterSubtitle}>Location</div>
                <div className={styles.searchFilterTitleContainer}>
                  <div className={styles.searchFilterTitle}>
                    {location ? location.title : "Anywhere"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.filterCategoryWrapper}>
            <div className={styles.filterContent} onClick={() => setSelected("category")}>
              <div className={styles.searchFilterIcon}>
                <ion-icon name="grid-outline"></ion-icon>
              </div>
              <div>
                <div className={styles.filterSubtitle}>Category</div>
                <div className={styles.searchFilterTitleContainer}>
                  <div className={styles.searchFilterTitle}>
                    {category ? category.title : "All"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.filterDateWrapper}>
            <div className={styles.filterContent} onClick={() => setSelected("date")}>
              <div className={styles.searchFilterIcon}>
                <ion-icon name="calendar-outline"></ion-icon>
              </div>
              <div>
                <div className={styles.filterSubtitle}>When Are You Going?</div>
                <div className={styles.searchFilterTitleContainer}>
                  <div className={styles.searchFilterTitle}>{date.text}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={cx("light-btn", styles.button)} onClick={handleSubmit}>
            Search Yoga
            <div className={styles.icon}>
              <ion-icon name="search-outline"></ion-icon>
            </div>
          </div>
        </div>
        <div
          className={cx(
            styles.dropdown,
            selected === "location" && styles.dropdownActive,
            selected === "location" && styles.locationDropdown
          )}
        >
          {locationData.map((option, index) => (
            <div
              key={index}
              onClick={() => {
                setSelected(null);
                setLocation(option);
              }}
              className={styles.dropdownOptions}
            >
              {option.title}
            </div>
          ))}
        </div>
        <div
          className={cx(
            styles.dropdown,
            selected === "category" && styles.dropdownActive,
            selected === "category" && styles.categoryDropdown
          )}
        >
          {categoryData.map((option, index) => (
            <div
              key={index}
              onClick={() => {
                setSelected(null);
                setCategory(option);
              }}
              className={styles.dropdownOptions}
            >
              {option.title}
            </div>
          ))}
        </div>
        <div
          className={cx(
            styles.dropdown,
            selected === "date" && styles.dropdownActive,
            selected === "date" && styles.dateDropdown
          )}
        >
          <div className={styles.dateTab}>
            <div
              className={cx(styles.specificDate, dateSelected === "specific" && styles.dateActive)}
              onClick={() => {
                setDateSelected("specific");
                setCurrentRangeSelection("start");
              }}
            >
              Specific dates
            </div>
            <div
              className={cx(styles.flexibleDate, dateSelected === "flexible" && styles.dateActive)}
              onClick={() => {
                setDateSelected("flexible");
                setOpenRangePicker(true);
              }}
            >
              Flexible dates
            </div>
            <div
              className={cx(styles.nosetDate, dateSelected === "nodate" && styles.dateActive)}
              onClick={() => {
                setDateSelected("nodate");
                setDate({ type: "nodate", text: "No set dates" });
                setSelected(null);
              }}
            >
              No set dates
            </div>
          </div>

          <div className={styles.dropdownDate}>
            {dateSelected === "specific" && (
              <div>
                <DateRangePicker
                  onChange={(item) => {
                    setDateRange([item.selection]);
                    if (currentRangeSelection === "start") {
                      setCurrentRangeSelection("end");
                    } else if (currentRangeSelection === "end") {
                      setSelected(null);
                      setCurrentRangeSelection("start");
                      setDate({
                        type: "specific",
                        text: `${item.selection.startDate?.toLocaleDateString(undefined, {
                          month: "short",
                          day: "numeric",
                        })} - ${item.selection.endDate?.toLocaleDateString(undefined, {
                          month: "short",
                          day: "numeric",
                        })}`,
                      });
                    }
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  minDate={new Date()}
                  ranges={dateRange}
                  direction="horizontal"
                  // staticRanges={[]}
                  // inputRanges={[]}
                />
              </div>
            )}
            {dateSelected === "nodate" && (
              <div>No dates selected, showing retreats for all dates</div>
            )}
            {dateSelected === "flexible" && (
              <div className={styles.flexibleContainer}>
                <div className={styles.flexibleHeader}>
                  <div className={styles.flexibleCardDate}>{currentYear}</div>
                  <div className={styles.flexibleRightArrow}>
                    <MdKeyboardArrowRight onClick={() => setCurrentYear(currentYear + 1)} />
                  </div>
                  {currentYear === new Date().getFullYear() ? (
                    ""
                  ) : (
                    <div className={styles.flexibleLeftArrow}>
                      <MdKeyboardArrowLeft onClick={() => setCurrentYear(currentYear - 1)} />
                    </div>
                  )}
                </div>
                <div className={styles.flexibleContent}>
                  {months.map((month, index) => (
                    <div
                      className={cx(
                        styles.flexibleCard,
                        currentYear === new Date().getFullYear() &&
                          month.id <= new Date().getMonth()
                          ? styles.flexibleCardDisabled
                          : null
                      )}
                      key={index}
                      onClick={() => {
                        if (
                          currentYear === new Date().getFullYear() &&
                          month.id <= new Date().getMonth()
                        ) {
                          return "";
                        } else {
                          setMonth(month);
                          setSelectedYear(currentYear);
                          setDate({
                            type: "flexible",
                            text: `${month.month}, ${currentYear}`,
                          });
                          setSelected("");
                        }
                      }}
                    >
                      {month.month}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.smallFilterContainer}>
          <div className={styles.smallFilterCard}>
            <div
              className={styles.smallLocationContainer}
              onClick={() => {
                setSlider("location");
              }}
            >
              <IoLocationOutline className={styles.smallIcon} />
              <div>
                <div className={styles.smallLocationTitle}>Location</div>
                <div className={styles.smallLocationSubtitle}>Where are you going?</div>
              </div>
            </div>
            <div className={styles.smallSearchContainer}>
              <AiOutlineSearch className={styles.smallIcon} />
            </div>
            <div className={cx(styles.slider, slider !== "" && styles.sliderActive)}>
              <div className={styles.sliderHeader}>
                <div className={styles.sliderTitleContainer}>
                  {slider === "location" ? (
                    <IoLocationOutline className={styles.sliderIcon} />
                  ) : slider === "category" ? (
                    <AppstoreOutlined className={styles.sliderIcon} />
                  ) : slider === "date" ? (
                    <AiOutlineCalendar className={styles.sliderIcon} />
                  ) : null}
                  <div className={styles.sliderTitle}>
                    {" "}
                    {slider === "location"
                      ? "Location"
                      : slider === "category"
                      ? "Category"
                      : slider === "date"
                      ? "Date"
                      : null}
                  </div>
                </div>
                <div
                  className={styles.sliderBack}
                  onClick={() => {
                    return slider === "location"
                      ? setSlider("")
                      : slider === "category"
                      ? setSlider("location")
                      : slider === "date"
                      ? setSlider("category")
                      : null;
                  }}
                >
                  Back
                </div>
              </div>
              {slider === "location" ? (
                <div className={styles.sliderContent}>
                  {locationData.map((option, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setLocation(option);
                        setSlider("category");
                      }}
                      className={styles.dropdownOptions}
                    >
                      {option.title}
                    </div>
                  ))}
                </div>
              ) : slider === "category" ? (
                <div className={styles.sliderContent}>
                  {categoryData.map((option, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSlider("date");
                        setCategory(option);
                      }}
                      className={styles.dropdownOptions}
                    >
                      {option.title}
                    </div>
                  ))}
                </div>
              ) : slider === "date" ? (
                <div className={styles.sliderContent}>
                  <div className={styles.dateTab}>
                    <div
                      className={cx(
                        styles.specificDate,
                        dateSelected === "specific" && styles.dateActive
                      )}
                      onClick={() => {
                        setDateSelected("specific");
                        setCurrentRangeSelection("start");
                      }}
                    >
                      Specific
                    </div>
                    <div
                      className={cx(
                        styles.flexibleDate,
                        dateSelected === "flexible" && styles.dateActive
                      )}
                      onClick={() => {
                        setDateSelected("flexible");
                        setOpenRangePicker(true);
                      }}
                    >
                      Flexible
                    </div>
                    <div
                      className={cx(
                        styles.nosetDate,
                        dateSelected === "nodate" && styles.dateActive
                      )}
                      onClick={() => {
                        setDateSelected("nodate");
                        var newD = { type: "nodate", text: "No set dates" };
                        setDate(newD);
                        setSlider("");
                        handleSubmitSmallScreen({ date: newD });
                      }}
                    >
                      No set dates
                    </div>
                  </div>
                  <div className={styles.contents}>
                    {" "}
                    {dateSelected === "specific" && (
                      <div>
                        <DateRangePicker
                          onChange={(item) => {
                            setDateRange([item.selection]);
                            if (currentRangeSelection === "start") {
                              setCurrentRangeSelection("end");
                            } else if (currentRangeSelection === "end") {
                              setCurrentRangeSelection("start");
                              let newD = {
                                type: "specific",
                                text: `${item.selection.startDate?.toLocaleDateString(undefined, {
                                  month: "short",
                                  day: "numeric",
                                })} - ${item.selection.endDate?.toLocaleDateString(undefined, {
                                  month: "short",
                                  day: "numeric",
                                })}`,
                              };
                              setDate(newD);
                              setSlider("");

                              handleSubmitSmallScreen({
                                date: newD,
                                dateRange: [item.selection],
                              });
                            }
                          }}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={width < 767 ? 1 : 2}
                          minDate={new Date()}
                          ranges={dateRange}
                          direction="horizontal"
                          // staticRanges={[]}
                          // inputRanges={[]}
                        />
                      </div>
                    )}
                    {dateSelected === "nodate" && (
                      <div>No dates selected, showing retreats for all dates</div>
                    )}
                    {dateSelected === "flexible" && (
                      <div className={styles.flexibleContainer}>
                        <div className={styles.flexibleHeader}>
                          <div className={styles.flexibleCardDate}>{currentYear}</div>
                          <div className={styles.flexibleRightArrow}>
                            <MdKeyboardArrowRight onClick={() => setCurrentYear(currentYear + 1)} />
                          </div>
                          {currentYear === new Date().getFullYear() ? (
                            ""
                          ) : (
                            <div className={styles.flexibleLeftArrow}>
                              <MdKeyboardArrowLeft
                                onClick={() => setCurrentYear(currentYear - 1)}
                              />
                            </div>
                          )}
                        </div>
                        <div className={styles.flexibleContent}>
                          {months.map((month, index) => (
                            <div
                              className={cx(
                                styles.flexibleCard,
                                currentYear === new Date().getFullYear() &&
                                  month.id <= new Date().getMonth()
                                  ? styles.flexibleCardDisabled
                                  : null
                              )}
                              key={index}
                              onClick={() => {
                                if (
                                  currentYear === new Date().getFullYear() &&
                                  month.id <= new Date().getMonth()
                                ) {
                                  return "";
                                } else {
                                  setMonth(month);
                                  setSelectedYear(currentYear);
                                  var newD = {
                                    type: "flexible",
                                    text: `${month.month}, ${currentYear}`,
                                  };
                                  setDate(newD);
                                  setSlider("");
                                  handleSubmitSmallScreen({
                                    date: newD,
                                    selectedYear: currentYear,
                                    month: month,
                                  });
                                }
                              }}
                            >
                              {month.month}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={styles.filterContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.titleContainer}>
              {cardData.length > 0 ? (
                <div className={styles.subtitle}>
                  Showing {pageConf.from} to {pageConf.to} of {pageConf.total} results
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={styles.headerButtonContainer}>
              <div className={cx(styles.filterBtnContainer)} onClick={showModal}>
                <div className={styles.filterIcon}>
                  <ion-icon name="funnel-outline"></ion-icon>
                </div>
                <div className={styles.filterTitle}>Filter</div>
              </div>
              <div
                className={cx(styles.filterBtnContainer, styles.searchFilterBtn)}
                onClick={() => setOpenFilter((prev) => !prev)}
              >
                <div className={styles.filterTitle}>{filterTitle.name}</div>

                {openFilter && (
                  <div className={styles.filterDropdown}>
                    {filterItemTitle.map((item, index) => (
                      <div
                        className={styles.filterDropdownItem}
                        key={index}
                        onClick={() => {
                          setFilterTitle({
                            name: item.name,
                            order: item.order,
                            direction: item.direction,
                          });
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <Modal open={isModalOpen} onCancel={handleCancel} footer={null} maskClosable={false}>
              <div className={styles.modalTitle}>Custom Filtering</div>
              <div className={styles.modalPriceContainer}>
                <div className={styles.modalRange}>
                  <div className={styles.modalSectionTitle}>Price</div>
                </div>
                <div className={styles.modalSectionSubtitle}>
                  The average price of an experience is $
                  {Math.round((lowerPriceRange + upperPriceRange) / 2)}
                </div>
                <Slider
                  defaultValue={[lowerPriceRange, upperPriceRange]}
                  marks={{
                    [lowerPriceRange]: `$${selectedPriceRange[0]}`,
                    [upperPriceRange]: `$${selectedPriceRange[1]}`,
                  }}
                  min={lowerPriceRange}
                  max={upperPriceRange}
                  range={{ draggableTrack: true }}
                  onChange={(value) => {
                    setSelectedPriceRange(value);
                  }}
                />
              </div>
              <div className={styles.modalDurationContainer}>
                <div className={styles.modalRange}>
                  <div className={styles.modalSectionTitle}>Duration</div>
                </div>
                <div className={styles.modalSectionSubtitle}>
                  The average price of an experience is{" "}
                  {Math.round((lowerDurationRange + upperDurationRange) / 2)}
                </div>
                <Slider
                  defaultValue={[lowerDurationRange, upperDurationRange]}
                  marks={{
                    [lowerDurationRange]: `${selectedDurationRange[0]}${
                      lowerDurationRange === 1 ? "day" : "days"
                    }`,
                    [upperDurationRange]: `${selectedDurationRange[1]}${
                      upperDurationRange === 1 ? "day" : "days"
                    }`,
                  }}
                  min={lowerDurationRange}
                  max={upperDurationRange}
                  onChange={(value) => {
                    setSelectedDurationRange(value);
                  }}
                  range={{ draggableTrack: true }}
                />
              </div>
              <div className={styles.modalButtonContainer}>
                <Button onClick={handleCancel} className={styles.modalButton}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleFilter();
                    handleOk();
                  }}
                  className={styles.modalButton}
                >
                  Apply Filter
                </Button>
              </div>
            </Modal>
          </div>

          <div className={styles.cardContainer}>
            {loading ? (
              <>
                <SearchFilterSkeleton />
                <SearchFilterSkeleton />
                <SearchFilterSkeleton />
              </>
            ) : cardData.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No data to show</span>}
              />
            ) : (
              cardData.map((item, index) => (
                <SearchCard key={item.id} cardItem={item} setRefresh={setRefresh} />
              ))
            )}
          </div>

          {/* pagination */}
          {/* <button
            onClick={() => {
              searchParams.set("page_number", 2);
              setSearchParams(searchParams);
            }}
          >
            {" "}
            d
          </button> */}

          <CustomPagination
            page={searchParams.get("page_number") || 1}
            pageSize={pageConf.pageSize}
            setPage={handlePageChange}
            total={pageConf.total}
            disabled={loading}
          />
        </div>
      </div>
    </section>
  );
};

export default SearchFilter;
