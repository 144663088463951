import { Button, Col, Form, Modal, Rate, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../App";
import { getUserToReview, rateBooking } from "../../../services/api/reviews";
import { getDateText } from "../../../utils/dateTime/dateAndTime";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import styles from "./ToReview.module.css";
import ToReviewCard from "./ToReviewCard";

const ToReview = ({ refresh, setRefresh }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [form] = Form.useForm();
  const [rating, setRating] = useState({
    rating: 5,
    description: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const abortCont = new AbortController();
    setLoading(true);
    getUserToReview(abortCont.signal)
      .then((res) => {
        setLoading(false);
        setData(res.data.bookings);
      })
      .catch((err) => {
        axiosErrorHandler(err);
        setLoading(false);
      });
    return () => {
      abortCont.abort();
    };
  }, [refresh]);

  const handleCancel = () => {
    setSelected({});
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleOk = () => {
    setSubmitting(true);
    rateBooking(selected.id, rating)
      .then((res) => {
        notifySuccess("Review Added");
        setRating({
          rating: 5,
          description: "",
        });
        setRefresh(!refresh);
        setIsModalOpen(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleReviewClick = (booking) => {
    setSelected(booking);
    setIsModalOpen(true);
    form.setFieldsValue({
      rating: 5,
      description: "",
    }); // Reset rating to empty
  };

  useEffect(() => {
    form.setFieldsValue({
      rating: 5,
      description: "",
    });
  }, []);

  return (
    <div>
      {loading ? (
        <div className={styles.container}>
          <Spin />
        </div>
      ) : data.length <= 0 ? (
        <div className={styles.emptyContainer}>
          <div className={styles.message}>There are no review items yet.</div>
          <Button onClick={() => navigate("/search/")}>
            Continue Exploring
          </Button>
        </div>
      ) : (
        data.map((item, index) => (
          <ToReviewCard
            review={item}
            key={item.id}
            onReview={handleReviewClick}
          />
        ))
      )}
      <Modal
        title="Share your Review"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button
            className="primary-btn"
            onClick={() => {
              handleOk();
            }}
            style={{ marginLeft: "auto" }}
          >
            Share Review
          </button>,
        ]}
        okButtonProps={{ disabled: submitting, loading: submitting }}
      >
        <div className={styles.reviewModal}>
          <Form
            className=""
            name="basic"
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <div className={styles.headerWrapper}>
              <div className={styles.imageContainer}>
                <img
                  src={selected.image}
                  className={styles.image}
                  alt="review"
                  loading="lazy"
                />
              </div>
              <div className={styles.rightWrapper}>
                <div>
                  <div className={styles.title}>{selected.title}</div>
                  <div className={styles.date}>
                    Attended on {getDateText(selected.date)}
                  </div>
                </div>
                <div className={styles.rateContainer}>
                  <Rate
                    onChange={(val) => {
                      setRating({
                        ...rating,
                        rating: val,
                      });
                    }}
                    value={rating.rating}
                    allowHalf
                    defaultValue="5"
                  />
                </div>
                <div className={styles.reviewWrapper}>
                  <Form.Item
                    name={`title`}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 24 }}
                    // validateStatus={errors[`title`] ? "error" : "success"}
                    // help={errors[`title`] ? errors[`title`][0] : ""}
                  >
                    <TextArea
                      placeholder="Please share your feedback"
                      value={rating.description}
                      rows={3}
                      defaultValue=""
                      onChange={(e) => {
                        setRating({
                          ...rating,
                          description: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <Col
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            ></Col>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ToReview;
