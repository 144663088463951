import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Radio,
  Row,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { notifyError, notifySuccess } from "../../../../../../App";
import useWindowDimensions from "../../../../../../hooks/useWindowDimensions";
import {
  getProgramById,
  toggleAccomodation,
  updatePrice,
} from "../../../../../../services/api/programs";
import { axiosErrorHandler } from "../../../../../../utils/errorHandler/axiosErrorHandler";
import AccomodationCard from "./AccomodationCard";
import styles from "./AccomodationEdit.module.css";

const iconChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return <SyncOutlined spin />;

    case 0:
      return <CloseCircleOutlined />;

    case 1:
      return <CheckCircleOutlined />;

    default:
      return <CheckCircleOutlined />;
  }
};

const tagChooser = (tags) => {
  switch (tags) {
    case 1:
      return "green";

    case 0:
      return "red";

    default:
      return "yellow";
  }
};

function AccomodationEdit({ id }) {
  const [form] = Form.useForm();
  const [data, setData] = useState({
    price: "",
    accomodation: [],
  });
  const { width } = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selected = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [hasAccomodation, setHasAccomodation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 300,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 300,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 300,
      render: (obj, item) => (
        <Tag
          key={item.id}
          color={tagChooser(item.status)}
          icon={iconChooser(item.status)}
          style={{ textTransform: "capitalize" }}
        >
          {item.status === 0 ? "Inactive" : "Active"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 300,
      render: (text, item) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                onClick(e.key, item.id, item);
              }}
              items={[
                {
                  key: "edit",
                  label: "Edit",
                },
                {
                  key: "status",
                  label: item.status === 0 ? "Activate" : "Deactivate",
                },
              ]}
            />
          }
          trigger="click"
          arrow
          text={text}
        >
          <a onClick={(e) => e.preventDefault()}>
            <EllipsisOutlined className={styles.actionIcon} />
          </a>
        </Dropdown>
      ),
    },
  ];

  const onClick = (key, id, item) => {
    if (key === "edit") {
      selected.current = item;
      setIsModalOpen(true);
    } else if (key === "status") {
      toggleAccomodation(item.id)
        .then((res) => {
          notifySuccess(res.message);
          setRefresh(!refresh);
        })
        .catch((err) => {});
    }
  };

  const handleSubmit = () => {
    if (hasAccomodation) {
      return;
    }
    updatePrice(id, { price: data.price })
      .then((res) => {
        notifySuccess(res.message);
        setRefresh(!refresh);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    setLoading(true);
    getProgramById(id, abortCont.signal)
      .then((res) => {
        setData({
          ...data,
          accomodation: res.data.program.accomodation,
          price: res.data.program.price,
        });
        setHasAccomodation(res.data.program.accomodation.length > 0);
        form.setFieldsValue({
          Price: res.data.program.price,
        });
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      abortCont.abort();
    };
  }, [id, refresh]);

  return (
    <div className={styles.container}>
      <Row>
        <Col>
          <Form.Item
            wrapperCol={{ span: 24 }}
            label="Does your retreat have accomodation or not? If not you have to enter the price."
            // validateStatus={errors["accomodation"] ? "error" : "success"}
            // help={errors["accomodation"] ? errors["accomodation"][0] : ""}
          >
            <Radio.Group
              onChange={(e) => {
                setHasAccomodation(e.target.value);
              }}
              value={hasAccomodation}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {hasAccomodation ? (
        <div>
          <div>
            <Button
              style={{ marginBottom: "20px" }}
              type="primary"
              onClick={() => {
                selected.current = {
                  title: "",
                  description: "",
                  images: [],
                  price: "",
                };
                setIsModalOpen(true);
              }}
            >
              Add
            </Button>
          </div>
          <AccomodationCard
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            accomodationData={
              selected.current || {
                images: [],
                title: "",
                description: "",
                price: "",
              }
            }
            programId={id}
            setRefresh={setRefresh}
          />
          <Table
            className={styles.table}
            // loading={loading}
            columns={columns}
            scroll={width < 1200 ? { x: 1200 } : null}
            dataSource={data.accomodation.map((booking, index) => {
              return {
                ...booking,
                sn: index + 1,
                key: index,
              };
            })}
            style={{ cursor: "pointer" }}
            pagination={false}
          />
        </div>
      ) : (
        <Form
          form={form}
          className={styles.formContainer}
          name="basic"
          autoComplete="off"
          layout="vertical"
          onFinish={handleSubmit}
          onSubmit={(e) => e.preventDefault()}
          id="program-form"
        >
          <Row>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Price"
                name="Price"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                validateStatus={errors["price"] ? "error" : "success"}
                help={errors["price"] ? errors["price"][0] : ""}
              >
                <Input
                  type="number"
                  placeholder="Price"
                  value={data.price}
                  onChange={(e) => {
                    setData({ ...data, price: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item wrapperCol={{ span: 16 }}>
                <Button
                  type="primary"
                  size="large"
                  loading={loading}
                  disabled={loading}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
}

export default AccomodationEdit;
