import { Table } from "antd";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { notifyError } from "../../../../App";
import { getPaymentHistoryById } from "../../../../services/api/payments";
import { axiosErrorHandler } from "../../../../utils/errorHandler/axiosErrorHandler";
import CustomPagination from "../../../components/Pagination";

function PaymentHistoryDetails() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const total = useRef(null);
  const pageSize = useRef(null);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Booking unique id",
      dataIndex: "unique_id",
      key: "unique_id",
      width: 100,
      render: (obj, item) => <>{item.booking.unique_id}</>,
    },
    {
      title: "Booking Id",
      dataIndex: "booking_id",
      key: "booking_id",
      width: 100,
    },

    {
      title: "Provider Income",
      dataIndex: "provider_income",
      key: "provider_income",
      width: 100,
    },
    {
      title: "Booking Date",
      dataIndex: "booking_date",
      key: "booking_date",
      width: 100,
      render: (obj, item) => <>{item.booking.date}</>,
    },
  ];

  const fetchPayments = async (id, signal) => {
    setLoading(true);
    await getPaymentHistoryById(id, signal)
      .then((res) => {
        if (res.status === "Success") {
          setData(res.data.payments);
          setLoading(false);
          total.current = res.data.total;
          pageSize.current = res.data.page_size;
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchPayments(id, abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, []);

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={data.map((payment, index) => {
          return {
            ...payment,
            sn: (page - 1) * pageSize.current + index + 1,
            key: index,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        total={total.current}
        pageSize={pageSize.current}
      />
    </div>
  );
}

export default PaymentHistoryDetails;
