import cx from "classnames";
import React, { useState } from "react";
import { notifyError, notifySuccess } from "../../../App";
import EbookModal from "../../../components/EbookModal/EbookModal";
import { downloadEbook } from "../../../services/api/blogs";
import styles from "./Newsletter.module.css";

// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function Newsletter() {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  // const [email, setEmail] = useState("");
  // const [error, setError] = useState([]);

  // const handleEmailChange = (e) => {
  //   e.stopPropagation();
  //   setEmail(e.target.value);
  // };

  // const handleSubmit = () => {
  //   let err = {};
  //   if (email.length === 0) {
  //     err["email"] = ["Email is required"];
  //   } else if (!emailRegex.test(email)) {
  //     err["email"] = ["Invalid email format"];
  //   }

  //   setError(err);
  //   if (Object.keys(err).length === 0) {
  //     setLoading(true);
  //     downloadEbook({ email: email })
  //       .then((res) => {
  //         notifySuccess("Ebook has been sent your email successfully!!!");
  //         setLoading(false);
  //         setIsModalOpen(false);
  //         setEmail("");
  //       })
  //       .catch((err) => {
  //         notifyError(
  //           "Some error occurred while sending ebook to the email !!!"
  //         );
  //         setLoading(false);
  //       });
  //   }
  // };

  return (
    <div className={styles.container}>
      <div className="container">
        <figure className={styles.imageContainer}>
          <img
            src="/images/newsletter/newsletter.webp"
            alt="newsletter"
            className={styles.image}
            loading="lazy"
          />
        </figure>
      </div>

      {/* <div className={styles.wrapper}>
        <img
          className={styles.bgImg}
          src="/images/background/bg5.png"
          loading="lazy"
          alt="bg"
        />
        <div className="container">
          <div className={styles.gridContainer}>
            <div className={styles.logoContainer}>
              <img
                src="/images/yoglogo.png"
                className={styles.image}
                loading="lazy"
                alt="logo"
              />
            </div>
            <div className={styles.content}>
              <div className={cx(styles.title, "section-title")}>
                Get <span>free E-book</span>
              </div>
              <div className={cx(styles.subtitle, "section-subtitle")}>
                Let our team of yogis hand-pick a few options for you. It's free
                and we'll get back to you within 24 hours.
              </div>
              <button
                className={cx(styles.button, "primary-btn")}
                onClick={showModal}
              >
                Download E-book
              </button>
            </div>
            <div className={styles.imageContainer}>
              <img
                src="/images/yoganews.png"
                className={styles.image}
                loading="lazy"
                alt="news"
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* {isModalOpen && (
        <EbookModal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          error={error}
          handleSubmit={handleSubmit}
          handleEmailChange={handleEmailChange}
          email={email}
          loading={loading}
        />
      )} */}
    </div>
  );
}

export default Newsletter;
