import cx from "classnames";
import React from "react";
import { ReviewSlider } from "../../../components/ReviewSlider";
import styles from "./Review.module.css";

const cardData = [
  {
    title: "Excellent Service",
    avatar: "/images/reviews/profile1.jpg",
    user: {
      f_name: "Charles",
      l_name: "Milner",
    },
    description:
      "Quick responses from retreats registered to questions. Retreat info and pictures gave excellent detail of the teachers, facilities and classes/agenda for the retreat. Thanks",
  },
  {
    title: "Excellent Service",
    avatar: "/images/reviews/profile2.jpg",
    user: {
      f_name: "Charles",
      l_name: "Milner",
    },
    description:
      "Quick responses from retreats registered to questions. Retreat info and pictures gave excellent detail of the teachers, facilities and classes/agenda for the retreat. Thanks",
  },
  {
    title: "Excellent Service",
    avatar: "/images/reviews/profile1.jpg",
    user: {
      f_name: "Charles",
      l_name: "Milner",
    },
    description:
      "Quick responses from retreats registered to questions. Retreat info and pictures gave excellent detail of the teachers, facilities and classes/agenda for the retreat. Thanks",
  },
  {
    title: "Excellent Service",
    avatar: "/images/reviews/profile2.jpg",
    user: {
      f_name: "Charles",
      l_name: "Milner",
    },
    description:
      "Quick responses from retreats registered to questions. Retreat info and pictures gave excellent detail of the teachers, facilities and classes/agenda for the retreat. Thanks",
  },
];

function Review() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img
          className={styles.bgImgTop}
          src="/images/background/bg3.png"
          alt="bgImg"
          loading="lazy"
        />
        <img
          className={styles.bgImgBottom}
          src="/images/background/himal_2.png"
          alt="bgImg"
          loading="lazy"
        />
        <div className="container">
          <div className={styles.gridWrapper}>
            <div className={styles.gridContainer}>
              <div className={cx(styles.subtitle, "section-subtitle")}>Great Words About</div>
              <div className={cx(styles.title, "section-title")}>People say about us</div>
            </div>
            <ReviewSlider cardData={cardData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
