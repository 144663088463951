import { Gauge, RingProgress } from "@ant-design/plots";
import { Badge, Calendar, Carousel, Space, Tooltip } from "antd";
import cx from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getBookingCounts,
  getBookingDates,
} from "../../../services/api/bookings";
import styles from "./AdminDashboard.module.css";

const bannerData = [
  { id: 1, image: "/images/banner/admin-banner-1.png" },
  { id: 2, image: "/images/banner/admin-banner-2.png" },
  { id: 3, image: "/images/banner/admin-banner-3.png" },
];

function AdminDashboard() {
  const [mark, setMark] = useState([]);
  const [bookingCounts, setBookingCounts] = useState({});

  const verifiedProgressConfig = {
    height: 100,
    width: 100,
    autoFit: true,
    percent:
      bookingCounts.verified /
      (bookingCounts.verified + bookingCounts.unverified), // 0.67
    color: ["#34B53A", "#E2FBD7"],
  };

  const unverifiedProgressConfig = {
    height: 100,
    width: 100,
    autoFit: true,
    percent:
      bookingCounts.unverified /
      (bookingCounts.verified + bookingCounts.unverified), // 0.46
    color: ["#FF000D", "#FED7D7"],
  };

  const total = bookingCounts.paid + bookingCounts.unpaid;
  const percent = total === 0 ? 0 : (bookingCounts.paid / total) * 100;

  const gaugeConfig = {
    percent,
    range: {
      color: "l(0) 0:#036d1e",
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -10,
        style: {
          fontSize: "20px",
          color: "#383E49",
          fontWeight: "600",
        },
        formatter: () => `${percent.toFixed(2)}%`,
      },
    },
  };
  // const checkDate = (date) => {
  //   let obj = mark.find((o) => o.date === date);
  //   return obj !== undefined;
  // };

  // const bookingCount = (date) => {
  //   let obj = mark.find((o) => o.date === date);
  //   return obj ? obj.total : 0;
  // };

  const fetchBookingDate = (year, month) => {
    getBookingDates(year, month)
      .then((res) => {
        console.log(res);
        setMark(res.data.booking);
      })
      .catch((err) => {});
  };

  const fetchBookingCount = () => {
    getBookingCounts()
      .then((res) => {
        setBookingCounts(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchBookingDate("", "");
    fetchBookingCount();
  }, []);

  const dateFullCellRender = (date) => {
    const matchingDates = mark.find((markedDate) =>
      moment(markedDate.date, "YYYY-MM-DD").isSame(date, "day")
    );
    if (matchingDates) {
      return (
        <Tooltip title={`${matchingDates.total} Bookings`}>
          <div className="highlight">{date.format("D")}</div>
        </Tooltip>
      );
    }

    return <div>{date.format("D")}</div>;
  };

  return (
    <div>
      <Carousel dots={true} autoplay className={styles.carousel}>
        {bannerData.map((item) => (
          <div key={item.id} className={styles.banner}>
            <img src={item.image} className={styles.image} alt="banner" />
          </div>
        ))}
      </Carousel>
      <div className={styles.headerContainer}>
        <div className={styles.overviewContainer}>
          <div className={styles.title}>Overview</div>
          <div className={styles.overviewGrid}>
            <div className={styles.bookingContainer}>
              <div className={styles.bookingtitleContainer}>
                <div className={styles.bookingSubtitle}>Completed</div>
                <div className={styles.bookingTitle}>Bookings</div>
              </div>
              <div className={cx(styles.booking, styles.accepted)}>
                {bookingCounts.completed}
              </div>
            </div>
            <div className={styles.bookingContainer}>
              <div className={styles.bookingtitleContainer}>
                <div className={styles.bookingSubtitle}>Cancelled</div>
                <div className={styles.bookingTitle}>Bookings</div>
              </div>
              <div className={cx(styles.booking, styles.cancelled)}>
                {bookingCounts.cancelled}
              </div>
            </div>
          </div>
        </div>{" "}
        <div className={styles.userContainer}>
          <div className={styles.bookingtitleContainer}>
            <div className={styles.bookingTitle}>Number of Users</div>
          </div>
          <div className={cx(styles.booking, styles.userCount)}>
            {bookingCounts.userCount}
          </div>
          {/* <UserOutlined className={styles.userIcon} /> */}
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.programsContainer}>
          <div className={styles.title}>Total Programs</div>
          <div className={styles.program}>
            <Space className={styles.programTitle} size={"large"}>
              <div className={styles.badgeContainer}>
                <Badge color="#34B53A" text="Verified" />
                <p>: {bookingCounts.verified}</p>
              </div>
              <div className={styles.badgeContainer}>
                <Badge color="#FF000D" text="Not Verified" />
                <p>: {bookingCounts.unverified}</p>
              </div>
            </Space>
            <Space size={"large"} className={styles.progressContainer}>
              <RingProgress {...verifiedProgressConfig} />
              <RingProgress {...unverifiedProgressConfig} />
            </Space>
          </div>
        </div>
        <div className={styles.financeContainer}>
          <div className={styles.financeHeader}>
            <div className={styles.title}>Finance</div>
            <Space direction="vertical">
              <div className={styles.badgeContainer}>
                <Badge color="var(--primary)" text="Paid" />
                <p>: ${bookingCounts.paid?.toFixed(2)}</p>
              </div>
              <div className={styles.badgeContainer}>
                <Badge color="#F1F1F1" text="Yet to be Paid" />
                <p>: ${bookingCounts.unpaid?.toFixed(2)}</p>
              </div>
            </Space>
          </div>
          <Gauge {...gaugeConfig} />
        </div>
      </div>
      <Calendar
        className="site-calendar-demo-card"
        fullscreen={false}
        dateCellRender={dateFullCellRender}
      />
    </div>
  );
}

export default AdminDashboard;
