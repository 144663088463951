import { Button, Rate } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getDateText } from "../../../utils/dateTime/dateAndTime";
import styles from "./ReviewHistory.module.css";

const ReviewHistoryCard = ({ review }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.bookingCard}>
      <div className={styles.orderWrapper}>
        <div className={styles.placedDate}>Attended on {getDateText(review.program_date)}</div>
      </div>
      <div className={styles.yourReview}>Your program rating & review:</div>
      <div className={styles.programWrapper}>
        <div
          className={styles.imageContainer}
          onClick={() => {
            navigate(`/programs/${review.yoga_program.id}/`);
          }}
        >
          <img
            src={process.env.REACT_APP_SERVER_URL + review.yoga_program.media[0].link}
            className={styles.image}
            loading="lazy"
            alt="review"
          />
        </div>
        <div className={styles.rightWrapper}>
          <div className={styles.detailsWrapper}>
            <div
              className={styles.title}
              onClick={() => {
                navigate(`/programs/${review.yoga_program.id}/`);
              }}
            >
              {review.yoga_program.title}
            </div>
            <div className={styles.country}>{review.yoga_program.country.name}</div>
            <div className={styles.rating}>
              <Rate disabled allowHalf value={review.rating} />
            </div>
          </div>
          {review.description && <div className={styles.reviewBox}>{review.description}</div>}
        </div>
      </div>
    </div>
  );
};

export default ReviewHistoryCard;
