import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Loading from "../../AdminPanel/components/Loading";
import { notifyError } from "../../App";
import { UserContext } from "../../contexts/UserContext";
import { passwordlessLogin } from "../../services/api/auth";
import { setCookie } from "../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";

const PasswordlessLogin = () => {
  const { token } = useParams();
  const { userData, setUserData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    passwordlessLogin(token, abort.signal)
      .then((res) => {
        // navigate("/");
        if (res.data.user.role === "provider" || res.data.user.role === "operator") {
          setCookie("token", res.data.token);
          setCookie("user", res.data.user);
          setUserData(res.data.user);
          navigate("/partner/");
        } else if (res.data.user.role === "user") {
          setCookie("token", res.data.token);
          setCookie("user", res.data.user);
          setUserData(res.data.user);
          navigate("/user/");
        } else {
          notifyError("Invalid User.");
          setUserData({});
        }
        setLoading(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
    return () => {
      abort.abort();
    };
  }, [token]);

  return loading ? <Loading /> : <></>;
};

export default PasswordlessLogin;
