import { Button, Divider, Form, Input } from "antd";
import cx from "classnames";
import React, { useContext, useState } from "react";
import ReactFacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { RiFacebookFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../App";
import { UserContext } from "../../../contexts/UserContext";
import { loginFacebook, register } from "../../../services/api/auth";
import { setCookie } from "../../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import styles from "./UserSignUp.module.css";

function UserSignUp() {
  const [data, setData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const { setUserData } = useContext(UserContext);

  const navigate = useNavigate();
  const [emailErr, setEmailErr] = useState("");

  const handleSubmit = () => {
    var formData = {
      f_name: data.f_name,
      l_name: data.l_name,
      email: data.email,
      password: data.password,
    };
    setLoading(true);
    register(formData)
      .then((res) => {
        if (res.status === "Success") {
          notifySuccess(res.message);
          setLoading(true);
          navigate("/login/");
        }
      })
      .catch((err) => {
        setEmailErr(err.response.data.data.email[0]);
        setLoading(false);
      });
  };

  const responseFacebook = (res) => {
    loginFacebook({ token: res.accessToken })
      .then((res) => {
        if (res.data.user.role === "user") {
          setCookie("token", res.data.token);
          setCookie("user", res.data.user);
          setUserData(res.data.user);
          navigate("/user/");
        } else {
          notifyError("Invalid User.");
          setUserData({});
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const responseGoogle = () => {};

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        src="/images/login-bg.png"
        alt="bg"
        loading="lazy"
      />
      <div className={styles.container}>
        <div className="container">
          <div className={styles.card}>
            <div className={styles.title}>Create your account</div>
            <div className={styles.subtitle}>
              Already have an account? <Link to="/login/">Log in</Link>
            </div>
            <Form
              className={styles.formContainer}
              name="basic"
              autoComplete="off"
              layout="vertical"
              onFinish={handleSubmit}
              onSubmit={(e) => e.preventDefault()}
              id="signupform"
            >
              <div className={styles.formItemWrapper}>
                <Form.Item
                  className={styles.formItem}
                  name="fname"
                  label="First Name"
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your first name`,
                    },
                  ]}
                >
                  <Input
                    className={styles.input}
                    placeholder="First Name"
                    value={data.f_name}
                    onChange={(e) => {
                      setData({ ...data, f_name: e.target.value }); //if state is dictionary, set data like this
                    }}
                  />
                </Form.Item>
              </div>
              <div className={styles.formItemWrapper}>
                <Form.Item
                  className={styles.formItem}
                  name="lname"
                  label="Last Name"
                  labelCol={{ span: 22 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: `Please enter your last name`,
                    },
                  ]}
                >
                  <Input
                    className={styles.input}
                    placeholder="Last Name"
                    value={data.l_name}
                    onChange={(e) => {
                      setData({ ...data, l_name: e.target.value }); //if state is dictionary, set data like this
                    }}
                  />
                </Form.Item>
              </div>

              {/* <Form.Item
              name="Phone Number"
              label="Phone Number"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `Please enter your phone number`,
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Phone"
                value={data.phone}
                suffix={<PhoneOutlined />}
                onChange={(e) => {
                  setData({ ...data, phone: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item> */}
              <div className={styles.formItemWrapper}>
                <Form.Item
                  className={styles.formItem}
                  name="Email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: `Please enter your email`,
                    },
                    {
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please enter a valid email address.",
                    },
                  ]}
                >
                  <Input
                    className={cx(styles.input, emailErr && styles.error)}
                    type="email"
                    placeholder="Email"
                    autocomplete="new-email"
                    value={data.email}
                    onChange={(e) => {
                      setData({ ...data, email: e.target.value }); //if state is dictionary, set data like this
                      setEmailErr("");
                    }}
                  />
                </Form.Item>
                {emailErr && <div className={styles.error}>{emailErr}</div>}
              </div>

              <div className={styles.formItemWrapper}>
                <Form.Item
                  className={styles.formItem}
                  label="Password"
                  name="Password"
                  autocomplete="new-password"
                  rules={[
                    {
                      required: true,
                      message: `Please enter your password`,
                    },
                    {
                      pattern: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                      message:
                        "Password should be at least 8 characters long and contain at least one letter and one digit.",
                    },
                  ]}
                >
                  <Input.Password
                    className={styles.input}
                    placeholder="Password"
                    value={data.password}
                    onChange={(e) => {
                      setData({ ...data, password: e.target.value }); //if state is dictionary, set data like this
                    }}
                  />
                </Form.Item>
              </div>
              <div className={styles.formItemWrapper}>
                <Form.Item className={styles.formItem}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={loading}
                    disabled={loading}
                    className={styles.button}
                  >
                    Create an Account
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <div>
              <div className={styles.facebook}>
                <ReactFacebookLogin
                  textButton="Sign up with Facebook"
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID} //APP ID NOT CREATED YET
                  fields="name,email,picture"
                  callback={responseFacebook}
                  onFailure={(a) => console.log("fff")}
                  autoLoad={false}
                  icon={<RiFacebookFill className="facebook-icon" />}
                  cssClass="facebook"
                  // scope="public_profile,user_friends,email"
                />
              </div>
              <div className={styles.google}>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GMAIL_APP_ID}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className="google"
                    >
                      <svg
                        width="18"
                        height="18"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="#000" fill-rule="evenodd">
                          <path
                            d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                            fill="#EA4335"
                          ></path>
                          <path
                            d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                            fill="#4285F4"
                          ></path>
                          <path
                            d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                            fill="#FBBC05"
                          ></path>
                          <path
                            d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                            fill="#34A853"
                          ></path>
                          <path fill="none" d="M0 0h18v18H0z"></path>
                        </g>
                      </svg>
                      Sign up with Google
                    </button>
                  )}
                  buttonText="Login with Google"
                  onSuccess={responseGoogle}
                  onFailure={(a) => console.log("fff")}
                  cookiePolicy="single_host_origin"
                  autoLoad={false}
                  className="google"
                />
              </div>
            </div>
            <div className={styles.footerTitle}>
              Proceeding confirms you accept Yogsewa's terms & privacy policy.
            </div>
            <Divider>OR</Divider>
            <Button
              type="primary"
              size="large"
              className={cx(styles.button, styles.loginBtn)}
              onClick={() => navigate("/signup/partner/")}
            >
              Sign up as a partner
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSignUp;
