const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const dateFormat = (today) => {
  let year = today.getFullYear();
  let month = (today.getMonth() + 1).toString().padStart(2, "0");
  let day = today.getDate().toString().padStart(2, "0");
  let yyyyMMdd = year + "-" + month + "-" + day;
  return yyyyMMdd;
};

export const getDateTime = (data) => {
  const today = new Date(data);
  const year = today.toLocaleDateString("en", {
    year: "numeric",
  });
  const date = `${today.getDate()} ${today.toLocaleDateString("en", {
    month: "long",
  })}\n\n, ${year}`;

  const hour = today.getHours();
  const wish = `Good ${
    (hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
  }, `;

  const time = today.toLocaleTimeString("en", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  return {
    date,
    time,
    wish,
  };
};

export const getFixedScheduleDate = (date) => {
  if (!date) return "";

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const startSplit = date.start_date.split("-");
  const endSplit = date.end_date.split("-");

  const startMonth = months[parseInt(startSplit[1]) - 1];
  const startDay = parseInt(startSplit[2]);
  const startYear = startSplit[0];

  const endMonth = months[parseInt(endSplit[1]) - 1];
  const endDay = parseInt(endSplit[2]);
  const endYear = endSplit[0];

  return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
};

export const getSeasonalDate = (schedules) => {
  let val = "";
  schedules.forEach((item, index) => {
    let startMonth = parseInt(item.start_date.split("-")[1]);
    let endMonth = parseInt(item.end_date.split("-")[1]);
    val +=
      startMonth !== endMonth
        ? `${months[startMonth - 1]} - ${months[endMonth - 1]}`
        : months[endMonth - 1];
    if (index !== schedules.length - 1) {
      val += " | ";
    }
  });
  return val;
};

export const getDateText = (date) => {
  let obj = new Date(date);
  let year = obj.getFullYear();
  let month = obj.getMonth(); // Use getMonth instead of getUTCMonth
  let day = obj.getDate(); // Use getDate to get the day

  return `${day} ${months[month]} ${year}`;
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toISOString().split("T")[0];
};

export const getDateRangeSeasonal = (date) => {
  if (!date) return "";

  let startSplit = date.start_date.split("-");
  let endSplit = date.end_date.split("-");

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (startSplit[0] === endSplit[0] && startSplit[1] === endSplit[1]) {
    return months[parseInt(startSplit[1]) - 1] + " " + endSplit[0];
  }

  return (
    months[parseInt(startSplit[1]) - 1] +
    " - " +
    months[parseInt(endSplit[1]) - 1] +
    " " +
    endSplit[0]
  );
};
