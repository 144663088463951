import React from "react";
import { AdminPaymentHistory, AdminReceive } from "../views/AdminAccounting";
import PaymentHistoryDetails from "../views/AdminAccounting/AdminPaymentHistory/PaymentHistoryDetails";
import { CancelledBookings, CompletedBookings } from "../views/AdminBookings";
import { ProgramBookings } from "../views/AdminBookings/ProgramBookings";
import { AdminBusiness } from "../views/AdminBusiness";
import { AdminDashboard } from "../views/AdminDashboard";
import { AdminPayments } from "../views/AdminPayments";
import { AdminProfile } from "../views/AdminProfile";
import { ProgramList } from "../views/AdminPrograms";
import AdminPrograms from "../views/AdminPrograms/AdminPrograms";
import { ProgramsEdit } from "../views/AdminPrograms/Edit";
import NotVerifiedPrograms from "../views/AdminPrograms/List/NotVerifiedPrograms";
import OperatorList from "../views/Operator/List/OperatorList";
import OperatorUpdate from "../views/Operator/Update/OperatorUpdate";

const routes = [
  { path: "/", element: <AdminDashboard /> },
  {
    path: "/yoga-programs/add/",
    element: <AdminPrograms mode="add" />,
  },
  {
    path: "/yoga-programs/verified/",
    element: <ProgramList />,
  },
  {
    path: "/yoga-programs/not-verified/",
    element: <NotVerifiedPrograms />,
  },

  { path: "/yoga-programs/edit/", element: <ProgramsEdit /> },
  { path: "/yoga-programs/bookings/", element: <ProgramBookings /> },
  {
    path: "/operators/add/",
    element: <OperatorUpdate mode="add" />,
    role: "provider",
  },
  {
    path: "/operators/edit/:id",
    element: <OperatorUpdate mode="edit" />,
    role: "provider",
  },
  {
    path: "/operators/",
    element: <OperatorList />,
    role: "provider",
  },
  { path: "/profile/", element: <AdminProfile /> },
  { path: "/payment/", element: <AdminPayments /> },
  { path: "/payment/", element: <AdminPayments /> },
  { path: "/business/", element: <AdminBusiness /> },
  { path: "/accounting/receive/", element: <AdminReceive /> },
  { path: "/accounting/history/", element: <AdminPaymentHistory /> },
  { path: "/accounting/history/:id", element: <PaymentHistoryDetails /> },
  { path: "/bookings/completed/", element: <CompletedBookings /> },
  { path: "/bookings/cancelled/", element: <CancelledBookings /> },
];

export default routes;
