import { Col, Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { notifySuccess } from "../../../../../../App";
import {
  addInstructor,
  updateInstructor,
} from "../../../../../../services/api/programs";
import ImageUpload from "../../../../../components/ImageUpload";
import TextEditor from "../../../../../components/TextEditor";
import styles from "./InstructorModal.module.css";

const InstructorModal = ({
  isModalOpen,
  setIsModalOpen,
  instructorData,
  programId,
  setRefresh,
  id,
}) => {
  const [form] = Form.useForm();

  const [data, setData] = useState(instructorData);
  const [photo, setPhoto] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageSizeError, setImageSizeError] = useState("");

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.fileList);
    }
    return e && e.fileList;
  };

  const handleOk = () => {
    if (loading === true) {
      return;
    }
    let err = {};
    if (data.name.length === 0) {
      err["name"] = ["Name is required."];
    }
    if (data.description.length === 0) {
      err["description"] = ["Description is required."];
    }
    if (photo.length === 0) {
      err["photo"] = ["Photo is required."];
    }
    if (data.designation.length === 0) {
      err["designation"] = ["Designation is required."];
    }
    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }
    let formData = {
      yoga_program_id: id,
      name: data.name,
      description: data.description,
      photo: photo[0]?.originFileObj,
      designation: data.designation,
    };
    setLoading(true);
    if (instructorData.id) {
      updateInstructor(instructorData.id, formData)
        .then((res) => {
          setRefresh((prev) => !prev);
          setIsModalOpen(false);
          notifySuccess("Updated successfully.");
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      addInstructor(programId, formData)
        .then((res) => {
          setRefresh((prev) => !prev);
          setIsModalOpen(false);
          notifySuccess("Added successfully.");
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: instructorData.name, //for showing intial value after fetching data (name is required from Form.Item)
      description: instructorData.description, //for select initial value placeholder
      designation: instructorData.designation,
      //for showing intial value after fetching data
    });
    if (instructorData.photo) {
      const fileList = [
        {
          url: process.env.REACT_APP_SERVER_URL + instructorData.photo,
        },
      ];

      setPhoto([...fileList]); // it just removes photo from array
    } else {
      setPhoto([]);
    }
    setData(instructorData);
    setErrors([]);
  }, [instructorData]);

  useEffect(() => {
    if (imageSizeError) {
      message.error(imageSizeError);
    }
  }, [imageSizeError]);

  return (
    <Modal
      title="Accomodation Details"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className={styles.accomodationContainer}>
        <Form
          className={styles.formContainer}
          name="basic"
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Name"
              name={`name`}
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors[`name`] ? "error" : "success"}
              help={errors[`name`] ? errors[`name`][0] : ""}
            >
              <Input
                placeholder="Name"
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              name={`photos`}
              listType="picture"
              getValueFromEvent={normFile}
              validateStatus={errors[`photo`] ? "error" : "success"}
              help={errors[`photo`] ? errors[`photo`][0] : ""}
            >
              <ImageUpload
                fileList={photo}
                setFileList={setPhoto}
                multiple={true}
                photoWidth="280"
                photoHeight="300"
                setError={(msg) => {
                  setImageSizeError(msg);
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Designation"
              name={`designation`}
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors[`designation`] ? "error" : "success"}
              help={errors[`designation`] ? errors[`designation`][0] : ""}
            >
              <Input
                type="text"
                placeholder="Designation"
                value={data.designation}
                onChange={(e) => {
                  setData({ ...data, designation: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Description"
              name={`description`}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors[`description`] ? "error" : "success"}
              help={errors[`description`] ? errors[`description`][0] : ""}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
description: value depends on text editor(before setValue={setDescription}) 
*/}

              <TextEditor
                value={data.description}
                onChange={(val) => {
                  setData({ ...data, description: val });
                }}
              />
            </Form.Item>
          </Col>
        </Form>
      </div>
    </Modal>
  );
};

export default InstructorModal;
