import { Button, Divider, Form, Input, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect } from "react";
import styles from "./AdminPayments.module.css";
import { getAllBanks } from "../../../services/api/payments";
import { useState } from "react";
import {
  getProvider,
  updateProviderPayment,
} from "../../../services/api/admin";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import { notifyError, notifySuccess } from "../../../App";

function AdminPayments() {
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({
    account_name: "",
    account_number: "",
    bank_id: "",
    branch: "",
  });
  const [form] = Form.useForm();

  useEffect(() => {
    let abortCont = new AbortController();
    getAllBanks(abortCont.signal)
      .then((res) => {
        setBanks(res.data);
      })
      .catch((e) => {});

    getProvider(abortCont.signal)
      .then((res) => {
        if (res.status === "Success") {
          form.setFieldsValue({
            AccountName: res.data.user.service_provider.account_name,
            AccountNumber: res.data.user.service_provider.account_number,
            BankName: res.data.user.service_provider.bank_id,
            BranchName: res.data.user.service_provider.branch,
            Password: "",
          });
          setData({
            account_name: res.data.user.service_provider.account_name,
            account_number: res.data.user.service_provider.account_number,
            bank_id: res.data.user.service_provider.bank_id,
            branch: res.data.user.service_provider.branch,
            password: "",
          });
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });

    return () => {
      abortCont.abort();
    };
  }, [refresh]);

  const handleCancel = () => {
    setRefresh((prev) => !prev);
  };

  const handleSubmit = () => {
    setLoading(true);
    updateProviderPayment(data)
      .then((res) => {
        notifySuccess(res.message);
        setRefresh((prev) => !prev);
        setLoading(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };
  return (
    <>
      <div className={styles.title}>Payment Information</div>

      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onSubmit={(e) => e.preventDefault()}
        onFinish={handleSubmit}
        form={form}
      >
        <div className={styles.section}>
          <div className={styles.sectionContent}>
            <div className={styles.content}>
              <div className={styles.label}>Account Name</div>
            </div>
            <Form.Item
              name="AccountName"
              rules={[
                {
                  required: true,
                  message: "Please input your account name",
                },
              ]}
            >
              <Input
                className={styles.input}
                value={data.account_name}
                onChange={(e) => {
                  setData({ ...data, account_name: e.target.value });
                }}
              />
            </Form.Item>
          </div>
          <div className={styles.sectionContent}>
            <div className={styles.content}>
              <div className={styles.label}>Account Number</div>
            </div>
            <Form.Item
              name="AccountNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your account number",
                },
              ]}
            >
              <Input
                className={styles.input}
                value={data.account_number}
                onChange={(e) => {
                  setData({ ...data, account_number: e.target.value });
                }}
              />
            </Form.Item>
          </div>
          <div className={styles.sectionContent}>
            <div className={styles.content}>
              <div className={styles.label}>Bank Name</div>
            </div>
            <Form.Item
              name="BankName"
              rules={[
                {
                  required: true,
                  message: "Please select a bank",
                },
              ]}
            >
              <Select
                placeholder="Select a bank"
                value={data.bank_id || undefined}
                onChange={(val) => {
                  setData({ ...data, bank_id: val });
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .startsWith(input.toLowerCase())
                }
                showSearch
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {banks.map((bank, index) => (
                  <Option key={index} value={bank.id}>
                    {bank.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.sectionContent}>
            <div className={styles.content}>
              <div className={styles.label}>Branch Name</div>
            </div>
            <Form.Item
              name="BranchName"
              rules={[
                {
                  required: true,
                  message: "Please enter branch name",
                },
              ]}
            >
              <Input
                className={styles.input}
                value={data.branch}
                onChange={(e) => {
                  setData({ ...data, branch: e.target.value });
                }}
              />
            </Form.Item>
          </div>
          <Divider type="horizontal" style={{ color: "black" }} />
          <div className={styles.sectionContent}>
            <div className={styles.content}>
              <div className={styles.label}>Enter password to proceed</div>
            </div>
            <Form.Item
              name="Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password
                type="password"
                placeholder=""
                className={styles.input}
                value={data.password}
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                }}
              />
            </Form.Item>
          </div>
        </div>

        <div className={styles.footer}>
          <Button
            type="primary"
            className={styles.saveButton}
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
          <Button
            className={styles.cancelButton}
            type="danger"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AdminPayments;
