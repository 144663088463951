import { baseRequest } from "./base";

const addOperator = async (body) => {
  var data = new FormData();
  Object.keys(body).forEach((key) => {
    if (body[key] !== null && body[key] !== undefined) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: "/api/operators",
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });

  return response;
};

const deleteOperator = async (id) => {
  var response = await baseRequest({
    url: `/api/operators/${id}`,
    method: "DELETE",
  });
  return response;
};

const updateOperatorById = async (id) => {
  var response = await baseRequest({
    url: `/api/operators/${id}/status`,
    method: "POST",
  });
  return response;
};

const updateOperator = async (id, body) => {
  var data = new FormData();
  Object.keys(body).forEach((key) => {
    if (body[key] !== null && body[key] !== undefined) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: `/api/operators/${id}`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });

  return response;
};

const getAllOperator = async (page, signal, search) => {
  var response = await baseRequest({
    url: `/api/operators?page=${page}&search=${search}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getOperatorById = async (id) => {
  var response = await baseRequest({
    url: `/api/operators/${id}`,
    method: "GET",
  });

  return response;
};

const getAllBookings = async (signal) => {
  var response = await baseRequest({
    url: "/api/user-booking-list",
    method: "GET",
    signal: signal,
  });

  return response;
};

export {
  addOperator,
  getAllOperator,
  getOperatorById,
  updateOperator,
  getAllBookings,
  deleteOperator,
  updateOperatorById,
};
