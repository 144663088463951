import { HeartOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../App";
import SearchCard from "../../components/SearchCard/SearchCard";
import { getFavourites } from "../../services/api/programs";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import styles from "./Wishlist.module.css";

const Wishlist = () => {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const abortCont = new AbortController();
    setLoading(true);
    getFavourites(abortCont.signal)
      .then((res) => {
        setCardData(res.data.favourites);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      })
      .finally(() => setLoading(false));
    return () => {
      abortCont.abort();
    };
  }, [refresh]);

  return loading ? (
    <div className={styles.container}>
      <Spin />
    </div>
  ) : (
    <section className={styles.wrapper}>
      <div className={styles.title}>My Wishlist</div>
      {cardData.length <= 0 ? (
        <div className={styles.emptyContainer}>
          <HeartOutlined className={styles.icon} />
          <div className={styles.message}>
            There are no favorites yet.
            <br /> Add your favorites to wishlist and they will show here.
          </div>
          <Button onClick={() => navigate("/search/")}>
            Continue Exploring
          </Button>
        </div>
      ) : (
        <div className={styles.cardContainer}>
          {cardData.map((item) => (
            <SearchCard
              cardItem={item}
              key={item.id}
              setRefresh={setRefresh}
              wishlist={true}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default Wishlist;
