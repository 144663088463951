import { Modal } from "antd";
import React from "react";
import styles from "./ReceiveModal.module.css";

function ReceiveModal({ open, onOk, onCancel, data, id }) {
  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} footer={null}>
      {data?.map(
        (item, index) =>
          id === item.id && (
            <div className={styles.gridContainer} key={index}>
              <div>Payment Id : </div>
              <div>{item?.payment_id}</div>
              <div>Booking Date : </div>
              <div>{item.booking?.date}</div>
              <div>Program Title : </div>
              <div>{item.booking?.program_title}</div>
              <div>Booked for : </div>
              <div>{item.booking?.name}</div>
              <div>Provider Income : </div>
              <div>$ {item.provider_income}</div>
            </div>
          )
      )}
    </Modal>
  );
}

export default ReceiveModal;
