import { Button, Col, Form, Input } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../services/api/auth";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import { notifyError, notifySuccess } from "../User/UserLayout";
import styles from "./ChangePassword.module.css";

function ChangePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const validatePassword = (rule, value, callback) => {
    if (newPassword !== confirmPassword) {
      callback("Password must be same.");
    } else {
      callback();
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    changePassword({ password: newPassword, old_password: oldPassword })
      .then((res) => {
        notifySuccess("Password Changed Successfully");
        navigate("/user/profile/");
        setLoading(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Change Password</div>
      <div className={styles.subtitle}>
        To make your account secure, please create a new password to replace the
        current password.
      </div>
      <Form
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmit}
        onSubmit={(e) => e.preventDefault()}
      >
        <Col style={{ maxWidth: "380px", padding: "0" }}>
          <Form.Item
            name="Current Password"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: `Please enter current password`,
              },
              {
                min: 8,
                message: "The password must be at least 8 characters.",
              },
            ]}
          >
            <Input.Password
              value={oldPassword}
              placeholder="Current Password"
              autoComplete="new-password"
              className={styles.input}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="New Password"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: `Please enter new password`,
              },
              {
                pattern: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                message:
                  "Password should be at least 8 characters long and contain at least one letter and one digit.",
              },
            ]}
          >
            <Input.Password
              placeholder="New Password"
              value={newPassword}
              autoComplete="new-password"
              className={styles.input}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="Confirm Password"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: `Please enter new password`,
              },
              {
                pattern: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
                message:
                  "Password should be at least 8 characters long and contain at least one letter and one digit.",
              },
              { validator: validatePassword },
            ]}
          >
            <Input.Password
              value={confirmPassword}
              className={styles.input}
              placeholder="Confirm Password"
              autoComplete="new-password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </Form.Item>
        </Col>
        <div className={styles.buttonContainer}>
          <Form.Item wrapperCol={{ span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              className={styles.button}
            >
              Change Password
            </Button>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              className={styles.backbutton}
              onClick={() => navigate("/user/")}
            >
              Back
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default ChangePassword;
