import { UserOutlined } from "@ant-design/icons";
import { Modal, Rate } from "antd";
import { Input } from "antd/lib";
import cx from "classnames";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb/CustomBreadcrumb";
import { ImageCarousel } from "../../../components/ImageCarousel";
import { SignInModal } from "../../../components/SignInModal";
import { UserContext } from "../../../contexts/UserContext";
import { converter } from "../../../utils/htmlConverter/htmlConverter";
import styles from "./ProgramDescription.module.css";

function ProgramDescription({ data, breadcrumb }) {
  const [collapse, setCollapse] = useState({
    description: false,
    summary: false,
  });
  const [openModal, setOpenModal] = useState(false);

  const { userData } = useContext(UserContext);

  const [bookData, setBookData] = useState({
    name: "",
    date: "",
    num_of_seats: "",
  });

  const navigate = useNavigate();

  const [programModal, setProgramModal] = useState(false);
  const showProgramModal = () => {
    setProgramModal(true);
  };

  const handleProgramCancel = () => {
    setProgramModal(false);
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className={styles.container}>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <div className="container">
        <div className={styles.titleContainer}>
          <div className={styles.title}>{data.title}</div>
          <div className={styles.subtitleContainer}>
            <div className={styles.ratingContainer}>
              <Rate disabled allowHalf value={data.reviews_avg_rating} />
              <div className={styles.rating}>
                {data.rating} <span>({data.reviews_count} reviews)</span>
              </div>
            </div>
            <div className={styles.locationContainer}>
              <div className={styles.icon}>
                <ion-icon name="location-outline"></ion-icon>
              </div>
              <div className={styles.location}>
                {data.area + " , " + data.country?.name}
              </div>
            </div>
            <div className={styles.guaranteeContainer}>
              <div className={styles.icon}>
                <ion-icon name="checkbox-outline"></ion-icon>
              </div>
              <div className={styles.guarantee}>Best Price Guarantee</div>
            </div>
          </div>
        </div>

        <div className={styles.gridContainer}>
          <div className={styles.detailContainer}>
            <div>
              <div style={{ margin: "0 auto" }}>
                <div className={styles.sliderWrapper}>
                  <ImageCarousel
                    data={data.media.map((item) => ({
                      id: item.id,
                      image: item.link,
                    }))}
                    customPage={false}
                  />
                </div>
              </div>
              <div className={styles.descriptionContainer}>
                <div className={styles.descriptionTitle}>Description</div>
                <div
                  className={cx(
                    styles.descriptionSubtitle,
                    collapse.description ? styles.descriptionOpen : null
                  )}
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(data.description),
                  }}
                />
                <div
                  className={styles.viewMore}
                  onClick={() =>
                    setCollapse({
                      ...collapse,
                      description: !collapse.description,
                    })
                  }
                >
                  <div className={styles.viewIconContainer}>
                    {collapse.description ? "View Less" : "View More"}
                    <div
                      className={styles.viewIcon}
                      style={
                        !collapse.description
                          ? { transform: "rotateZ(-180deg)" }
                          : null
                      }
                    >
                      <ion-icon name="chevron-up-outline"></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.summaryContainer}>
                <div className={styles.descriptionTitle}>Summary</div>
                <div
                  className={cx(
                    styles.descriptionSubtitle,
                    collapse.summary ? styles.descriptionOpen : null
                  )}
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(data.summary),
                  }}
                />
                <div
                  className={styles.viewMore}
                  onClick={() =>
                    setCollapse({
                      ...collapse,
                      summary: !collapse.summary,
                    })
                  }
                >
                  <div className={styles.viewIconContainer}>
                    {collapse.summary ? "View Less" : "View More"}
                    <div
                      className={styles.viewIcon}
                      style={
                        !collapse.summary
                          ? { transform: "rotateZ(-180deg)" }
                          : null
                      }
                    >
                      <ion-icon name="chevron-up-outline"></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureContainer}>
                <div className={styles.descriptionTitle}>Features</div>
                <div className={styles.features}>
                  <div className={styles.featureWrapper}>
                    {data.feature_style && (
                      <div className={styles.featureDetailsContainer}>
                        <div className={styles.featureTitle}>Styles</div>
                        <div className={styles.featureSubtitle}>
                          {data.feature_style}
                        </div>
                      </div>
                    )}
                    {data.feature_skill_level && (
                      <div className={styles.featureDetailsContainer}>
                        <div className={styles.featureTitle}>Skill Level</div>
                        <div className={styles.featureSubtitle}>
                          {data.feature_skill_level}
                        </div>
                      </div>
                    )}
                    {data.feature_food && (
                      <div className={styles.featureDetailsContainer}>
                        <div className={styles.featureTitle}>Food</div>
                        <div className={styles.featureSubtitle}>
                          {data.feature_food}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.programContainer}>
                <div className={styles.descriptionTitle}>Program</div>
                <div
                  className={styles.programSubtitle}
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(data.program),
                  }}
                />
                <div className={styles.viewMore}>
                  <div
                    className={styles.viewIconContainer}
                    onClick={showProgramModal}
                  >
                    View Detailed Program
                    <div className={styles.viewIcon}>
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.benefitContainer}>
                <div className={styles.descriptionTitle}>Benefits</div>
                <div
                  className={styles.benefitItems}
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(data.benefits),
                  }}
                />
              </div>
              {data?.instructors?.length > 0 && (
                <div className={styles.instructorContainer}>
                  <div className={styles.descriptionTitle}>
                    Meet The Instructor
                  </div>
                  <div className={styles.instructorCardContainer}>
                    {data?.instructors?.map((instructor, index) => (
                      <div className={styles.instructorCard} key={index}>
                        <div className={styles.instructorImageContainer}>
                          <img
                            src={
                              process.env.REACT_APP_SERVER_URL +
                              instructor.photo
                            }
                            className={styles.instructorImage}
                            alt="instructor"
                            loading="lazy"
                          />
                        </div>
                        <div className={styles.overlay}></div>
                        <div className={styles.instructorContent}>
                          <div className={styles.instructorTitle}>
                            {instructor.name}
                          </div>
                          <div className={styles.instructorSubtitle}>
                            {instructor.designation}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.widgetContainer}>
            <div className={styles.widgetSubtitle}>{data.num_of_days} days</div>
            <div className={styles.widgetFlex}>
              <div className={styles.costLabel}>From</div>
              <div className={styles.cost}>${data.price}</div>
            </div>
            {/* <div className={styles.widgetFlex}>
              <div className={styles.depositLabel}>Deposit</div>
              <div className={styles.deposit}>$120</div>
            </div> */}
            <form className={styles.formContainer} id="programDetailsField">
              <div className={styles.programDetailsField}>
                <Input
                  placeholder="Full Name"
                  className={styles.name}
                  onChange={(e) => {
                    setBookData({ ...bookData, name: e.target.value });
                  }}
                />
              </div>
              <div className={styles.formGroup}>
                {/* <div className={styles.programDetailsField}>
                  <DatePicker
                    disabledDate={(current) => {
                      return current && current.valueOf() < Date.now();
                    }}
                    className={styles.date}
                    onChange={(date, dateString) => {
                      setBookData({ ...data, date: dateString });
                    }}
                  />
                </div> */}
                <div className={styles.programDetailsField}>
                  <Input
                    placeholder="No. of Person"
                    prefix={<UserOutlined />}
                    className={styles.person}
                    type="number"
                    onChange={(e) => {
                      setBookData({
                        ...bookData,
                        num_of_seats: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {userData.role === "provider" || userData.role === "operator" ? (
                <div className={cx("light-btn", styles.disabledButton)}>
                  Cannot book
                </div>
              ) : (
                <div
                  className={"light-btn"}
                  onClick={() =>
                    userData.role === "user"
                      ? navigate(`/bookings/${data.id}`, {
                          state: {
                            data: {
                              ...data,
                              accomodation_id: null,
                              name: bookData.name,
                              num_of_seats: bookData.num_of_seats,
                              date: bookData.date,
                            },
                          },
                        })
                      : setOpenModal(true)
                  }
                >
                  <div className={styles.button}>Book Instantly</div>
                </div>
              )}
            </form>
            <div className={styles.widgetFooter}>
              <div className={styles.widgetFooterTitle}>
                <div className={styles.widgetFooterIcon}>
                  <ion-icon name="checkbox-outline"></ion-icon>
                </div>{" "}
                <div>Free Cancellation Available</div>
              </div>
              <div className={styles.widgetFooterTitle}>
                <div className={styles.widgetFooterIcon}>
                  <ion-icon name="checkbox-outline"></ion-icon>
                </div>
                <div>No booking or credit card fees</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="program-modal">
        {programModal && (
          <Modal
            title={
              <div style={{ fontSize: "21px", color: "#353535" }}>Program</div>
            }
            open={showProgramModal}
            onCancel={handleProgramCancel}
            footer={null}
            width={892}
          >
            <div>
              {data.program_morning && (
                <div className={styles.programCard}>
                  <div className={styles.programTitle}>Morning</div>
                  <div className={styles.programDescription}>
                    <div
                      className={styles.eventsItem}
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(data.program_morning),
                      }}
                    />
                  </div>
                </div>
              )}
              {data.program_afternoon && (
                <div className={styles.programCard}>
                  <div className={styles.programTitle}>Afternoon</div>
                  <div className={styles.programDescription}>
                    <div
                      className={styles.eventsItem}
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(data.program_afternoon),
                      }}
                    />
                  </div>
                </div>
              )}
              {data.program_evening && (
                <div className={styles.programCard}>
                  <div className={styles.programTitle}>Evening</div>
                  <div className={styles.programDescription}>
                    <div
                      className={styles.eventsItem}
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(data.program_evening),
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
      <SignInModal
        openModal={openModal}
        closeModal={closeModal}
        showModal={showModal}
        bookingData={data}
        bookData={bookData}
      />
    </div>
  );
}

export default ProgramDescription;
