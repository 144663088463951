import {
  BankOutlined,
  LinkOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Collapse, Form, Input, Modal } from "antd";
import { InputOTP } from "antd-input-otp";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../App";
import { providerRegister } from "../../services/api/auth";
import { resendOtp, verifyOtp } from "../../services/api/otp";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import styles from "./SignUpAsPartner.module.css";
const { Panel } = Collapse;
const partnerData = [
  {
    title: "Global Reach",
    subtitle: "Connect with conscious travelers worldwide.",
    icon: "/images/partnerSignup/language.png",
  },
  {
    title: "Increased Visibility",
    subtitle: "Showcase your unique programs to a diverse community.",
    icon: "/images/partnerSignup/visibility.png",
  },
  {
    title: "Enhanced Credibility",
    subtitle: "Benefit from YogSewa's trusted reputation.",
    icon: "/images/partnerSignup/security.png",
  },
  {
    title: "Streamlined Bookings",
    subtitle: "Simplify reservation management with our partner dashboard.",
    icon: "/images/partnerSignup/event.png",
  },
  {
    title: "Customer Insights",
    subtitle: "Gain valuable traveler insights for tailored experiences.",
    icon: "/images/partnerSignup/person.png",
  },
  {
    title: "Secure Payments",
    subtitle: "Enjoy hassle-free transactions and direct transfers.",
    icon: "/images/partnerSignup/payment.png",
  },
  {
    title: "Marketing Tools",
    subtitle: "Expand reach through our online campaigns.",
    icon: "/images/partnerSignup/megaphone.png",
  },
  {
    title: "Dedicated Support",
    subtitle: "Receive personalized assistance from our team.",
    icon: "/images/partnerSignup/support.png",
  },
];

const stepsData = {
  first: [
    {
      title: "Sign Up",
      subtitle:
        "Create your partner account by providing your business information and contact details.",
      img: "/images/partnerSignup/signup-icon.png",
      number: "1",
    },
    {
      title: "Create Your Profile",
      subtitle:
        "Build your business profile by adding relevant information and captivating images.",
      img: "/images/partnerSignup/profile-icon.png",
      number: "2",
    },
    {
      title: "Add Program Listings",
      subtitle:
        "Showcase your yoga retreats, teacher training, or trekking packages.",
      img: "/images/partnerSignup/list-icon.png",
      number: "3",
    },
    {
      title: "Customize Your Listings",
      subtitle:
        "Add enticing descriptions, attractive images, and highlight unique features.",
      img: "/images/partnerSignup/customize-icon.png",
      number: "4",
    },
  ],
  second: [
    {
      title: "Set Availability and Pricing",
      subtitle:
        "Specify availability dates, pricing details, and any special offers.",
      img: "/images/partnerSignup/money-icon.png",
      number: "5",
    },
    {
      title: "Publish and Go Live",
      subtitle:
        "Once your listings are ready, publish them and start attracting bookings.",
      img: "/images/partnerSignup/publish-icon.png",
      number: "6",
    },
  ],
};

const collapseData = [
  {
    title: "How can I create a partner account on YogSewa?",
    subtitle:
      "Creating a partner account is quick and easy. Simply click on the 'Join Us' button and follow the step-by-step registration process. Once completed, you'll gain access to your partner dashboard and the full suite of features.",
  },
  {
    title: "What are the costs involved in partnering with YogSewa?",
    subtitle:
      "Creating a business profile and listing any number of programs is absolutely free at YogSewa. We believe in supporting your growth from the start. We only charge a 10% commission + a payment processing fee starting from your second booking through YogSewa. This means that we bring you your first customer at no cost to you.",
  },
  {
    title: "How can I showcase my programs and offerings on YogSewa?",
    subtitle:
      "Your partner dashboard provides a user-friendly interface to showcase your programs and offerings. Add captivating descriptions, high-quality visuals, detailed itineraries, and pricing information to attract potential customers.",
  },
  {
    title: "Can I manage bookings and availability through YogSewa?",
    subtitle:
      "Absolutely! Our partner dashboard enables you to seamlessly manage bookings and availability. You can update your calendar, set custom availability, and receive notifications for new reservations.",
  },
  {
    title: "How does YogSewa handle payments?",
    subtitle:
      "YogSewa provides a secure payment system that facilitates hassle-free transactions. Guests can make payments directly through our platform, and funds are securely transferred to your designated bank account.",
  },
  {
    title: "What support does YogSewa offer to its partners?",
    subtitle:
      "We are committed to your success. Our dedicated support team is available to assist you with any inquiries, technical support, or account related questions you may have. Reach out to us via email or through our support portal.",
  },
];

function SignUpAsPartner() {
  const [data, setData] = useState({
    business_name: "",
    email: "",
    website: "",
    password: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [countdown, setCountdown] = useState(120);
  const [otpValue, setOtpValue] = useState([]);
  const [otpError, setOtpError] = useState("");

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setOtpError("");
    setCountdown(120);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setOtpError("");
    setCountdown(120);
  };

  const handleResendOTP = () => {
    if (!resendEnabled) return;
    setOtpLoading(true);
    resendOtp({ phone: data.phone })
      .then((res) => {
        setOtpLoading(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setOtpLoading(false);
      });
    setResendEnabled(false);
    setCountdown(120);
  };

  const handleOtpSubmit = () => {
    if (otpValue.length !== 4) {
      setOtpError("Please enter otp code");
      return;
    }
    setOtpLoading(true);
    verifyOtp({ phone: data.phone, otp: parseInt(otpValue.join(""), 10) })
      .then((res) => {
        setOtpLoading(false);
        navigate("/login-as-partner/");
        notifySuccess(res.message);
        setIsModalOpen(false);
      })
      .catch((err) => {
        // setOtpError(err);
        {
          err.response?.data?.message &&
            setOtpError(err.response?.data?.message);
        }
        console.log(err);
        // axiosErrorHandler(err, notifyError);
        setOtpLoading(false);
      });
  };

  const handleSubmit = () => {
    var formData = {
      business_name: data.business_name,
      email: data.email,
      website: data.website,
      password: data.password,
      phone: data.phone,
    };
    setLoading(true);
    providerRegister(formData)
      .then((res) => {
        setLoading(false);
        showModal();
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  useEffect(() => {
    let interval;

    if (isModalOpen) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown <= 0) {
      setResendEnabled(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isModalOpen, countdown]);

  return (
    <div className={styles.container}>
      <div className={styles.heroContainer}>
        <div className="container">
          <div className={styles.hero}>
            <div className={styles.heroDetails}>
              <div className={styles.mainTitle}>
                Welcome to YogSewa: Your Path to Partner Success!
              </div>
              <div className={styles.subtitle}>
                Join Our Journey as a Partner
                <div className={styles.details}>
                  Unlock Boundless Opportunities in Spiritual Tourism
                </div>
              </div>
              <div className={styles.welcomeImageContainer}>
                <img
                  src="/images/partnerSignup/hiking.png"
                  alt="hiking"
                  loading="lazy"
                  className={styles.welcomeImage}
                />
                <img
                  src="/images/partnerSignup/welcome.png"
                  alt="welcome"
                  loading="lazy"
                  className={styles.welcomeImage}
                />
              </div>
            </div>
            <div className={styles.form}>
              <div className={styles.formSubtitle}>Become a Partner</div>
              <div className={styles.formTitle}>
                Start promoting your programs today
              </div>
              <Form
                name="basic"
                layout="vertical"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                onSubmit={(e) => e.preventDefault()}
                onFinish={handleSubmit}
                onFinishFailed={(e) => e.preventDefault()}
              >
                <Form.Item
                  className={styles.formItem}
                  name="business"
                  rules={[
                    {
                      required: true,
                      message: "Please input your business name",
                    },
                  ]}
                  initialValue={data.business_name}
                >
                  <Input
                    type="text"
                    placeholder="Business Name"
                    prefix={<BankOutlined className={styles.formIcon} />}
                    value={data.business_name}
                    className={styles.input}
                    onChange={(e) =>
                      setData({ ...data, business_name: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  className={styles.formItem}
                  name="url"
                  rules={[
                    {
                      required: true,
                      message: "Please input your website",
                    },
                  ]}
                  initialValue={data.website}
                >
                  <Input
                    type="text"
                    placeholder="URL/Website"
                    prefix={<LinkOutlined className={styles.formIcon} />}
                    value={data.website}
                    className={styles.input}
                    onChange={(e) =>
                      setData({ ...data, website: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  className={styles.formItem}
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email" },
                    {
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please enter a valid email address.",
                    },
                  ]}
                  initialValue={data.email}
                >
                  <Input
                    type="text"
                    placeholder="Email"
                    autoComplete={false}
                    prefix={<MailOutlined className={styles.formIcon} />}
                    value={data.email}
                    className={styles.input}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="Phone"
                  className={styles.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number",
                    },
                    {
                      pattern: /^9\d{9}$/,
                      message: "Enter a valid 10 digits phone number",
                    },
                  ]}
                  initialValue={data.phone}
                >
                  <Input
                    type="text"
                    placeholder="Phone Number"
                    autoComplete={false}
                    prefix={<PhoneOutlined className={styles.formIcon} />}
                    className={styles.input}
                    value={data.phone}
                    onChange={(e) => {
                      setData({ ...data, phone: e.target.value });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="Password"
                  autoComplete={false}
                  className={styles.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                    {
                      pattern:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                      message:
                        "Password should be at least 8 characters long and contain at least one upper case , one lower case, one special character and one digit.",
                    },
                  ]}
                  initialValue={data.password}
                >
                  <Input.Password
                    type="password"
                    placeholder="Password"
                    autoComplete={false}
                    prefix={<LockOutlined className={styles.formIcon} />}
                    className={styles.input}
                    value={data.password}
                    onChange={(e) => {
                      setData({ ...data, password: e.target.value });
                    }}
                  />
                </Form.Item>
                <div className={styles.term}>
                  I've read and understood the{" "}
                  <span>
                    <Link to="" className={styles.termLink}>
                      Terms of service
                    </Link>
                  </span>
                </div>
                <div className={styles.flexContainer}>
                  <Button
                    className={styles.registerBtn}
                    type="outline"
                    htmlType="submit"
                    disabled={loading}
                    loading={loading}
                  >
                    Register
                  </Button>
                  <div className={styles.account}>
                    <Link to="/login-as-partner/">
                      I already have an account
                    </Link>
                  </div>
                </div>
              </Form>
              <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                className={styles.otpModal}
              >
                <div className={styles.otpContainer}>
                  <img
                    src="/images/partnerSignup/OTP.png"
                    className={styles.otp}
                    alt="otp"
                    loading="lazy"
                  />
                  <div className={styles.otpTitle}>Verify OTP</div>
                  <div className={styles.otpSubtitle}>
                    A 4 digit code has been sent to mobile number
                  </div>
                  <InputOTP
                    inputType="numeric"
                    inputClassName={styles.otpInput}
                    length={4}
                    onChange={setOtpValue}
                    value={otpValue}
                  />
                  <div className={styles.otpError}>{otpError && otpError}</div>
                  {countdown <= 0 ? (
                    <div className={styles.otpResend} onClick={handleResendOTP}>
                      Resend OTP
                    </div>
                  ) : (
                    <div className={styles.otpdetails}>
                      Resends OTP in{" "}
                      <span>{`${Math.floor(countdown / 60)
                        .toString()
                        .padStart(2, "0")}:${(countdown % 60)
                        .toString()
                        .padStart(2, "0")}`}</span>
                    </div>
                  )}
                  {/* <div className={styles.otpResend}>Resend OTP?</div> */}
                  <Button type="primary" onClick={handleOtpSubmit}>
                    Submit
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className="container">
          <div className={styles.detailsWrapper}>
            <div className={styles.details}>
              <span>At YogSewa</span>, we believe in the transformative power of
              spiritual journeys. We invite you to join us as a valued partner
              on this extraordinary adventure. As Nepal's premier platform for
              conscious travel experiences, YogSewa opens doors to a world of
              possibilities for yoga centers, hotels and resorts, and trekking
              agencies. Let's embark on a collaborative journey where we elevate
              your business and connect you with like-minded travelers seeking
              profound connections and enlightening discoveries.
            </div>
            <div className={styles.detailsImageContainer}>
              <img
                className={styles.detailsImage}
                alt="camping"
                loading="lazy"
                src="/images/partnerSignup/subtitle.png"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={styles.partnerContainer}>
          <div className={styles.partnerDetailsContainer}>
            <div className={cx(styles.sectionTitle, styles.parnterTitle)}>
              Why Partner with YogSewa?
            </div>
            <ul className={styles.partnerCardContainer}>
              {partnerData.map((partner) => (
                <li key={partner.title} className={styles.partnerCard}>
                  <div className={styles.partnerIcon}>
                    <img
                      src={partner.icon}
                      alt={partner.title}
                      className={styles.icon}
                      loading="lazy"
                    />
                  </div>

                  <div className={styles.partnerContent}>
                    <div
                      className={cx(
                        styles.sectionSubtitle,
                        styles.partnerSubtitle
                      )}
                    >
                      {partner.title}
                    </div>
                    <div className={styles.sectionDetails}>
                      {partner.subtitle}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.aboutContainer}>
          <div className={styles.aboutDetailsContainer}>
            <div className={cx(styles.sectionTitle, styles.whoweareTitle)}>
              Who We Are For?
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.sectionContent}>
                <figure className={styles.whoImageContainer}>
                  <img
                    src="/images/partnerSignup/yoga.png"
                    className={styles.whoweareImage}
                    alt="yoga"
                  />
                </figure>
                <div className={styles.whoweareContents}>
                  <div
                    className={cx(
                      styles.sectionSubtitle,
                      styles.whoweareSubtitle
                    )}
                  >
                    Yoga Centers
                  </div>
                  <div className={styles.sectionDetails}>
                    <span>Expand Your Reach:</span> Connect with a global
                    audience of seekers. <br />
                    <br />
                    <span>Showcase Your Programs:</span> Promote yoga retreats
                    and teacher training.
                    <br />
                    <br /> <span>Amplify Your Presence:</span> Benefit from our
                    extensive marketing efforts.
                  </div>
                </div>
              </div>
              <div className={styles.sectionContent}>
                <figure className={styles.whoImageContainer}>
                  <img
                    src="/images/partnerSignup/hotel.png"
                    className={styles.whoweareImage}
                    alt="hotel"
                  />
                </figure>
                <div className={styles.whoweareContents}>
                  <div
                    className={cx(
                      styles.sectionSubtitle,
                      styles.whoweareSubtitle
                    )}
                  >
                    Hotels and Resorts
                  </div>
                  <div className={styles.sectionDetails}>
                    <span>Attract Conscious Travelers: </span>
                    Offer a tranquil stay for spiritual seekers.
                    <br />
                    <br />
                    <span>Increase Bookings:</span> Position your property as a
                    preferred choice. <br />
                    <br />
                    <span>Targeted Exposure:</span> Reach a specific audience
                    seeking holistic experiences.
                  </div>
                </div>
              </div>
              <div className={styles.sectionContent}>
                <figure className={styles.whoImageContainer}>
                  <img
                    src="/images/partnerSignup/travel.png"
                    className={styles.whoweareImage}
                    alt="travel"
                  />
                </figure>
                <div className={styles.whoweareContents}>
                  <div
                    className={cx(
                      styles.sectionSubtitle,
                      styles.whoweareSubtitle
                    )}
                  >
                    Travel and Trekking Agencies
                  </div>
                  <div className={styles.sectionDetails}>
                    <span>Unique Offerings:</span> Combine yoga and spirituality
                    with adventure travel. <br />
                    <br />
                    <span>Stand Out from Competitors:</span> Cater to the
                    growing demand for spiritual adventures.
                    <br />
                    <br /> <span>Enhanced Experiences:</span> Provide
                    experienced yoga teachers during treks.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.stepWrapper}>
        <div className="container">
          <div className={styles.stepsContainer}>
            <div className={cx(styles.sectionTitle, styles.stepWrapperTitle)}>
              Join YogSewa in Just a Few Simple Steps!
            </div>
            <ul className={styles.stepCardContainer}>
              {stepsData.first.map((step) => (
                <div className={styles.stepCardNumberWrapper}>
                  <div className={styles.stepCardWrapper} key={step.title}>
                    <li className={styles.stepCard}>
                      <div className={styles.stepImageContainer}>
                        <img
                          src={step.img}
                          alt={step.title}
                          className={styles.stepImage}
                          loading="lazy"
                        />
                      </div>
                      <div className={styles.stepSubtitle}>{step.subtitle}</div>
                      <div className={styles.stepContent}>
                        <div className={styles.stepTitle}>{step.title}</div>
                      </div>
                    </li>
                  </div>
                  <div className={styles.stepNumber}>{step.number}</div>
                </div>
              ))}
            </ul>
            <ul
              className={cx(
                styles.stepCardContainer,
                styles.secondStepCardContainer
              )}
            >
              {stepsData.second.map((step) => (
                <div className={styles.stepCardNumberWrapper}>
                  <div className={styles.stepCardWrapper} key={step.title}>
                    <li className={styles.stepCard}>
                      <div className={styles.stepImageContainer}>
                        <img
                          src={step.img}
                          alt={step.title}
                          className={styles.stepImage}
                          loading="lazy"
                        />
                      </div>
                      <div className={styles.stepSubtitle}>{step.subtitle}</div>
                      <div className={styles.stepContent}>
                        <div className={styles.stepTitle}>{step.title}</div>
                      </div>
                    </li>
                  </div>
                  <div className={styles.stepNumber}>{step.number}</div>
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className="container">
          <div className={styles.footerContainer}>
            <div className={styles.footerTitle}>
              {" "}
              Ready to Begin Your Partnership Journey?
            </div>
            <button
              className={styles.button}
              onClick={() => window.scrollTo(0, 0)}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
      <div className={styles.faqWrapper}>
        <div className="container">
          <div className={styles.faqContainer}>
            <div className={styles.faqSubtitle}>
              Ready to Begin Your Partnership Journey?
            </div>
            <div className={cx(styles.sectionTitle, styles.faqTitle)}>
              FAQs (Frequently Asked Questions)
            </div>
            <Collapse defaultActiveKey={["1"]}>
              {collapseData.map((item) => (
                <Panel
                  header={
                    <div
                      className={cx(styles.sectionSubtitle, styles.faqDetails)}
                    >
                      {item.title}
                    </div>
                  }
                  key={item.title}
                >
                  <div className={styles.sectionDetails}>{item.subtitle}</div>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpAsPartner;
