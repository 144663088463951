import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ResourceNotFound.module.css";


function ResourceNotFound() {
    const navigate = useNavigate();
    return (
      <div className={styles.container}>
        <Result
            status="500"
            title="410"
            subTitle="Resource Not Found"
            extra={<Button type="primary" onClick={()=> navigate("/")}>Back Home</Button>}
  />
      </div>
    );
  }
  
  export default ResourceNotFound;
  