import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { notifyError } from "../../App";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import { sendLoginLink } from "../../services/api/auth";
import styles from "./LoginWithoutPassword.module.css";
import Loading from "../../AdminPanel/components/Loading";

function LoginWithoutPassword() {
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    if (data.length === 0) {
      return;
    }
    setLoading(true);
    sendLoginLink(data)
      .then((res) => {
        setLoading(false);
        setSuccess(
          "We have just sent you an email with the details. Please check your inbox."
        );
        setError("");
      })
      .catch((err) => {
        setError(
          "Sorry, we cannot find an account that is using this email address"
        );
        setSuccess("");
        // axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className="container">
          <h1 className={styles.title}>Quick and easy Login</h1>
          <div className={styles.subtitle}>
            Please, enter your registered email address. You'll receive an email
            with a temporary link that will allow you to login directly. The
            link expires after 1 day.
          </div>
          <div className={styles.formContainer}>
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              onSubmitCapture={handleSubmit}
            >
              <Form.Item
                className={styles.formItem}
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please input your email" }]}
              >
                <Input
                  type="text"
                  value={data.email}
                  className={styles.input}
                  onChange={(e) => setData(e.target.value)}
                />
              </Form.Item>

              <Form.Item className={styles.formItem}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className={styles.button}
                >
                  Request Login Link
                </Button>
              </Form.Item>
              {error && <div className={styles.error}>{error}</div>}
              {success && <div className={styles.success}>{success}</div>}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginWithoutPassword;
