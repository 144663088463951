import { Dropdown, Menu, Popover, Spin } from "antd";
import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { logout } from "../../services/api/auth";
import { getAllCategories } from "../../services/api/programs";
import { clearCookie } from "../../utils/cookie/cookies";
import { converter } from "../../utils/htmlConverter/htmlConverter";
import styles from "./Header.module.css";

const navData = [
  {
    title: "Explore Yogsewa",
    path: "/explore/",
  },
  { title: "Blogs", path: "/blogs/" },
  { title: "About", path: "/about/" },
];

const providerSidebar = [
  {
    title: "Dashboard",
    path: "/partner",
  },
  {
    title: "Payment Information",
    path: "/partner/payment/",
  },
  {
    title: "My Profile",
    path: "/partner/profile/",
  },
  {
    title: "Business Information",
    path: "/partner/business/",
  },
  {
    title: "Operators",
    path: "/partner/operators/",
    role: "provider",
  },
  {
    title: "Yoga Programs",
    path: "/partner/yoga-programs/verified/",
  },
  {
    title: "Accounting",
    path: "/partner/accounting/receive/",
  },
  {
    title: "Bookings",
    path: "/partner/bookings/completed/",
  },
];

const userSidebar = [
  { title: "My profile", path: "/user/profile/" },
  { title: "My wishlist", path: "/user/wishlist/" },
  { title: "My bookings", path: "/user/bookings/" },
  { title: "My reviews", path: "/user/reviews/" },
];

const rightNavItems = [
  { title: "Login", path: "/login/" },
  { title: "Sign Up", path: "/signup/user/" },
];

function Header() {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();

  const [openNav, setOpenNav] = useState(false);
  const [navItems, setNavItems] = useState(navData);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [openCurrencyModal, setOpenCurrencyModal] = useState(false);
  const { userData, setUserData } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const handleItemClick = () => {
    setVisible(false);
  };

  const openNavbar = () => {
    setOpenNav(true);
  };

  const closeNavbar = () => {
    setOpenNav(false);
  };

  // const showCurrencyModal = () => {
  //   setOpenCurrencyModal(true);
  // };

  // const handleOk = () => {
  //   setOpenCurrencyModal(false);
  // };

  // const handleCancel = () => {
  //   setOpenCurrencyModal(false);
  // };

  const handleExploreClick = (item) => {
    navigate(`/search?category=${item.slug}`);
  };

  const handleProfileNavigation = (key, path) => {
    if (key === "out") {
      handleLogout();
    } else {
      navigate(key);
    }
  };

  const handleLogout = () => {
    logout()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        clearCookie("login-token");
        setUserData("");
        navigate("/");
      });
  };

  const fetchCategory = async (signal) => {
    setLoading(true);
    await getAllCategories(signal)
      .then((res) => {
        if (res.status === "Success") {
          const temp = [...navData];
          temp[0].dropdown = [...res.data];
          setNavItems(temp);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          // axiosErrorHandler(err, notifyError);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchCategory(abortCont.signal);

    return () => {
      abortCont.abort();
    };
  }, []);

  useEffect(() => {
    if (openNav && width < 992) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }
  }, [openNav]);

  return (
    <div className={styles.container}>
      <div className={cx("container")}>
        <div className={styles.header}>
          <div
            className={cx(styles.overlay, openNav && styles.overlayActive)}
            onClick={closeNavbar}
          ></div>
          <Link to="/" className={styles.logo}>
            {
              <img
                src={"/images/logo1.png"}
                className={styles.logoImg}
                alt="logo"
                loading="lazy"
                width={140}
              />
            }
          </Link>
          <div className={styles.rightContainer}>
            <nav className={cx(styles.navbar, openNav && styles.navActive)}>
              <div className={styles.navbarTop}>
                <Link to="/" className={styles.logo} onClick={closeNavbar}>
                  <img
                    src="/images/logo1.png"
                    className={styles.logoImg}
                    alt="logo"
                    loading="lazy"
                    width={140}
                  />
                </Link>
                <button
                  className={cx(
                    styles.navCloseBtn,
                    openNav ? styles.rotation : ""
                  )}
                  onClick={closeNavbar}
                >
                  <ion-icon name="close"></ion-icon>
                </button>
              </div>
              <ul className={styles.navbarList}>
                {width >= 992 ? (
                  navItems.map((navItem, index) =>
                    navItem.title === "Explore Yogsewa" ? (
                      <Popover
                        content={
                          loading ? (
                            <div className={styles.loader}>
                              <Spin />
                            </div>
                          ) : (
                            <div className={styles.gridDropdown}>
                              {navItem?.dropdown?.map((item, index) => (
                                <div
                                  className={styles.dropdownContent}
                                  onClick={() => {
                                    closeNavbar();
                                    if (navItem.title === "Explore Yogsewa") {
                                      handleExploreClick(item);
                                      handleItemClick();
                                    }
                                  }}
                                >
                                  <div className={styles.dropdownIcon}>
                                    <img
                                      src={
                                        process.env.REACT_APP_SERVER_URL +
                                        item?.icon
                                      }
                                      alt="yoga"
                                      className={styles.dropdownImage}
                                      loading="lazy"
                                    />
                                  </div>
                                  <div className={styles.dropdownDetails}>
                                    <div
                                      key={index}
                                      className={styles.dropdownItem}
                                    >
                                      {item?.title}
                                    </div>
                                    <div
                                      key={index}
                                      className={styles.dropdownDescription}
                                      dangerouslySetInnerHTML={{
                                        __html: converter.makeHtml(
                                          item?.overview
                                        ),
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        }
                        key={index}
                        open={visible}
                        onOpenChange={handleVisibleChange}
                      >
                        <div className={styles.navbarItem}>
                          <div
                            className={cx(
                              styles.navbarLink,
                              navItem.path === location.pathname &&
                                styles.navbarLinkActive
                            )}
                          >
                            {navItem.title}
                          </div>
                        </div>
                      </Popover>
                    ) : (
                      // <Dropdown
                      //   key={index}
                      //   placement="bottom"
                      //   arrow
                      //   overlay={
                      //     <Menu>
                      //       {navItem?.dropdown?.map((item, index) => (
                      //         <Menu.Item
                      //           key={index}
                      //           onClick={() => {
                      //             closeNavbar();
                      //             if (navItem.title === "Explore Yogsewa") handleExploreClick(item);
                      //           }}
                      //         >
                      //           {item?.title}
                      //         </Menu.Item>
                      //       ))}
                      //     </Menu>
                      //   }
                      // >
                      //   <div className={styles.navbarItem}>
                      //     <div
                      //       className={cx(
                      //         styles.navbarLink,
                      //         navItem.path === location.pathname && styles.navbarLinkActive
                      //       )}
                      //     >
                      //       {navItem.title}
                      //     </div>
                      //   </div>
                      // </Dropdown>
                      <div className={styles.navbarItem}>
                        <li>
                          {navItem.title !== "Explore Yogsewa" ? (
                            <Link
                              className={cx(
                                styles.navbarLink,
                                navItem.path === location.pathname &&
                                  styles.navbarLinkActive
                              )}
                              to={navItem.path}
                              onClick={() => {
                                setDropdownOpen(false);
                                closeNavbar();
                              }}
                            >
                              {navItem.title}
                            </Link>
                          ) : (
                            <>
                              <div
                                className={cx(
                                  styles.navbarLink,

                                  location.pathname.includes(navItem.path)
                                    ? styles.navItemActive
                                    : null
                                )}
                                onClick={() => {
                                  width < 992 &&
                                    setDropdownOpen((prev) => !prev);
                                }}
                              >
                                {navItem.title}
                              </div>
                            </>
                          )}
                        </li>
                      </div>
                    )
                  )
                ) : (
                  <>
                    {navItems.map((navItem, index) => (
                      <div
                        className={styles.navbarItem}
                        key={index}
                        onClick={() => {
                          navItem.title !== "Explore Yogsewa" && closeNavbar();
                        }}
                      >
                        <li>
                          {navItem.title !== "Explore Yogsewa" ? (
                            <Link
                              className={cx(
                                styles.navbarLink,
                                navItem.path === location.pathname &&
                                  styles.navbarLinkActive
                              )}
                              to={navItem.path}
                            >
                              {navItem.title}
                              {navItem.dropdown && (
                                <div className={styles.icon}>+</div>
                              )}
                            </Link>
                          ) : (
                            <>
                              <div
                                className={cx(styles.navbarLink)}
                                onClick={() => {
                                  setDropdownOpen((prev) => !prev);
                                }}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {navItem.title}
                                {navItem.dropdown && (
                                  <div className={styles.icon}>+</div>
                                )}
                              </div>
                            </>
                          )}
                        </li>
                        {dropdownOpen && navItem.dropdown && (
                          <div className={styles.dropdownContainer}>
                            {navItem?.dropdown?.map((item, index) => (
                              <div
                                className={styles.navbarDropdown}
                                key={index}
                                onClick={() => {
                                  closeNavbar();
                                  setDropdownOpen(false);
                                  if (navItem.title === "Explore Yogsewa")
                                    handleExploreClick(item);
                                }}
                              >
                                <div className={styles.dropdownIcon}>
                                  <img
                                    src={
                                      process.env.REACT_APP_SERVER_URL +
                                      item?.icon
                                    }
                                    alt="yoga"
                                    className={cx(
                                      styles.dropdownImage,
                                      styles.mobileIcon
                                    )}
                                    loading="lazy"
                                  />
                                </div>{" "}
                                {item?.title}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </ul>
              <ul className={cx(styles.navbarList, styles.navHidden)}>
                {userData.role === "provider" &&
                Object.keys(userData).length > 0
                  ? providerSidebar.map((item, index) => (
                      <li key={index} className={styles.navbarItem}>
                        <Link
                          className={cx(
                            styles.navbarLink,
                            item.path === location.pathname &&
                              styles.navbarLinkActive
                          )}
                          to={item.path}
                          onClick={closeNavbar}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))
                  : userData.role === "user" && Object.keys(userData).length > 0
                  ? userSidebar.map((item, index) => (
                      <li key={index} className={styles.navbarItem}>
                        <Link
                          className={cx(
                            styles.navbarLink,
                            item.path === location.pathname &&
                              styles.navbarLinkActive
                          )}
                          to={item.path}
                          onClick={closeNavbar}
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))
                  : null}

                {/* <li
                  className={cx(styles.navbarLink, styles.flexbox)}
                  onClick={showCurrencyModal}
                >
                  <ion-icon name="globe"></ion-icon>
                  <div>{currency}</div>
                </li> */}
              </ul>
              <div className={styles.loginBtnContainer}>
                {Object.keys(userData).length > 0 ? (
                  <>
                    <li
                      className={styles.logout}
                      onClick={() => {
                        handleLogout();
                        closeNavbar();
                      }}
                    >
                      Log out
                    </li>
                  </>
                ) : (
                  rightNavItems.map((navItem, index) => (
                    <li key={index} onClick={closeNavbar}>
                      <Link
                        className={cx(
                          navItem.path === location.pathname &&
                            styles.navbarLinkActive,
                          styles.loginBtn,
                          navItem.title === "Sign Up"
                            ? styles.logout
                            : styles.login
                        )}
                        to={navItem.path}
                      >
                        {navItem.title}
                      </Link>
                    </li>
                  ))
                )}
              </div>
            </nav>
            {/* <div
              className={cx(
                styles.navbarLink,
                styles.flexbox,
                styles.currencyDisplay
              )}
              onClick={showCurrencyModal}
            >
              <ion-icon name="globe"></ion-icon>
              <div>{currency}</div>
            </div> */}

            <div className={styles.profileContainer} id="nav-dropdown">
              {width < 992 ? (
                <button className={cx(styles.navOpenBtn)} onClick={openNavbar}>
                  {/* <ion-icon name="person"></ion-icon> */}
                  <img
                    src="/images/mandala.png"
                    alt="mandala"
                    loading="lazy"
                    className={styles.mandala}
                  />
                  <div
                    className={styles.username}
                    title={
                      userData.role === "provider"
                        ? userData.business_name
                        : userData.f_name
                    }
                  >
                    {userData.role === "provider"
                      ? userData.business_name
                      : userData.f_name}
                  </div>
                  {/* <ion-icon name="menu-outline"></ion-icon> */}
                </button>
              ) : (
                <Dropdown
                  placement="bottomRight"
                  className={styles.dropdown}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  style={{ width: "300px" }}
                  overlay={
                    <Menu
                      onClick={(e) => handleProfileNavigation(e.key)}
                      items={
                        userData.role === "provider" &&
                        Object.keys(userData).length > 0
                          ? [
                              {
                                label: "Dashboard",
                                key: "/partner/",
                              },
                              {
                                label: "Payment Information",
                                key: "/partner/payment/",
                              },
                              {
                                label: "My Profile",
                                key: "/partner/profile/",
                              },
                              {
                                label: "Business Information",
                                key: "/partner/business/",
                              },
                              {
                                label: "Operators",
                                key: "/partner/operators/",
                              },
                              {
                                label: "Yoga Programs",
                                key: "/partner/yoga-programs/verified/",
                              },
                              {
                                label: "Accounting",
                                key: "/partner/accounting/receive/",
                              },
                              {
                                label: "Bookings",
                                key: "/partner/bookings/completed/",
                              },
                              {
                                label: "Log Out",
                                key: "out",
                              },
                            ]
                          : userData.role === "user" &&
                            Object.keys(userData).length > 0
                          ? [
                              { label: "My profile", key: "/user/profile/" },
                              { label: "My wishlist", key: "/user/wishlist/" },
                              { label: "My bookings", key: "/user/bookings/" },
                              { label: "My reviews", key: "/user/reviews/" },
                              {
                                key: "out",
                                label: "Log Out",
                              },
                            ]
                          : [
                              { key: "/login/", label: "Login" },
                              { key: "/signup/user/", label: "Sign Up" },
                            ]
                      }
                    />
                  }
                  trigger="click"
                  arrow
                >
                  <button
                    className={cx(styles.navOpenBtn)}
                    onClick={openNavbar}
                  >
                    {/* <ion-icon name="person"></ion-icon> */}
                    <img
                      src="/images/mandala.png"
                      alt="mandala"
                      loading="lazy"
                      className={styles.mandala}
                    />
                    <div
                      className={styles.username}
                      title={
                        userData.role === "provider"
                          ? userData.business_name
                          : userData.f_name
                      }
                    >
                      {userData.role === "provider"
                        ? userData.business_name
                        : userData.f_name}
                    </div>
                    {/* <ion-icon name="menu-outline"></ion-icon> */}
                  </button>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
        {/* {openCurrencyModal && (
          <CurrencyModal
            setCurrency={setCurrency}
            showCurrencyModal={showCurrencyModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
            closeNavbar={closeNavbar}
          />
        )} */}
      </div>
    </div>
  );
}

export default Header;
