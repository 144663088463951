import { Button, Col, Form, Input, message, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../App";
import { getAllCountries } from "../../../../services/api/country";
import {
  addOperator,
  getOperatorById,
  updateOperator,
} from "../../../../services/api/users";
import { axiosErrorHandler } from "../../../../utils/errorHandler/axiosErrorHandler";
import ImageUpload from "../../../components/ImageUpload";
import styles from "./OperatorUpdate.module.css";

function OperatorUpdate({ mode }) {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [photo, setPhoto] = useState([]);
  const [country, setCountry] = useState([]);
  const [data, setData] = useState({
    f_name: "",
    l_name: "",
    phone: "",
    email: "",
    password: "",
    address: "",
    dob: "",
    country_id: "",
  });
  // const [category, setCategory] = useState([]);
  // const [groupCompany, setGroupCompany] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [imageSizeError, setImageSizeError] = useState("");
  const { id } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.fileList);
    }
    return e && e.fileList;
  };

  const handleSubmitFailed = () => {}; //used for photo upload
  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in 'YYYY-MM-DD' format

  const handleSubmit = () => {
    let err = {};
    if (photo.length === 0) {
      err["photo"] = ["Upload a photo"];
    }
    if (data.f_name.length === 0) {
      err["f_name"] = ["First name is required"];
    }
    if (data.l_name.length === 0) {
      err["l_name"] = ["Last name is required"];
    }
    if (data.phone.length === 0) {
      err["phone"] = ["Phone is required"];
    }
    if (data.email.length === 0) {
      err["email"] = ["Email is required"];
    }
    if (mode === "add" && data.password.length === 0) {
      err["password"] = ["Password is required"];
    }
    if (data.country_id.length === 0) {
      err["country_id"] = ["Country is required"];
    }
    if (data.dob.length === 0) {
      err["dob"] = ["Dob is required"];
    }
    setErrors(err);
    if (Object.keys(err).length > 0) {
      return;
    }

    var formData = {
      f_name: data.f_name,
      l_name: data.l_name,
      phone: data.phone,
      email: data.email,
      address: data.address,
      country_id: data.country_id,
      dob: data.dob,
      profile_picture: photo[0]?.originFileObj,
    };

    if (mode === "add") {
      formData["password"] = data.password;

      setLoading(true);
      setErrors([]);

      addOperator(formData)
        .then((res) => {
          notifySuccess("Operator added successfully");
          setLoading(false);
          navigate("/partner/operators");
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && err.response.data) {
            setErrors(err.response.data.data);
          }
          axiosErrorHandler(err, () => {});
        });
    } else if (mode === "edit") {
      setLoading(true);
      setErrors([]);

      updateOperator(id, formData)
        .then((res) => {
          notifySuccess("Operator updated successfully");
          setLoading(false);
          navigate("/partner/operators");
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && err.response.data) {
            setErrors(err.response.data.data);
          }
          axiosErrorHandler(err, () => {});
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const abortCont = new AbortController();
    getAllCountries(abortCont.signal).then((res) => {
      setCountry(res.data.countries);
    });

    return () => {
      abortCont.abort();
    };
  }, []);

  useEffect(() => {
    if (imageSizeError) {
      message.error(imageSizeError);
    }
  }, [imageSizeError]);

  useEffect(() => {
    if (mode === "edit") {
      const abortCont = new AbortController();
      getOperatorById(id, abortCont.signal)
        .then((res) => {
          setData({
            f_name: res.data.operator.f_name,
            l_name: res.data.operator.l_name,
            phone: res.data.operator.phone,
            email: res.data.operator.email,
            dob: res.data.operator.dob,
            address: res.data.operator.address,
            country_id: res.data.operator.country_id,
          });
          form.setFieldsValue({
            First: res.data.operator.f_name,
            Last: res.data.operator.l_name,
            //for showing intial value after fetching data (name is required from Form.Item)
            Phone: res.data.operator.phone, //for showing intial value after fetching data
            Email: res.data.operator.email,
            DOB: res.data.operator.dob,
            Address: res.data.operator.address,
            Country: res.data.operator.country_id,
          });
          if (res.data.operator.profile_picture) {
            const fileList = [
              {
                url:
                  process.env.REACT_APP_SERVER_URL +
                  res.data.operator.profile_picture,
              },
            ];
            setPhoto([...fileList]);
          }
        })
        .catch((err) => {
          axiosErrorHandler(err, notifyError);
        })
        .finally(() => {
          setLoading(false);
        });
      return () => {
        abortCont.abort();
      };
    }
  }, [mode, id]);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div>
          <h2 className={styles.title}>
            {mode === "add" ? "Add" : "Edit"} Operator
          </h2>
        </div>
      </div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onSubmit={(e) => e.preventDefault()}
        form={form}
      >
        <Row gutter={[12]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="First Name"
              name="First"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["f_name"] ? "error" : "success"}
              help={errors["f_name"] ? errors["f_name"][0] : ""}
            >
              <Input
                placeholder="Name"
                value={data.f_name}
                onChange={(e) => {
                  setData({ ...data, f_name: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Last Name"
              name="Last"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["l_name"] ? "error" : "success"}
              help={errors["l_name"] ? errors["l_name"][0] : ""}
            >
              <Input
                placeholder="Name"
                value={data.l_name}
                onChange={(e) => {
                  setData({ ...data, l_name: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              name="dragger"
              listType="picture"
              getValueFromEvent={normFile}
              validateStatus={errors["photo"] ? "error" : "success"}
              help={errors["photo"] ? errors["photo"][0] : ""}

              // photo length for setting photo error (for upload component)
            >
              <ImageUpload
                setError={(msg) => {
                  setImageSizeError(msg);
                }}
                enableResolutionValidation={false}
                fileList={photo}
                setFileList={setPhoto}
                maxUpload={1}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12]}>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Email"
              name="Email"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["email"] ? "error" : "success"}
              help={errors["email"] ? errors["email"][0] : ""}
              autoComplete="new-password"
            >
              <Input
                placeholder="Email"
                type="email"
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value }); //if state is dictionary, set data like this
                }}
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Phone"
              name="Phone"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["phone"] ? "error" : "success"}
              help={errors["phone"] ? errors["phone"][0] : ""}
            >
              <Input
                type="number"
                placeholder="Phone"
                value={data.phone}
                onChange={(e) => {
                  setData({ ...data, phone: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Date of Birth"
              name="DOB"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["dob"] ? "error" : "success"}
              help={errors["dob"] ? errors["dob"][0] : ""}
            >
              <Input
                placeholder="Name"
                type="date"
                value={data.dob}
                onChange={(e) => setData({ ...data, dob: e.target.value })}
                max={currentDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12]}>
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 20 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Country"
              name="Country"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              validateStatus={errors["country_id"] ? "error" : "success"}
              help={errors["country_id"] ? errors["country_id"][0] : ""}
              rules={[
                {
                  required: true,
                  message: "Please select country",
                },
              ]}
            >
              <Select
                placeholder="Select country"
                style={{ width: 200 }}
                value={data.country_id}
                onChange={(value) => setData({ ...data, country_id: value })}
                getPopupContainer={(trigger) => trigger.parentNode}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {country.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Address"
              name="Address"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 22 }}
              validateStatus={errors["address"] ? "error" : "success"}
              help={errors["address"] ? errors["address"][0] : ""}
            >
              <Input
                placeholder="Address"
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, address: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {mode === "add" && (
          <Row gutter={[12]}>
            <Col
              xxl={{ span: 12 }}
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Password"
                name="Password"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 22 }}
                validateStatus={errors["password"] ? "error" : "success"}
                help={errors["password"] ? errors["password"][0] : ""}
                autoComplete="new-password"
              >
                <Input
                  placeholder="Password"
                  type="password"
                  value={data.password}
                  onChange={(e) => {
                    setData({ ...data, password: e.target.value }); //if state is dictionary, set data like this
                  }}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button type="primary" onClick={handleSubmit} size="large">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default OperatorUpdate;
