import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { notifyError } from "../../../App";
import { notifySuccess } from "../../../UserPanel/User/UserLayout";
import {
  getProvider,
  updateProviderProfile,
} from "../../../services/api/admin";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import ImageUpload from "../../components/ImageUpload";
import styles from "./AdminProfile.module.css";

function AdminProfile() {
  const [form] = Form.useForm();

  const [photo, setPhoto] = useState([]);
  const [imageSizeError, setImageSizeError] = useState("");
  const [videoSizeError, setVideoSizeError] = useState("");
  const [source, setSource] = useState();
  const [video, setVideo] = useState();
  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState(false);

  const [data, setData] = useState({
    business_name: "",
    description: "",
  });
  const inputRef = React.useRef();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSize = file.size / 1024 / 1024; // File size in MB
      if (fileSize > 2) {
        message.error("Video size should be less than 2 MB.");
        setVideoSizeError("Video size should be less than 2 MB.");
        setSource(null);
        return;
      }
      setVideoSizeError("");
      setSource(file);
    }
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.length > 0 ? [e[0].originFileObj] : null);
    }
    return e && e.fileList;
  };

  const handleCancel = () => {
    setRefresh((prev) => !prev);
  };

  const handleSubmit = () => {
    var formData = {
      business_name: data.business_name,
      description: data.description,
      featured_photo: photo ? photo[0].originFileObj : null,
      featured_video: source,
    };
    setLoading(true);
    updateProviderProfile(formData)
      .then((res) => {
        if (res.status === "Success") {
          notifySuccess(res.message);
          setRefresh((prev) => !prev);
          setLoading(false);
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (imageSizeError) {
      message.error(imageSizeError);
    }
  }, [imageSizeError]);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     Business: "", //for showing intial value after fetching data (name is required from Form.Item)
  //     Description: "",
  //   });
  // }, []);

  useEffect(() => {
    getProvider()
      .then((res) => {
        if (res.status === "Success") {
          form.setFieldsValue({
            Business: res.data.user.service_provider.business_name,
            Description: res.data.user.service_provider.description,
          });
          if (res.data.user.service_provider.featured_photo) {
            const fileList = [
              {
                url:
                  process.env.REACT_APP_SERVER_URL +
                  res.data.user.service_provider.featured_photo,
              },
            ];
            setPhoto([...fileList]);
          }
          setVideo(
            new URL(
              res.data.user.service_provider.featured_video,
              process.env.REACT_APP_SERVER_URL
            )
          );
          setData({
            business_name: res.data.user.service_provider.business_name,
            description: res.data.user.service_provider.description,
          });
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
  }, [refresh]);
  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>My Profile Page</div>
          <div className={styles.subtitle}>
            Basic information to define your profile and public data.
          </div>
        </div>
      </div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        // onSubmit={(e) => e.preventDefault()}
        onFinish={handleSubmit}
        form={form}
      >
        <div className={styles.sectionContainer}>
          <div className={styles.content}>
            <div className={styles.sectionTitle}>Business Name</div>
          </div>
          <Form.Item name="Business">
            <Input
              className={styles.input}
              value={data.business_name}
              onChange={(e) => {
                setData({ ...data, business_name: e.target.value });
              }}
            />
          </Form.Item>

          <div className={styles.content}>
            <div className={styles.sectionTitle}>Description</div>
            <div className={styles.sectionSubtitle}>
              Please provide a short description of your organization like your
              company's mission and vision.
            </div>
          </div>
          <Form.Item name="Description">
            <TextArea
              className={styles.input}
              rows={6}
              value={data.description}
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
              }}
            />
          </Form.Item>

          <div className={styles.content}>
            <div className={styles.sectionTitle}>Featured Photo</div>
            <div className={styles.sectionSubtitle}>
              This high quality picture will be shown on your organizer page and
              listing pages.
            </div>
          </div>
          <Form.Item
            name="dragger"
            listType="picture"
            getValueFromEvent={normFile}
          >
            <ImageUpload
              fileList={photo}
              setFileList={setPhoto}
              maxUpload={1}
              enableResolutionValidation={false}
              setError={(msg) => {
                setImageSizeError(msg);
              }}
            />
          </Form.Item>
          <div className={styles.sectionTitle}>Featured video</div>
          <div>
            <input
              className="video-input"
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
              accept=".mov,.mp4"
            />
            {
              <Button onClick={handleChoose} className={styles.choose}>
                <UploadOutlined /> Choose
              </Button>
            }
            {(source || video) && (
              <video
                width="100%"
                height={300}
                controls
                src={source ? URL.createObjectURL(source) : video?.href}
              />
            )}
            <div className={styles.details}>
              {video ? "" : "Nothing selected"}
              {videoSizeError && (
                <div className={styles.error}>{videoSizeError}</div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            type="primary"
            className={styles.saveButton}
            onClick={handleSubmit}
            loading={loading}
          >
            Save
          </Button>
          <Button
            className={styles.cancelButton}
            type="danger"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AdminProfile;
