import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFeaturedCategories } from "../../services/api/featured";
import styles from "./Footer.module.css";

function Footer() {
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);

  const handleClick = (item) => {
    navigate(`/search?category=${item.id}`);
  };

  useEffect(() => {
    let abort = new AbortController();
    getFeaturedCategories(abort.signal)
      .then((res) => {
        setCategoryData(res.data.categories);
      })
      .catch((err) => {});

    return () => {
      abort.abort();
    };
  }, []);
  return (
    <footer className={styles.footer}>
      <div className="wave footer"></div>
      <div className={styles.container}>
        <div className="container">
          <div className={styles.footerTop}>
            <div className={styles.header}>
              <p className={styles.footerImage}>
                <img
                  src="/images/logo2.png"
                  className={styles.logo}
                  alt="logo"
                  loading="lazy"
                  width={300}
                />
              </p>
              {/* <div className={cx("section-subtitle", styles.subtitle)}>
                Conscious Travels, Enlightened Discoveries
              </div> */}
              <ul className={styles.paymentList}>
                <li>
                  <img
                    src="/images/footer/visa.png"
                    className={styles.paymentIcon}
                    alt="Visa"
                    loading="lazy"
                  />
                </li>
                <li>
                  <img
                    src="/images/footer/mastercard.png"
                    className={styles.paymentIcon}
                    alt="Master Card"
                    loading="lazy"
                  />
                </li>
                <li className={styles.paymentItem}>
                  <img
                    src="/images/footer/american-express.png"
                    className={styles.paymentIcon}
                    alt="American Express"
                    loading="lazy"
                  />
                </li>
                <li>
                  <img
                    src="/images/footer/discover.png"
                    className={styles.paymentIcon}
                    alt="Discover"
                    loading="lazy"
                  />
                </li>
              </ul>
            </div>
            <ul className={styles.footerList}>
              <li>
                <p className={styles.footerListTitle}>Yogsewa</p>
              </li>
              <li>
                <p
                  className={styles.footerListText}
                  onClick={() => navigate("/contact-us/")}
                >
                  Contact Us
                </p>
              </li>
              <li onClick={() => navigate("/about/")}>
                <p className={styles.footerListText}>About Us</p>
              </li>
              <li>
                <p
                  className={styles.footerListText}
                  onClick={() => navigate("/careers/")}
                >
                  Careers
                </p>
              </li>
              <li>
                <p className={styles.footerListText}>Customer Care</p>
              </li>
            </ul>
            <ul className={styles.footerList}>
              <li>
                <p className={styles.footerListTitle}>Features</p>
              </li>
              {categoryData.map((item) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    <p className={styles.footerListText}>{item.title}</p>
                  </li>
                );
              })}
            </ul>
            <ul className={styles.footerList}>
              <li>
                <p className={styles.footerListTitle}>Get in touch!</p>
              </li>
              <li>
                <p className={styles.footerListText}>+977 9811568734</p>
              </li>
              <li>
                <p className={styles.footerListText}>support@yogsewa.com</p>
              </li>
              <div className={styles.iconContainer}>
                <a href="https://www.facebook.com/yogsewanepal" target="_blank">
                  <ion-icon name="logo-facebook"></ion-icon>
                </a>
                <ion-icon name="logo-instagram"></ion-icon>
                <ion-icon name="logo-twitter"></ion-icon>
                <ion-icon name="logo-youtube"></ion-icon>
              </div>
            </ul>
          </div>
          <div className={styles.footerBottom}>
            <div className={styles.wrapper}>
              <p className={styles.copyright}>
                Copyright © 2023 Yogsewa International Pvt. Ltd. | All Rights
                Reserved{" "}
              </p>
              <div className={styles.termsContainer}>
                <p
                  className={styles.term}
                  onClick={() => navigate("/terms-and-conditions/")}
                >
                  Terms & Conditions
                </p>
                <p
                  className={styles.privacy}
                  onClick={() => navigate("/privacy-policy/")}
                >
                  Privacy Policy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
