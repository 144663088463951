import { ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { Col, Form, Input, Popover, Row, Skeleton, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import ImageUpload from "../../../components/ImageUpload";
import TextEditor from "../../../components/TextEditor";
import styles from "../Program.module.css";
import cx from "classnames";

function ProgramAdd({
  categoryLoading,
  data,
  setData,
  photo,
  setPhoto,
  setImageSizeError,
  errors,
  categories,
}) {
  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.fileList);
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue({
      Title: data.title, //for showing intial value after fetching data (name is required from Form.Item)
      Summary: data.summary,
      Description: data.description, //for select initial value placeholder
      Program: data.program,
      MorningProgram: data.program_morning,
      AfternoonProgram: data.program_afternoon,
      EveningProgram: data.program_evening,
      FeatureStyle: data.feature_style,
      FeatureSkillLevel: data.feature_skill_level,
      FeatureFood: data.feature_food,
      Benefits: data.benefits,
      //for showing intial value after fetching data
    });
  }, [data]);

  return (
    <div className={styles.container}>
      <Form
        form={form}
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onSubmit={(e) => e.preventDefault()}
        id="program-form"
      >
        {categoryLoading ? (
          <div>
            <Skeleton.Input
              className={styles.optionTitle}
              active={true}
              size="small"
            />
            <div className={styles.categoryGrid}>
              <div className={styles.imageContainer}>
                <Skeleton.Image
                  className={styles.image}
                  style={{ width: "120px", height: "120px" }}
                  active={true}
                ></Skeleton.Image>
              </div>
              <div className={styles.imageContainer}>
                <Skeleton.Image
                  className={styles.image}
                  style={{ width: "120px", height: "120px" }}
                  active={true}
                ></Skeleton.Image>
              </div>
              <div className={styles.imageContainer}>
                <Skeleton.Image
                  className={styles.image}
                  style={{ width: "120px", height: "120px" }}
                  active={true}
                ></Skeleton.Image>
              </div>
              <div className={styles.imageContainer}>
                <Skeleton.Image
                  className={styles.image}
                  style={{ width: "120px", height: "120px" }}
                  active={true}
                ></Skeleton.Image>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.optionTitle}>
              Select a category
              {errors.category && (
                <span className={styles.fieldError}>{errors.category}</span>
              )}
            </div>
            <div className={styles.categoryGrid}>
              {categories.map((item) => (
                <div
                  key={item.id}
                  onClick={() => {
                    setData({ ...data, yoga_category_id: item.id });
                  }}
                >
                  <div
                    className={cx(
                      styles.imageContainer,
                      data?.yoga_category_id === item?.id ? styles.active : null
                    )}
                  >
                    <img
                      src={process.env.REACT_APP_SERVER_URL + item.photo}
                      className={styles.image}
                      alt="yoga"
                    />
                  </div>
                  <div
                    className={cx(
                      styles.categorySubtitle,
                      data?.yoga_category_id === item?.id
                        ? styles.titleActive
                        : null
                    )}
                  >
                    {item.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <Row gutter={[0, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Title"
              name="Title"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["title"] ? "error" : "success"}
              help={errors["title"] ? errors["title"][0] : ""}
            >
              <Input
                placeholder="Title"
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              name="dragger"
              listType="picture"
              getValueFromEvent={normFile}
              validateStatus={errors["photo"] ? "error" : "success"}
              help={errors["photo"] ? errors["photo"][0] : ""}
            >
              <ImageUpload
                fileList={photo}
                setFileList={setPhoto}
                multiple={true}
                photoWidth="625"
                photoHeight="348"
                setError={(msg) => {
                  setImageSizeError(msg);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Summary"
              name="Summary"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["summary"] ? "error" : "success"}
              help={errors["summary"] ? errors["summary"][0] : ""}
            >
              <TextEditor
                value={data.summary}
                onChange={(value) => setData({ ...data, summary: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Description"
              name="Description"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["description"] ? "error" : "success"}
              help={errors["description"] ? errors["description"][0] : ""}
            >
              <TextEditor
                value={data.description}
                onChange={(value) => setData({ ...data, description: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Program Details"
              name="Program"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["program"] ? "error" : "success"}
              help={errors["program"] ? errors["program"][0] : ""}
            >
              <TextEditor
                value={data.program}
                onChange={(value) => setData({ ...data, program: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Morning Program{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img
                            src="/images/program/morning.gif"
                            alt="morning"
                          />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo{" "}
                        <ExclamationCircleOutlined
                          style={{ color: "#108ee9" }}
                        />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img
                            src="/images/program/morning.jpg"
                            alt="morning"
                          />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="MorningProgram"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["program_morning"] ? "error" : "success"}
              help={
                errors["program_morning"] ? errors["program_morning"][0] : ""
              }
            >
              <TextEditor
                value={data.program_morning}
                onChange={(value) =>
                  setData({ ...data, program_morning: value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Afternoon Program{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img
                            src="/images/program/morning.gif"
                            alt="morning"
                          />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo{" "}
                        <ExclamationCircleOutlined
                          style={{ color: "#108ee9" }}
                        />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img
                            src="/images/program/morning.jpg"
                            alt="morning"
                          />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="AfternoonProgram"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["program_afternoon"] ? "error" : "success"}
              help={
                errors["program_afternoon"]
                  ? errors["program_afternoon"][0]
                  : ""
              }
            >
              <TextEditor
                value={data.program_afternoon}
                onChange={(value) =>
                  setData({ ...data, program_afternoon: value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Evening Program{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img
                            src="/images/program/morning.gif"
                            alt="morning"
                          />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo{" "}
                        <ExclamationCircleOutlined
                          style={{ color: "#108ee9" }}
                        />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img
                            src="/images/program/morning.jpg"
                            alt="morning"
                          />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="EveningProgram"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["program_evening"] ? "error" : "success"}
              help={
                errors["program_evening"] ? errors["program_evening"][0] : ""
              }
            >
              <TextEditor
                value={data.program_evening}
                onChange={(value) =>
                  setData({ ...data, program_evening: value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Feature Style"
              name="FeatureStyle"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["feature_style"] ? "error" : "success"}
              help={errors["feature_style"] ? errors["feature_style"][0] : ""}
            >
              <TextArea
                placeholder="Feature Styles(General Yoga, Hatha Yoga, Restorative Yoga, Vipassana & Yoga)"
                value={data.feature_style}
                rows={3}
                onChange={(e) => {
                  setData({ ...data, feature_style: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>

          <Col
            xxl={{ span: 8 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Feature Food"
              name="FeatureFood"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["feature_food"] ? "error" : "success"}
              help={errors["feature_food"] ? errors["feature_food"][0] : ""}
            >
              <TextArea
                placeholder="Feature Food(Vegetarian, Organic, Low Fat, Diabetic-Friendly, Sugar Consciuos)"
                value={data.feature_food}
                rows={3}
                onChange={(e) => {
                  setData({ ...data, feature_food: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xxl={{ span: 8 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Feature Skill Level"
              name="FeatureSkillLevel"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              validateStatus={
                errors["feature_skill_level"] ? "error" : "success"
              }
              help={
                errors["feature_skill_level"]
                  ? errors["feature_skill_level"][0]
                  : ""
              }
            >
              <TextArea
                placeholder="Feature Skill Level(General Yoga, Hatha Yoga, Restorative Yoga, Vipassana & Yoga)"
                value={data.feature_skill_level}
                rows={3}
                onChange={(e) => {
                  setData({ ...data, feature_skill_level: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Benefits{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img
                            src="/images/program/morning.gif"
                            alt="morning"
                          />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo{" "}
                        <ExclamationCircleOutlined
                          style={{ color: "#108ee9" }}
                        />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img
                            src="/images/program/morning.jpg"
                            alt="morning"
                          />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="Benefits"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateStatus={errors["benefits"] ? "error" : "success"}
              help={errors["benefits"] ? errors["benefits"][0] : ""}
            >
              <TextEditor
                value={data.benefits}
                onChange={(value) => setData({ ...data, benefits: value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ProgramAdd;
