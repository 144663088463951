import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import BookingTable from "./BookingTable";

const BookingsList = ({ fetchData, status }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortCont = new AbortController();
    setLoading(true);
    fetchData(abortCont.signal, status)
      .then((res) => {
        setData(res.data.bookings);
      })
      .catch((err) => {
        axiosErrorHandler(err);
      })
      .finally(() => setLoading(false));
    return () => {
      abortCont.abort();
    };
  }, []);

  return loading ? (
    <Spin />
  ) : (
    <div>
      <BookingTable booking={data} />
    </div>
  );
};

export default BookingsList;
