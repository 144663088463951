import { Pagination } from "antd";
import styles from "./styles.module.scss";

function CustomPagination({ page, setPage, pageSize = 5, total, disabled }) {
  return (
    <div className={styles.container}>
      <Pagination
        defaultCurrent={1}
        current={page} //current page
        total={total} //total data
        onChange={(page) => {
          setPage(page);
        }}
        pageSize={pageSize} // number of data per page
        showSizeChanger={false}
        disabled={disabled}
      />
    </div>
  );
}

export default CustomPagination;
