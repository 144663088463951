import { Button, Divider, Form, Input, Modal } from "antd";
import React, { useContext, useState } from "react";
import ReactFacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { RiFacebookFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../App";
import { UserContext } from "../../contexts/UserContext";
import { login } from "../../services/api/auth";
import { setCookie } from "../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import styles from "./SignInModal.module.css";

const SignInModal = ({ openModal, closeModal, bookingData, bookData }) => {
  const [data, setData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext);

  const responseFacebook = () => {};
  const responseGoogle = () => {};

  const handleSubmit = () => {
    if (data.email.length === 0 || data.password.length === 0) {
      return;
    }
    login(data)
      .then((res) => {
        if (
          res.data.user.role === "provider" ||
          res.data.user.role === "operator"
        ) {
          setCookie("token", res.data.token);
          setCookie("user", res.data.user);
          setUserData(res.data.user);
          notifySuccess("Logged In");
          closeModal();
        } else if (res.data.user.role === "user") {
          setCookie("token", res.data.token);
          setCookie("user", res.data.user);
          setUserData(res.data.user);
          navigate(`/bookings/${bookingData.id}`, {
            state: {
              data: {
                ...bookingData,
                accomodation_id: null,
                name: bookData.name,
                num_of_seats: bookData.num_of_seats,
                date: bookData.date,
              },
            },
          });
          notifySuccess("Logged In");
          closeModal();
        } else {
          notifyError("Invalid User.");
          setUserData({});
        }
        setLoading(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };
  return (
    <Modal open={openModal} onCancel={closeModal} footer={null}>
      <div className={styles.container}>
        <div className="container">
          <h1 className={styles.title}>Log in</h1>
          <div className={styles.register}>
            Don't have an account?
            <Link to="/signup/user/">Sign Up</Link>
          </div>
          <div className={styles.formContainer}>
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              onSubmitCapture={handleSubmit}
            >
              <Form.Item
                className={styles.formItem}
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please input your email" }]}
              >
                <Input
                  type="text"
                  value={data.email}
                  className={styles.input}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="Password"
                className={styles.formItem}
                rules={[
                  { required: true, message: "Please input your password" },
                ]}
              >
                <Input
                  type="password"
                  className={styles.input}
                  value={data.password}
                  onChange={(e) => {
                    setData({ ...data, password: e.target.value });
                  }}
                />
              </Form.Item>
              <div className={styles.forgotPassword}>
                <Link to="/forgot-password/">Forgot Password?</Link>
              </div>
              <Form.Item className={styles.formItem}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.button}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className={styles.socialGrid}>
            {" "}
            <div className={styles.facebook}>
              <ReactFacebookLogin
                textButton="Login with Facebook"
                appId={process.env.REACT_APP_FACEBOOK_APP_ID} //APP ID NOT CREATED YET
                fields="name,email,picture"
                callback={responseFacebook}
                onFailure={(a) => console.log("fff")}
                autoLoad={false}
                icon={<RiFacebookFill className="facebook-icon" />}
                cssClass="facebook"
                // scope="public_profile,user_friends,email"
              />
            </div>
            <div className={styles.google}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GMAIL_APP_ID}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="google"
                  >
                    <svg
                      width="18"
                      height="18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="#000" fill-rule="evenodd">
                        <path
                          d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                          fill="#EA4335"
                        ></path>
                        <path
                          d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                          fill="#4285F4"
                        ></path>
                        <path
                          d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                          fill="#FBBC05"
                        ></path>
                        <path
                          d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                          fill="#34A853"
                        ></path>
                        <path fill="none" d="M0 0h18v18H0z"></path>
                      </g>
                    </svg>
                    Login with Google
                  </button>
                )}
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={(a) => console.log(a)}
                cookiePolicy="single_host_origin"
                autoLoad={false}
                className="google"
              />
            </div>
          </div>
          <Divider>OR</Divider>
          <div className={styles.footerContainer}>
            <div className={styles.footerTitle}>Login without a password</div>
            <div className={styles.footerSubtitle}>
              We will send you an email with a temporary login link.
            </div>
            <div
              className={styles.partner}
              onClick={() => navigate("/login-without-password/")}
            >
              Get Quick Email Access
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default SignInModal;
