import React from "react";
import styles from "./AdminNavbar.module.css";

const AdminNavbar = ({ showDrawer }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon} onClick={showDrawer}>
        <ion-icon name="menu-outline"></ion-icon>
      </div>
    </div>
  );
};

export default AdminNavbar;
