import React from "react";
import { Spin } from "antd";

function Loading() {
  return (
    <div
      style={{ minHeight: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Spin tip="Loading..." size="large" />
    </div>
  );
}

export default Loading;
