import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { notifyError, notifySuccess } from "../../../../../../App";
import useWindowDimensions from "../../../../../../hooks/useWindowDimensions";
import {
  deleteInstructor,
  getProgramById,
} from "../../../../../../services/api/programs";
import { axiosErrorHandler } from "../../../../../../utils/errorHandler/axiosErrorHandler";
import styles from "./InstructorEdit.module.css";
import InstructorModal from "./InstructorModal";

function InstructorEdit({ id }) {
  const [data, setData] = useState([]);
  const { width } = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selected = useRef(null);
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (obj, item) => (
        <div className={styles.nameContainer}>
          <img
            src={process.env.REACT_APP_SERVER_URL + item.photo}
            style={{ width: "70px", height: "70px" }}
          />
          <div>{item.name}</div>
        </div>
      ),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      width: 300,
    },
    {
      title: "Action",
      key: "action",
      width: 300,
      render: (text, item) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                onClick(e.key, item.id, item);
              }}
              items={[
                {
                  key: "edit",
                  label: "Edit",
                },
                {
                  key: "delete",
                  label: "Delete",
                },
              ]}
            />
          }
          trigger="click"
          arrow
          text={text}
        >
          <a onClick={(e) => e.preventDefault()}>
            <EllipsisOutlined className={styles.actionIcon} />
          </a>
        </Dropdown>
      ),
    },
  ];

  const onClick = (key, id, item) => {
    if (key === "edit") {
      selected.current = item;
      setIsModalOpen(true);
    } else if (key === "delete") {
      deleteInstructor(item.id)
        .then((res) => {
          setRefresh(!refresh);
          notifySuccess("Instructor Removed.");
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    const abortCont = new AbortController();

    getProgramById(id, abortCont.signal)
      .then((res) => {
        setData(res.data.program.instructors);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
    return () => {
      abortCont.abort();
    };
  }, [id, refresh]);

  return (
    <div>
      <div>
        <Button
          style={{ marginBottom: "20px" }}
          type="primary"
          onClick={() => {
            selected.current = {
              name: "",
              description: "",
              photo: null,
              designation: "",
            };
            setIsModalOpen(true);
          }}
        >
          Add
        </Button>
      </div>
      <InstructorModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id={id}
        instructorData={
          selected.current || {
            name: "",
            description: "",
            photo: null,
            designation: "",
          }
        }
        programId={id}
        setRefresh={setRefresh}
      />
      <Table
        className={styles.table}
        // loading={loading}
        columns={columns}
        scroll={width < 1200 ? { x: 1200 } : null}
        dataSource={data.map((booking, index) => {
          return {
            ...booking,
            sn: index + 1,
            key: index,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
      />
    </div>
  );
}

export default InstructorEdit;
