import React, { useEffect, useRef, useState } from "react";
import { notifyError } from "../../App";
import { BlogCard } from "../../components/BlogCard";
import { getAllBlogs } from "../../services/api/blogs";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import styles from "./Blogs.module.css";
import BlogSkeleton from "../../components/BlogCard/BlogSkeleton";
import { useParams } from "react-router-dom";
import { Hero } from "../../components/Hero";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const { category } = useParams();

  const total = useRef();
  const pageSize = useRef();
  const lastPage = useRef();

  const fetchBlogs = async (signal) => {
    setLoading(true);
    await getAllBlogs(currentPage, category, signal)
      .then((res) => {
        if (res.status === "Success") {
          lastPage.current = res.data.last_page;
          total.current = res.data.total;
          pageSize.current = res.data.page_size;
          setBlogs((prev) =>
            currentPage > 1 ? [...prev, ...res.data.blogs] : res.data.blogs
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchBlogs(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, [currentPage]);
  return (
    <>
      <Hero
        title="Blogs"
        subtitle={
          "Join our team for Digital Marketing jobs in Nepal and help shape the future of digital marketing. We believe in learning, growing, and celebrating diversity together at Yogsewa to create amazing things."
        }
      />
      <div className={styles.container}>
        <div className="container">
          <div className={styles.cardContainer}>
            {blogs.map((item, index) => (
              <BlogCard item={item} index={index} />
            ))}
          </div>
          {loading ? (
            <div className={styles.cardContainer}>
              <BlogSkeleton />
              <BlogSkeleton />
              <BlogSkeleton />
            </div>
          ) : (
            lastPage.current !== currentPage && (
              <div
                className={styles.button}
                onClick={() => {
                  setCurrentPage((prev) => prev + 1);
                }}
              >
                Show More
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default Blogs;
