import { Button, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../App";
import { addProgram, getAllCategories } from "../../../services/api/programs";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import AccomodationAdd from "./AccomodationAdd/AccomodationAdd";
import InstructorAdd from "./InstructorAdd/InstructorAdd";
import LocationAdd from "./LocationAdd/LocationAdd";
import styles from "./Program.module.css";
import ProgramAdd from "./ProgramAdd/ProgramAdd";

const steps = [
  {
    title: "Program Details",
    current: 0,
    number: 1,
  },
  {
    title: "Location & Schedule",
    current: 1,
    number: 2,
  },
  {
    title: "Accomodation & Pricing",
    current: 2,
    number: 3,
  },
  {
    title: "Instructor",
    current: 3,
    number: 4,
  },
];
const AdminPrograms = () => {
  const { form } = useForm();
  const [photo, setPhoto] = useState([]);
  const [imageSizeError, setImageSizeError] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const topRef = useRef(null);
  const navigate = useNavigate();
  const [categories, setCategory] = useState([]);
  const [hasAccomodation, setHasAccomodation] = useState(false);
  const [data, setData] = useState({
    yoga_category_id: "",
    title: "",
    description: "",
    summary: "",
    program: "",
    program_morning: "",
    program_afternoon: "",
    program_evening: "",
    feature_style: "",
    feature_skill_level: "",
    feature_food: "",
    benefits: "",
    price: "",
    scheduling: "",
    schedules: [{ start_date: "", end_date: "" }],
    start_time: "",
    end_time: "",
    program_days: "",
    num_of_days: "",
    seat_capacity: "",
    country_id: "",
    location: "",
    area: "",
    seasonal_info: "",
    weather_info: "",
    visa_stuff: "",
    status: "",
    booking_status: "",
    yoga_instructors_id: [],
    accomodation: [],
    instructor: [],
  });
  const [current, setCurrent] = useState(0);
  const [pointVals, setPointVals] = useState([]);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const contentStyle = {
    lineHeight: "260px",
    color: "black",
    backgroundColor: "#FAFAFA",
    borderRadius: "10px",
    border: `1px dashed #fafafa`,
    marginTop: 16,
  };

  const handleSubmit = () => {
    let err = {};
    var accomodation = [];
    var instructor = [];

    if (current === 0) {
      if (photo.length === 0) {
        err["photo"] = ["Upload atleast one photo"];
      }
      if (data.yoga_category_id.length === 0) {
        err["category"] = ["Category is required."];
      }
      if (data.title.length === 0) {
        err["title"] = ["Title is required."];
      }
      if (data.description.length === 0) {
        err["description"] = ["Description is required."];
      }
      if (data.summary.length === 0) {
        err["summary"] = ["Summary is required."];
      }
      if (data.program.length === 0) {
        err["program"] = ["Program Details is required."];
      }

      if (data.feature_style.length === 0) {
        err["feature_style"] = ["Feature Style is required."];
      }
      if (data.feature_food && data.feature_food.length === 0) {
        err["feature_food"] = ["Feature Food is required."];
      }
      if (data.feature_skill_level.length === 0) {
        err["feature_skill_level"] = ["Feature Skill Level is required."];
      }
      if (data.benefits.length === 0) {
        err["benefits"] = ["Benefits is required."];
      }
      if (data.program_morning.length === 0) {
        err["program_morning"] = ["Morning program is required."];
      }
      if (data.program_afternoon.length === 0) {
        err["program_afternoon"] = ["Afternoon program is required."];
      }
      if (data.program_evening.length === 0) {
        err["program_evening"] = ["Evening program is required."];
      }
    }
    if (current === 1) {
      if (data.area.length === 0) {
        err["area"] = ["Area is required."];
      }

      if (data.scheduling.length === 0) {
        err["scheduling"] = ["Scheduling is required."];
      }
      // if (config.seatCapacity && data.seat_capacity.length === 0) {
      //   err["seat_capacity"] = ["Seat Capacity is required."];
      // }
      if (data.country_id.length === 0) {
        err["country_id"] = ["Country is required."];
      }
      if (data.location.length === 0) {
        err["location"] = ["Location is required."];
      }
      if (data.scheduling !== 3 && data.num_of_days.length === 0) {
        err["num_of_days"] = ["Num of days is required."];
      }
      if (data.scheduling === 3 && data.start_time.length === 0) {
        err["start_time"] = ["Available from is required."];
      }
      if (data.scheduling === 3 && data.end_time.length === 0) {
        err["end_time"] = ["Available to is required."];
      }
      if (data.scheduling === 3 && data.program_days.length === 0) {
        err["program_days"] = ["Program days is required."];
      }
      if (data.seasonal_info.length === 0) {
        err["seasonal_info"] = ["Seasonal Info is required."];
      }
      if (data.weather_info.length === 0) {
        err["weather_info"] = ["Weather Info is required."];
      }
      if (data.visa_stuff.length === 0) {
        err["visa_stuff"] = ["Visa Stuff is required."];
      }
      if (data.status.length === 0) {
        err["status"] = ["Status is required."];
      }
      if (data.booking_status.length === 0) {
        err["booking_status"] = ["Booking Status is required."];
      }
      if (pointVals.length === 0) {
        err["latitude"] = ["Select program location in the map"];
      }
      if (data.scheduling === 0 || data.scheduling === 2) {
        data.schedules.forEach((item, index) => {
          if (item.start_date.length === 0) {
            err[`schedules.${index}.start_date`] = ["Date is required."];
          }
          if (item.end_date.length === 0) {
            err[`schedules.${index}.end_date`] = ["End date is required."];
          }
        });
      }
    }
    if (current >= 2) {
      if (!hasAccomodation) {
        if (data.price.length === 0) {
          err["price"] = ["Price is required."];
        }
      } else {
        if (data.accomodation.length === 0) {
          err["accomodation"] = ["Accomodation is required."];
        } else {
          data.accomodation.forEach((item, index) => {
            if (item.title.length === 0) {
              err[`accomodation.${index}.title`] = ["Title is required."];
            }
            if (item.description.length === 0) {
              err[`accomodation.${index}.description`] = [
                "Description is required.",
              ];
            }
            if (item.price.length === 0) {
              err[`accomodation.${index}.price`] = ["Price is required."];
            }
            if (item.photo.length === 0) {
              err[`accomodation.${index}.photo`] = ["Photo is required."];
            }
            accomodation.push({
              title: item.title,
              image: item.photo.map((i) => i.originFileObj),
              price: item.price,
              description: item.description,
            });
          });
        }
      }
    }

    data.instructor.forEach((item, index) => {
      if (item.name.length === 0) {
        err[`instructor.${index}.name`] = ["Name is required."];
      }
      // if (item.description.length === 0) {
      //   err[`instructor.${index}.description`] = ["Description is required."];
      // }
      if (item.designation.length === 0) {
        err[`instructor.${index}.designation`] = ["Designation is required."];
      }
      if (item.photo.length === 0) {
        err[`instructor.${index}.photo`] = ["Photo is required."];
      }
      instructor.push({
        name: item.name,
        image: item.photo[0] ? item.photo[0].originFileObj : null,
        designation: item.designation,
        description: item.description,
      });
    });

    // if (data.scheduling === 0 || data.scheduling === 2) {
    //   data.schedules.forEach((item, index) => {
    //     if (item.start_date.length === 0) {
    //       err[`schedules.${index}.start_date`] = ["Date is required."];
    //     }
    //     if (item.end_date.length === 0) {
    //       err[`schedules.${index}.end_date`] = ["End date is required."];
    //     }
    //   });
    // }
    setErrors(err);

    if (Object.keys(err).length > 0) {
      return;
    }
    setCurrent(current + 1);
    if (current < steps.length - 1) {
      return;
    }

    var newPhotos = [];
    var deletedPhotoIds = [];

    // oldPhotos.forEach((photo) => {
    //   if (photo.status === "removed") {
    //     deletedPhotoIds.push(photo.id); // deleted photos id
    //   }
    // });

    photo.forEach((photo) => {
      if (photo.originFileObj) {
        newPhotos.push(photo.originFileObj); // add new photos
      }
    });

    var formData = {
      yoga_category_id: data.yoga_category_id,
      title: data.title,
      description: data.description,
      summary: data.summary,
      program: data.program,
      program_morning: data.program_morning,
      program_afternoon: data.program_afternoon,
      program_evening: data.program_evening,
      feature_style: data.feature_style,
      feature_skill_level: data.feature_skill_level,
      feature_food: data.feature_food,
      benefits: data.benefits,
      price: data.price,
      scheduling: data.scheduling,
      schedules: data.schedules,
      num_of_days: data.num_of_days,
      start_date: data.start_date,
      end_date: data.end_date,
      seat_capacity: data.seat_capacity,
      country_id: data.country_id,
      area: data.area,
      location: data.location,
      seasonal_info: data.seasonal_info,
      weather_info: data.weather_info,
      visa_stuff: data.visa_stuff,
      status: data.status,
      booking_status: data.booking_status,
      photos: newPhotos,
      accomodation: accomodation,
      latitude: pointVals[0][0],
      longitude: pointVals[0][1],
      instructor: instructor,
      start_time: data.start_time,
      end_time: data.end_time,
      program_days: data.program_days,
    };

    setLoading(true);

    addProgram(formData)
      .then((res) => {
        if (res.status === "Success") {
          notifySuccess(res.message);
          setLoading(true);
          message.success("Processing complete!");
          navigate("/partner/yoga-programs/verified/");
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data.data);
        }
        if (
          err.response.data.status === "Error" &&
          err.response.data.message === "Data validation Error." &&
          err.response.data.data
        ) {
          Object.keys(err.response.data.data).forEach((fieldName) => {
            axiosErrorHandler(
              {
                response: {
                  data: {
                    message: err.response.data.data[fieldName][0],
                  },
                },
              },
              notifyError
            );
          });
          return;
        }

        setLoading(false);
      });
  };

  const handleDateChange = (index, dateString) => {
    // setData({
    //   ...data,
    //   scheduling: [{ start_date: dateString[0], end_date: dateString[1] }],
    // });
    setData((prev) => {
      let p = [...prev.schedules];
      p.forEach((item, i) => {
        if (i === index) {
          item.start_date = dateString[0];
          item.end_date = dateString[1];
        }
      });
      return { ...prev, schedules: p };
    });
  };
  const removeDate = (index) => {
    setData((prev) => {
      let n = prev.schedules.filter((item, i) => {
        if (i !== index) {
          return item;
        }
      });
      if (n.length === 0) {
        n = [
          {
            start_date: "",
            end_date: "",
          },
        ];
      }
      return { ...prev, schedules: n };
    });
  };

  const addAccomodation = () => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc.push({
        title: "",
        description: "",
        price: "",
        photo: [],
      });
      return { ...data, accomodation: prevAcc };
    });
  };

  const handleAccomodationTitleChange = (e, index) => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc[index].title = e.target.value;
      return { ...data, accomodation: prevAcc };
    });
  };
  const handleAccomodationDescriptionChange = (val, index) => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc[index].description = val;
      return { ...data, accomodation: prevAcc };
    });
  };
  const handleAccomodationPriceChange = (e, index) => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc[index].price = e.target.value;
      return { ...data, accomodation: prevAcc };
    });
  };

  const handleAccomodationPhotoChange = (list, index) => {
    setData((prev) => {
      let prevAcc = [...prev.accomodation];
      prevAcc[index].photo = list;
      return { ...data, accomodation: prevAcc };
    });
  };

  const removeAccomodation = (index) => {
    setData((prev) => {
      let newData = prev.accomodation.filter((item, ind) => {
        if (index !== ind) {
          return item;
        }
      });
      return { ...data, accomodation: newData };
    });
  };

  const addInstructor = () => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc.push({
        name: "",
        designation: "",
        description: "",
        photo: [],
      });
      return { ...data, instructor: prevAcc };
    });
  };

  const handleInstructorNameChange = (e, index) => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc[index].name = e.target.value;
      return { ...data, instructor: prevAcc };
    });
  };

  const handleInstructorDesignationChange = (e, index) => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc[index].designation = e.target.value;
      return { ...data, instructor: prevAcc };
    });
  };

  const handleInstructorDescriptionChange = (val, index) => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc[index].description = val;
      return { ...data, instructor: prevAcc };
    });
  };

  const handleInstructorPhotoChange = (list, index) => {
    setData((prev) => {
      let prevAcc = [...prev.instructor];
      prevAcc[index].photo = list;
      return { ...data, instructor: prevAcc };
    });
  };

  const removeInstructor = (index) => {
    setData((prev) => {
      let newData = prev.instructor.filter((item, ind) => {
        if (index !== ind) {
          return item;
        }
      });
      return { ...data, instructor: newData };
    });
  };

  const handleAccomodationAvailable = (e) => {
    setHasAccomodation(e.target.value);
    if (!e.target.value) {
      setData({ ...data, accomodation: [] });
    } else {
      setData({ ...data, price: "" });
    }
  };

  useEffect(() => {
    if (imageSizeError) {
      message.error(imageSizeError);
    }
  }, [imageSizeError]);

  useEffect(() => {
    const abortCont = new AbortController();
    setCategoryLoading(true);
    getAllCategories(abortCont.signal)
      .then((res) => {
        setCategory(res.data);
        setCategoryLoading(false);
      })
      .catch((err) => {
        setCategoryLoading(false);
      });

    return () => {
      abortCont.abort();
    };
  }, []);

  return (
    <>
      <div style={contentStyle} ref={topRef}>
        <div className={styles.steps}>
          <ul className={styles.programSteps}>
            {steps.map((item) => (
              <li
                className={cx(
                  styles.stepItem,
                  item.current === current && styles.stepItemActive
                )}
                key={item.current}
              >
                <div className={styles.stepIconContainer}>
                  <div
                    className={cx(
                      styles.stepIcon,
                      item.current === current && styles.stepIconActive
                    )}
                  >
                    <div
                      className={cx(
                        styles.stepNumber,
                        item.current === current && styles.stepNumberActive
                      )}
                    >
                      {item.number}
                    </div>
                  </div>
                </div>
                <div className={styles.stepContent}>{item.title}</div>
              </li>
            ))}
          </ul>
        </div>
        {current === 0 ? (
          <ProgramAdd
            data={data}
            setData={setData}
            photo={photo}
            setPhoto={setPhoto}
            imageSizeError={imageSizeError}
            setImageSizeError={setImageSizeError}
            errors={errors}
            setErrors={setErrors}
            form={form}
            categories={categories}
            categoryLoading={categoryLoading}
          />
        ) : current === 1 ? (
          <LocationAdd
            data={data}
            setData={setData}
            errors={errors}
            setErrors={setErrors}
            handleDateChange={handleDateChange}
            removeDate={removeDate}
            pointVals={pointVals}
            setPointVals={setPointVals}
          />
        ) : current === 2 ? (
          <AccomodationAdd
            data={data}
            addAccomodation={addAccomodation}
            removeAccomodation={removeAccomodation}
            handleAccomodationTitleChange={handleAccomodationTitleChange}
            handleAccomodationDescriptionChange={
              handleAccomodationDescriptionChange
            }
            handleAccomodationPriceChange={handleAccomodationPriceChange}
            handleAccomodationPhotoChange={handleAccomodationPhotoChange}
            errors={errors}
            setImageSizeError={setImageSizeError}
            setData={setData}
            hasAccomodation={hasAccomodation}
            setHasAccomodation={setHasAccomodation}
            handleAccomodationAvailable={handleAccomodationAvailable}
          />
        ) : (
          <InstructorAdd
            addInstructor={addInstructor}
            data={data}
            removeInstructor={removeInstructor}
            handleInstructorNameChange={handleInstructorNameChange}
            handleInstructorDescriptionChange={
              handleInstructorDescriptionChange
            }
            handleInstructorDesignationChange={
              handleInstructorDesignationChange
            }
            handleInstructorPhotoChange={handleInstructorPhotoChange}
            errors={errors}
            setImageSizeError={setImageSizeError}
          />
        )}
      </div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => {
              scrollToTop();
              handleSubmit();
            }}
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => {
              scrollToTop();
              prev();
            }}
          >
            Previous
          </Button>
        )}
      </div>
    </>
  );
};
export default AdminPrograms;
