import { Segmented } from "antd";
import React, { useEffect, useState } from "react";
import ReviewHistory from "./History/ReviewHistory";
import styles from "./Review.module.css";
import ToReview from "./ToReview/ToReview";
// import BookingsList from "./BookingsList";

const Reviews = () => {
  const [segmentedValue, setSegmentedValue] = useState("To be Reviewed");
  const [refresh, setRefresh] = useState(false);

  const handleSegmentedChange = (value) => {
    // Update the segmented value and trigger the necessary actions
    setSegmentedValue(value);
    localStorage.setItem("review", value); // Store in localStorage
    // Perform any other actions based on the new value
  };

  useEffect(() => {
    // Retrieve value from localStorage
    const storedValue = localStorage.getItem("review");
    // Set the stored value as initial state if it exists
    if (storedValue) {
      setSegmentedValue(storedValue);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>My Reviews</div>
      <Segmented
        options={["To Be Reviewed", "History"]}
        value={segmentedValue}
        onChange={handleSegmentedChange}
        className={styles.segment}
      ></Segmented>
      {/* Render the component below based on the segmented value */}
      {segmentedValue === "To Be Reviewed" && (
        <ToReview refresh={refresh} setRefresh={setRefresh} />
      )}
      {segmentedValue === "History" && (
        <ReviewHistory refresh={refresh} setRefresh={setRefresh} />
      )}
    </div>
  );
};

export default Reviews;
