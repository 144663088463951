import { baseRequest } from "./base";

const verifyOtp = async (data) => {
  var response = await baseRequest({
    url: "/api/verify-phone",
    method: "POST",
    body: data,
  });

  return response;
};

const resendOtp = async (data) => {
  var response = await baseRequest({
    url: "/api/resend-otp",
    method: "POST",
    body: data,
  });

  return response;
};

export { verifyOtp, resendOtp };
