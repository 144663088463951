import { FileImageOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";

//Converting bytes into base64
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function ImageUpload({
  fileList,
  setFileList,
  title = "Choose your photo",
  multiple = false,
  maxUpload,
  photoWidth,
  photoHeight,
  setError,
  enableResolutionValidation = true,
}) {
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  //Setting new image when uploading new image
  const onChange = ({ fileList: newFileList }) => {
    // setFileList(newFileList);
    setError("");
    let imgArray = newFileList.filter((item) => {
      if (!item.originFileObj) {
        return item;
      }
    });

    let err = "";
    let newFiles = newFileList.filter((item) => {
      if (item.originFileObj) {
        return item;
      }
    });

    newFiles.forEach((file, index) => {
      var image = new Image();

      image.onload = function () {
        // Check if image is bad/invalid
        const { width, height } = image;
        // set image width and height to your state here
        if (
          enableResolutionValidation &&
          (width !== +photoWidth || height !== +photoHeight)
        ) {
          err = `Image resolution must be ${photoWidth}x${photoHeight}`;
        } else {
          imgArray.push({ ...file });
        }

        if (index === newFiles.length - 1 && err.length === 0) {
          setFileList(imgArray);
        }
        if (setError) {
          setError(err);
        }
      };

      image.onerror = function () {
        alert("Invalid image. Please select an image file.");
      };

      image.src = URL.createObjectURL(file.originFileObj);
    });

    if (newFileList.length === 0) {
      setFileList([]);
    }
  };

  //Preview in modal
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const beforeUpload = (file) => {
    return false;
  };

  return (
    <div>
      <Upload.Dragger
        action="http://localhost:3000/"
        listType="picture"
        fileList={fileList}
        onChange={onChange}
        onPreview={handlePreview}
        beforeUpload={beforeUpload}
        multiple={multiple}
        maxCount={maxUpload}
      >
        <div className={styles.imageContainer}>
          <FileImageOutlined style={{ fontSize: "2rem" }} />
          <div className={styles.titleContainer}>
            <h1 className={styles.imageHeading}>{title}</h1>
            {photoHeight ? (
              <p className={styles.dimension}>
                Image must of dimension {photoWidth} * {photoHeight}
              </p>
            ) : (
              <p className={styles.subHeading}>Drag and Drop or Choose Photo</p>
            )}
          </div>
        </div>
      </Upload.Dragger>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={previewImage}
          loading="lazy"
        />
      </Modal>
    </div>
  );
}

export default ImageUpload;
