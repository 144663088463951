import { Button, Table, Tag } from "antd";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getDateText } from "../../../utils/dateTime/dateAndTime";
import styles from "./Bookings.module.css";

const statusPicker = (status) => {
  if (status === 0) {
    return <Tag color="red">Unpaid</Tag>;
  } else if (status === 1) {
    return <Tag color="green">Paid</Tag>;
  }
};

const BookingTable = ({ booking }) => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const handleDownload = async (bookingId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}api/booking-payment/${bookingId}`,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "payment-slip.pdf");

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the PDF", error);
    }
  };
  const columns = [
    {
      title: "Package",
      dataIndex: "title",
      key: "title",
      width: 1000,
      render: (obj, item) => (
        <div className={styles.gridContainer}>
          <div
            className={styles.imageContainer}
            onClick={() => {
              navigate(`/programs/${item.yoga_program.id}/`);
            }}
          >
            <img
              src={
                process.env.REACT_APP_SERVER_URL +
                item.yoga_program.media[0].link
              }
              className={styles.image}
              alt="yoga programs"
              loading="lazy"
            />
          </div>
          <div className={styles.detailContainer}>
            <div className={styles.details}>{item.yoga_program.title}</div>
            <button
              className={styles.actionButton}
              onClick={() => {
                navigate(`/user/bookings/${item.id}`);
              }}
            >
              Order Details
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Order",
      dataIndex: "unique_id",
      key: "unique_id",
      width: 200,
    },
    {
      title: "Placed On",
      dataIndex: "created_at",
      key: "created_at",
      width: 300,
      render: (obj, item) => <>{getDateText(item.created_at)}</>,
    },
    {
      title: "Status",
      dataIndex: "payment_status",
      key: "payment_status",
      width: 100,
      render: (obj, item) => statusPicker(item.payment_status),
    },
    { title: "Program On", dataIndex: "date", key: "date", width: 200 },

    {
      title: "Receipt",
      dataIndex: "receipt",
      key: "receipt",
      width: 100,
      render: (obj, item) => (
        <Button onClick={() => handleDownload(item.id)}>Download</Button>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      scroll={width < 992 ? { x: 1300 } : null}
      dataSource={booking.map((booking, index) => {
        return {
          ...booking,
          key: index,
        };
      })}
      style={{ cursor: "pointer" }}
      pagination={true}
    />
  );
};

export default BookingTable;
