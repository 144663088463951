import { ExclamationCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { Modal } from "antd";

const { confirm } = Modal;

const confirmDelete = (onConfirm, onClose) => {
  confirm({
    title: "Do you want to delete the item?",
    icon: <ExclamationCircleOutlined />,

    onOk() {
      onConfirm();
    },

    onCancel() {
      onClose();
    },
  });
};

const confirmBooking = (onConfirm, onClose) => {
  confirm({
    title: "Do you want to confirm your bookings?",
    icon: <CheckOutlined />,

    onOk() {
      onConfirm();
    },

    onCancel() {
      onClose();
    },
  });
};

export { confirmDelete, confirmBooking };
