import React from "react";
import { Landing, Search } from "../../sections/Home";
import { Categories } from "../../sections/Home/Categories";
import Destinations from "../../sections/Home/Destinations/Destinations";
import { Newsletter } from "../../sections/Home/Newsletter";
import { Packages } from "../../sections/Home/Packages";
import { Programs } from "../../sections/Home/Programs";
import { Review } from "../../sections/Home/Review";
import styles from "./Home.module.css";
import Blogs from "../../sections/Home/Blogs/Blogs";
import RecentPackages from "../../sections/Home/RecentPackages/RecentPackages";

function Home() {
  return (
    <div>
      <Landing />
      <div className={styles.searchSection}>
        <div className={styles.searchContainer}>
          <Search />
        </div>
        <Destinations />
      </div>
      <Packages />
      <Categories />
      <Newsletter />
      <RecentPackages />
      <Programs />
      <Review />
      <Blogs />
    </div>
  );
}

export default Home;
