import React, { useEffect, useState } from "react";
import LocationPicker from "react-leaflet-location-picker";

const CustomLocationPicker = ({ pointVals, setPointVals, mapCenter }) => {
  const [centerPoint, setCenterPoint] = useState([]);
  const pointMode = {
    banner: true,
    control: {
      values: pointVals,
      onClick: (point) => {
        setPointVals([point]);
      },
      onRemove: (point) => {
        setPointVals([]);
      },
    },
  };
  const circleMode = {
    banner: false,
  };
  useEffect(() => {
    if (mapCenter === undefined) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords } = position;
          setCenterPoint([coords.latitude, coords.longitude]);
        },
        (error) => {
          setCenterPoint([85, 20]);
        }
      );
    } else {
      setCenterPoint(mapCenter);
    }
  }, [mapCenter]);
  return (
    <>
      {centerPoint.length > 0 && (
        <LocationPicker
          pointMode={pointMode}
          circleMode={circleMode}
          startPort={{
            center: centerPoint,
            zoom: 7,
          }}
          showInputs={false}
        />
      )}
    </>
  );
};

export default CustomLocationPicker;
