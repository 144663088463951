import { baseRequest } from "./base";

const getFeaturedCategories = async (signal) => {
  var response = await baseRequest({
    url: `/api/featured-categories`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getPopularCategories = async (signal) => {
  var response = await baseRequest({
    url: `/api/popular-categories`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getPopularDestination = async (signal) => {
  var response = await baseRequest({
    url: `/api/popular-destinations`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getPopularDestinationById = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/popular-destinations/${id}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

export {
  getFeaturedCategories,
  getPopularDestination,
  getPopularCategories,
  getPopularDestinationById,
};
