import React, { useEffect, useState } from "react";
import styles from "./Blogs.module.css";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { getBlogsCategory } from "../../services/api/blogs";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import { notifyError } from "../../App";

function BlogCategory() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);

  const fetchBlogCategories = (signal) => {
    setLoading(true);
    getBlogsCategory(signal)
      .then((res) => {
        if (res.status === "Success") {
          setLoading(false);
          setCategories(res.data);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchBlogCategories(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <div className={cx(styles.container, styles.categoryWrapper)}>
      <div className="container">
        <div className="section-title"> Blog Categories </div>
        <div className={styles.gridContainer}>
          {categories.map((category) => (
            <div className={styles.cardWrapper}>
              <div className={styles.imageContainer}>
                <img
                  src="/images/blogs/1-min.jpg"
                  alt="yoga"
                  className={styles.image}
                  loading="lazy"
                />
              </div>
              <div
                className={styles.card}
                onClick={() => navigate(`/blogs/category/${category.id}/`)}
              >
                {category.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogCategory;
