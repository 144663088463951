import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getDateText } from "../../../utils/dateTime/dateAndTime";
import styles from "./ToReviewCard.module.css";

const ToReviewCard = ({ review, onReview }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.bookingCard}>
      <div className={styles.orderWrapper}>
        <div className={styles.placedDate}>Attended on {getDateText(review.date)}</div>
      </div>
      <div className={styles.programWrapper}>
        <div
          className={styles.imageContainer}
          onClick={() => {
            navigate(`/programs/${review.yoga_program.id}/`);
          }}
        >
          <img
            src={process.env.REACT_APP_SERVER_URL + review.yoga_program.media[0].link}
            className={styles.image}
            alt="program"
            loading="lazy"
          />
        </div>
        <div className={styles.rightWrapper}>
          <div className={styles.detailsWrapper}>
            <div
              className={styles.title}
              onClick={() => {
                navigate(`/programs/${review.yoga_program.id}/`);
              }}
            >
              {review.yoga_program.title}
            </div>
          </div>
          <div className={styles.reviewButtonWrapper}>
            <Button
              onClick={() => {
                onReview({
                  title: review.yoga_program.title,
                  image: process.env.REACT_APP_SERVER_URL + review.yoga_program.media[0].link,
                  id: review.id,
                  country: review.yoga_program.country.name,
                  date: review.date,
                });
              }}
              className={styles.button}
            >
              Review
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToReviewCard;
