import { Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./UserProfile.module.css";
import { UserContext } from "../../contexts/UserContext";
import { getAllBookings } from "../../services/api/users";
import { axiosErrorHandler } from "../../utils/errorHandler/axiosErrorHandler";
import { notifyError } from "../User/UserLayout";

const columns = [
  {
    title: "Unique Id",
    dataIndex: "unique_id",
    key: "unique_id",
    width: 300,
  },
  {
    title: "Program Title",
    dataIndex: "program_title",
    key: "program_title",
    render: (obj) => <>{obj}</> /*getDateTime(date).date */,
    width: 500,
  },

  {
    title: "Total Price",
    dataIndex: "total",
    key: "total",
    width: 200,
    render: (obj, item) => <>Rs. {obj}</>,
  },
];

function UserProfile() {
  const { width } = useWindowDimensions();
  const { userData, setUserData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);

  const navigate = useNavigate();

  const fetchUserBookings = (signal) => {
    setLoading(true);
    getAllBookings(signal)
      .then((res) => {
        setBookings(res.data.bookings);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchUserBookings(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainTitle}>Manage My Account</div>
      <div className={styles.cardContainer}>
        <div className={styles.profileContainer}>
          <div className={styles.icon}>
            <ion-icon name="person-circle-outline"></ion-icon>
          </div>
          <div className={styles.profile}>
            <div className={styles.profileTitle}>Personal Details</div>
            <div className={styles.profileSubtitle}>
              {userData.f_name + " " + userData.l_name}
            </div>
            <div className={styles.profileSubtitle}>{userData.email}</div>
            {userData.google_id === null && userData.facebook_id === null && (
              <div className={styles.actionButtons}>
                {/* <button className={styles.action}>Edit</button> */}
                <button
                  className={styles.action}
                  onClick={() => navigate("/user/change-password/")}
                >
                  Change password
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {
        <Table
          title={() => <div className={styles.tableTitle}>Recent Orders</div>}
          className={styles.table}
          loading={loading}
          columns={columns}
          scroll={width < 800 ? { x: 1300 } : null}
          dataSource={bookings.slice(0, 5).map((product, index) => {
            return {
              ...product,
              // sn: (page - 1) * pageSize + index + 1,
              key: index,
            };
          })}
          style={{ cursor: "pointer" }}
          pagination={false}
        />
      }
    </div>
  );
}

export default UserProfile;
