import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllCategories } from "../../services/api/programs";
import { converter } from "../../utils/htmlConverter/htmlConverter";
import styles from "./Categories.module.css";

const Categories = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let abortCont = new AbortController();
    setLoading(true);
    getAllCategories(abortCont.signal)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
    return () => {
      abortCont.abort();
    };
  }, []);
  return (
    <div className={styles.container}>
      {" "}
      <div className="container">
        <div className={styles.title}>Explore all categories in YogSewa</div>
        <div className={styles.categoriesWrapper}>
          {data.map((item) => (
            <div
              key={item.id}
              className={styles.categoryCard}
              onClick={() => {
                navigate(`/search?category=${item.id}`);
              }}
            >
              <div className={styles.imageContainer}>
                <img
                  src={process.env.REACT_APP_SERVER_URL + item.photo}
                  alt="category"
                  className={styles.image}
                  loading="lazy"
                />
              </div>
              <div className={styles.categoryTitle}>{item.title}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(item.overview),
                }}
                className={styles.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
