import { baseRequest } from "./base";

const addProgram = async (body) => {
  var body = { ...body };
  var data = new FormData();

  body.photos.forEach((dat, index) => {
    data.append(`media[${index}]`, dat);
  });
  delete body["photos"];

  body.schedules.forEach((dat, index) => {
    if (dat.start_date !== null && dat.start_date.length > 0) {
      data.append(`schedules[${index}][start_date]`, dat.start_date);
    }
    if (dat.start_date !== null && dat.start_date.length > 0) {
      data.append(`schedules[${index}][end_date]`, dat.end_date);
    }
  });
  delete body["schedules"];

  body.accomodation.forEach((dat, index) => {
    data.append(`accomodation[${index}][title]`, dat.title);
    data.append(`accomodation[${index}][price]`, dat.price);
    dat.image.forEach((img, i) => {
      data.append(`accomodation[${index}][images][${i}]`, img);
    });
    data.append(`accomodation[${index}][description]`, dat.description);
  });
  delete body["accomodation"];

  body.instructor.forEach((dat, index) => {
    data.append(`instructor[${index}][name]`, dat.name);
    data.append(`instructor[${index}][designation]`, dat.designation);
    data.append(`instructor[${index}][photo]`, dat.image);
    data.append(`instructor[${index}][description]`, dat.description);
  });
  delete body["instructor"];

  Object.keys(body).forEach((key) => {
    if (body[key] !== null && body[key] !== undefined) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: "/api/yoga-programs",
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });

  return response;
};

const getAllPrograms = async (page, signal, search) => {
  var response = await baseRequest({
    url: `/api/client-yoga-programs?page=${page}&search=${search}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getNonVerifiedPrograms = async (page, signal, search) => {
  var response = await baseRequest({
    url: `/api/client-pending-programs?page=${page}&search=${search}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const updateProgram = async (id, uploadData) => {
  var body = { ...uploadData };
  var data = new FormData();
  body.photos.forEach((element) => {
    data.append("media[]", element);
  });

  delete body["photos"];

  body.deletePhotoIds.forEach((element) => {
    data.append("delete_media[]", element);
  });
  delete body["delete_media"];

  Object.keys(body).forEach((key) => {
    if (body[key] && body[key].length !== 0) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: `/api/yoga-programs-basic/${id}`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });
  return response;
};

const updateSchedule = async (id, uploadData) => {
  var body = { ...uploadData };

  var data = new FormData();

  if (body.scheduling === 3) {
    let sch = body.schedules[0];
    if (sch.id) {
      data.append(`schedules[${0}][id]`, sch.id);
    }
    data.append(`schedules[${0}][start_time]`, sch.start_time);
    data.append(`schedules[${0}][end_time]`, sch.end_time);
    data.append(`schedules[${0}][program_days]`, sch.program_days);
  } else {
    body.schedules.forEach((sch, index) => {
      if (sch.id) {
        data.append(`schedules[${index}][id]`, sch.id);
      }
      data.append(`schedules[${index}][start_date]`, sch.start_date);
      data.append(`schedules[${index}][end_date]`, sch.end_date);
    });
  }

  delete body["schedules"];

  Object.keys(body).forEach((key) => {
    if (
      body[key] !== null &&
      body[key] !== undefined &&
      body[key].length !== 0
    ) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: `/api/yoga-programs-schedule/${id}`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });
  return response;
};

const updatePrice = async (id, uploadData) => {
  var body = { ...uploadData };
  var data = new FormData();
  data.append("price", body.price);

  var response = await baseRequest({
    url: `/api/yoga-programs/${id}/price`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });
  return response;
};

const getPublicPrograms = async (page, signal, search) => {
  var response = await baseRequest({
    url: `/api/yoga-programs?page=${page}&search=${search}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getRecentPackages = async (signal) => {
  var response = await baseRequest({
    url: `/api/recent-yoga-programs`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getAllCategories = async (signal) => {
  var response = await baseRequest({
    url: `/api/yoga-categories`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const getProgramById = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/yoga-programs/${id}`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const toggleFav = async (id) => {
  var response = await baseRequest({
    url: `/api/favourites/${id}`,
    method: "POST",
  });

  return response;
};

const getFavourites = async (signal) => {
  var response = await baseRequest({
    url: `/api/favourites`,
    method: "GET",
    signal: signal,
  });

  return response;
};

const updateAccomodation = async (id, uploadData) => {
  var body = { ...uploadData };
  var data = new FormData();
  body.images.forEach((element) => {
    data.append("images[]", element);
  });

  delete body["images"];

  body.deletePhotoIds.forEach((element) => {
    data.append("delete_image[]", element);
  });
  delete body["delete_image"];

  Object.keys(body).forEach((key) => {
    if (body[key] && body[key].length !== 0) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: `/api/accomodation/${id}`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });
  return response;
};

const addAccomodation = async (id, uploadData) => {
  var body = { ...uploadData };
  var data = new FormData();
  body.images.forEach((element) => {
    data.append("images[]", element);
  });

  delete body["images"];

  Object.keys(body).forEach((key) => {
    if (body[key] && body[key].length !== 0) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: `/api/yoga-programs/${id}/accomodation`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });
  return response;
};

const updateInstructor = async (id, uploadData) => {
  var body = { ...uploadData };
  var data = new FormData();

  Object.keys(body).forEach((key) => {
    if (body[key] && body[key].length !== 0) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: `/api/instructor/${id}`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });
  return response;
};

const addInstructor = async (id, uploadData) => {
  var body = { ...uploadData };
  var data = new FormData();

  Object.keys(body).forEach((key) => {
    if (body[key] && body[key].length !== 0) {
      data.append(key, body[key]);
    }
  });

  var response = await baseRequest({
    url: `/api/yoga-programs/${id}/instructor`,
    method: "POST",
    body: data,
    contentType: "multipart/form-data",
  });
  return response;
};

const toggleAccomodation = async (id) => {
  var response = await baseRequest({
    url: `/api/accomodation/${id}`,
    method: "DELETE",
  });
  return response;
};

const deleteInstructor = async (id) => {
  var response = await baseRequest({
    url: `/api/instructor/${id}`,
    method: "DELETE",
  });
  return response;
};

const searchProgram = async (
  page,
  countryId,
  categoryId,
  startDate,
  endDate,
  signal = "",
  price_min = "",
  price_max = "",
  duration_min = "",
  duration_max = "",
  order = "",
  direction = ""
) => {
  var response = await baseRequest({
    url: `/api/search-programs?page=${page}&location=${countryId}&category=${categoryId}&start_date=${startDate}&end_date=${endDate}&price_min=${price_min}&price_max=${price_max}&duration_min=${duration_min}&duration_max=${duration_max}&order=${order}&direction=${direction}
    `,
    method: "GET",
    signal: signal,
  });

  return response;
};

export {
  addProgram,
  getAllPrograms,
  getAllCategories,
  getProgramById,
  getPublicPrograms,
  toggleFav,
  getFavourites,
  updateProgram,
  updateAccomodation,
  addAccomodation,
  toggleAccomodation,
  updateInstructor,
  addInstructor,
  deleteInstructor,
  updateSchedule,
  searchProgram,
  getNonVerifiedPrograms,
  updatePrice,
  getRecentPackages,
};
