import { Button, Table } from "antd";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../../../App";
import { getPaymentHistory } from "../../../../services/api/payments";
import { axiosErrorHandler } from "../../../../utils/errorHandler/axiosErrorHandler";
import CustomPagination from "../../../components/Pagination";

function AdminPaymentHistory() {
  const navigate = useNavigate();

  const handleDownload = (imageUrl, imageName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = imageName;

        link.click();

        URL.revokeObjectURL(link.href);
        link.remove();
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Payment Id",
      dataIndex: "payment_id",
      key: "payment_id",
      width: 300,
    },
    {
      title: "Transaction Charge($)",
      dataIndex: "transaction_charge",
      key: "transaction_charge",
      width: 100,
    },

    {
      title: "Provider Income($)",
      dataIndex: "provider_income",
      key: "provider_income",
      width: 100,
    },

    {
      title: "BookingDate",
      dataIndex: "booking_date",
      key: "booking_date",
      width: 100,
      render: (text, item) => item.booking.date,
    },

    {
      title: "Receipt",
      key: "receipt",
      width: 100,
      render: (text, item) => (
        <Button
          type="ghost"
          onClick={() =>
            handleDownload(
              process.env.REACT_APP_SERVER_URL +
                "storage/uploads/" +
                item.receipt,
              `${item.payment_id}.png`
            )
          }
        >
          Download
        </Button>
      ),
    },

    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text, item) => (
        <Button
          type="primary"
          onClick={() => {
            navigate(`/partner/accounting/history/${item?.transaction_id}`);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const total = useRef(null);
  const pageSize = useRef(null);

  const fetchPayments = async (signal) => {
    setLoading(true);
    await getPaymentHistory(page, signal)
      .then((res) => {
        if (res.status === "Success") {
          total.current = res.data.total;
          pageSize.current = res.data.page_size;
          setData(res.data.payments);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchPayments(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, [page]);
  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={data.map((payment, index) => {
          return {
            ...payment,
            sn: (page - 1) * pageSize.current + index + 1,
            key: index,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        total={total.current}
        pageSize={pageSize.current}
      />
    </div>
  );
}

export default AdminPaymentHistory;
