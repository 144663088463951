import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { notifyError } from "../../../../App";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { getProgramBookings } from "../../../../services/api/bookings";
import { getDateTime } from "../../../../utils/dateTime/dateAndTime";
import { axiosErrorHandler } from "../../../../utils/errorHandler/axiosErrorHandler";
import CustomPagination from "../../../components/Pagination";
import { SearchBox } from "../../../components/SearchBox";
import styles from "./ProgramBookings.module.css";

const iconChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return <SyncOutlined spin />;

    case 0:
      return <CloseCircleOutlined />;

    case 1:
      return <CheckCircleOutlined />;

    default:
      return <CheckCircleOutlined />;
  }
};

const tagChooser = (tags) => {
  switch (tags) {
    case 1:
      return "green";

    case 0:
      return "red";

    default:
      return "yellow";
  }
};

// const onClick = (key, id) => {
//   if (key === "edit") {
//     navigate(`/partner/operators/edit/${id}`);
//   } else if (key === "delete") {
//     confirmDelete(
//       () => handleDelete(id),
//       () => {}
//     );
//   }
// };

function ProgramBookings() {
  const { width } = useWindowDimensions();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const totalProducts = useRef(1);
  const [loading, setLoading] = useState(false);
  const pageSize = useRef(1);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Unique Id",
      dataIndex: "unique_id",
      key: "unique_id",
      width: 300,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "Start Date",
      dataIndex: "date",
      key: "date",
      width: 300,
      render: (obj, item) => <div>{getDateTime(item.date).date}</div>,
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      width: 300,
      render: (obj, item) => (
        <Tag
          key={item.id}
          color={tagChooser(item.payment_status)}
          icon={iconChooser(item.payment_status)}
          style={{ textTransform: "capitalize" }}
        >
          {item.payment_status === 0 ? "Not Paid" : "Paid"}
        </Tag>
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   width: 300,
    //   render: (text, item) => (
    //     <Dropdown
    //       overlay={
    //         <Menu
    //           onClick={(e) => onClick(e.key, item.id)}
    //           items={[
    //             {
    //               key: "edit",
    //               label: "Edit",
    //             },
    //             {
    //               key: "delete",
    //               label: "Delete",
    //             },
    //           ]}
    //         />
    //       }
    //       trigger="click"
    //       arrow
    //       text={text}
    //     >
    //       <a onClick={(e) => e.preventDefault()}>
    //         <EllipsisOutlined className={styles.actionIcon} />
    //       </a>
    //     </Dropdown>
    //   ),
    // },
  ];

  useEffect(() => {
    if (location.state) {
      const abortCont = new AbortController();
      setLoading(true);
      getProgramBookings(location.state.id, page, search, abortCont.signal)
        .then((res) => {
          totalProducts.current = res.data.total;
          pageSize.current = res.data.page_size;
          setData(res.data.bookings);
        })
        .catch((err) => {
          axiosErrorHandler(err, notifyError);
        })
        .finally(() => {
          setLoading(false);
        });
      return () => {
        abortCont.abort();
      };
    }
  }, [page, search, location]);
  return !location.state ? (
    <Navigate to="/partner/yoga-programs/" />
  ) : (
    <div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          Bookings for {location.state.program.title}
        </div>
      </div>
      <div></div>
      <div className={styles.headerContainer}>
        <div>
          <SearchBox setSearch={setSearch} placeholder="Search Bookings" />
        </div>
      </div>
      <Table
        className={styles.table}
        // loading={loading}
        columns={columns}
        scroll={width < 1200 ? { x: 1200 } : null}
        dataSource={data.map((booking, index) => {
          return {
            ...booking,
            sn: (page - 1) * pageSize.current + index + 1,
            key: index,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        total={totalProducts.current}
        pageSize={pageSize.current}
      />
    </div>
  );
}

export default ProgramBookings;
