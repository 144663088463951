import { CustomerServiceOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Drawer, Menu, Popover, Row } from "antd";
import React, { useContext, useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
// import Loading from "../components/Loading";
// import { NotFound } from "../views/404";
import { LogoutOutlined } from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import cx from "classnames";
import NotFound from "../../pages/404/NotFound";
import { logout } from "../../services/api/auth";
import { clearCookie } from "../../utils/cookie/cookies";
import AdminNavbar from "../components/AdminNavbar/AdminNavbar";
import Sidebar from "../components/Sidebar";
import menuItems from "../components/Sidebar/menuItems";
import styles from "./AdminLayout.module.css";
import adminRoutes from "./adminRoutes";

const getBreadcrumbs = (title) => {
  switch (title) {
    case "profile":
      return "Profile Information";

    case "business":
      return "Business Information";

    case "payment":
      return "Payment Information";

    case "yoga-programs":
      return "Yoga Programs";

    case "not-verified":
      return "Not Verified";

    case "accounting":
      return "Finance";

    default:
      return title;
  }
};

function AdminLayout() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true);
    logout()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        clearCookie("login-token");
        setUserData("");
        navigate("/");
        setLoading(false);
      });
  };

  const pathSnippets = location.pathname.split("/").filter((i) => i); // ["blogs", "list", "edit"]
  const getBreadCrumbItems = (pathSnippets) => {
    if (pathSnippets[0] === "partner" && pathSnippets.length === 1) {
      return null;
    } else {
      const breadcrumbs = pathSnippets.map((title, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}/`; // /blogs/list/edit/
        const isValidRoute = adminRoutes.some((route) => {
          const provider = "/partner";
          const concatRoute = provider + route.path;
          return concatRoute === url;
        });

        return (
          <Breadcrumb.Item key={url}>
            {isValidRoute && location.pathname !== url ? (
              <Link to={url}>
                {index === 0 ? "Home" : getBreadcrumbs(title)}
              </Link>
            ) : (
              <div>{getBreadcrumbs(title)}</div>
            )}
          </Breadcrumb.Item>
        );
      });

      return breadcrumbs;
    }
  };

  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  const { userData, setUserData } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  if (userData.role !== "provider" && userData.role !== "operator") {
    return <Navigate to="/login/" />;
  } else {
    return loading ? (
      <>Loading</>
    ) : (
      <div className={styles.container}>
        <Row>
          <Col span={width < 1200 ? 0 : width < 1440 ? 5 : 4}>
            <Sidebar setUserData={setUserData} handleLogout={handleLogout} />
          </Col>
          <Col
            span={width < 1200 ? 24 : width < 1440 ? 19 : 20}
            style={{
              backgroundColor: "#f2f2f2",
            }}
            className={styles.wrapper}
          >
            <div className={styles.navbar}>
              <AdminNavbar showDrawer={showDrawer} />
            </div>

            <div className={styles.content}>
              {
                <Breadcrumb
                  separator=">"
                  style={
                    pathSnippets.length === 0 ||
                    (pathSnippets.length === 1 && pathSnippets[0] === "partner")
                      ? { display: "none" }
                      : { margin: "2em 0", textTransform: "capitalize" }
                  }
                >
                  {getBreadCrumbItems(pathSnippets)}
                </Breadcrumb>
              }
              <Routes>
                {adminRoutes.map((route, index) => {
                  if (!route.role || route.role === userData.role) {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        element={route.element}
                      />
                    );
                  }
                })}
                <Route path="*" element={<Navigate to="/404" replace />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </Col>
        </Row>
        <Drawer
          placement="left"
          onClose={onClose}
          open={open}
          className={styles.drawer}
          width={280}
          title={
            <div>
              <img src="/images/logo-with-tagline.png" alt="logo" />
            </div>
          }
        >
          <Menu
            className={styles.menu}
            mode="inline"
            selectedKeys={[location.pathname]}
            defaultOpenKeys={["sub1"]}
          >
            {menuItems.map((menuItem, index) =>
              menuItem.subNav ? (
                <SubMenu
                  icon={menuItem.icon}
                  title={menuItem.title}
                  id="sidebar"
                  key={index}
                >
                  {menuItem.subNav.map((subNavItem, index) => (
                    <Menu.Item
                      key={subNavItem.path}
                      icon={subNavItem.icon}
                      className={cx(styles.menuTitle)}
                      onClick={() => {
                        navigate(subNavItem.path);
                        onClose();
                      }}
                    >
                      {subNavItem.title}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  icon={menuItem.icon}
                  key={menuItem.path}
                  onClick={() => {
                    navigate(menuItem.path);
                    onClose();
                  }}
                >
                  {menuItem.title}
                </Menu.Item>
              )
            )}
            <Menu.Item icon={<LogoutOutlined />} onClick={handleLogout}>
              Log Out
            </Menu.Item>
          </Menu>
          <div className={styles.copyright}>
            Copyright © 2022 Yogsewa | All Rights Reserved
          </div>
        </Drawer>
        <Popover
          placement="left"
          content={
            <div className={styles.supportContainer}>
              <div className={styles.support}>
                <div className={styles.icon}>
                  <ion-icon name="call-outline"></ion-icon>
                </div>
                +977 9811568734
              </div>
              <div className={styles.support}>
                <div className={styles.icon}>
                  <ion-icon name="mail-outline"></ion-icon>
                </div>
                support@yogsewa.com
              </div>
            </div>
          }
        >
          <button className={styles.info}>
            <CustomerServiceOutlined />
          </button>
        </Popover>
      </div>
    );
  }
}
export default AdminLayout;
