import React from "react";
import styles from "./CustomBreadcrumb.module.css";
import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";

const CustomBreadcrumb = ({ breadcrumb }) => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className={styles.breadcrumb}>
        <Breadcrumb separator=">">
          {breadcrumb.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <div
                key={index}
                className={
                  item.to && index < breadcrumb.length - 1 && styles.hover
                }
                onClick={() => {
                  if (item.to && index < breadcrumb.length - 1) {
                    navigate(item.to);
                  }
                }}
              >
                {item.title}
              </div>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </div>
  );
};

export default CustomBreadcrumb;
