import React from "react";
import BookingDetails from "../Bookings/Details/BookingDetails";
import Bookings from "../Bookings/List/Bookings";
import { ChangePassword } from "../ChangePassword";
import Reviews from "../Reviews/Reviews";
import { UserProfile } from "../UserProfile";
import Wishlist from "../Wishlist/Wishlist";

const routes = [
  { path: "/", element: <UserProfile /> },
  { path: "/profile/", element: <UserProfile /> },
  { path: "/wishlist/", element: <Wishlist /> },
  { path: "/bookings/", element: <Bookings /> },
  { path: "/reviews/", element: <Reviews /> },
  { path: "/bookings/:id", element: <BookingDetails /> },
  { path: "/change-password/", element: <ChangePassword /> },
];

export default routes;
