import React, { useEffect, useState, useRef } from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Modal, Table, Tag } from "antd";
import { notifyError } from "../../../../App";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { getDateTime } from "../../../../utils/dateTime/dateAndTime";
import { axiosErrorHandler } from "../../../../utils/errorHandler/axiosErrorHandler";
import CustomPagination from "../../../components/Pagination";
import { SearchBox } from "../../../components/SearchBox";
import styles from "./BookingsTable.module.css";

const iconChooser = (tags) => {
  switch (tags) {
    case "assigned":
      return <SyncOutlined spin />;

    case 0:
      return <CloseCircleOutlined />;

    case 1:
      return <CheckCircleOutlined />;

    default:
      return <CheckCircleOutlined />;
  }
};

const tagChooser = (tags) => {
  switch (tags) {
    case 1:
      return "green";

    case 0:
      return "red";

    default:
      return "yellow";
  }
};

function BookingsTable({ fetchData, status, handleCheckBox }) {
  const { width } = useWindowDimensions();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const totalProducts = useRef(1);
  const [loading, setLoading] = useState(false);
  const pageSize = useRef(1);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [filterDate, setFilterDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [search, setSearch] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (booking) => {
    setIsModalOpen(true);
    setSelectedBooking(booking);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Unique Id",
      dataIndex: "unique_id",
      key: "unique_id",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "Title",
      dataIndex: "yoga_program_title",
      key: "yoga_program_title",
      width: 700,
      render: (obj, item) => <div>{item.yoga_program.title}</div>,
    },
    {
      title: "Start Date",
      dataIndex: "date",
      key: "date",
      width: 300,
      render: (obj, item) => <div>{getDateTime(item.date).date}</div>,
    },
    {
      title: "Booked Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 300,
      render: (obj, item) => <div>{getDateTime(item.created_at).date}</div>,
    },
    {
      title: "Attended",
      dataIndex: "attended",
      key: "attended",
      width: 300,
      render: (_, item) => (
        <>
          <Checkbox
            onChange={(e) => handleCheckBox(e, item, setData)}
            checked={item.attended}
          />
        </>
      ),
    },
    {
      title: "User Details",
      dataIndex: "user",
      key: "user",
      width: 150,
      render: (obj, item) => (
        <Button onClick={() => showModal(item)}>View</Button>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      width: 300,
      render: (obj, item) => (
        <Tag
          key={item.id}
          color={tagChooser(item.payment_status)}
          icon={iconChooser(item.payment_status)}
          style={{ textTransform: "capitalize" }}
        >
          {item.payment_status === 0 ? "Not-Paid" : "Paid"}
        </Tag>
      ),
    },
  ];

  const { RangePicker } = DatePicker;

  useEffect(() => {
    const abortCont = new AbortController();
    setLoading(true);
    fetchData(
      page,
      abortCont.signal,
      search,
      status,
      filterDate.start_date,
      filterDate.end_date
    )
      .then((res) => {
        totalProducts.current = res.data.total;
        pageSize.current = res.data.page_size;
        setData(res.data.bookings);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      abortCont.abort();
    };
  }, [page, search, filterDate]);

  return (
    <div>
      <div className={styles.headerContainer}>
        <div>
          <SearchBox setSearch={setSearch} placeholder="Search Bookings" />
        </div>
        <div>
          <RangePicker
            onChange={(date, dateString) => {
              setFilterDate({
                start_date: dateString[0],
                end_date: dateString[1],
              });
            }}
          />
        </div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        scroll={width < 1200 ? { x: 1200 } : null}
        dataSource={data.map((booking, index) => ({
          ...booking,
          sn: (page - 1) * pageSize.current + index + 1,
          key: index,
        }))}
        style={{ cursor: "pointer" }}
        pagination={false}
        loading={loading}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        total={totalProducts.current}
        pageSize={pageSize.current}
      />
      <Modal
        title="User Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedBooking && (
          <main className={styles.userContainer}>
            <div className={styles.userTable}>
              <p>Name: </p>
              <p>{`${selectedBooking.user.f_name} ${selectedBooking.user.l_name}`}</p>
              <p>Email: </p>
              <p>{`${selectedBooking.email} `}</p>
              {selectedBooking.phone && <p>Phone: </p>}
              {selectedBooking.phone && <p>{`${selectedBooking.phone} `}</p>}
            </div>
          </main>
        )}
      </Modal>
    </div>
  );
}

export default BookingsTable;
