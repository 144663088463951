import React from "react";
import { Hero } from "../../components/Hero";
import styles from "./AboutUs.module.css";

const missionData = [
  {
    icon: "/images/about/healing.png",
    subtitle: "To re-establish Nepal as the spiritual hub of the world.",
  },
  {
    icon: "/images/about/trek.png",
    subtitle:
      "To foster a true transformation in every individual who visits our enchanting country.",
  },
  {
    icon: "/images/about/retreat.png",
    subtitle:
      "To provide an opportunity for the world to experience the real, mystical Nepal.",
  },
];

function AboutUs() {
  return (
    <>
      <Hero title="About YogSewa" />
      <div className={styles.container}>
        <div className="container">
          <div className={styles.introContainer}>
            <div className={styles.introContent}>
              <div className={styles.philosophyContainer}>
                <div className={styles.title}>Our Philosophy</div>
                <div className={styles.details}>
                  Yoga, in its truest form, is more than a series of physical
                  exercises; it is a holistic philosophy that reaches beyond the
                  physical body and touches the essence of existence. In today's
                  world, the meaning of 'Yoga' often gets misinterpreted,
                  leading many to overlook its true essence - the unity with
                  nature and the universe. It is a deep-rooted philosophy that
                  aims to foster a connection with our fundamental nature of
                  being. Despite the common misrepresentations, the ultimate
                  purpose of yoga remains to unite us with the universe and all
                  that exists within it.
                </div>
              </div>
              <div className={styles.visionContainer}>
                <div className={styles.title}>Our Vision</div>
                <div className={styles.details}>
                  YogSewa envisions a world where individuals prioritize the
                  realization of inner peace and the discovery of their true
                  purpose before delving into external pursuits. With a variety
                  of carefully curated retreat plans, we aim to guide everyone
                  on their journey towards spiritual enlightenment.
                </div>
              </div>
            </div>
            <div className={styles.introImageContainer}>
              <img
                src="/images/about/intro.png"
                alt="Intro Image"
                loading="lazy"
                className={styles.introImage}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className="container">
          <div className={styles.missionContainer}>
            <div className={styles.title}>Our Mission</div>
            <div className={styles.missionGridContainer}>
              {missionData.map((mission) => (
                <div key={mission.subtitle} className={styles.missionCard}>
                  <img
                    src={mission.icon}
                    alt={mission.subtitle}
                    loading="lazy"
                  />
                  <div className={styles.subtitle}>{mission.subtitle}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className="container">
          <div className={styles.nepalContainer}>
            <div className={styles.nepalImageContainer}>
              <img
                src="/images/about/nepal.png"
                alt="Nepal"
                loading="lazy"
                className={styles.nepalImage}
              />
            </div>
            <div className={styles.nepalContent}>
              <div className={styles.title}>Why Nepal?</div>
              <ul className={styles.nepalList}>
                <li className={styles.nepalListItems}>
                  One of the oldest countries in South Asia, Nepal is the
                  birthplace of Gautam Buddha, and has been home to numerous
                  sages and enlightened beings throughout history.
                </li>
                <li className={styles.nepalListItems}>
                  The origin point of yoga and yogic culture, serving as a
                  vessel for various religious and spiritual activities over the
                  ages.
                </li>
                <li className={styles.nepalListItems}>
                  Breathtaking landscapes that naturally lend themselves to
                  self-connection and introspection.
                </li>
                <li className={styles.nepalListItems}>
                  The valleys and peaks symbolize the valleys and peaks of
                  consciousness, providing a natural setting for spiritual
                  growth.
                </li>
                <li className={styles.nepalListItems}>
                  Distanced from the hustle and bustle of chaotic cities, Nepal
                  offers a conducive environment for deepening one's spiritual
                  understanding through yoga, meditation, and more.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.yogsewaWrapper}>
        <div className={styles.container}>
          <div className="container">
            <div className={styles.yogsewaContainer}>
              <div className={styles.yogsewaContent}>
                <div className={styles.title}>Why Yogsewa?</div>
                <ul className={styles.yogsewaList}>
                  <li className={styles.yogsewaListItems}>
                    We ensure you have comfortable and enriching yoga retreats
                    in Nepal.
                  </li>
                  <li className={styles.yogsewaListItems}>
                    Our highly-skilled yoga instructors are committed to
                    providing a once-in-a-lifetime yoga experience.
                  </li>
                  <li className={styles.yogsewaListItems}>
                    We guide you to serene and mindfully selected locations with
                    beautiful surroundings for your yoga retreats.
                  </li>
                  <li className={styles.yogsewaListItems}>
                    YogSewa operates as a cohesive unit to ensure smooth
                    operations in a cozy, familial atmosphere.
                  </li>
                </ul>
              </div>{" "}
              <div className={styles.nepalImageContainer}>
                <img
                  src="/images/about/logo.png"
                  alt="Nepal"
                  loading="lazy"
                  className={styles.yogsewaImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
