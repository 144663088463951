import React, { useEffect, useState } from "react";
import styles from "./Blogs.module.css";
import { getAllBlogs } from "../../../services/api/blogs";
import { axiosErrorHandler } from "../../../utils/errorHandler/axiosErrorHandler";
import { notifyError } from "../../../App";
import BlogSkeleton from "../../../components/BlogCard/BlogSkeleton";
import { BlogCard } from "../../../components/BlogCard";
import cx from "classnames";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState();

  const fetchBlogs = async (signal) => {
    setLoading(true);
    await getAllBlogs(1, "", signal)
      .then((res) => {
        if (res.status === "Success") {
          setBlogs(res.data.blogs);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "canceled") {
        } else {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    const abortCont = new AbortController();
    fetchBlogs(abortCont.signal);
    return () => {
      abortCont.abort();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img
          className={styles.bgImgTop}
          src="/images/background/mandala_1.png"
          alt="bgImg"
          loading="lazy"
        />
        <img
          className={styles.bgImgBottom}
          src="/images/background/bg4.png"
          alt="bgImg"
          loading="lazy"
        />
        <div className="container">
          <div className={cx("section-title", styles.title)}>Blogs</div>
          <div className={styles.cardContainer}>
            {loading ? (
              <>
                <BlogSkeleton />
                <BlogSkeleton />
                <BlogSkeleton />
              </>
            ) : blogs.length < 1 ? (
              "No blogs to show"
            ) : (
              blogs
                .slice(0, 3)
                .map((blog, index) => <BlogCard key={index} item={blog} />)
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
