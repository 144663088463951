import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Modal, Table, Tag } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import Loading from "../../../components/Loading";
import CustomPagination from "../../../components/Pagination";
import styles from "./ProgramList.module.css";

function ProgramTable({
  data,
  loading,
  page,
  setPage,
  total,
  pageSize,
  onEditClick,
  onBookingClick,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const onClick = (key, id, item, slug) => {
    if (key === "edit") {
      onEditClick(id, item);
    } else if (key === "booking") {
      onBookingClick(id, item, slug);
    } else if (key === "message") {
      showModal();
    } else if (key === "preview") {
      navigate(`/programs/${slug}`);
    }
  };

  const iconChooser = (tags) => {
    switch (tags) {
      case 0:
        return <SyncOutlined spin />;
      case 1:
        return <CheckCircleOutlined />;
      case 2:
        return <CloseCircleOutlined />;
      default:
        return <CheckCircleOutlined />;
    }
  };

  const tagChooser = (tags) => {
    switch (tags) {
      case 0:
        return "yellow";
      case 1:
        return "green";
      case 2:
        return "red";
      default:
        return "green";
    }
  };

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 500,
      render: (obj, item) => (
        <div className={styles.description}>{item.title}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "verification_status",
      key: "status",
      width: 200,
      render: (obj, item) => (
        <Tag
          key={item.id}
          color={tagChooser(item.verification_status)}
          icon={iconChooser(item.verification_status)}
          style={{ textTransform: "capitalize" }}
        >
          {item.verification_status === 0
            ? "Pending"
            : item.verification_status === 1
            ? "Verified"
            : "Not-Verified"}
        </Tag>
      ),
    },

    {
      title: "Action",
      key: "action",
      width: 200,
      render: (text, item) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => onClick(e.key, item.id, item, item.slug)}
              items={[
                {
                  key: "edit",
                  label: "Edit",
                },

                {
                  key: "booking",
                  label: "Bookings",
                },
                {
                  key: "preview",
                  label: "Preview",
                },
                item.rejection_message && {
                  key: "message",
                  label: "Rejection Message",
                },
              ]}
            />
          }
          trigger="click"
          arrow
          text={text}
        >
          <a onClick={(e) => e.preventDefault()}>
            <EllipsisOutlined className={styles.actionIcon} />
          </a>
        </Dropdown>
      ),
    },
  ];

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Table
        className={styles.table}
        loading={loading}
        columns={columns}
        scroll={width < 1200 ? { x: 1200 } : null}
        dataSource={data.map((operator, index) => {
          return {
            ...operator,
            sn: (page - 1) * pageSize + index + 1,
            key: index,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        total={total}
        pageSize={pageSize}
      />
      <Modal
        title="Rejection Message"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {data.map((data, index) => (
          <div>{data?.rejection_message && data.rejection_message}</div>
        ))}
      </Modal>
    </div>
  );
}

export default ProgramTable;
