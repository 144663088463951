import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, notification, Radio, Row, Space } from "antd";
import React, { useState } from "react";
import styles from "./Questionnaire.module.css";

const QuestionCheckBox = ({ label, options, onChange, qNo, questionIndex }) => {
  return (
    <Row>
      <Form.Item label={label}>
        <Checkbox.Group
          className={styles.checkbox}
          onChange={(value) => onChange(qNo, value, questionIndex)}
          options={options}
        />
      </Form.Item>
    </Row>
  );
};
const QuestionRadio = ({ label, options, onChange, qNo, questionIndex }) => {
  return (
    <Row>
      <Form.Item label={label}>
        <Radio.Group onChange={(e) => onChange(qNo, e.target.value, questionIndex)}>
          <Space direction={"vertical"}>
            {options.map((item, index) => (
              <Radio value={item.value} key={index}>
                {" "}
                {item.title}{" "}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </Row>
  );
};

function Questionnaire() {
  const [api, contextHolder] = notification.useNotification();
  const [currentQuestion, setCurrentQuestion] = useState(1);

  const [data, setData] = useState({
    question1: "",
    question2: [],
    question3: [],
    question4: "",
    question5: [],
    question6: [],
    question7: [],
    question8: "",
    question9: "",
    email: "",
  });

  const question = [
    {
      question: QuestionRadio,
      label: "1. When did first hear about yoga?",
      options: [
        { value: "childhood", title: "During Childhood" },
        { value: "adolescene", title: "During Adolescene" },
        { value: "40s", title: "In my 40's" },
        { value: "50s", title: "In my 50's" },
        { value: "later", title: "Later" },
      ],
      qNo: "question1",
      questionIndex: 1,
    },
    {
      question: QuestionCheckBox,
      label: "2. According to you what is yoga for?",
      options: [
        {
          label: "For physical fitness",
          value: "physical",
        },
        {
          label: "For mental wellbeing",
          value: "mental",
        },
        {
          label: "For becoming spiritual",
          value: "spiritual",
        },
        {
          label: "To become intune with existence",
          value: "existence",
        },
      ],
      qNo: "question2",
      questionIndex: 2,
    },
    {
      question: QuestionCheckBox,
      label: `3. What is the connection between the terms "Yoga", "Meditation", "Spirituality"?`,
      options: [
        {
          label: "For physical fitness",
          value: "physical",
        },
        {
          label: "For mental wellbeing",
          value: "mental",
        },
        {
          label: "For becoming spiritual",
          value: "spiritual",
        },
        {
          label: "To become intune with existence",
          value: "existence",
        },
      ],
      qNo: "question3",
      questionIndex: 3,
    },
    {
      question: QuestionRadio,
      label: `4. Which stage of personal/spiritual growth would you consider yourself at now?`,
      options: [
        { value: "beginner", title: "Beginner" },
        { value: "intermediate", title: "Intermediate" },
        { value: "advance", title: "Advance" },
      ],
      qNo: "question4",
      questionIndex: 4,
    },
    {
      question: QuestionCheckBox,
      label: `5. What forms of yoga/meditation are you familiar with?`,
      options: [
        {
          label: "Kriya Yoga",
          value: "kriya",
        },
        {
          label: "Hatha Yoga",
          value: "hatha",
        },
        {
          label: "Vinyasa",
          value: "vinyasa",
        },
        {
          label: "Pilates",
          value: "pilates",
        },
        {
          label: "Pranayam",
          value: "pranayam",
        },
        {
          label: "Asana",
          value: "asana",
        },
        {
          label: "Mantra Chanting",
          value: "mantra",
        },
        {
          label: "Transcendental Meditation",
          value: "transcendental",
        },
      ],
      qNo: "question5",
      questionIndex: 5,
    },
    {
      question: QuestionCheckBox,
      label: `6. Select all the famous names of Yoga & Spiritual teachers/Philoshophers you have heard of?`,
      options: [
        {
          label: "Swami Vivekananda",
          value: "vivekananda",
        },
        {
          label: "Paramhansa Yogananda",
          value: "yogananda",
        },
        {
          label: "Maharshi Mahesh Yogi",
          value: "mahesh",
        },
        {
          label: "Baba Ramdev",
          value: "ramdev",
        },
        {
          label: "Sadhguru",
          value: "sadhguru",
        },
        {
          label: "Sri Sri Ravishankar",
          value: "ravishankar",
        },
        {
          label: "J. Krishnamurthy",
          value: "krishnamurthy",
        },
      ],
      qNo: "question6",
      questionIndex: 6,
    },
    {
      question: QuestionCheckBox,
      label: `7. What do expect to achieve with yoga?`,
      options: [
        {
          label: "Physical Fitness",
          value: "physical",
        },
        {
          label: "Weight Loss",
          value: "loss",
        },
        {
          label: "Tackle lifestyle induced health issues",
          value: "health",
        },
        {
          label: "Mental Peace",
          value: "mental",
        },
        {
          label: "Become Spiritual",
          value: "spiritual",
        },
        {
          label: "Become Peaceful",
          value: "peace",
        },
        {
          label: "Improve concentration Power",
          value: "concerntrtaion",
        },
      ],
      qNo: "question7",
      questionIndex: 7,
    },
    {
      question: QuestionRadio,
      label: `8. What do you think is the ideal age for doing yoga?`,
      options: [
        { value: "childhood", title: "During Childhood " },
        { value: "adolescene", title: "During Adolescene" },
        { value: "40s", title: "In my 40's" },
        { value: "50s", title: "In my 50's" },
      ],
      qNo: "question8",
      questionIndex: 8,
    },
    {
      question: QuestionRadio,
      label: `9. How much of lifestyle change do you think yoga will require?`,
      options: [
        { value: "high", title: "High" },
        { value: "medium", title: "Medium" },
        { value: "low", title: "Low" },
      ],
      qNo: "question9",
      questionIndex: 9,
    },
  ];

  const handleChange = (qNo, value, questionIndex) => {
    setData({ ...data, [qNo]: value });
    if (questionIndex >= currentQuestion) {
      setCurrentQuestion(questionIndex + 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let err = {};
    if (data.question1.length === 0) {
      err["question1"] = ["Answer is required"];
    }
    if (data.question2.length === 0) {
      err["question2"] = ["Answer is required"];
    }
    if (data.question3.length === 0) {
      err["question3"] = ["Answer is required"];
    }
    if (data.question4.length === 0) {
      err["question4"] = ["Answer is required"];
    }
    if (data.question5.length === 0) {
      err["question5"] = ["Answer is required"];
    }
    if (data.question6.length === 0) {
      err["question6"] = ["Answer is required"];
    }
    if (data.question7.length === 0) {
      err["question7"] = ["Answer is required"];
    }
    if (data.question8.length === 0) {
      err["question8"] = ["Answer is required"];
    }
    if (data.question9.length === 0) {
      err["question9"] = ["Answer is required"];
    }
    if (data.email.length === 0) {
      err["email"] = ["Email is required"];
    }
    if (Object.keys(err).length > 0) {
      openNotification();
      return;
    }
  };

  const openNotification = () => {
    api.open({
      description: "Please fill out all of the questions",
      icon: (
        <ExclamationCircleOutlined
          style={{
            color: "#FF4D4F",
          }}
        />
      ),
    });
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className="container">
        <div className={styles.introSection}>
          <div>
            <div className={styles.introSubtitle}>Yogsewa Questionnaire</div>
            <div className={styles.introTitle}>An introduction to Yoga</div>
            <div className={styles.contentDivider}></div>
          </div>

          <div className={styles.introDetails}>
            Yoga is not just about doing some sets of physical activities, it embodies a deeper
            philoshopy that reaches so far as to touch the very core of creation & existence.
            <br />
            <br /> The rumors and disorientation surrounding "YOGA" has led people to disregard its
            true meaning and purpose i.e " Uniting with Existence/Nature/or Creation.
          </div>
        </div>
        <div className={styles.infoSection}>
          <div className={styles.infoTitle}>
            LETS FILL OUT A SMALL QUESTIONNAIRE TO BETTER KNOW OUR UNDERSTANDING OF "YOGA"
          </div>
          <div className={styles.contentDivider}></div>

          <div className={styles.infoDetails}>
            Your efforts at answering these question will be rewarded with our beautifully written
            E-book that will quickly help you clear your doubts and misconceptions about the true
            purpose of the PATH OF YOGA and the DIFFERENT ways of navigating that path. You can
            choose two or more options depending on the nature of the question.{" "}
          </div>
        </div>
        <div className={styles.quizSection} id="questionnaires">
          <div className={styles.quizTitle}>LET'S BEGIN</div>
          <div className={styles.contentDivider}></div>
          <Form
            className={styles.formContainer}
            name="basic"
            autoComplete="off"
            layout="vertical"
            onSubmit={(e) => e.preventDefault()}
            id="questionnaire"
          >
            {question.map(
              (question, index) =>
                index < currentQuestion && (
                  <question.question
                    key={index}
                    label={question.label}
                    options={question.options}
                    qNo={question.qNo}
                    questionIndex={question.questionIndex}
                    onChange={handleChange}
                  />
                )
            )}
            {currentQuestion === 10 && (
              <div className={styles.footer}>
                <div className={styles.footerSubtitle}>Yogsewa Questionnaire</div>
                <div className={styles.footerTitle}>Ready to receive?</div>
                <div className={styles.btnIn}>
                  <Input
                    type="email"
                    placeholder="Please enter your email"
                    className={styles.input}
                    onChange={(e) => {
                      setData({ ...data, email: e.target.value });
                    }}
                  />
                  <Button
                    className={styles.button}
                    type="primary"
                    size="large"
                    onClick={handleSubmit}
                  >
                    Take me to my Results
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Questionnaire;
