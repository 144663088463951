import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PackageCard } from "../../../components/PackageCard";
import { getPublicPrograms } from "../../../services/api/programs";
import styles from "./Packages.module.css";
import PackageSkeleton from "../../../components/PackageCard/PackageSkeleton";

function Packages() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    let formData = {
      location: { id: null, name: "Anywhere" },
      category: { id: null, title: "All" },
      date: { type: "nodate", text: "No set dates" },
    };

    navigate("/search");
  };

  useEffect(() => {
    const abortCont = new AbortController();
    setLoading(true);
    getPublicPrograms(1, abortCont.signal)
      .then((res) => {
        setData(res.data.yoga_programs.slice(0, 3));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    return () => {
      abortCont.abort();
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img
          className={styles.bgImg}
          src="/images/background/mandala.png"
          loading="lazy"
          alt="mandala"
        />
        <div className="container">
          <div className={styles.headerContainer}>
            <h3 className={cx(styles.title, "section-title")}>Popular Packages</h3>
            <p className={cx(styles.subtitle, "section-subtitle")}>
              Here are some of the best Yoga retreat locations and destinations around the world!
            </p>
          </div>
          <div className={styles.cardContainer}>
            {loading ? (
              <>
                <PackageSkeleton /> <PackageSkeleton /> <PackageSkeleton />
                <PackageSkeleton />
              </>
            ) : (
              data.map((item, index) => <PackageCard item={item} key={index} />)
            )}
          </div>
          <button className={cx("light-btn", styles.discoverBtn)} onClick={handleSubmit}>
            Discover More{" "}
            <div className={styles.icon}>
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Packages;
