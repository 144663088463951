import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Switch, Table } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { updateOperatorById } from "../../../../services/api/users";
import { confirmDelete } from "../../../../utils/confirmDelete/confirmDelete";
import Loading from "../../../components/Loading";
import CustomPagination from "../../../components/Pagination";
import styles from "./OperatorTable.module.css";

function OperatorTable({
  data,
  loading,
  page,
  setPage,
  total,
  pageSize,
  statuses,
  setStatuses,
  setRefresh,
}) {
  const navigate = useNavigate();

  const { width } = useWindowDimensions();
  const onClick = (key, id) => {
    if (key === "edit") {
      navigate(`/partner/operators/edit/${id}`);
    }
    // else if (key === "delete") {
    //   confirmDelete(
    //     () => handleDelete(id),
    //     () => {}
    //   );
    // }
  };

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "First Name",
      dataIndex: "f_name",
      key: "f_name",
      width: 300,
    },
    {
      title: "Last Name",
      dataIndex: "l_name",
      key: "l_name",
      width: 300,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 300,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 300,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (obj, item, index) => (
        <Space direction="vertical">
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked={item.status === 1} // Change this condition
            onChange={(checked) => {
              const newStatus = checked ? 0 : 1;
              const newStatuses = [...statuses];
              newStatuses[index] = newStatus;
              setStatuses(newStatuses);
              updateOperatorById(item.id).then((res) =>
                setRefresh((prev) => !prev)
              );
            }}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 300,
      render: (text, item) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => onClick(e.key, item.id)}
              items={[
                {
                  key: "edit",
                  label: "Edit",
                },
              ]}
            />
          }
          trigger="click"
          arrow
          text={text}
        >
          <a onClick={(e) => e.preventDefault()}>
            <EllipsisOutlined className={styles.actionIcon} />
          </a>
        </Dropdown>
      ),
    },
  ];
  return loading ? (
    <Loading />
  ) : (
    <div>
      <Table
        className={styles.table}
        loading={loading}
        columns={columns}
        scroll={width < 1200 ? { x: 1200 } : null}
        dataSource={data.map((operator, index) => {
          return {
            ...operator,
            sn: (page - 1) * pageSize + index + 1,
            key: index,
          };
        })}
        style={{ cursor: "pointer" }}
        pagination={false}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        total={total}
        pageSize={pageSize}
      />
    </div>
  );
}

export default OperatorTable;
