import cx from "classnames";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getAllCategories } from "../../../services/api/programs";
import styles from "./Categories.module.css";
import { useNavigate } from "react-router-dom";

function Categories() {
  var settings = {
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    dots: true,
    dotsClass: "button__bar",
    // centerMode: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          //   centerMode: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          //   centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          //   centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          //   centerMode: true,
        },
      },
    ],
  };
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const handleClick = (item) => {
    navigate(`/search?category=${item.slug}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    let abortCont = new AbortController();
    getAllCategories(abortCont.signal)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .finally(() => {});
    return () => {
      abortCont.abort();
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img
          className={styles.bgImgTop}
          src="/images/background/mandala_1.png"
          loading="lazy"
          alt="mandala"
        />
        {/* <img className={styles.bgImgBottom} src="/images/background/bg4.png" /> */}
        <div className="container">
          <div className={styles.header}>
            <div className={cx(styles.subtitle, "section-subtitle")}>
              Explore
            </div>
            <div className={"section-title"}>All Cateogories</div>
          </div>

          <div>
            {data.length > 0 && (
              <Slider {...settings}>
                {data.map((item, index) => (
                  <div key={index} onClick={() => handleClick(item)}>
                    <div className={styles.slickWrapper}>
                      <div className={styles.card}>
                        <div className={styles.overlay}></div>
                        <img
                          alt="yoga"
                          className={cx(styles.image, "w-100")}
                          src={process.env.REACT_APP_SERVER_URL + item.photo}
                          loading="lazy"
                        />
                        <div className={styles.infoContainer}>
                          <div className={styles.infoTitle}>{item.title}</div>
                          <div
                            className={styles.infoSubtitle}
                            dangerouslySetInnerHTML={{
                              __html: item.overview,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categories;
