import { AppstoreOutlined } from "@ant-design/icons";
import cx from "classnames";
import { addDays } from "date-fns";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getAllDestinations } from "../../../services/api/country";
import { getAllCategories } from "../../../services/api/programs";
import styles from "./Search.module.css";
import { dateFormat } from "../../../utils/dateTime/dateAndTime";

const months = [
  { month: "January", id: 1 },
  { month: "February", id: 2 },
  { month: "March", id: 3 },
  { month: "April", id: 4 },
  { month: "May", id: 5 },
  { month: "June", id: 6 },
  { month: "July", id: 7 },
  { month: "August", id: 8 },
  { month: "September", id: 9 },
  { month: "October", id: 10 },
  { month: "November", id: 11 },
  { month: "December", id: 12 },
];

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <div ref={ref} onClick={onClick} className={styles.dateContainer}>
    <div className={styles.dateLabel}>When are you going?</div>
    <div className={styles.dateValueContainer}>
      {<AiOutlineCalendar className={styles.dateIcon} />}
      <div className={styles.dateValue}>{value || "Date"}</div>
    </div>
  </div>
));

const Search = () => {
  const dropdownRef = useRef(null);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState();
  const [month, setMonth] = useState("");
  const navigate = useNavigate();
  const [location, setLocation] = useState();
  const [category, setCategory] = useState();
  const [date, setDate] = useState({ type: "nodate", text: "No set dates" });
  const [selected, setSelected] = useState("");
  const [dateSelected, setDateSelected] = useState("nodate");
  const [openRangePicker, setOpenRangePicker] = useState(false);
  const [currentRangeSelection, setCurrentRangeSelection] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [slider, setSlider] = useState("");
  const { width } = useWindowDimensions();
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const handleSubmitSmallScreen = ({
    date,
    dateRange = null,
    selectedYear = null,
    month = null,
  }) => {
    let formData = {};
    if (location) {
      formData.location = location.slug;
    }
    if (category) {
      formData.category = category.slug;
    }

    if (date.type === "specific") {
      formData.start_date = dateFormat(dateRange[0]?.startDate);
      formData.end_date = dateFormat(dateRange[0]?.endDate);
      formData.date_type = "specific";
    } else if (date.type === "flexible") {
      var lastDay = new Date(selectedYear, month.id, 0).getDate();
      formData["start_date"] = `${selectedYear}-${month.id}-1`;
      formData["end_date"] = `${selectedYear}-${month.id}-${lastDay}`;
      formData.date_type = "flexible";
    }

    formData.page_number = 1;
    let url = Object.keys(formData).length > 0 ? "/search?" : "/search";
    Object.keys(formData).forEach((element, index) => {
      if (formData[element]) {
        url += `${element}=${formData[element]}`;
        url += index !== Object.keys(formData).length - 1 ? `&` : "";
      }
    });
    navigate(url);
  };

  const handleSubmit = () => {
    let formData = {};
    if (location) {
      formData.location = location.slug;
    }
    if (category) {
      formData.category = category.slug;
    }
    if (date.type === "specific") {
      formData.start_date = dateFormat(dateRange[0]?.startDate);
      formData.end_date = dateFormat(dateRange[0]?.endDate);
      formData.date_type = "specific";
    } else if (date.type === "flexible") {
      var lastDay = new Date(selectedYear, month.id, 0).getDate();
      formData["start_date"] = `${selectedYear}-${month.id}-1`;
      formData["end_date"] = `${selectedYear}-${month.id}-${lastDay}`;
      formData.date_type = "flexible";
    }
    formData.page_number = 1;
    let url = Object.keys(formData).length > 0 ? "/search?" : "/search";
    Object.keys(formData).forEach((element, index) => {
      if (formData[element]) {
        url += `${element}=${formData[element]}`;
        url += index !== Object.keys(formData).length - 1 ? `&` : "";
      }
    });
    navigate(url);
  };

  const handleDropdownClick = () => {
    const targetElement = document.getElementById(`filterContainer`);
    const topOffset = targetElement.getBoundingClientRect().top + window.scrollY - 20;

    window.scrollTo({
      top: topOffset,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (slider !== "") {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }
  }, [slider]);

  useEffect(() => {
    let abort = new AbortController();
    getAllCategories(abort.signal)
      .then((res) => {
        setCategoryData([{ id: null, title: "All" }, ...res.data]);
      })
      .catch((err) => {});
    getAllDestinations(abort.signal)
      .then((res) => {
        setLocationData([{ id: null, title: "Anywhere" }, ...res.data.destinations]);
      })
      .catch((err) => {});

    return () => {
      abort.abort();
    };
  }, []);

  useEffect(() => {
    if (selected) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }
  }, [selected]);

  return (
    <section>
      {selected && <div className={cx(styles.overlay)} onClick={() => setSelected(null)}></div>}
      <div className="container">
        <div className={cx(styles.container)} id="filterContainer">
          <div className={styles.filterLocationWrapper}>
            <div
              className={styles.filterContent}
              onClick={() => {
                setSelected("location");
                handleDropdownClick();
              }}
            >
              <div className={styles.searchFilterIcon}>
                <ion-icon name="location-outline"></ion-icon>
              </div>
              <div>
                <div className={styles.filterSubtitle}>Location</div>
                <div className={styles.searchFilterTitleContainer}>
                  <div className={styles.searchFilterTitle}>
                    {location ? location.title : "Anywhere"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.filterCategoryWrapper}>
            <div
              className={styles.filterContent}
              onClick={() => {
                setSelected("category");
                handleDropdownClick();
              }}
            >
              <div className={styles.searchFilterIcon}>
                <ion-icon name="grid-outline"></ion-icon>
              </div>
              <div>
                <div className={styles.filterSubtitle}>Category</div>
                <div className={styles.searchFilterTitleContainer}>
                  <div className={styles.searchFilterTitle}>
                    {category ? category.title : "All"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.filterDateWrapper}>
            <div
              className={styles.filterContent}
              onClick={() => {
                setSelected("date");
                handleDropdownClick();
              }}
            >
              <div className={styles.searchFilterIcon}>
                <ion-icon name="calendar-outline"></ion-icon>
              </div>
              <div>
                <div className={styles.filterSubtitle}>When Are You Going?</div>
                <div className={styles.searchFilterTitleContainer}>
                  <div className={styles.searchFilterTitle}>{date.text}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={cx("light-btn", styles.button)} onClick={handleSubmit}>
            Search Yoga
            <div className={styles.icon}>
              <ion-icon name="search-outline"></ion-icon>
            </div>
          </div>
        </div>
        <div
          className={cx(
            styles.dropdown,
            selected === "location" && styles.dropdownActive,
            selected === "location" && styles.locationDropdown
          )}
        >
          {locationData.map((option, index) => (
            <div
              key={index}
              onClick={() => {
                setSelected(null);
                setLocation(option);
              }}
              className={styles.dropdownOptions}
            >
              {option.title}
            </div>
          ))}
        </div>
        <div
          className={cx(
            styles.dropdown,
            selected === "category" && styles.dropdownActive,
            selected === "category" && styles.categoryDropdown
          )}
        >
          {categoryData.map((option, index) => (
            <div
              key={index}
              onClick={() => {
                setSelected(null);
                setCategory(option);
              }}
              className={styles.dropdownOptions}
            >
              {option.title}
            </div>
          ))}
        </div>
        <div
          className={cx(
            styles.dropdown,
            selected === "date" && styles.dropdownActive,
            selected === "date" && styles.dateDropdown
          )}
        >
          <div className={styles.dateTab}>
            <div
              className={cx(styles.specificDate, dateSelected === "specific" && styles.dateActive)}
              onClick={() => {
                setDateSelected("specific");
                setCurrentRangeSelection("start");
              }}
            >
              Specific dates
            </div>
            <div
              className={cx(styles.flexibleDate, dateSelected === "flexible" && styles.dateActive)}
              onClick={() => {
                setDateSelected("flexible");
                setOpenRangePicker(true);
              }}
            >
              Flexible dates
            </div>
            <div
              className={cx(styles.nosetDate, dateSelected === "nodate" && styles.dateActive)}
              onClick={() => {
                setDateSelected("nodate");
                setDate({ type: "nodate", text: "No set dates" });
                setSelected(null);
              }}
            >
              No set dates
            </div>
          </div>

          <div className={styles.dropdownDate}>
            {dateSelected === "specific" && (
              <div>
                <DateRangePicker
                  onChange={(item) => {
                    setDateRange([item.selection]);
                    if (currentRangeSelection === "start") {
                      setCurrentRangeSelection("end");
                    } else if (currentRangeSelection === "end") {
                      setSelected(null);
                      setCurrentRangeSelection("start");
                      setDate({
                        type: "specific",
                        text: `${item.selection.startDate?.toLocaleDateString(undefined, {
                          month: "short",
                          day: "numeric",
                        })} - ${item.selection.endDate?.toLocaleDateString(undefined, {
                          month: "short",
                          day: "numeric",
                        })}`,
                      });
                    }
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  minDate={new Date()}
                  ranges={dateRange}
                  direction="horizontal"
                  // staticRanges={[]}
                  // inputRanges={[]}
                />
              </div>
            )}
            {dateSelected === "nodate" && (
              <div>No dates selected, showing retreats for all dates</div>
            )}
            {dateSelected === "flexible" && (
              <div className={styles.flexibleContainer}>
                <div className={styles.flexibleHeader}>
                  <div className={styles.flexibleCardDate}>{currentYear}</div>
                  <div className={styles.flexibleRightArrow}>
                    <MdKeyboardArrowRight onClick={() => setCurrentYear(currentYear + 1)} />
                  </div>
                  {currentYear === new Date().getFullYear() ? (
                    ""
                  ) : (
                    <div className={styles.flexibleLeftArrow}>
                      <MdKeyboardArrowLeft onClick={() => setCurrentYear(currentYear - 1)} />
                    </div>
                  )}
                </div>
                <div className={styles.flexibleContent}>
                  {months.map((month, index) => (
                    <div
                      className={cx(
                        styles.flexibleCard,
                        currentYear === new Date().getFullYear() &&
                          month.id <= new Date().getMonth()
                          ? styles.flexibleCardDisabled
                          : null
                      )}
                      key={index}
                      onClick={() => {
                        if (
                          currentYear === new Date().getFullYear() &&
                          month.id <= new Date().getMonth()
                        ) {
                          return "";
                        } else {
                          setMonth(month);
                          setSelectedYear(currentYear);
                          setDate({
                            type: "flexible",
                            text: `${month.month}, ${currentYear}`,
                          });
                          setSelected("");
                        }
                      }}
                    >
                      {month.month}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.smallFilterContainer}>
          <div className={styles.smallFilterCard}>
            <div
              className={styles.smallLocationContainer}
              onClick={() => {
                setSlider("location");
              }}
            >
              <div className={styles.smallIcon}>
                <ion-icon name="location-outline"></ion-icon>
              </div>
              <div>
                <div className={styles.smallLocationTitle}>Location</div>
                <div className={styles.smallLocationSubtitle}>Where are you going?</div>
              </div>
            </div>
            <div className={styles.smallSearchContainer}>
              <div className={styles.smallIcon}>
                <ion-icon name="search-outline"></ion-icon>
              </div>
            </div>
          </div>
        </div>
        <div className={cx(styles.slider, slider !== "" && styles.sliderActive)}>
          <div className={styles.sliderHeader}>
            <div className={styles.sliderTitleContainer}>
              {slider === "location" ? (
                <IoLocationOutline className={styles.sliderIcon} />
              ) : slider === "category" ? (
                <AppstoreOutlined className={styles.sliderIcon} />
              ) : slider === "date" ? (
                <AiOutlineCalendar className={styles.sliderIcon} />
              ) : null}
              <div className={styles.sliderTitle}>
                {" "}
                {slider === "location"
                  ? "Location"
                  : slider === "category"
                  ? "Category"
                  : slider === "date"
                  ? "Date"
                  : null}
              </div>
            </div>
            <div
              className={styles.sliderBack}
              onClick={() => {
                return slider === "location"
                  ? setSlider("")
                  : slider === "category"
                  ? setSlider("location")
                  : slider === "date"
                  ? setSlider("category")
                  : null;
              }}
            >
              Back
            </div>
          </div>
          {slider === "location" ? (
            <div className={styles.sliderContent}>
              {locationData.map((option, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setLocation(option);
                    setSlider("category");
                  }}
                  className={styles.dropdownOptions}
                >
                  {option.title}
                </div>
              ))}
            </div>
          ) : slider === "category" ? (
            <div className={styles.sliderContent}>
              {categoryData.map((option, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSlider("date");
                    setCategory(option);
                  }}
                  className={styles.dropdownOptions}
                >
                  {option.title}
                </div>
              ))}
            </div>
          ) : slider === "date" ? (
            <div className={styles.sliderContent}>
              <div className={styles.dateTab}>
                <div
                  className={cx(
                    styles.specificDate,
                    dateSelected === "specific" && styles.dateActive
                  )}
                  onClick={() => {
                    setDateSelected("specific");
                    setCurrentRangeSelection("start");
                  }}
                >
                  Specific
                </div>
                <div
                  className={cx(
                    styles.flexibleDate,
                    dateSelected === "flexible" && styles.dateActive
                  )}
                  onClick={() => {
                    setDateSelected("flexible");
                    setOpenRangePicker(true);
                  }}
                >
                  Flexible
                </div>
                <div
                  className={cx(styles.nosetDate, dateSelected === "nodate" && styles.dateActive)}
                  onClick={() => {
                    setDateSelected("nodate");
                    let newD = { type: "nodate", text: "No set dates" };
                    setDate(newD);
                    setSlider("");
                    handleSubmit();
                    handleSubmitSmallScreen({ date: newD });
                  }}
                >
                  No set dates
                </div>
              </div>
              <div className={styles.contents}>
                {" "}
                {dateSelected === "specific" && (
                  <div>
                    <DateRangePicker
                      onChange={(item) => {
                        setDateRange([item.selection]);
                        if (currentRangeSelection === "start") {
                          setCurrentRangeSelection("end");
                        } else if (currentRangeSelection === "end") {
                          setCurrentRangeSelection("start");
                          let newD = {
                            type: "specific",
                            text: `${item.selection.startDate?.toLocaleDateString(undefined, {
                              month: "short",
                              day: "numeric",
                            })} - ${item.selection.endDate?.toLocaleDateString(undefined, {
                              month: "short",
                              day: "numeric",
                            })}`,
                          };
                          setDate(newD);
                          setSlider("");
                          handleSubmitSmallScreen({
                            date: newD,
                            dateRange: [item.selection],
                          });
                        }
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={width < 767 ? 1 : 2}
                      minDate={new Date()}
                      ranges={dateRange}
                      direction="horizontal"
                      // staticRanges={[]}
                      // inputRanges={[]}
                    />
                  </div>
                )}
                {dateSelected === "nodate" && (
                  <div>No dates selected, showing retreats for all dates</div>
                )}
                {dateSelected === "flexible" && (
                  <div className={styles.flexibleContainer}>
                    <div className={styles.flexibleHeader}>
                      <div className={styles.flexibleCardDate}>{currentYear}</div>
                      <div className={styles.flexibleRightArrow}>
                        <MdKeyboardArrowRight onClick={() => setCurrentYear(currentYear + 1)} />
                      </div>
                      {currentYear === new Date().getFullYear() ? (
                        ""
                      ) : (
                        <div className={styles.flexibleLeftArrow}>
                          <MdKeyboardArrowLeft onClick={() => setCurrentYear(currentYear - 1)} />
                        </div>
                      )}
                    </div>
                    <div className={styles.flexibleContent}>
                      {months.map((month, index) => (
                        <div
                          className={cx(
                            styles.flexibleCard,
                            currentYear === new Date().getFullYear() &&
                              month.id <= new Date().getMonth()
                              ? styles.flexibleCardDisabled
                              : null
                          )}
                          key={index}
                          onClick={() => {
                            if (
                              currentYear === new Date().getFullYear() &&
                              month.id <= new Date().getMonth()
                            ) {
                              return "";
                            } else {
                              setMonth(month);
                              setSelectedYear(currentYear);
                              let newD = {
                                type: "flexible",
                                text: `${month.month}, ${currentYear}`,
                              };
                              setDate(newD);
                              setSlider("");
                              handleSubmitSmallScreen({
                                date: newD,
                                selectedYear: currentYear,
                                month: month,
                              });
                            }
                          }}
                        >
                          {month.month}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Search;
