import { Skeleton } from "antd";
import React from "react";
import styles from "./SearchCard.module.css";

function SearchFilterSkeleton() {
  return (
    <div className={styles.card}>
      <figure className={styles.cardBanner}>
        <Skeleton.Image
          className={styles.image}
          style={{ width: "100%", objectFit: "cover", height: "284px" }}
          active={true}
        ></Skeleton.Image>
      </figure>
      <div className={styles.cardContent}>
        <Skeleton />
      </div>
      <div className={styles.cardPrice}>
        <div className={styles.wrapper}>
          <p className={styles.reviews}>
            <Skeleton.Input size="small" />
          </p>
        </div>
        <p className={styles.price}>
          <Skeleton.Input size="small" />
        </p>
        <Skeleton.Button size="small" />
      </div>
    </div>
  );
}

export default SearchFilterSkeleton;
