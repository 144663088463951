import { ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Popover, Row, Skeleton } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../../App";
import {
  getAllCategories,
  getProgramById,
  updateProgram,
} from "../../../../../../services/api/programs";
import { axiosErrorHandler } from "../../../../../../utils/errorHandler/axiosErrorHandler";
import ImageUpload from "../../../../../components/ImageUpload";
import TextEditor from "../../../../../components/TextEditor";
import styles from "./DetailsEdit.module.css";
import cx from "classnames";

function DetailsEdit({ item, id }) {
  const [form] = Form.useForm();

  const [photo, setPhoto] = useState([]);
  const [imageSizeError, setImageSizeError] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({
    title: "",
    summary: "",
    description: "",
    program: "",
    program_morning: "",
    program_afternoon: "",
    program_evening: "",
    feature_style: "",
    feature_skill_level: "",
    feature_food: "",
    benefits: "",
    price: "",
    seat_capacity: "",
    yoga_category_id: "",
  });
  const [photoError, setPhotoError] = useState();
  const [loading, setLoading] = useState(false);
  const [oldPhotos, setOldPhotos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const navigate = useNavigate();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setPhoto(e.fileList);
    }
    return e && e.fileList;
  };

  const handleSubmitFailed = () => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }
  };

  const handleSubmit = () => {
    if (photo.length === 0) {
      setPhotoError("Please upload your photo");
      return;
    }
    var newPhotos = [];
    var deletedPhotoIds = [];

    oldPhotos.forEach((photo) => {
      if (photo.status === "removed") {
        deletedPhotoIds.push(photo.id); // deleted photos id
      }
    });

    photo.forEach((photo) => {
      if (photo.originFileObj) {
        newPhotos.push(photo.originFileObj); // add new photos
      }
    });

    var formData = {
      title: data.title,
      summary: data.summary,
      description: data.description,
      program: data.program,
      program_morning: data.program_morning,
      program_afternoon: data.program_afternoon,
      program_evening: data.program_evening,
      feature_style: data.feature_style,
      feature_skill_level: data.feature_skill_level,
      feature_food: data.feature_food,
      benefits: data.benefits,
      price: data.price,
      seat_capacity: data.seat_capacity,
      photos: newPhotos,
      deletePhotoIds: deletedPhotoIds, // provides deleted photos ids
      yoga_category_id: data.yoga_category_id,
    };

    setLoading(true);
    updateProgram(id, formData)
      .then((res) => {
        if (res.status === "Success") {
          notifySuccess(res.message);
          setLoading(false);
          setRefresh((prev) => !prev);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      Title: "", //for showing intial value after fetching data (name is required from Form.Item)
      Summary: "",
      Description: "", //for select initial value placeholder
      Program: "",
      ProgramMorning: "",
      ProgramAfternoon: "",
      ProgramEvening: "",
      FeatureStyle: "",
      FeatureSkillLevel: "",
      FeatureFood: "",
      Benefits: "",
      Price: "",
      SeatCapacity: "",
      //for showing intial value after fetching data
    });
    setPhoto([]);

    const abortCont = new AbortController();
    getAllCategories(abortCont.signal)
      .then((res) => {
        setCategories(res.data);
        setCategoryLoading(false);
      })
      .catch((err) => {
        setCategoryLoading(false);
      });
    return () => {
      abortCont.abort();
    };
  }, []);

  useEffect(() => {
    const abortCont = new AbortController();

    getProgramById(id, abortCont.signal)
      .then((res) => {
        if (res.status === "Success") {
          form.setFieldsValue({
            Title: res.data.program.title, //for showing intial value after fetching data (name is required from Form.Item)
            Summary: res.data.program.summary,
            Description: res.data.program.description, //for select initial value placeholder
            Program: res.data.program.program,
            MorningProgram: res.data.program.program_morning,
            AfternoonProgram: res.data.program.program_afternoon,
            EveningProgram: res.data.program.program_evening,
            FeatureStyle: res.data.program.feature_style,
            FeatureSkillLevel: res.data.program.feature_skill_level,
            FeatureFood: res.data.program.feature_food,
            Benefits: res.data.program.benefits,
            Price: res.data.program.price,
            SeatCapacity: res.data.program.seat_capacity,
            //for showing intial value after fetching data
          });
          setData(res.data.program);
          if (res.data.program.media) {
            const fileList = res.data.program.media?.map((photo, index) => {
              return {
                url: process.env.REACT_APP_SERVER_URL + photo.link,
                id: photo.id, // to get removed photo id
              };
            });
            setPhoto([...fileList]); // it just removes photo from array
            setOldPhotos([...fileList]); // it contains status(removed)
          }
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
    return () => {
      abortCont.abort();
    };
  }, [id, refresh]);

  useEffect(() => {
    if (imageSizeError) {
      message.error(imageSizeError);
    }
  }, [imageSizeError]);

  return (
    <div>
      <Form
        className={styles.formContainer}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onSubmit={(e) => e.preventDefault()}
        form={form}
        id="program-form"
      >
        {categoryLoading ? (
          <div>
            <Skeleton.Input className={styles.optionTitle} active={true} size="small" />
            <div className={styles.categoryGrid}>
              <div className={styles.imageContainer}>
                <Skeleton.Image
                  className={styles.image}
                  style={{ width: "120px", height: "120px" }}
                  active={true}
                ></Skeleton.Image>
              </div>
              <div className={styles.imageContainer}>
                <Skeleton.Image
                  className={styles.image}
                  style={{ width: "120px", height: "120px" }}
                  active={true}
                ></Skeleton.Image>
              </div>
              <div className={styles.imageContainer}>
                <Skeleton.Image
                  className={styles.image}
                  style={{ width: "120px", height: "120px" }}
                  active={true}
                ></Skeleton.Image>
              </div>
              <div className={styles.imageContainer}>
                <Skeleton.Image
                  className={styles.image}
                  style={{ width: "120px", height: "120px" }}
                  active={true}
                ></Skeleton.Image>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.optionTitle}>
              Select a category
              {/* {errors.category && <span className={styles.fieldError}>{errors.category}</span>} */}
            </div>
            <div className={styles.categoryGrid}>
              {categories.map((item) => (
                <div
                  key={item.id}
                  onClick={() => {
                    setData({ ...data, yoga_category_id: item.id });
                  }}
                >
                  <div
                    className={cx(
                      styles.imageContainer,
                      data?.yoga_category_id === item?.id ? styles.active : null
                    )}
                  >
                    <img
                      src={process.env.REACT_APP_SERVER_URL + item.photo}
                      className={styles.image}
                      alt="yoga"
                    />
                  </div>
                  <div
                    className={cx(
                      styles.categorySubtitle,
                      data?.yoga_category_id === item?.id ? styles.titleActive : null
                    )}
                  >
                    {item.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <Row gutter={[0, 16]}>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Title"
              name="Title"
              rules={[
                {
                  required: true,
                  message: `Please enter program title`,
                },
              ]}
            >
              <Input
                placeholder="Title"
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            xxl={{ span: 12 }}
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              name="dragger"
              listType="picture"
              getValueFromEvent={normFile}
              validateStatus="error"
              help={photo.length > 0 ? "" : photoError}
            >
              <ImageUpload
                fileList={photo}
                setFileList={setPhoto}
                multiple={true}
                setError={(msg) => {
                  setImageSizeError(msg);
                }}
                photoHeight="348"
                photoWidth="625"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Summary"
              name="Summary"
              rules={[{ required: true, message: `Please enter summary` }]}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
  description: value depends on text editor(before setValue={setDescription}) 
*/}

              <TextEditor
                value={data.summary}
                onChange={(value) => setData({ ...data, summary: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Description"
              name="Description"
              rules={[{ required: true, message: "Please enter description" }]}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
  description: value depends on text editor(before setValue={setDescription}) 
*/}

              <TextEditor
                value={data.description}
                onChange={(value) => setData({ ...data, description: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Program Details"
              name="Program"
              rules={[{ required: true, message: `Please enter program details` }]}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
  description: value depends on text editor(before setValue={setDescription}) 
*/}

              <TextEditor
                value={data.program}
                onChange={(value) => setData({ ...data, program: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Morning Program{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img src="/images/program/morning.gif" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo <ExclamationCircleOutlined style={{ color: "#108ee9" }} />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img src="/images/program/morning.jpg" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="MorningProgram"
              rules={[{ required: true, message: `Please enter morning program` }]}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
  description: value depends on text editor(before setValue={setDescription}) 
*/}

              <TextEditor
                value={data.program_morning}
                onChange={(value) => setData({ ...data, program_morning: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Afternoon Program{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img src="/images/program/morning.gif" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo <ExclamationCircleOutlined style={{ color: "#108ee9" }} />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img src="/images/program/morning.jpg" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="AfternoonProgram"
              rules={[{ required: true, message: `Please enter afternoon program` }]}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
  description: value depends on text editor(before setValue={setDescription}) 
*/}

              <TextEditor
                value={data.program_afternoon}
                onChange={(value) => setData({ ...data, program_afternoon: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Evening Program{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img src="/images/program/morning.gif" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo <ExclamationCircleOutlined style={{ color: "#108ee9" }} />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img src="/images/program/morning.jpg" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="EveningProgram"
              rules={[{ required: true, message: `Please enter evening program` }]}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
  description: value depends on text editor(before setValue={setDescription}) 
*/}

              <TextEditor
                value={data.program_evening}
                onChange={(value) => setData({ ...data, program_evening: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Feature Style"
              name="FeatureStyle"
              rules={[{ required: true, message: `Please enter feature style` }]}
            >
              <TextArea
                placeholder="Feature Styles(General Yoga, Hatha Yoga, Restorative Yoga, Vipassana & Yoga)"
                value={data.feature_style}
                rows={3}
                onChange={(e) => {
                  setData({ ...data, feature_style: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
          {item?.feature_food && (
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Feature Food"
                name="FeatureFood"
                rules={[{ required: true, message: `Please enter feature food` }]}
              >
                <TextArea
                  placeholder="Feature Food(Vegetarian, Organic, Low Fat, Diabetic-Friendly, Sugar Consciuos)"
                  value={data.feature_food}
                  rows={3}
                  onChange={(e) => {
                    setData({ ...data, feature_food: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label="Feature Skill Level"
              name="FeatureSkillLevel"
              rules={[{ required: true, message: `Please enter feature skill level` }]}
            >
              <TextArea
                placeholder="Feature Skill Level(General Yoga, Hatha Yoga, Restorative Yoga, Vipassana & Yoga)"
                value={data.feature_skill_level}
                rows={3}
                onChange={(e) => {
                  setData({ ...data, feature_skill_level: e.target.value }); //if state is dictionary, set data like this
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Form.Item
              label={
                <div className={styles.labelContainer}>
                  Benefits{" "}
                  <div className={styles.formPopover}>
                    <Popover
                      title="Way to add items"
                      content={
                        <div>
                          <img src="/images/program/morning.gif" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Demo <ExclamationCircleOutlined style={{ color: "#108ee9" }} />
                      </div>
                    </Popover>
                    <Popover
                      title="Result shown in website"
                      content={
                        <div>
                          <img src="/images/program/morning.jpg" />
                        </div>
                      }
                    >
                      <div className={styles.popoverItem}>
                        Preview <EyeOutlined style={{ color: "#79BF5A" }} />
                      </div>
                    </Popover>
                  </div>
                </div>
              }
              name="Benefits"
              rules={[{ required: true, message: `Please enter benefits` }]}
            >
              {/*onChange is used instead of setValue because form item's children need value and onChange as props
  description: value depends on text editor(before setValue={setDescription}) 
*/}

              <TextEditor
                value={data.benefits}
                onChange={(value) => setData({ ...data, benefits: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {item?.seat_capacity && (
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Form.Item
                label="Seat Capacity"
                name="SeatCapacity"
                rules={[{ required: true, message: `Please enter seat capacity` }]}
              >
                <Input
                  type="number"
                  placeholder="Seat Capacity"
                  value={data.seat_capacity}
                  onChange={(e) => {
                    setData({ ...data, seat_capacity: e.target.value }); //if state is dictionary, set data like this
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button
                type="primary"
                size="large"
                loading={loading}
                disabled={loading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default DetailsEdit;
