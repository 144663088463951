import React, { Suspense, useEffect, useState } from "react";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AdminLayout from "./AdminPanel/Admin/AdminLayout";
import "./App.less";
import UserLayout from "./UserPanel/User/UserLayout";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { UserContext } from "./contexts/UserContext";
import NotFound from "./pages/404/NotFound";
import { AboutUs } from "./pages/AboutUs";
import { Blogs } from "./pages/Blogs";
import BlogCategory from "./pages/Blogs/BlogCategory";
import BlogDetails from "./pages/Blogs/BlogDetails";
import { Bookings } from "./pages/Bookings";
import BookingFailure from "./pages/Bookings/BookingFailure";
import BookingResult from "./pages/Bookings/BookingResult";
import Categories from "./pages/Categories/Categories";
import { Contact } from "./pages/Contact";
import { Customization } from "./pages/Cutomization";
import { Home } from "./pages/Home";
import { JoinUs } from "./pages/JoinUs";
import Login from "./pages/Login/Login";
import { LoginAsPartner } from "./pages/LoginAsPartner";
import { LoginWithoutPassword } from "./pages/LoginWithoutPassword";
import PasswordlessLogin from "./pages/PasswordlessLogin/PasswordlessLogin";
import { ProgramDetails } from "./pages/ProgramDetails";
import { Questionnaire } from "./pages/Questionnaire";
import { ResourceNotFound } from "./pages/ResourceNotFound";
import { SearchListing } from "./pages/SearchListing";
import { UserSignUp } from "./pages/SignUp";
import { SignUpAsPartner } from "./pages/SignUpAsPartner";
import { isLogged } from "./services/api/auth";
import { clearCookie, getCookie, setCookie } from "./utils/cookie/cookies";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";

export const notifySuccess = (message) => toast.success(message);
export const notifyError = (message) => toast.error(message);

const GoToTop = React.lazy(() => import("./components/GoToTop/GoToTop"));

function NonAdmin() {
  return (
    <div>
      <Header />
      {/* <Navbar /> */}
      <Outlet />
      <Footer />
      <Suspense fallback={<>Loading...</>}>
        <GoToTop />
      </Suspense>
    </div>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [userData, setUserData] = useState(getCookie("user") || {});
  useEffect(() => {
    let abort = new AbortController();
    if (getCookie("user")) {
      isLogged(abort.signal)
        .then((res) => {
          if (res.data.logged_in === false) {
            clearCookie("token");
            clearCookie("user");
            setUserData({});
          } else {
            setCookie(res.data.user);
            setUserData(res.data.user);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      abort.abort();
    };
  }, []);
  return (
    <>
      <UserContext.Provider value={{ userData, setUserData }}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<NonAdmin />}>
              <Route path="/" element={<Home />} />
              <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions/"
                element={<TermsAndConditions />}
              />

              <Route path="/category/" element={<Categories />} />
              <Route path="/blogs/" element={<BlogCategory />} />
              <Route path="/blogs/category/:category/" element={<Blogs />} />
              <Route path="/blogs/:id/" element={<BlogDetails />} />
              <Route path="/programs/:id" element={<ProgramDetails />} />
              <Route path="/bookings/:id" element={<Bookings />} />
              <Route path="/bookings/success/:id" element={<BookingResult />} />
              <Route
                path="/bookings/failure/:id"
                element={<BookingFailure />}
              />
              <Route path="/search/" element={<SearchListing />} />
              <Route path="/user/*" element={<UserLayout />} />
              <Route path="/login/" element={<Login />} />
              <Route path="/forgot-password/" element={<ForgotPassword />} />
              <Route path="/careers/" element={<JoinUs />} />
              <Route path="/questionnaire/" element={<Questionnaire />} />
              <Route path="/login-as-partner/" element={<LoginAsPartner />} />
              {/* <Route path="/signup/" element={<SignUpConfig />} /> */}
              <Route path="/signup/user/" element={<UserSignUp />} />
              <Route path="/signup/partner/" element={<SignUpAsPartner />} />
              <Route path="/about/" element={<AboutUs />} />
              <Route
                path="/customized-recommendations/"
                element={<Customization />}
              />
              <Route path="/contact-us/" element={<Contact />} />
              <Route
                path="/resource-not-found/"
                element={<ResourceNotFound />}
              />
              <Route
                path="/login-link/:token/"
                element={<PasswordlessLogin />}
              />

              <Route
                path="/login-without-password/"
                element={<LoginWithoutPassword />}
              />
            </Route>
            <Route path="/partner/*" element={<AdminLayout />} />
            <Route path="/not-found/" element={<NotFound />} />
            <Route path="/*" element={<NotFound />} />
            {/* <Route path="/privacypolicy/" element={<Privacy />} />
        <Route path="/terms/" element={<Terms />} /> */}
          </Routes>
          <Routes></Routes>
        </Router>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </UserContext.Provider>
    </>
  );
}

export default App;
