import React from "react";
import { converter } from "../../../utils/htmlConverter/htmlConverter";
import styles from "./SearchCategory.module.css";
import { Collapse } from "antd";
import cx from "classnames";

const { Panel } = Collapse;

function SearchCategory({ categoryDetails, faq }) {
  const htmlString = faq && faq;
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const title = doc.querySelector("h3")?.textContent; //finds the first h3 in html document and retrieves its text content (<h3>FAQ</h3> --> retrieves FAQ)

  const questions = [];
  const questionElements = doc.querySelectorAll("h3 + p"); // iterates over all paragraphs <p> that follows <h3>

  questionElements.forEach((element) => {
    const question = element.previousElementSibling.textContent;
    const answer = element.textContent;

    questions.push({ question, answer });
  });

  const result = { title, faq: questions };

  return (
    <div className={styles.container} id="search-details">
      <div className="container">
        {categoryDetails && (
          <div
            className={styles.detailsContainer}
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(categoryDetails),
            }}
          />
        )}
        {faq ? (
          <div className={styles.detailsContainer}>
            <div className={cx(styles.sectionTitle, styles.faqTitle)}>
              {result.title}
            </div>
            <Collapse defaultActiveKey={["1"]}>
              {result.faq.map((item) => (
                <Panel
                  header={
                    <div
                      className={cx(styles.sectionSubtitle, styles.faqDetails)}
                    >
                      {item.question}
                    </div>
                  }
                  key={item.question}
                >
                  <div className={styles.sectionDetails}>{item.answer}</div>
                </Panel>
              ))}
            </Collapse>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default SearchCategory;
