import React from "react";
import { converter } from "../../../utils/htmlConverter/htmlConverter";
import styles from "./ProgramLocation.module.css";

function ProgramLocation({ area, location, latitude, longitude }) {
  return (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.locationGrid}>
          <div className={styles.content}>
            <div className={styles.title}>Location</div>
            <div className={styles.degree}>
              {latitude}, {longitude}
            </div>
            <div className={styles.footer}>
              <div className={styles.footerContent}>
                <div className={styles.iconContainer}>
                  <div className={styles.icon}>
                    <ion-icon name="location-outline"></ion-icon>
                  </div>
                  <div className={styles.footerTitle}>Address</div>
                </div>
                <div className={styles.footerSubtitle}>{area}</div>
              </div>
              <div className={styles.footerContent}>
                <div className={styles.iconContainer}>
                  <div className={styles.icon}>
                    <ion-icon name="mail-outline"></ion-icon>
                  </div>
                  <div className={styles.footerTitle}>Support</div>
                </div>
                <div className={styles.footerSubtitle}>support@yogsewa.com</div>
              </div>
            </div>
            <div
              className={styles.subtitle}
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(location),
              }}
            />
          </div>
          <div className={styles.location}>
            <iframe
              title="map"
              className={styles.map}
              src={`https://maps.google.com/maps?q=${latitude},${longitude}&z=11&output=embed`}
              width="600"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullscreen=""
              aria-hidden="false"
              tabIndex="0"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramLocation;
